import React from "react";
import {
    withStyles,
    AppBar,
    Toolbar,
    Typography,
    Button
} from "@material-ui/core";
import { LinearTimelineStyles } from "../../../styles";
import PrimaryVariantButton from "../../../custom/buttons/PrimaryVariantButton";
import { APPORIO_LOGO } from "../../../../values/Icons";
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Paper from '@material-ui/core/Paper';
import StepButton from '@material-ui/core/StepButton';
import * as LanguageManager from '../../../../managers/language/LanguageManager'

// const signupSteps = [
//   {
//     id: 0,
//     label:'Account creation'
//   },
//   {
//     id: 1,
//     label:'Personal documents'
//   },  {
//     id: 2,
//     label:'Vehicle documents'
//   },  {
//     id: 3,
//     label:'Document Verificaion'
//   },
// ];

const signupSteps = [
  {
    id: 0,
    label:LanguageManager.Strings.STEPPER_INDICATOR1
  },
  {
    id: 1,
    label:LanguageManager.Strings.STEPPER_INDICATOR3
  },  
  {
    id: 2,
    label:LanguageManager.Strings.STEPPER_INDICATOR4
  },  
  {
    id: 3,
    label:LanguageManager.Strings.STEPPER_INDICATOR5
  },
];

class LinearTimeline extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showBorder: null,
            activeStep:0,
            setActiveStep:0
        }
    }

    render() {
        const { classes } = this.props
        // const steps = this.getSteps();

        return (
            <div className={classes.root}>
              <Stepper activeStep={this.state.activeStep} orientation="horizontal">
                {signupSteps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel className={classes.labelContainer}></StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Typography className={classes.stageLabel}>{signupSteps[this.state.activeStep].label}</Typography>

            </div>
          );
    }
}

export default withStyles(LinearTimelineStyles)(LinearTimeline);

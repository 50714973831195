import React from "react";
import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Button
} from "@material-ui/core";
import { IntroTextStyles } from "../../../styles";
import PrimaryVariantButton from "../../../custom/buttons/PrimaryVariantButton";
import { APPORIO_LOGO } from "../../../../values/Icons";
import { COMPANY_NAME } from "../../../../values/Typography";

import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import * as LanguageManager from "../../../../managers/language/LanguageManager";

class IntroTextLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBorder: null
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography
          component="h2"
          variant="h1"
          gutterBottom
          align="left"
          className={classes.introTextTitle}
        >
          {/* Drive with {COMPANY_NAME} */}
          {this.props.DRIVER_LIST.header.heading}
        </Typography>
        <Typography
          variant="h5"
          align="left"
          gutterBottom
          className={classes.introTextDetail}
        >
          {/* {LanguageManager.Strings.DRIVER_HOME_INTRO} */}
          {this.props.DRIVER_LIST.header.subHeading}
        </Typography>
        <Grid
          xl={12}
          container
          direction="row"
          justify="flex-end"
          className={classes.buttonContainer}
        >
          <Link
            className="link-text"
            to="/driver/signup"
            location={this.props.location}
          >
            <Button
              variant="contained"
              style={{ backgroundColor: "none" }}
              disableTouchRipple={true}
              disableFocusRipple={true}
              className={classes.SignupButton}
            >
              {LanguageManager.Strings.DRIVER_SIGNBTN}
              <Icon>arrow_forward_ios</Icon>
            </Button>
          </Link>
        </Grid>
      </div>
    );
  }
}

export default withStyles(IntroTextStyles)(IntroTextLayer);

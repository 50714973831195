import React, { Component } from "react";
import {
  Grid,
  Avatar,
  Paper,
  Typography,
  Card,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import UserLayout from "../common/UserLayout";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import CustomLoader from '../../../custom/CustomLoader';
import CustomSnackbar from "../../../custom/CustomSnackbar";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";


class FavouriteDriver extends Component {

  constructor(props) {
    super(props);
    this.state = {
      favoriteDrivers: [],
      isLoading: false,
      showSnackbar: false,
      snackbarVariant: 'error',
      snakBarMessage: '',
    }}

  componentDidMount() {
    this.fetchFavoriteDrivers();
  }

  handleSnackBarClose = () => {
    this.setState({
      snakBarMessage: "",
      showSnackbar: false
    });
  };

  fetchFavoriteDrivers = () => {
    this.setState({ isLoading: true });
    ApiManager.callPostApi(
      "FavouriteDrivers",
      "FavouriteDrivers APi",
      ApiEndPoints.APPORIO_TAXI_APIS.USER_FAV_DRIVERS,
      {}
    )
      .then(res => {
        console.log("vbvbvbvbv" + JSON.stringify(res))
        if (res.result === "1") {
          this.setState({ favoriteDrivers: res.data, isLoading: false })
        } else {
          this.setState({
            isLoading: false,
            showSnackbar: true,
            snakBarMessage: res.message
          });
        }
      })
      .catch(err => alert(JSON.stringify(err)));
  };

  getLogoutResponse(result) {
    console.log("~~~~@~~~~", result)
    if (result) {
      console.log("@1@1@1@ ::" + result)
      SessionManager.particularKey(SessionKeys.ACCESS_TOKEN);
       this.props.history.replace('/')
       this.props.history.index = 0;

    }
  }

  render() {
    return (
      <UserLayout logoutCallBack={this.getLogoutResponse.bind(this)}>
        <CustomLoader showLoader={this.state.isLoading} />

        <CustomSnackbar
          showSnackbar={this.state.showSnackbar}
          variant={this.state.snackbarVariant}
          message={this.state.snakBarMessage}
          onClose={this.handleSnackBarClose} />

        <Grid alignContent="center" justify="center" container>
          {
            (this.state.favoriteDrivers.length > 0) ?
              this.state.favoriteDrivers.map((res, index) => (
                <Grid container xs={3} style={{ margin: "2%" }}>
                  <Grid item xs={12} style={{ boxShadow: "1px 1px 1px #9E9E9E" }}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        height: 120,
                        position: "relative",
                        justifyContent: "center",
                        display: "flex"
                      }}
                    >
                      <Grid
                        container
                        xs={12}
                        style={{
                          backgroundColor: "#4392DC",
                          height: 90
                        }}
                      >
                        <Grid item xs={10} style={{ padding: 5 }}>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "bold",
                              textAlign: "left",
                              color: "white"
                            }}
                          >
                          {res.driver_name}
                      </Typography>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              textAlign: "left",
                              color: "white"
                            }}
                          >
                          {res.driver_email}
                      </Typography>
                          <Typography
                            style={{
                              fontSize: "0.8rem",
                              textAlign: "left",
                              color: "white"
                            }}
                          >
                            {res.driver_phone}
                      </Typography>
                        </Grid>

                        <Grid item xs={2}>
                          <IconButton>
                            <MoreVertIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Avatar
                        src={ApiEndPoints.USERAPP_IMAGE_URL+res.driver_image}
                        style={{
                          width: 55,
                          height: 55,
                          position: "absolute",
                          bottom: 0
                        }}
                      />
                    </Grid>

                    <Grid container xs={12}>
                      <Grid
                        alignItems="center"
                        justify="center"
                        container
                        xs={6}
                        style={{ padding: 10 }}
                      >
                        <Card
                          style={{
                            width: 15,
                            height: 15,
                            borderRadius: 15 / 2,
                            backgroundColor: "#F14741"
                          }}
                        />
                        <Typography
                          style={{
                            fontSize: "0.8rem",
                            marginLeft: 5,
                            fontWeight: "bold"
                          }}
                        >
                          OFFLINE
                    </Typography>
                      </Grid>
                      <Grid
                        alignItems="center"
                        justify="center"
                        container
                        xs={6}
                        style={{ padding: 10 }}
                      >
                        <Card
                          style={{
                            width: 15,
                            height: 15,
                            borderRadius: 15 / 2,
                            backgroundColor: "#40D590"
                          }}
                        />
                        <Typography
                          style={{
                            fontSize: "0.8rem",
                            marginLeft: 5,
                            fontWeight: "bold"
                          }}
                        >
                          FREE
                    </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )) :
              <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Typography style={{ color: 'gray',marginTop:'50px',fontSize:'1rem' }}>
                  No favourite drivers to show right now
        </Typography>
              </div>
          }
        </Grid>
      </UserLayout>
    );
  }
}

export default FavouriteDriver;

// import { getTheme } from "../../../managers/theme/ThemeManager";
import { CUSTOM_FONT } from "../../../values/Typography";

const styles = theme => {
  // const configTheme = getTheme()

  return {
    textFeild: {
      width: 300,
      fontFamily: CUSTOM_FONT,
      fontSize: 14,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12
      },
      border: "0px solid",
      outline: "none !important"
    },
    detailFields: {
      width: "70%",
      marginLeft: "1%"
    }
    // fieldContainer:{
    //     backgroundColor:'red',
    // },
    // noIconFieldContainer:{

    //     backgroundColor:'red',
    // }
  };
};

export default styles;

// import { getTheme } from "../../../../managers/theme/ThemeManager";
// import { PADDING, MARGIN } from "../../../../values/Typography";

const styles = theme => {
  // const configTheme = getTheme();

  const drawerWidth = 300;

  return {
    root: {
      display: "flex"
    },
    // carImg: {
    //   backgroundColor: 'blue',

    //   [theme.breakpoints.up('md')]: {
    //     backgroundColor: 'red',
    //   },
    //   [theme.breakpoints.down('sm')]: {
    //     backgroundColor: 'green',
    //   },
    // },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      marginLeft: drawerWidth,
      [theme.breakpoints.up("xs")]: {
        width: `calc(100% - ${drawerWidth}px)`
      }
    },
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up("xs")]: {
        display: "none"
      }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing.unit * 3
    },
    textWhite: {
      color: "#fff !important"
    },
    viewDocButton: {
      // fontSize: 5,
      [theme.breakpoints.up("md")]: {
        // backgroundColor: 'red',
        // fontSize: 5,
      },
      [theme.breakpoints.down("xs")]: {
        // backgroundColor: 'green',
        fontSize: 8,
        marginRight: 15
      },
      ["@media (min-width:320px)"]: {
        marginLeft: -10
      }
    },
    LiveStatusButton: {
      backgroundColor: " #CCE0F8",
      textTransform: "capitalize",
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.down("xs")]: {
        // fontSize: 8,
        // margin: 5,
      }
    },
    LiveText: {
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.down("xs")]: {
        fontSize: 8
      }
    },
    LiveMessage: {
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.down("xs")]: {
        fontSize: 8
      }
    },
    ServiceTypes: {
      color: "#4A4A4A",
      [theme.breakpoints.up("md")]: {},
      [theme.breakpoints.down("xs")]: {
        fontSize: 8
      }
    },
    Headings: {
      fontWeight: 600,
      color: "#4A4A4A",
      fontSize: "1rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: 10
      }
    },
    ActiveVehicleDetails: {
      fontWeight: 600,
      [theme.breakpoints.down("xs")]: {
        fontSize: 10
      }
    },
    ActiveVehicleName: {
      fontWeight: 600,
      color: "#4A4A4A",
      fontSize: "1.25rem",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px !important"
      }
    },
    ActiveVehicleNumber: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px !important"
      }
    },
    vehicle_type: {
      marginTop: 8,
      fontWeight: 600,
      color: "#4A4A4A",
      [theme.breakpoints.down("sm")]: {
        marginTop: 8,
        fontSize: 10
      }
    },
    vehicle_number: {
      // backgroundColor: "rgb(246, 179, 68)",
      margin: "0 10px",
      backgroundColor: "#f2ba60",

      [theme.breakpoints.down("sm")]: {
        fontSize: "8px !important",
        lineHeight: 1,
        padding: "3px 0px",
        margin: "0px 3px",
        fontWeight: "bold"
      }
    },
    manage_vehicle_stat: {
      marginTop: 15,
      fontSize: 10,
      backgroundColor: "#6695cc",
      margin: 10,
      color: "#fff",
      [theme.breakpoints.down("xs")]: {
        margin: "15px 0 0 -10px",

        fontSize: 7,
        padding: 0
      }
    },
    vehicleGraph: {
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        width: "90%"
      }
    },
    vehicleTypeImage: {
      height: "65px",
      width: "auto",
      [theme.breakpoints.down("sm")]: {
        height: "35px"
      }
    },
    ServiceHead: {
      fontWeight: 600,
      color: "#4A4A4A",
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px"
      }
    },
    service_types_list: {
      color: "#4A4A4A",
      [theme.breakpoints.down("sm")]: {
        fontSize: 6
      }
    },
    ListPadService: {
      borderLeft: "1px solid grey",
      padding: 5,
      [theme.breakpoints.down("sm")]: {
        padding: 10
      }
    },
    ActivatedVehicle: {
      fontWeight: "600",
      color: "#26699d",
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px"
      }
    },
    OtherVehicle: {
      fontWeight: "600",
      color: "#4A4A4A",
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px"
      }
    },
    AddNewVeh: {
      backgroundColor: "#62c48b",
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        fontSize: 10
      }
    },
    Statustab: {
      [theme.breakpoints.down("sm")]: {
        fontSize: 10
      }
    }
  };
};

export default styles;

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { Modal } from "@material-ui/core";
// import { pureWhite } from "../../../values/Color";
import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";

const styles = theme => ({
  centerContainer: {
    display: "flex",
    backgroundColor: 'white',//pureWhite,
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    //paddingLeft: "30px",
    bordeRadius: 10,
    //width:'80h',
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "40px",
    paddingRight: "40px"
  },
  closeBtn: {
    display: "flex",

    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: -15,
    top: -15,
    zIndex: 999
  }
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    // width: "50vw",
    borderRadius: "8px",
    transform: `translate(-${top}%, -${left}%)`
  };
}

class DialogForm extends React.Component {
  render() {
    const { classes, children } = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.visible}
        disableAutoFocus={true}
        onClose={() => {
          if (this.props.onOutsideTouch) this.props.onOutsideTouch();
          if (this.props.onClose) this.props.onClose();
        }}
      >
        <div
          className={classes.centerContainer}
          style={Object.assign({}, getModalStyle(), {
            backgroundColor: '#EBEBEB'
          })}
        >
          {children}

          {this.props.overFlowButton === false ? null : (
            <Fab
              size="small"
              aria-label="Close"
              className={classes.closeBtn}
              onClick={() => {
                if (this.props.onOutsideTouch) this.props.onOutsideTouch();
                if (this.props.onClose) this.props.onClose();
              }}
            >
              <CloseIcon />
            </Fab>
          )}
        </div>
      </Modal>
    );
  }
}

DialogForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DialogForm);

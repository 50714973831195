import React from "react";
import PropTypes from "prop-types";
import { withStyles, Grid, Button, MenuItem, Select } from "@material-ui/core";
import { DriverProfileStyles } from "../../../../styles";
import Typography from "@material-ui/core/Typography";
import { APPORIO_TAXI_APIS } from "../../../../../managers/api/ApiEndPoints";
import * as ApiManager from "../../../../../managers/api/ApiManager";
import CustomLoader from "../../../../custom/CustomLoader";
import DriverTextField from "../../common/DriverTextField";
import EditPhoneOtp from "./EditPhoneOtp";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
        textAlign: "left"
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class GetOtp extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    value: 0,
    checked: false,
    drivername: "",
    number: "",
    open: false,
    openList: false,
    changePassword: false,
    mainContent: true,
    phonecode: this.props.DRIVER_CONFIG_ROWS.countries[0].phonecode,
    phoneNumber: "",
    user_name: ""
  };

  fetchPhoneNumber(result) {
    this.setState({ phoneNumber: result });
  }

  handlePhoneCode = event => {
    this.setState({
      phonecode: event.target.value
    });
  };

  handleSwitch = () => {
    this.setState(state => ({ checked: !state.checked }));
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  getOtp = () => {
    console.log(
      "**************DRIVER_OTP_GEN***********",
      APPORIO_TAXI_APIS.DRIVER_OTP_GEN
    );

    this.setState({
      isLoading: true
    });

    if (this.state.phoneNumber === "") {
      // alert("Please enter user name");
      this.setState(
        {
          isLoading: false
        },
        () => {
          this.props.openSnack("Please Enter Phone Number", "success");
        }
      );
    } else if (this.state.phonecode === "") {
      // alert("Please enter password");
      // this.setState({ isLoading: false });
      this.setState(
        {
          isLoading: false
        },
        () => {
          this.props.openSnack("Please Enter Country Code", "success");
        }
      );
    } else {
      ApiManager.callPostApi(
        "DRIVER_OTP_GEN",
        "",
        APPORIO_TAXI_APIS.DRIVER_OTP_GEN,
        {
          type: "1",
          for: "PHONE",
          user_name: this.state.phonecode + this.state.phoneNumber
        }
      )
        .then(response => {
          console.log("*****response*******", response);
          // alert(JSON.stringify(response))
          switch (parseInt(response.result)) {
            case 1:
              this.setState(
                {
                  OTP_ROW: response.data,
                  isLoading: false,
                  enterotp: true,
                  mainContent: false
                },
                () => {
                  this.props.openSnack(response.message, "success");
                }
              );
              break;

            case 0:
              this.setState(
                {
                  OTP_ROW: response.data,
                  isLoading: false,
                  enterotp: false,
                  mainContent: true
                },
                () => {
                  this.props.openSnack(response.message, "warning");
                }
              );
              break;
          }

          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  render() {
    console.log("driver home render", this.props);
    // const { classes } = this.props;
    // const { value } = this.state;
    // const { checked } = this.state;

    if (this.state.isLoading) {
      return <CustomLoader showLoader={this.state.isLoading} />;
    } else {
      return (
        <div style={{ height: "100%" }}>
          {this.state.mainContent && (
            <div>
              <Typography
                variant="h5"
                style={{ color: "#26699d", marginTop: "10px" }}
              >
                Edit Phone Number
              </Typography>

              <Grid
                container
                xl
                justify="flex-start"
                alignItems="center"
                spacing={8}
                style={{ marginTop: "10px" }}
              >
                <Grid item xl>
                  <Select
                    value={this.state.phonecode}
                    onChange={event => this.handlePhoneCode(event)}
                    inputProps={{
                      name: "phonecode",
                      id: "prefix-simple"
                    }}
                  >
                    {this.props.DRIVER_CONFIG_ROWS.countries.map(country => {
                      return (
                        <MenuItem value={country.phonecode}>
                          {country.phonecode}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>

                <Grid item>
                  <DriverTextField
                    placeHolder="Mobile number"
                    // iconName="assignment_ind"
                    callback={this.fetchPhoneNumber.bind(this)}
                  />
                  {/* <input
                                        maxLength={10}
                                        className={classes.textFeild}
                                        onChange={(evt) => console.log(evt.target.value)}
                                        placeholder="Phone Number"
                                        style={{ width: 'auto', borderBottom: '1px solid silver' }}
                                    /> */}
                </Grid>
              </Grid>

              <Typography
                variant="body2"
                style={{ fontWeight: 600, marginTop: "40px" }}
              >
                Enter your phone number
              </Typography>
              <Typography variant="display4" style={{ fontSize: "15px" }}>
                we will send OTP to this number
              </Typography>

              <Grid item xl style={{ marginTop: "40px" }}>
                <Button
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: "#4990E2", color: "#fff" }}
                  onClick={() => this.getOtp()}
                >
                  Get OTP
                </Button>
              </Grid>
            </div>
          )}
          {this.state.enterotp && (
            <EditPhoneOtp
              openSnack={this.props.openSnack}
              handleModal={this.props.handleModal}
              OTP_ROW={this.state.OTP_ROW}
              user_name={this.state.phonecode + this.state.phoneNumber}
              setPhoneNumber={this.props.setPhoneNumber}
              // onBackPress={() =>
              //   this.setState({
              //     enterotp: !this.state.enterotp,
              //     mainContent: !this.state.mainContent
              //   })
              // }
            />
          )}
        </div>
      );
    }
  }
}

export default withStyles(DriverProfileStyles)(GetOtp);

import React from 'react'
import { withStyles } from '@material-ui/core';
import  DriverHomeHeroStyles  from '../../../styles/driver/home/hero-css';
import { DRIVER_HERO_IMAGE } from '../../../../values/Icons';

class Hero extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        const { classes } = this.props
        return (
            <div className={classes.root}>
                <div className={classes.imageBox}>
                    <img src={DRIVER_HERO_IMAGE} />
                </div>
            </div>
        )
    }
}

export default withStyles(DriverHomeHeroStyles)(Hero)
/* To handle first name validation*/

export function validateFirstName(name){
    var nameRegex = /([A-z][\s\.]|[A-z])+$/;
    
  
    if(name=="" || name==undefined || name==null ){
      return {status:false,error:'Please enter first name.'};
    }
    else{
  
        if(!nameRegex.test(name)){
            return {status:false,error:'Please enter a valid first name.'};
          }else{
            return {status:true,error:''};
          }
  }}

  export function validateLastName(name){
    var nameRegex = /([A-z][\s\.]|[A-z])+$/;
    
    if(name=="" || name==undefined || name==null ){
      return {status:false,error:'Please enter last name.'};
    }
    else{
  
        if(!nameRegex.test(name)){
            return {status:false,error:'Please enter a valid last name.'};
          }else{
            return {status:true,error:''};
          }
  }}


  
   export function validateUserName(username){
     console.log("userName" + username);
    var usernameRegex = /^[a-zA-Z0-9]+$/;
    
    // username=username.trim();
  
    if(username=="" || username==undefined || username==null ){
      return {status:false,error:'Please enter first username.'};
    }
    else if(!usernameRegex.test(username)){
      return {status:false,error:'Please enter valid username.'};
    }
    else{
      return {status:true,error:''};
    }
  }
  
    /* To handle last name validation*/
  
  
    export function validateUserId(userId){
      var nameRegex = /^[a-zA-Z ]+$/;
  
     // userId=userId.trim();
  
      if(userId=="" || userId==undefined || userId==null ){
        return {status:false,error:'Please enter user id.'};
      }
      else{
        return {status:true,error:''};
      }
    }
  /* To handle first name LicenseNo*/
  

  /* To handle email validation */
  
  export function validateEmail(email){
  //var emailRegex = (/^[A-Z0-9_]+([\.][A-Z0-9_]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,4})+$/i)
  var emailRegex = /^[A-Z0-9_]+([\.][A-Z0-9_]+)*@[A-Z0-9-]+(\.[a-zA-Z]{2,3})+$/i;
  
  
      if(email=="" || email==undefined || email==null ){
        return {status: false ,error:'Please enter email id.',height:20};
      }
      else{
     //   email=email.trim();
  
        if(!emailRegex.test(email)){
          return {status: false,error:'Please enter valid email address.',height:20};
        }else{
        return {status:true,error:'',height:0};
        }
      }
    }
  
  
  /// To validate password /
  
  export function validatePassword(password){
    // A password contains at least eight characters, including at least one number and includes both lower and uppercase letters and special characters
  
   // var passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
  var passwordRegex = /^ (?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
        //  password = password.trim();    
    
          if(password=="" || password==undefined || password==null ){
            return {status:false,error:'Please enter password.',height:20,pss:password}
          }
          else if(password.length<8){
            return {status:false,error:'Password should contain at-least 8 characters.',height:40};
          }
          else{
            return {status:true,error:'',height:0} 
          }
      }
  
  
    /* To validate country */
  
  export function validateLocation(country){
   // country = country.trim();    
  
      if(country=="Location"){
        return {status:false,error:'Please enter location.'}
      }
      else{
        return {status:true,error:''} 
      }
  
  }
  
  
  export function validatePhone(phone){
    if(phone){
      if(phone.length!=10){
        return {status: false ,error:'Please enter a valid phone number.',height:20};
      }else{
        return {status: true ,error:'',height:0};
      }
    }else{
      return {status: false ,error:'Please enter phone number.',height:20};
    }
  
  }

  export function validateLicensePlate(license){
    if(license){
      let licenseRegex= /^[A-Z]{2}[ -][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$/;

      if(!licenseRegex.test(license)){
        return {status: false ,error:'Please enter a valid license number.',height:20};
      }else{
        return {status: true ,error:'',height:0};
      }
    }else{
      return {status: false ,error:'Please enter vehicle license number.',height:20};
    }
  
  }


  export function validateColor(color){
    var nameRegex = /([A-z][\s\.]|[A-z])+$/;
    
  
    if(color=="" || color==undefined || color==null ){
      return {status:false,error:'Please enter vehicle color.'};
    }
    else{
  
        if(!nameRegex.test(color)){
            return {status:false,error:'Please enter a valid vehicle color.'};
          }else{
            return {status:true,error:''};
          }
  }}


import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import { TAXI_IMAGE_URL } from "../../../../managers/api/ApiEndPoints";
import { CUSTOM_FONT } from "../../../../values/Typography";

class HomeBottomContainer extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          padding: "0.5em",
          flexDirection: "column",
          alignItems:
            this.props.align == 1
              ? "flex-start"
              : this.props.align == 2
              ? "center"
              : "flex-end"
        }}
      >
        <div
          style={{
            width: "85%",

            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <img
            src={this.props.image}
            // src={TAXI_IMAGE_URL + this.props.image}
            style={{ height: 100 }}
          />
          <Typography
            style={{
              fontFamily: CUSTOM_FONT,
              fontSize: "1.2rem",
              fontWeight: "bold ",
              color: "black"
            }}
          >
            {this.props.title}
          </Typography>
          <Typography style={{ fontFamily: CUSTOM_FONT, fontSize: "0.8rem" }}>
            {this.props.des}
          </Typography>
        </div>
      </div>
    );
  }
}

export default HomeBottomContainer;

import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import {
    Grid,
    Button,
  } from "@material-ui/core";
import * as ApiManager from "./managers/api/ApiManager";
import * as ApiEndPoints from "./managers/api/ApiEndPoints";
import CustomLoader from "./UI/custom/CustomLoader";
import CustomSnackbar from "./UI/custom/CustomSnackbar";

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  
  async submit(ev) {
    // User clicked submit
    //console.log("&&&&&&&&KK"+JSON.stringify(this.props.stripe.createToken({name: "Name"})))
     let {token} = await this.props.stripe.createToken({name: "Name"});

      this.setState({ isLoading: true });
  
      ApiManager.callPostApi(
        "CheckoutFOrm",
        "Save USer Card api",
        ApiEndPoints.APPORIO_TAXI_APIS.USER_SAVECARD,
        {}
      ).then(res => {
        console.log("GoGoGoGoGoGoGo"+JSON.stringify(res))
        this.setState({ isLoading: false });
  
        if (res.result === "1") {
          // this.setState({ isDiverVehicle: true });
        } else {
          this.setState({ isLoading: false });

          alert(JSON.stringify(res.message));
        }
      });
  }

// submit = (ev) => {
//     // We don't want to let default form submission happen here, which would refresh the page.
//     ev.preventDefault();

//     // Within the context of `Elements`, this call to createPaymentMethod knows from which Element to
//     // create the PaymentMethod, since there's only one in this group.
//     // See our createPaymentMethod documentation for more:
//     // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
//     this.props.stripe
//       .createPaymentMethod('card', {billing_details: {name: 'Jenny Rosen'}})
//       .then(({paymentMethod}) => {
//         console.log('Received Stripe PaymentMethod:'+JSON.stringify( paymentMethod));
//       });

//     // You can also use handleCardPayment with the Payment Intents API automatic confirmation flow.
//     // See our handleCardPayment documentation for more:
//     // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment

//     // You can also use createToken to create tokens.
//     // See our tokens documentation for more:
//     // https://stripe.com/docs/stripe-js/reference#stripe-create-token
//     this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});
//     // token type can optionally be inferred if there is only one one Element
//     // with which to create tokens
//     // this.props.stripe.createToken({name: 'Jenny Rosen'});

//     // You can also use createSource to create Sources.
//     // See our Sources documentation for more:
//     // https://stripe.com/docs/stripe-js/reference#stripe-create-source
//     this.props.stripe.createSource({
//       type: 'card',
//       owner: {
//         name: 'Jenny Rosen',
//       },
//     });
//   };

  render() {
    return (
      <div className="checkout" style={{paddingTop:'10%'}}>
        <CardElement    style={{ base: {
      color: '#32325D',
      fontWeight: 500,
      fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
      fontSize: '28px',
      fontSmoothing: 'antialiased',

      '::placeholder': {
        color: '#CFD7DF',
      },
      ':-webkit-autofill': {
        color: '#e39f48',
      },
    },
    invalid: {
      color: '#E25950',

      '::placeholder': {
        color: '#FFCCA5',
      },
    }}} />
 <Grid container xl justify="center" style={{marginTop:200}}>
 <Grid xs={7} item>
     <Button variant="contained" fullWidth disableTouchRipple={true} disableFocusRipple={true} style={{backgroundColor: '#23669B',
        fontSize: 18,
        height:40,
        textTransform: 'none',
        color: 'white',
        fontWeight: 'bold'}} onClick={this.submit}>
         Submit
     </Button>
     </Grid>
 </Grid>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
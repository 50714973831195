import React from 'react'
import DialogForm from '../../driver/signUp/DialogForm';
import { withStyles, Paper, InputBase } from '@material-ui/core';
import { Button, Grid, Typography,TextField,Checkbox,Radio} from '@material-ui/core';
import { PADDING, MARGIN,CUSTOM_FONT} from "../../../../values/Typography";

class CardSelectPopup extends React.PureComponent {

    constructor(props) {
        super(props)
        this.imageRef = React.createRef()
        this.state = {
        debitSelected:false,
        creditSelected:true
        }
    }

   async doneSelection(){
        //console.log("vvv"+this.state.newArray)
        await this.props.callback(this.state.newArray);
        this.setState({newArray:[]});
        this.props.onClose();
    }

   async closeTask(propClose){
      this.props.dismiss();
    }

    sendAction(evt){
        this.props.callback(evt.target.value);
        this.props.dismiss()
    }

    handleChange() {
        if (this.state.debitSelected) {
          this.setState({ creditSelected: true, debitSelected: false });
          this.props.callback(1)
          setTimeout(()=>{this.props.dismiss()},400)
        } else {
          this.setState({ debitSelected: false, creditSelected: true });
          this.props.callback(1)
          setTimeout(()=>{this.props.dismiss()},400)
        }
      }
    


    render() {
        console.log('document upload props', this.props)
        const { classes,keyValue,inputList,onClose} = this.props
        return (
            <div>

                <DialogForm
                    centerColor={(this.props.color) ? this.props.color : '#666666'}
                    visible={this.props.visible}
                    onOutsideTouch={()=>{this.closeTask(onClose)}}>
                    
                    <div className={classes.uploader}>
                        <Typography variant="h6" className={classes.heading} align="center">
                            Select Card Type
                        </Typography>

                                <Grid xl container justify="space-between" alignItems="center" style={{marginTop:'30px'}}>
                                <Typography variant="body1" className={classes.genderTitle}>
                                Credit Card
                            </Typography>

                              <Radio
                                style={{ marginLeft: "2%" }}
                                onClick={(evt)=>this.sendAction(evt)}
                                checked={this.state.creditSelected}
                                onChange={(evt) => this.handleChange(evt)}
                                value={1}
                                color="primary"
                                name="radio-Credit"
                                aria-label="C"/>
                                </Grid>
                    </div>

                </DialogForm>
            </div>


        )
    }
}

const styles = (theme) => ({

    uploader: {
        height:'40vh',
        width:'40vw'
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      expiryContainer:{
          marginTop:theme.spacing.unit*10
      },
      defaultImage:{
        width:100,
        height:100
      },
      validLabel:{
          fontWeight:'bold',
          fontFamily:'Montserrat'
      },
      textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
      },
    heading: {
        [theme.breakpoints.down('sm')]: {
            fontSize:'0.9rem'
          }, 
        marginTop:theme.spacing.unit*5,
        fontFamily: 'Montserrat',//FONT_FAMILY,
        fontWeight:'bold'
    },
    detail: {
        fontFamily: 'Montserrat',
        paddingRight: '20%',
        paddingLeft: '20%',//FONT_FAMILY,
        fontWeight:'bold'
    },
    footer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    addButton: {
        textTransform: 'capitalize',
        backgroundColor: 'green',//colorMutedGreen,
        color: 'white',// pureWhite,
        padding: '3px 10px',
        fontWeight: 600
    },
    box: {
        // padding: '10px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    imageBox: {
        backgroundSize:'contain',
        display: 'flex',
        backgroundColor:'#EBEBEB',
        justifyContent: 'center',
        alignItems: 'center',
        width: '200px',
        height: '250px',
        marginBottom: '15px',
        overflow: 'hidden',
        '& button': {
            width: '100%',
            height: '100%',
            boxShadow: 'none',
            border: 'none'
        }
    },
    inputBox: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxWidth: '400px',
        boxShadow: 'none',
        marginBottom: '15px',
    },
    label: {
        color: 'white',// pureWhite,
        alignSelf: 'start',
        fontWeight: 600,
        fontFamily: 'Montserrat',//FONT_FAMILY
    },
    SignupButton: {
        backgroundColor: '#23669B',
        fontFamily: 'Montserrat',//CUSTOM_FONT,
        fontSize: 18,
        textTransform: 'none',
        width: '100%',
        marginTop: 20,
        height: '5vh',
        color: 'white',
        fontWeight: 'bold'
    },
    containerStyle:{
        marginTop:theme.spacing.unit*10,
        marginBottom:theme.spacing.unit*10
    },
    genderTitle:{
        [theme.breakpoints.down('sm')]: {
            fontSize:'0.7rem'
          }, 
        fontFamily:CUSTOM_FONT,
        fontWeight:'bold',
    },
    genderContainer:{
        marginTop:10
    },

})

export default withStyles(styles)(CardSelectPopup)
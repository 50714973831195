import React from "react";
import PropTypes from "prop-types";
import { withStyles} from "@material-ui/core";
import { ManageTripStyles } from "../../../../styles";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Active from "./tabs/Active";
import Scheduled from "./tabs/Scheduled";
import Past from "./tabs/Past";
import DrawerLayout from "../../common/DrawerLayout";
import * as LanguageManager from '../../../../../managers/language/LanguageManager'

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 10,
        textAlign: "left"
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class ManageTrips extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  render() {
    console.log("driver home render", this.props);
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <DrawerLayout>
        <div
          position="fixed"
          className={classes.appBar}
          style={{ backgroundImage: " linear-gradient(to top, #ffffff, #e0e0f7, #bac3f0, #8ca8e9, #4990e2)" }}        >
          <Tabs
            value={this.state.value}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChange}>
            <Tab label={LanguageManager.Strings.ACTIVE_TRIP_LABEL} />
            <Tab label={LanguageManager.Strings.SCHEDULED_TRIP_LABEL} />
            <Tab label={LanguageManager.Strings.PAST_TRIP_LABEL} />
            {/* <Tab label="New Request" /> */}
          </Tabs>
        </div>

        {value === 0 && (
          <TabContainer>
            <Active/>
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <Scheduled/>
          </TabContainer>
        )}
        {value === 2 && (
          <TabContainer>
            <Past/>
          </TabContainer>
        )}
        {/* {value === 3 && (
          <TabContainer>
            <NoReq />
          </TabContainer>
        )} */}
      </DrawerLayout>
    );
  }
}

export default withStyles(ManageTripStyles)(ManageTrips);

import React from "react";
import { withStyles, Grid, CardMedia } from "@material-ui/core";
import { DriverSignUpStyles } from "../../../styles";
import { Link } from "react-router-dom";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import { DRIVEFRICA, DFRICA, DriverLogoFinal } from "../../../../values/Icons";
class LogoCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showBorder: null
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        className={classes.imageView}
        alignItems="center"
        style={{ borderTopWidth: 2, borderBottomWidth: 2 }}
      >
        <div
          className={classes.logoContainer}
          justify="flex-end"
          style={{
            borderTop: "2px solid white",
            borderBottom: "2px solid white"
          }}
        >
          <Grid xl justify="flex-end" alignItems="center">
            <Link to="/driver/home" style={{ textDecoration: "none" }}>
              <CardMedia
                style={{
                  height: 150,
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundSize: "contain"
                }}
                // image={DriverLogoFinal}
                image={JSON.parse(
                  SessionManager.getSessionData(SessionKeys.DRIVER_LOGO)
                )}
                title="Logo"
              />
            </Link>
          </Grid>
        </div>
      </Grid>
    );
  }
}

export default withStyles(DriverSignUpStyles)(LogoCard);

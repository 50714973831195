import React, { Component, Fragment } from "react";
import {
  withStyles,
  Typography,
  Button,
  Grid,
  Tabs,
  Tab,
  ButtonBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  TextField,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  NativeSelect,
  OutlinedInput,
  FilledInput,
  InputBase,
} from "@material-ui/core";

import { AddCarStyles } from '../../../styles';
import DocBarComponent from '../common/DocBarComponent';
import LinearTimeline from './LinearTimeline';//'../common/TimelineComponent';
import DocumentUploader from './DocumentUploader';
import Checkbox from '@material-ui/core/Checkbox';
import MenuListBox from '../common/MenuListBox';
import CreateCarForm from './CreateCarForm';
import * as ApiManager from "../../../../managers/api/ApiManager"
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints"
import CustomLoader from '../../../custom/CustomLoader';
import ServiceSelector from '../common/ServiceTypeModal'
import { validateLicensePlate, validateColor } from '../../../../utils/validation';
import CustomSnackbar from "../../../custom/CustomSnackbar";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import * as LanguageManager from '../../../../managers/language/LanguageManager'
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

class AddCar extends React.Component {

  constructor(props) {
    super(props);
    this.imageRef = React.createRef()
    this.plateRef = React.createRef()
    this.typeRef = React.createRef();
    this.makeRef = React.createRef();
    this.modelRef = React.createRef();

    this.state = {
      showBorder: null,
      value: 0,
      visible: false,
      userSmoker: false,
      smokerSelected: false,
      nonSmokerSelected: true,
      configData: {},
      isLoading: true,
      showFields: false,
      modelData: [],
      showService: false,
      vehicleColor: '',
      vehicleNumber: '',
      vehicleID: '',
      showItems: [],
      serviceIndices: [],
      showSnackbar: false,
      snackbarVariant: "error",
      snakBarMessage: "",
      showSnackbar: false,
      selectedServices: [],
      existingCarImage: null,
      existingOwnerName: null,
      existingVehicleType: null,
      typeName:"car_type",
      modelName:"car_model",
      makeName:"car_make",
      selectedModel:"",
      DRIVER_CONFIG_ROWS:undefined
    };
  }

 async componentDidMount()
  {
    let data =  await JSON.parse(
      SessionManager.getSessionData(SessionKeys.DRIVER_CONFIG)
    );


    this.setState(
      {  isLoading: true,
        DRIVER_CONFIG_ROWS: data,
        isLoading: false,
      }
    );
  }

   componentWillMount() {
  

    this.getCarType(this.props.areaCode)
    //this.getCarType(3)
  }

  getComboResult(idVal, driverID, carID) {
    let comboResult = {}
    comboResult["id"] = idVal + 1
    comboResult["driverID"] = driverID
    comboResult["carID"] = carID
    return (comboResult);
  }

  addExistingCar = (inputVal,driverID) => {

    this.setState({ isLoading: true })
    if (this.state.vehicleID == "") {
      this.setState({
        showSnackbar: true,
        snakBarMessage: "Please enter Car Number",
        isLoading: false
      });

    }else{

                  ApiManager.callPostApi("AddCar", "driver_add_newCar", ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_ADD_EXISTING_CAR, {
                    
                    code:this.state.vehicleID,
                    driver_id:driverID 

                  }).then(res => {
                    console.log("existingCarRESPzzzzz" + JSON.stringify(res))
                    this.setState({ isLoading: true })
                    if (res.result == 1) {
                      this.setState({ isLoading: false,
                      existingCarImage:res.data.vehicle_image,
                    existingOwnerName:res.data.owner_driver.first_name,
                  existingVehicleType:res.data.VehicleTypeName})
                      //this.props.callBack(inputVal+1)
                      // this.props.callBack(this.getComboResult(inputVal, res.data.driver_id, res.data.id))

                      // this.setState({ configData: res.data })
                      // setTimeout(() => { this.setState({ showFields: true }) }, 500)
                    } else {
                      this.setState({
                        showSnackbar: true,
                        snakBarMessage: res.message,
                        isLoading: false
                      });
                    }
                    //alert(JSON.stringify(res))
                  })
                }

    // this.props.callBack(idVal+1)
  }

  addNewCars(){
    console.log("@$@$@"+JSON.stringify(this.state))
  }

  addNewCar = (inputVal) => {

    this.setState({ isLoading: true })

    if (this.state.carTypeValue) {
      if (this.state.driverCarMake) {
        if (this.state.selectedModel) {
          if (this.state.selectedServices.length > 0) {
            if (this.state.vehicleNumber) {
              let colorResult = validateColor(this.state.vehicleColor)
              if (colorResult.status == true) {
                if (this.state.carImage) {
                  if (this.state.numberPlateImage) {

                    let reqData = {
                      vehicle_make: this.state.driverCarMake,
                      driver_id: this.props.driverID,
                      vehicle_color: this.state.vehicleColor,
                      service_type: this.state.serviceIndices.toString(),
                      vehicle_number: this.state.vehicleNumber,
                      vehicle_model: this.state.selectedModel,
                      vehicle_type: this.state.carTypeValue,
                      owner_type: 2,
                      vehicle_image: "data:image/png;base64," + this.state.carImage,
                      vehicle_number_plate_image: "data:image/png;base64," + this.state.numberPlateImage,
                      api_type: 1
                    }

                    ApiManager.callPostApi("AddCar", "driver_add_newCar", ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_ADDNEWCAR, {
                      vehicle_make: this.state.driverCarMake,
                      driver_id: this.props.driverID,
                      vehicle_color: this.state.vehicleColor,
                      service_type: this.state.serviceIndices.toString(),
                      vehicle_number: this.state.vehicleNumber,
                      vehicle_model: this.state.selectedModel,
                      vehicle_type: this.state.carTypeValue,
                      owner_type: 2,
                      vehicle_image: this.state.carImage,
                      vehicle_number_plate_image: this.state.numberPlateImage,
                      api_type: 1

                    }).then(res => {
                      console.log("addCarRESPzzzzz" + JSON.stringify(res))
                      console.log("addNewCarReqqqqq....." + JSON.stringify(reqData))

                      this.setState({ isLoading: true })
                      if (res.result == 1) {
                        this.setState({ isLoading: false })
                        //this.props.callBack(inputVal+1)
                        this.props.callBack(this.getComboResult(inputVal, res.data.driver_id, res.data.id))

                        // this.setState({ configData: res.data })
                        // setTimeout(() => { this.setState({ showFields: true }) }, 500)
                      } else {
                        this.setState({
                          showSnackbar: true,
                          snakBarMessage: res.message,
                          isLoading: false,
                          snackbarVariant:"error"
                        });
                      }
                    })

                  } else {
                    this.setState({
                      showSnackbar: true,
                      snakBarMessage: "Please upload vehicle license plate image",
                      isLoading: false,
                      snackbarVariant:"warning"
                    });
                  }

                } else {
                  this.setState({
                    showSnackbar: true,
                    snakBarMessage: "Please upload vehicle image",
                    isLoading: false,
                    snackbarVariant:"warning"
                  });
                }
              } else {
                this.setState({
                  showSnackbar: true,
                  snakBarMessage: colorResult.error,
                  isLoading: false,
                  snackbarVariant:"warning"
                });
              }
            } else {
              this.setState({
                showSnackbar: true,
                snakBarMessage: "Please enter license number",
                isLoading: false,
                snackbarVariant:"warning"
              });
            }
          } else {
            this.setState({
              showSnackbar: true,
              snakBarMessage: "Please select services",
              isLoading: false,
              snackbarVariant:"warning"
            });
          }
        } else {
          this.setState({
            showSnackbar: true,
            snakBarMessage: "Please select vehicle model",
            isLoading: false,
            snackbarVariant:"warning"
          });
        }
      }
      else {
        this.setState({
          showSnackbar: true,
          snakBarMessage: "Please select vehicle make",
          isLoading: false,
          snackbarVariant:"warning"
        });
      }
    } else {
      this.setState({
        showSnackbar: true,
        snakBarMessage: "Please select vehicle type",
        isLoading: false,
        snackbarVariant:"warning"
      });
    }

    // this.props.callBack(idVal+1)
  }

  handleChange(event) {
    console.log("vvv" + event)
    this.setState({ value: event });
  }

  getValue = (val) => {
    this.props.callBack(val)
  }

  getCarType = (areaID) => {

    this.setState({ isLoading: true })

    ApiManager.callPostApi("AddCar", "driver_show_carTypes", ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_GETCARTYPES, {
      area: areaID,

    }).then(res => {
      console.log("hihihih" + JSON.stringify(res))
      this.setState({ isLoading: true })
      if (res.result == 1) {

        this.setState({ isLoading: false })
        this.setState({ configData: res.data })
        setTimeout(() => { this.setState({ showFields: true }) }, 500)
      } else {
        this.setState({
          isLoading: false,
          showSnackbar: true,
          snakBarMessage: res.message,
          isLoading: false
        });
      }
      //alert(JSON.stringify(res))
    })
  }


  fetchModels = (makeID) => {

    console.log("xxx" + makeID + "," + this.state.carTypeValue)

    this.setState({ isLoading: true })

    ApiManager.callPostApi("AddCar", "driver_show_carModels", ApiEndPoints.APPORIO_TAXI_APIS.FETCH_MODELS, {
      vehicle_make: makeID,
      vehicle_type: this.state.carTypeValue
    }).then(res => {
      // console.log("hihihih"+JSON.stringify(res))
      if (res.result == 1) {
        this.setState({ isLoading: false })
        this.setState({ modelData: res.data })
        console.log("hihihih" + JSON.stringify(res))

      } else {
        this.setState({ isLoading: false })
        alert("Add Vehicle Error: " + res.message)
      }
      //alert(JSON.stringify(res))
    })
  }

  renderExistingCarForm(styles, inputVal,driverID) {
    return (

      <div style={{ margin: '10px' }}>
        <Grid xl container justify="space-between" alignItems="center" style={{ paddingRight: '1%', paddingLeft: '1%' }}>
          <TextField
            label={LanguageManager.Strings.CAR_ID_HINT}
            helperText="ie 0000011"
            InputProps={{
              style: { fontSize: 15, fontFamily: 'Montserrat', fontWeight: 'bold' }
            }}
            InputLabelProps={{
              style: { fontSize: 15, fontFamily: 'Montserrat', fontWeight: 'bold' }
            }}
            //className={styles.detailFields}
            // style={{ height: 100 }}
            onChange={(evt) => this.setState({ vehicleID: evt.target.value })}
            margin="normal"
            variant="outlined"
          />

        <Button variant="contained" disableTouchRipple={true} disableFocusRipple={true} className={styles.SignupButton} onClick={() => this.addExistingCar(inputVal,driverID)}>
        {LanguageManager.Strings.ADD_CAR_LABEL}
            </Button>
        </Grid>

        <Grid xl container justify="space-between" alignItems="center" style={{ paddingRight: '1%', paddingLeft: '1%', marginTop: '2px' }}>
          <Grid xs={5} item>
            {
              (this.state.existingCarImage) ?
                <img src={ApiEndPoints.TAXI_IMAGE_URL + this.state.existingCarImage} height="100" width="100" /> :
                null
            }
          </Grid>

          <Grid item xs={7}>
            <Typography variant="body1" className={styles.ownerDetailStyle}>
              {this.state.existingOwnerName}
            </Typography>
            <Typography variant="body1" className={styles.ownerDetailStyle}>
              {this.state.existingVehicleType}
            </Typography>
          </Grid>
        </Grid>


      </div>
    );
  }

  fetchCarTypeValue(result) {
    //console.log("[[["+result)
    this.setState({ carTypeValue: result })
  }

  fetchCarMakeValue(result) {
    //console.log("]]]"+result)
    this.setState({ driverCarMake: result })
    this.fetchModels(result)
  }

  fetchCarModelValue(result) {
    this.setState({ selectedModel: result })
    // console.log("````"+result)
  }

  fetchServices(result) {
    this.setState({ selectedServices: result })
    console.log("fffff" + JSON.stringify(result))

    this.setState({ showItems: result.map(function (e) { return e.name }) })
    this.setState({ serviceIndices: result.map(function (e) { return e.id }) })
  }

  openExplorer = () => {
    this.imageRef.current.click()
  }

  openExplorerPlate = () => {
    this.plateRef.current.click()
  }

  // --- handle upload ----
  handleCarUpload = (event) => {
    if (event.target.files && event.target.files[0]) {

      let reader = new FileReader
      reader.onload = (e) => {
        console.log("uhuhu>>>" + JSON.stringify(e.target))
        this.setState({
          carImage: e.target.result
        })
      }
      reader.readAsDataURL(event.target.files[0])
    }
  }

  handlePlateUpload = (event) => {
    if (event.target.files && event.target.files[0]) {

      let reader = new FileReader
      reader.onload = (e) => {
        console.log("uhuhu>>>" + JSON.stringify(e.target))
        this.setState({
          numberPlateImage: e.target.result
        })
      }
      reader.readAsDataURL(event.target.files[0])
    }
  }


  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false
    });
  };

  handleCarTypeChange(event,id) {
     console.log("###"+event.target.name)
    // this.props.callback(event.target.value)
    // this.setState({ carTypeValue: result })

    // this.setState({id:event.target.value})
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
      [this.state.makeName]:"",
      [this.state.modelName]:"",
      carTypeValue:event.target.value,
      driverCarMake:"",
      selectedModel:""
    });
  }

  handleCarMakeChange(event,id) {
    // this.props.callback(event.target.value)
    // this.setState({ carTypeValue: result })

    // this.setState({id:event.target.value})
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
      driverCarMake:event.target.value,
      selectedModel:"",
      [this.state.modelName]:"",
    });
    this.fetchModels(event.target.value)

  }

    handleCarModelChange(event,id) {
    // this.props.callback(event.target.value)
    // this.setState({ carTypeValue: result })

    // this.setState({id:event.target.value})
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
      selectedModel:event.target.value,
    });
  }



  render() {
    //  console.log("********Render Check--->"  ,this.state.DRIVER_CONFIG_ROWS?   this.state.DRIVER_CONFIG_ROWS.general_config.existing_vehicle_enable:"still undefined")
    const { classes, callback, indexValue, areaCode } = this.props;
console.log("cartypeValue",this.state.carTypeValue)
    return (
      <Grid item xl justify='center' alignItems='center' className={classes.docBarContainer}>
        <CustomSnackbar
          showSnackbar={this.state.showSnackbar}
          variant={this.state.snackbarVariant}
          message={this.state.snakBarMessage}
          onClose={this.handleSnackBarClose}
        />
        {
          (this.state.configData) ?
            <ServiceSelector carTypeValue={this.state.carTypeValue} inputList={this.state.configData} visible={this.state.showService} callback={this.fetchServices.bind(this)} onClose={() => this.setState({ showService: false })} /> : null
        }
        <CustomLoader showLoader={this.state.isLoading} />
        <Typography variant="h5" component="h3" className={classes.loginTitle}>
        {LanguageManager.Strings.ADD_CAR_LABEL}
    </Typography>

        <Tabs
          value={this.state.value}
          onChange={(event, value) => this.handleChange(value)}
          variant="fullWidth"
          classes={{ root: classes.root, indicator: classes.tabsIndicator }}>
          <Tab
            label="New Vehicle"
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          />
          {this.state.DRIVER_CONFIG_ROWS && this.state.DRIVER_CONFIG_ROWS.general_config.existing_vehicle_enable &&
          <Tab
            label="Existing Vehicle"
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            disableRipple
          />
          }
        </Tabs>

        {(this.state.value === 0 && this.state.showFields) ?
          <div style={{ margin: '10px' }}>
            <Typography variant="h6" gutterBottom align="left" className={classes.sectionHead}>
            {LanguageManager.Strings.CREATE_VEHICLE_LABEL}
            </Typography>
            <Grid xl container justify='flex-start' style={{padding:10}}>
              <Grid container className={classes.optionsContainer}>
                {/* <MenuListBox fieldLabel="Vehicle Type" listData={this.state.configData.vehicle_type} callback={this.fetchCarTypeValue.bind(this)} indexID={1} /> */}
                <Grid xl container justify="flex-start" alignItems="center" spacing={16} className={classes.selectContainer}>
                  <Grid item style={{flex:1}}>
                  <Typography variant="subtitle" className={classes.detailTitle}>
                  {LanguageManager.Strings.VEHICLE_TYPE_LABEL}
                  </Typography>
                  </Grid>
                  <Grid item style={{flex:1}}>

                 {/* <FormControl className={classes.formControl}>
                  <Select
                    native inputProps={{ style: { width: 100 } }}
                    value={this.state.age}
                    displayEmpty
                   
                    onChange={(evt,id) => this.handleCarTypeChange(evt,id)}
                    className={classes.selectOption}
                    input={<BootstrapInput  ref={this.typeRef} name={this.state.typeName} placeholder="" id="car_type-select" />}>
                    <option value="">None</option>
                  {this.state.configData.vehicle_type.map(row => (
                                          <option value={row.id} className={styles.areaItem}>{row.vehicleTypeName}</option>
                                      ))}
                  </Select>
                </FormControl> */}

                      <FormControl className={classes.formControl}>
                        <Select
                          value={this.state.carTypeValue}
                          onChange={(evt,id) => this.handleCarTypeChange(evt,id)}
                          inputProps={{
                            name: 'age',
                            id: 'age-simple',
                          }}>

                          <MenuItem value="">
                            <em>{LanguageManager.Strings.NONE_LABEL}</em>
                          </MenuItem>
                          {this.state.configData.vehicle_type.map(row => (
                                          <MenuItem value={row.id} className={styles.areaItem} >{row.vehicleTypeName}</MenuItem>
                                      ))}
                        </Select>
                      </FormControl>
                </Grid>
          </Grid>

                {/* <MenuListBox fieldLabel="Vehicle Make" listData={this.state.configData.vehicle_make} callback={this.fetchCarMakeValue.bind(this)} indexID={2} /> */}
                <Grid xl container justify="flex-start" alignItems="center" spacing={16} className={classes.selectContainer}>
                  <Grid item style={{flex:1}}>
                  <Typography variant="subtitle" className={classes.detailTitle}>
                  {LanguageManager.Strings.VEHICLE_MAKE_LABEL}
                  </Typography>
                  </Grid>
                  <Grid item style={{flex:1}}>

                 <FormControl className={classes.formControl}>
                  {/* <Select
                    ref={this.makeReft}
                    native inputProps={{ style: { width: 100 } }}
                    value={this.state.age}
                    displayEmpty
                    onChange={(evt,id) => this.handleCarMakeChange(evt,id)}
                    className={classes.selectOption}
                    input={<BootstrapInput name={this.state.makeName} placeholder="" id="car_make-select" />}>

                  <option value="">None</option>
                  {this.state.configData.vehicle_make.map(row => (
                                          <option value={row.id} className={styles.areaItem}>{row.vehicleMakeName}</option>
                                      ))}
                  </Select> */}
                        <Select
                          value={this.state.driverCarMake}
                          onChange={(evt,id) => this.handleCarMakeChange(evt,id)}
                          inputProps={{
                            name: 'age',
                            id: 'age-simple',
                          }}>

                          <MenuItem value="">
                            <em>{LanguageManager.Strings.NONE_LABEL}</em>
                          </MenuItem>
                          {this.state.configData.vehicle_make.map(row => (
                                          <MenuItem value={row.id} className={styles.areaItem} >{row.vehicleMakeName}</MenuItem>
                                      ))}
                        </Select>
                </FormControl>
                </Grid>
            </Grid>
                {/* <MenuListBox fieldLabel="Vehicle Model" listData={this.state.modelData} callback={this.fetchCarModelValue.bind(this)} indexID={3} /> */}
                <Grid xl container justify="flex-start" alignItems="center" spacing={16} className={classes.selectContainer}>
                  <Grid item style={{flex:1}}>
                  <Typography variant="subtitle" className={classes.detailTitle}>
                  {LanguageManager.Strings.VEHICLE_MODEL_LABEL}
                  </Typography>
                  </Grid>
                  <Grid item style={{flex:1}}>

                 <FormControl className={classes.formControl}>
                  {/* <Select
                    ref={this.modelRef}
                    native inputProps={{ style: { width: 100 } }}
                    value={this.state.age}
                    displayEmpty
                    onChange={(evt,id) => this.handleCarModelChange(evt,id)}
                    className={classes.selectOption}
                    input={<BootstrapInput name={this.state.modelName} placeholder="" id="car_model-select" />}>
                  <option value="">None</option>
                  {this.state.modelData.map(row => (
                                          <option value={row.id} className={styles.areaItem}>{row.vehicleModelName}</option>
                                      ))}
                  </Select> */}
                        <Select
                          value={this.state.selectedModel}
                          onChange={(evt,id) => this.handleCarModelChange(evt,id)}
                          inputProps={{
                            name: 'age',
                            id: 'age-simple',
                          }}>
                          <MenuItem value="">
                            <em>{LanguageManager.Strings.NONE_LABEL}</em>
                          </MenuItem>
                          {this.state.modelData.map(row => (
                                          <MenuItem value={row.id} className={styles.areaItem} >{row.vehicleModelName}</MenuItem>
                                      ))}
                        </Select>
                </FormControl>
                </Grid>
              </Grid>
              </Grid>
              {
                (this.state.showFields&&this.state.selectedModel) ?
                  <Grid container xl justify="space-between" className={classes.detailTitle}>
                    <Button variant="outlined" className={classes.servicesButton} onClick={() => { this.setState({ showService: true }) }}>
                    {LanguageManager.Strings.SERVICE_SELECT_LABEL}
                    </Button>

                    <Typography variant="body1" gutterBottom align="left" className={classes.itemSet}>
                      {this.state.showItems.join(',')}
                    </Typography>
                  </Grid> : null
              }
            </Grid>


            <Typography variant="h6" gutterBottom align="left" className={classes.detailTitle}>
            {LanguageManager.Strings.ADD_CAR_DETAILS}
            </Typography>


              <div style={{ flex: 1, justifyContent: 'space-between' }}>
              <Grid xl container justify="space-between" alignItems="center">
                <TextField
                  label={LanguageManager.Strings.VEHICLE_NUMBER_HINT}
                  helperText="e.g SS 88 TA 1234"

                  className={classes.detailFields}
                  InputProps={{
                    style: { fontSize: 15, fontFamily: 'Montserrat', fontWeight: 'bold' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: 15, fontFamily: 'Montserrat', fontWeight: 'bold' }
                  }}
                  onChange={(evt) => this.setState({ vehicleNumber: evt.target.value })}
                  margin="normal"
                  variant="outlined"
                />
                                <div className={classes.detailsContainer}>
                  <Typography variant="body1" gutterBottom  className={classes.uploadImageLabel}>
                  {LanguageManager.Strings.UPLOAD_CAR_IMAGE_LABEL}
                </Typography>

                  <ButtonBase accept="image/*" htmlFor="icon-button-file" id="icon-button-file" focusRipple onClick={() => this.openExplorer()} >
                    <Grid item className={classes.avatarContainer}>
                      {
                        (Boolean(this.state.carImage)) ?
                          <img src={this.state.carImage} className={classes.avatarStyle} />
                          :
                          <img src='https://image.flaticon.com/icons/svg/55/55283.svg' className={classes.avatarStyle} />

                      }

                    </Grid>
                  </ButtonBase>
                  <input type="file" ref={this.imageRef} style={{ width: '0', height: '0', overflow: 'hidden' }} onChange={(event) => this.handleCarUpload(event)}></input>
                </div>
                </Grid>   

                <Grid xl container justify="space-between" alignItems="center" style={{marginTop:'5px'}}>
                <TextField
                  label={LanguageManager.Strings.CAR_COLOR_HINT}
                  InputProps={{
                    style: { fontSize: 15, fontFamily: 'Montserrat', fontWeight: 'bold' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: 15, fontFamily: 'Montserrat', fontWeight: 'bold' }
                  }}
                  className={classes.detailFields}
                  onChange={(evt) => this.setState({ vehicleColor: evt.target.value })}
                  margin="normal"
                  variant="outlined"
                />
                                <div className={classes.detailsContainer}>
                  <Typography variant="body1" gutterBottom  className={classes.uploadImageLabel}>
                  {LanguageManager.Strings.NUMBER_PLATE_UPLOAD_IMAGE}
                </Typography>

                  <ButtonBase accept="image/*" htmlFor="icon-button-file" id="icon-button-file" focusRipple onClick={() => this.openExplorerPlate()} >
                    <Grid item className={classes.avatarContainer}>
                      {
                        (Boolean(this.state.numberPlateImage)) ?
                          <img src={this.state.numberPlateImage} className={classes.avatarStyle} />
                          :
                          <img src='https://www.flaticon.com/premium-icon/icons/svg/713/713981.svg' className={classes.avatarStyle} />

                      }

                    </Grid>
                  </ButtonBase>
                  <input type="file" ref={this.plateRef} style={{ width: '0', height: '0', overflow: 'hidden', display:'none' }} onChange={(event) => this.handlePlateUpload(event)}></input>
                </div>
                </Grid>
              </div>


            <Button fullWidth variant="contained" disableTouchRipple={true} disableFocusRipple={true} className={classes.SignupButton} onClick={() => this.addNewCar(indexValue)}>
            {LanguageManager.Strings.ADD_CAR_LABEL}
                </Button>
          </div>
          : null}

        {this.state.value === 1 && this.renderExistingCarForm(classes, indexValue,this.props.driverID)}
      </Grid>

    );
  }
}

export default withStyles(AddCarStyles)(AddCar);





import React from "react";
import PropTypes from "prop-types";
import { withStyles, Grid, Fab, CardMedia, Icon } from "@material-ui/core";
import { ManageTripStyles } from "../../../../styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { DRIVER_IMAGE, SLEEPING_CAR } from "../../../../../values/Icons";

class NoAddress extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    value: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  render() {
    console.log("driver home render", this.props);
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div style={{ textAlign: "center", marginTop: 100 }}>
        <img src={SLEEPING_CAR} height="100" width="200" />
        <Typography variant="display1" style={{ fontSize: 30, }}>
          Please add Address
        </Typography>
      </div>
    );
  }
}

export default withStyles(ManageTripStyles)(NoAddress);

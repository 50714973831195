import React from "react";
import PropTypes from "prop-types";
import { withStyles, Grid, Fab, CardMedia, Icon, Avatar, TablePagination, Paper, Button } from "@material-ui/core";
import { ManageVehicleStyles } from "../../../../../styles";
import Typography from "@material-ui/core/Typography";
// import { DRIVER_IMAGE } from "../../../../../../values/Icons";
// import DashboardPaginationActions from "../DashboardPaginationActions";
import * as ApiManager from "../../../../../../managers/api/ApiManager";
import { APPORIO_TAXI_APIS, TAXI_IMAGE_URL } from "../../../../../../managers/api/ApiEndPoints";
// import NoReq from "../../../common/NoReq"
// import FullScreenDialog from "./FullScreenDialog"
import CustomLoader from "../../../../../custom/CustomLoader"


class VehicleListing extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    value: 0,
    ACTIVE_ROWS: [],
    page: 0,
    rowsPerPage: 3,
    loading: true,
    fullmodal: {
      open: false,
      row: null,
    }
  };



  closeModal = () => {
    this.setState({
      fullmodal: {
        row: null,
        open: false
      }
    })
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };
  // -- handle page -- //
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };



  openModal = (row) => {
    this.setState
      ({
        fullmodal: {
          open: true,
          row: row
        }
      })
  }




  activeVehicle = (vehicleId) => {
    // start laoding
    this.setState({
      loading: true,
    })
    ApiManager.callPostApi(
      "CHANGE_VEHICLE",
      "",
      APPORIO_TAXI_APIS.CHANGE_VEHICLE,
      {
        driver_vehicle: vehicleId,
      }
    )

      .then((response) => {
        console.log("******CHANGE_VEHICLE******", response)
        //   this.setState({
        //     loading: false,
        //   },
        //     () => this.props.executeManageVehicleListApi())
        // })
        switch (parseInt(response.result)) {
          case 1:
            this.setState({
              loading: false,
            }, () => { this.props.finalSnack(response.message, "success") })
            break;
          case 0:
            this.setState({
              loading: false,
            }, () => { this.props.openSnack(response.message, "warning") })
            break;
        }
        console.log(response)
      })
      .catch((error) => {
      })
  }


  render() {
    const { rowsPerPage, page } = this.state;
    console.log("driver home render", this.props);
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div>
        {this.props.VEHICLE_ROWS.map(row => {
          return (
            <Paper style={{ marginBottom: 5 }}>
              <Grid
                container
                xs={12}
                style={{ border: "1px solid lightgrey", }}
              >
                <Grid item xs={2} style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <img src={TAXI_IMAGE_URL + row.vehicleTypeImage}
                    className={classes.vehicleTypeImage}
                  />
                </Grid>
                <Grid item md={2} xs={3}>
                  <Typography
                    className={classes.vehicle_type}
                    variant="subtitle1"
                    style={{}}
                  >
                    {row.vehicle_type}
                  </Typography>
                  <Paper className={classes.vehicle_number}
                    style={{}}>
                    {row.vehicle_number}
                  </Paper>
                </Grid>
                <Grid item md={6} xs={4} className={classes.ListPadService}
                >
                  <Typography
                    variant="body2"
                    align="left"
                    className={classes.ServiceHead}

                  >
                    Services
              </Typography>
                  <Typography
                    variant="body2"
                    align="left"
                    className={classes.service_types_list}
                  >
                    {row.service_types}
                  </Typography>
                </Grid>
                <Grid item md={2} xs={2} align="right">
                  <Grid container xs={12}>
                    {row.ready_for_live == 2 &&
                      <Grid item xs={6}>
                        <Button
                          variant="contained"
                          className={classes.manage_vehicle_stat}
                          style={{}}
                        >
                          {row.show_msg}
                        </Button>
                      </Grid>
                    }
                    {row.ready_for_live == 1 &&
                      <Grid item xs={6} justify="right">
                        <Button
                          variant="contained"
                          className={classes.manage_vehicle_stat}
                          style={{ backgroundColor: "#2dcc70" }}
                          onClick={() => this.activeVehicle(row.id)}>
                          Activate
                     </Button>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          )
        }
        )
        }

        {
          this.props.VEHICLE_ROWS.length == 0 &&
          <Grid container xs={12} style={{ padding: 15 }}>
            <Typography
              variant="h6"
              style={{ fontWeight: "600", color: "#4A4A4A" }}
            >
              No Vehicles
                </Typography>
          </Grid>
        }

      </div>
    );

  }
}

export default withStyles(ManageVehicleStyles)(VehicleListing);

import { getTheme } from "../../../../managers/theme/ThemeManager";
import {
  PADDING,
  MARGIN,
  CUSTOM_FONT,
  CUSTOM_COLOUR,
  CUSTOM_DRIVER_COLOUR
} from "../../../../values/Typography";

const styles = theme => {
  const configTheme = getTheme();

  return {
    loginFormSheet: {
      // paddingRight: theme.spacing.unit * 10,
      // paddingLeft: theme.spacing.unit * 10,
      // paddingTop: theme.spacing.unit * 5,
      // paddingBottom: theme.spacing.unit * 5,
      padding: theme.spacing.unit * 5,
      flex: 1,
      [theme.breakpoints.down("sm")]: {
        padding: "10px"
      }
    },
    loginTitle: {
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      marginTop: 20
    },

    SignupButton: {
      backgroundColor: CUSTOM_DRIVER_COLOUR,
      fontFamily: CUSTOM_FONT,
      fontSize: "1rem",
      textTransform: "none",
      width: "100%",
      marginTop: "20px",
      color: "white",
      fontWeight: "bold"
    },

    SUbutton: {
      textTransform: "none",
      fontFamily: CUSTOM_FONT,
      fontSize: "1rem",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.8rem"
      }
    },
    formLink: {
      textTransform: "none",
      fontFamily: CUSTOM_FONT,
      fontSize: "0.8rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.5rem"
      }
    },
    linkContainer: {
      paddingRight: theme.spacing.unit * 3,
      paddingLeft: theme.spacing.unit * 3
    },
    formContainer: {
      padding: theme.spacing.unit * 2,
      width: "50%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        padding: "2px"
        // paddingLeft:theme.spacing.unit*2,
        // paddingRight: theme.spacing.unit*2,
      }
      //paddingRight:theme.spacing.unit*5,
      //paddingLeft:theme.spacing.unit*5
    },
    FPbutton: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.8rem"
      }
    }
  };
};

export default styles;

import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Grid,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
  Input,
  Hidden,
  Button,
  CardMedia
} from "@material-ui/core";
import { DriverProfileStyles } from "../../../../styles";
import DriverLayout from "../../common/DriverLayout";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import NoAddress from "./NoAddress";
import {
  APPORIO_TAXI_APIS,
  TAXI_IMAGE_URL
} from "../../../../../managers/api/ApiEndPoints";
import * as ApiManager from "../../../../../managers/api/ApiManager";
import DialogForm from "../../../../custom/DialogForm";
import Edit from "./Edit";
import CustomLoader from "../../../../custom/CustomLoader";
import CustomSnackbar from "../../../../custom/CustomSnackbar";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
        textAlign: "left"
      }}
    >
      {props.children}
    </Typography>
  );
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class ManageDoc extends React.Component {
  constructor(props) {
    super(props);
    this.profileImageRef = React.createRef();
  }
  state = {
    value: 0,
    checked: false,
    drivername: "",
    number: "",
    open: true,
    openList: false,
    openBankModal: false,
    openAddModal: false,
    openEditModalId: false,
    openEditModalDl: false,
    image: "",
    DOCUMENT_ROWS: [],
    loading: true,
    showSnackbar: false,
    snackbarVariant: "error",
    snakBarMessage: "",
    docId: ""
  };

  handleClickOpenModal = (name, id) => {
    // alert(id);
    this.setState({
      docId: id,
      [name]: !this.state[name]
    });
  };
  handleSwitch = () => {
    this.setState(state => ({ checked: !state.checked }));
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };
  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };
  handleClickOpen = name => {
    this.setState({
      [name]: !this.state[name]
    });
  };
  openExplorer = () => {
    console.log("click");
    this.profileImageRef.current.click();
  };
  // --- handle upload ----
  profileUpload = event => {
    let reader = new FileReader();
    reader.onload = e => {
      this.setState({
        image: e.target.result
      });
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  openSnack = (message = "", variant = "error") => {
    this.setState({
      showSnackbar: true,
      snakBarMessage: message,
      snackbarVariant: variant
    });
  };

  finalSnack = (message = "", variant = "error") => {
    this.setState(
      {
        showSnackbar: true,
        snakBarMessage: message,
        snackbarVariant: variant,
        openAddModal: false
      },
      () => {
        this.ManageDocList();
      }
    );
  };

  handleSnackBarClose = () => {
    this.setState({
      showSnackbar: false
    });
  };

  componentDidMount() {
    this.ManageDocList();
  }

  ManageDocList() {
    console.log(
      "**************DRIVER_PERSONAL_DOCUMENT***********",
      APPORIO_TAXI_APIS.DRIVER_DETAILS
    );

    this.setState({
      loading: true
    });
    ApiManager.callPostApi(
      "DRIVER_PERSONAL_DOCUMENT",
      "",
      APPORIO_TAXI_APIS.DRIVER_PERSONAL_DOCUMENT
    )

      .then(response => {
        console.log("*****response*******", response);
        // alert(JSON.stringify(response))

        this.setState({
          DOCUMENT_ROWS: response.data,
          loading: false,
          open: false
        });
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    console.log("driver home render", this.state);
    const { classes } = this.props;
    const { value } = this.state;
    const { checked } = this.state;

    if (this.state.loading) {
      return <CustomLoader showLoader={this.state.loading} />;
    } else {
      return (
        <Grid xs={12} align="left" style={{ marginTop: 10 }}>
          <CustomSnackbar
            showSnackbar={this.state.showSnackbar}
            variant={this.state.snackbarVariant}
            message={this.state.snakBarMessage}
            onClose={this.handleSnackBarClose}
          />
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row"
            }}
          >
            <Grid item xs={6} style={{}}>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "600", color: "#26699d" }}
              >
                Manage Documents
              </Typography>
            </Grid>
          </Grid>
          <hr style={{ color: "#eaeaea" }} />
          {this.state.DOCUMENT_ROWS.length > 0 ? (
            this.state.DOCUMENT_ROWS.map(row => {
              return (
                <div style={{ marginBottom: 10 }}>
                  <Paper
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.handleClickOpenModal("openAddModal", row.id)
                    }
                  >
                    <Grid container xs={12} style={{}}>
                      <Grid item xs={4} md={2} style={{ padding: 10 }}>
                        <img
                          src={TAXI_IMAGE_URL + row.image}
                          height="100"
                          width="100"
                        />
                      </Grid>
                      <Grid item md={10} xs={8} style={{ padding: 20 }}>
                        <Grid container xs={12}>
                          <Grid item md={6} xs={6}>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 600 }}
                            >
                              {row.documentname}
                            </Typography>
                            <Typography
                              variant="display4"
                              style={{ fontSize: "15px" }}
                            >
                              Uploaded on: {row.uploaded_at}
                            </Typography>
                          </Grid>
                          <Grid item md={6} xs={6} align="right" style={{}}>
                            <Button
                              // style={{ backgroundColor: "#" + row.status_color }}
                              style={{ backgroundColor: "#2dcc70" }}
                            >
                              <Typography
                                variant="caption"
                                className={classes.btnText}
                                style={{}}
                              >
                                {row.status}
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              );
            })
          ) : (
            <Typography variant="h4">Please Add Documents</Typography>
          )}

          <DialogForm
            visible={this.state.openAddModal}
            onOutsideTouch={() => this.handleClickOpenModal("openAddModal")}
          >
            <Edit
              docId={this.state.docId}
              openSnack={this.openSnack}
              finalSnack={this.finalSnack}
            />
          </DialogForm>
        </Grid>
      );
    }
  }
}

export default withStyles(DriverProfileStyles)(ManageDoc);

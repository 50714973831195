import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { Modal } from "@material-ui/core";

import Fab from "@material-ui/core/Fab";
import CloseIcon from "@material-ui/icons/Close";
const styles = theme => ({
  centerContainer: {
    backgroundColor: "white",
    flexDirection: "column",
    position: "absolute",
    //paddingLeft: "30px",
    borderRadius: 10,
    // [theme.breakpoints.down('sm')]: {
    //   paddingTop: "10%",
    //   paddingBottom: "10%",
    //   paddingLeft: "5%",
    //   paddingRight: "5%"
    // },
    paddingTop: "2%",
    paddingBottom: "2%",
    paddingLeft: "2%",
    paddingRight: "2%"
  },
  closeBtn: {
    display: "flex",

    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: -15,
    top: -15,
    zIndex: 999
  }
});

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    // width: "50vw",
    borderRadius: "8px",
    transform: `translate(-${top}%, -${left}%)`
  };
}

class DialogForm extends React.Component {
  render() {
    const { classes, children } = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.visible}
        disableAutoFocus={true}
        onClose={() => {
          if (this.props.onOutsideTouch) this.props.onOutsideTouch();
          if (this.props.onClose) this.props.onClose();
        }}
      >
        <div
          className={classes.centerContainer}
          style={Object.assign({}, getModalStyle(), {
            backgroundColor: this.props.centerColor
              ? this.props.centerColor
              : "white"
          })}
        >
          {children}

          {this.props.overFlowButton === false ? null : (
            <Fab
              size="small"
              aria-label="Close"
              className={classes.closeBtn}
              onClick={() => {
                if (this.props.onOutsideTouch) this.props.onOutsideTouch();
                if (this.props.onClose) this.props.onClose();
              }}
            >
              <CloseIcon />
            </Fab>
          )}
        </div>
      </Modal>
    );
  }
}

DialogForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DialogForm);

import React, { Component } from "react";
import { Grid, Typography, Paper, Button } from "@material-ui/core";
// import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
// import * as ApiManager from "../../../../managers/api/ApiManager";

class BookingOtpDetails extends Component {
  state = {
    driverList: []
  };
  //   componentDidMount() {
  //     ApiManager.callPostApi(
  //       "BookingOtpDetails",
  //       "fav driver list",
  //       ApiEndPoints.APPORIO_TAXI_APIS.SELECT_FAV_DRIVER,
  //       {
  //         checkout_id: this.props.checkoutId
  //       }
  //     )
  //       .then(res => this.setState({ driverList: res.data }))
  //       .catch(err => alert(JSON.stringify(err)));
  //   }
  render() {
    return (
      <Grid item md={12} style={{}}>
        <Grid md={12} style={{ padding: "0px 5px" }}>
          <Paper
            style={{
              backgroundColor: "#26699D",
              padding: 10
            }}
          >
            <Typography variant="subtitle1" style={{ color: "#fff" }}>
              Booking details
            </Typography>
            <Typography
              variant="caption"
              style={{ color: "#fff", opacity: "0.5" }}
            >
              Details
            </Typography>
          </Paper>
        </Grid>

        <Grid container md={12} style={{ padding: "0 10px" }}>
          <Grid item md={6} style={{ padding: 20 }}>
            OTP : {this.props.ride_otp}
          </Grid>
        </Grid>
        <Grid md={12} align="right" style={{}}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            // className={classes.margin}
            style={{ margin: "0 5px" }}
            // onClick={() => this.props.addDocumentImage()}
          >
            OK
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default BookingOtpDetails;

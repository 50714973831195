import React from "react";
import { Typography, Button, Paper, Grid, Avatar } from "@material-ui/core";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import * as LanguageManager from "../../../../managers/language/LanguageManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class HolderCarSelection extends React.PureComponent {
  state = {
    selectedPackage: 0,
    selectedIndex: this.props.indexActive,
    selectedCarIndex: 0
  };

  setInitialData = res => {
    let package_id =
      this.props.data[this.state.selectedIndex].package.length > 0
        ? this.props.data[this.state.selectedIndex].package[0].id
        : "";
    let vehicle_id =
      this.props.data[this.state.selectedIndex].package.length > 0
        ? this.props.data[this.state.selectedIndex].package[0].vehicles[0].id
        : this.props.data[this.state.selectedIndex].vehicles[0].id;
    this.props.getServiceId(res.id, package_id, vehicle_id);
  };

  componentDidMount() {
    console.log("GGGGGG" + JSON.stringify(this.props.data));
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "2%",
          height: "100%"
        }}
      >
        {this.props.data.length > 0 ? (
          [
            <Typography
              style={{
                marginLeft: "0.5rem",
                fontSize: "0.9rem",
                fontWeight: "bold",
                textAlign: "left"
              }}
            >
              {LanguageManager.Strings.AVAILABLE_BOOKINGS_LABEL}
            </Typography>,
            <div style={{ display: "flex", padding: "0.5em" }}>
              {this.props.data.map((res, index) => (
                <Button
                  onClick={() =>
                    this.setState(
                      {
                        selectedIndex: index,
                        selectedPackage: 0,
                        selectedCarIndex: 0
                      },
                      () => {
                        this.setInitialData(res);
                        this.props.callback(res.id);
                      }
                    )
                  }
                  style={{
                    fontSize: "0.7rem",

                    marginRight: "0.5rem",
                    paddingLeft: "1.5em",
                    paddingRight: "1.5em",
                    borderRadius: 15,
                    fontWeight:
                      index == this.state.selectedIndex ? "bold" : null,
                    background:
                      index == this.state.selectedIndex ? "#1F447F" : "#EBEBEB",
                    color: index == this.state.selectedIndex && "white"
                  }}
                >
                  {res.serviceName}
                </Button>
              ))}
            </div>,
            <div style={{ display: "flex", padding: "0.5em" }}>
              {this.props.data.length > 0 &&
                this.props.data[this.state.selectedIndex].package.map(
                  (res, index) => (
                    <Button
                      onClick={() =>
                        this.setState({ selectedPackage: index }, () =>
                          this.props.getPackageId(res.id)
                        )
                      }
                      style={{
                        fontSize: "0.7rem",

                        marginRight: "0.5rem",
                        paddingLeft: "1.5em",
                        paddingRight: "1.5em",
                        borderRadius: 15,
                        fontWeight:
                          index == this.state.selectedPackage ? "bold" : null,
                        background:
                          index == this.state.selectedPackage
                            ? "#1F447F"
                            : "#EBEBEB",
                        color: index == this.state.selectedPackage && "white"
                      }}
                    >
                      {res.name}
                    </Button>
                  )
                )}
            </div>,
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                overflowY: "scroll"
              }}
            >
              {this.props.data.length > 0 &&
              this.props.data[this.state.selectedIndex].package.length > 0
                ? this.props.data[this.state.selectedIndex].package[
                    this.state.selectedPackage
                  ].vehicles.map((res, index) => (
                    <Paper
                      onClick={() => {
                        this.setState({ selectedCarIndex: index }, () =>
                          this.props.getVehicleTypeId(res.id)
                        );
                      }}
                      style={{
                        backgroundColor:
                          index == this.state.selectedCarIndex && "#D6E9FD",
                        margin: "0.5rem",
                        display: "flex",
                        flex: 3
                      }}
                    >
                      <div style={{ display: "flex", flex: 0.6 }}>
                        <img
                          src={
                            ApiEndPoints.TAXI_IMAGE_URL + res.vehicleTypeImage
                          }
                          style={{
                            width: 80,
                            height: 80,
                            margin: "0.5rem",
                            backgroundSize: "cover"
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1.7,
                          flexDirection: "column"
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "1rem",
                            fontWeight: "bold",
                            textAlign: "left"
                          }}
                        >
                          {res.vehicleTypeName}
                        </Typography>
                        <Typography
                          style={{ fontSize: "0.7rem", textAlign: "left" }}
                        >
                          {LanguageManager.Strings.DESCRIPTION_HERE_LABEL}
                        </Typography>
                        <Typography
                          style={{ fontSize: "0.7rem", textAlign: "left" }}
                        >
                          {LanguageManager.Strings.ETA_LABEL}: {res.eta}
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flex: 0.8,
                          justifyContent: "ceter",
                          alignItems: "center"
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "1.5rem",
                            fontWeight: "bolder",
                            color: "#40D590"
                          }}
                        >
                          {res.estimate_fase}
                        </Typography>
                      </div>
                    </Paper>
                  ))
                : this.props.data.length > 0 &&
                  this.props.data[this.state.selectedIndex].vehicles.map(
                    (res, index) => (
                      <Paper
                        onClick={() => {
                          this.setState({ selectedCarIndex: index }, () =>
                            this.props.getVehicleTypeId(res.id)
                          );
                        }}
                        style={{
                          backgroundColor:
                            index == this.state.selectedCarIndex && "#D6E9FD",
                          margin: "0.5rem",
                          display: "flex",
                          flex: 3
                        }}
                      >
                        <div style={{ display: "flex", flex: 0.6 }}>
                          <img
                            src={
                              ApiEndPoints.TAXI_IMAGE_URL + res.vehicleTypeImage
                            }
                            style={{
                              width: 50,
                              height: 50,
                              margin: "0.5rem",
                              backgroundSize: "cover"
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: 1.7,
                            flexDirection: "column"
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "1rem",
                              fontWeight: "bold",
                              textAlign: "left"
                            }}
                          >
                            {res.vehicleTypeName}
                          </Typography>

                          {console.log("****ETA:::", res.eta)}
                          {res.eta == null || res.eta == "" ? null : (
                            <Typography
                              style={{ fontSize: "0.7rem", textAlign: "left" }}
                            >
                              {LanguageManager.Strings.ETA_LABEL}: {res.eta}
                            </Typography>
                          )}
                          <Typography
                            style={{ fontSize: "0.7rem", textAlign: "left" }}
                          >
                            {res.estimate_fase}
                          </Typography>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flex: 0.8,
                            justifyContent: "ceter",
                            alignItems: "center"
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 18,
                              fontWeight: "bolder",
                              color: "#40D590"
                            }}
                          >
                            {res.estimate_fase}
                          </Typography>
                        </div>
                      </Paper>
                    )
                  )}
            </div>
          ]
        ) : (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Avatar
              style={{ width: 80, height: 80 }}
              src="https://cdn2.iconfinder.com/data/icons/picons-essentials/71/smiley_sad-512.png"
            />
            <FontAwesomeIcon
              icon="spinner"
              className="fa-spin"
              style={{ color: "black", width: "10%", height: "10%" }}
            />

            <Typography style={{ fontSize: 12 }}>
              {LanguageManager.Strings.NO_SERVICE_MESSAGE}
            </Typography>
          </Grid>
        )}
      </div>
    );
  }
}

export default HolderCarSelection;

import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import HomeHeader from "../home/common/HeaderComponent";
import ImageFormComponent from "../home/common/ImageFormComponent";
import { HomeStyles } from "../../styles";
import HomeEstimatecontainer from "./common/HomeEstimatecontainer";
import HomeBottomContainer from "./common/HomeBottomContainer";
import HomeFooterContainer from "./common/HomeFooterContainer";
import * as ApiManager from "../../../managers/api/ApiManager";
import * as SessionKeys from "../../../managers/session/SessionKeys";
import { PHONE } from "../../../values/Icons";
import * as ApiEndPoints from "../../../managers/api/ApiEndPoints";
import CustomLoader from "../../custom/CustomLoader";
import * as SessionManager from "../../../managers/session/SessionManager";
import CustomSnackbar from "../../custom/CustomSnackbar";
import FullScreenDialog from "../../custom/FullScreenDialog";
import { CUSTOM_FONT } from "../../../values/Typography";

class ScreenOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonColor1: "gold",
      buttonColor2: "gray",
      spacing: "16",
      gridLayout: true,
      rowData: [],
      imageData: {},
      textFieldHint: null,
      featureBoardData: [],
      estimateData: null,
      featureCompData: null,
      userBannerImage: "",
      languages: "",
      userConfig: undefined,
      features_component: [],
      showDialog: false,
      contentStyle: "paragraph",
      contentBody: "",
      contentTitle: "",
      isLoading: false,
      showSnackbar: false,
      snackbarVariant: "error",
      snakBarMessage: "",
      finalMessage: ""
    };
  }
  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false
    });
  };

  fetchUserCmsData = slugValue => {
    const { userConfig } = this.state;

    this.setState({ isLoading: true });

    ApiManager.callPostApi(
      "LoginForm",
      "driver_cms_data_api",
      ApiEndPoints.APPORIO_TAXI_APIS.USER_CMSDATA,
      {
        slug: slugValue,
        country_id: userConfig.countries[0].id
      }
    ).then(res => {
      // console.log("*(*(*(*" + JSON.stringify(res));
      if (res.result === "1") {
        this.setState(
          {
            contentBody: res.data.description,
            contentTitle: res.data.title,
            isLoading: false
          },
          () => {
            this.setState({ openDialog: true });
          }
        );
      } else {
        this.setState({
          isLoading: false,
          showSnackbar: true,
          snakBarMessage: res.message,
          snackbarVariant: "error"
        });
      }
    });
  };

  async componentDidMount() {
    this.setState({ isLoading: true });

    ApiManager.callPostApi(
      "Home.js",
      "USER_HOME_LIST",
      ApiEndPoints.APPORIO_TAXI_APIS.USER_HOME_LIST
    )
      .then(async response => {
        // console.log("USER_HOME_LIST", response);
        SessionManager.saveSessionData(
          SessionKeys.DRIVER_LOGO,
          JSON.stringify(response.data.app_logo)
        );
        SessionManager.saveSessionData(
          SessionKeys.USER_BG,
          JSON.stringify(response.data.user_login_bg_image)
        );
        SessionManager.saveSessionData(
          SessionKeys.IOS_LINK,
          JSON.stringify(response.data.ios_link)
        );
        SessionManager.saveSessionData(
          SessionKeys.ANDROID_LINK,
          JSON.stringify(response.data.android_link)
        );

        if (response.result === "1") {
          this.setState(
            // SessionManager.saveSessionData(
            //   SessionKeys.DRIVER_LOGO,
            //   JSON.stringify(response.data.app_logo)
            // ),
            {
              userBannerImage: response.data.banner_image,
              appLogo: response.data.app_logo,
              textFieldHint: {
                pick: response.data.book_form_config.start_address_hint,
                drop: response.data.book_form_config.end_address_hint,
                button: response.data.book_form_config.book_btn_title
              },
              featureBoardData: response.data.feature_board_data,
              features_component: response.data.features_component,
              featured_component_main_image:
                response.data.featured_component_main_image,
              estimateData: response.data.estimate_container,
              featureCompData: response.data.features_component,
              iosLink: response.data.ios_link,
              iosDriverLink: response.data.iosDriverLink,
              androidLink: response.data.android_link,
              androidDriverLink: response.data.androidDriverLink,

              user_estimate_image: response.data.user_estimate_image,
              footer: response.data.footer
            },
            async () => {
              const getData = await JSON.parse(
                SessionManager.getSessionData(SessionKeys.USER_CONFIG)
              );
              this.setState({ userConfig: getData, isLoading: false });
            }
          );
        } else {
          this.setState({ isLoading: false });
          // console.log("HOME CONFIG ERR:" + response.message);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    // console.log("123467890987654321", this.state.appLogo);
    const { classes } = this.props;
    const { userConfig } = this.state;

    if (this.state.isLoading) {
      return <CustomLoader showLoader={this.state.isLoading} />;
    } else {
      return (
        <div style={{ flexGrow: 1, minWidth: "360px", overflow: "hidden" }}>
          {userConfig !== undefined &&
            userConfig !== null &&
            userConfig !== "" && (
              <div style={{ flexGrow: 1, minWidth: "360px" }}>
                <HomeHeader
                  appLogo={this.state.appLogo}
                  userConfig={this.state.userConfig.languages}
                  icon={this.state.userConfig.segments[0].icon}
                />

                <CustomSnackbar
                  showSnackbar={this.state.showSnackbar}
                  variant={this.state.snackbarVariant}
                  message={this.state.snakBarMessage}
                  onClose={this.handleSnackBarClose}
                />
                <CardMedia
                  className={classes.backgroundImage}
                  image={this.state.userBannerImage}
                >
                  <Grid
                    container
                    xl={12}
                    className={classes.formViewContainer}
                    justify="flex-end"
                    direction="row"
                  >
                    {this.state.textFieldHint ? (
                      <ImageFormComponent
                        hintsObject={this.state.textFieldHint}
                        buttonTitle={this.state.imageFormButton}
                      />
                    ) : null}
                  </Grid>

                  <FullScreenDialog
                    contentStyle={this.state.contentStyle}
                    showDialog={this.state.openDialog}
                    handleClose={() =>
                      this.state.contentStyle === "list"
                        ? this.setState({
                            openDialog: false,
                            contentStyle: "paragraph",
                            contentBody: ""
                          })
                        : this.setState({ openDialog: false })
                    }
                    contentTitle={this.state.contentTitle}
                    contentBody={this.state.contentBody}
                  />

                  <Paper elevation={10} className={classes.characterGrid}>
                    <Grid align="center">
                      {" "}
                      {/* <Typography
                        style={{
                          fontFamily: CUSTOM_FONT,
                          fontSize: "2rem",
                          fontWeight: "bolder",
                          paddingBottom: "10px"
                        }}
                      >
                        Why Easy Taxi Nepal ?
                      </Typography> */}
                    </Grid>
                    {this.state.featureBoardData.length > 0 ? (
                      <Grid
                        container
                        justify="center"
                        className={classes.featureGrid}
                      >
                        {this.state.featureBoardData.map((item, index) => (
                          <div
                            key={index}
                            className={classes.featureBoardContainer}
                          >
                            <Typography
                              key={index}
                              variant="subtitle1"
                              style={{
                                fontWeight: "bold",
                                fontSize: "1.5rem",
                                fontFamily: CUSTOM_FONT
                              }}
                              align="center"
                            >
                              {item.title}
                            </Typography>
                            <div
                              className={classes.featureCard}
                              style={{
                                fontFamily: CUSTOM_FONT,
                                borderRight:
                                  index !==
                                  this.state.featureBoardData.length - 1
                                    ? "1px solid grey"
                                    : null
                              }}
                            >
                              <Typography
                                key={index}
                                style={{
                                  fontSize: "0.9rem",
                                  fontFamily: CUSTOM_FONT,
                                  color: "#7F7F7F",
                                  textAlign: "left"
                                }}
                              >
                                {item.description}
                              </Typography>
                            </div>
                          </div>
                        ))}
                      </Grid>
                    ) : null}
                  </Paper>
                </CardMedia>
                {this.state.estimateData ? (
                  <HomeEstimatecontainer
                    user_estimate_image={this.state.user_estimate_image}
                    data={this.state.estimateData}
                  />
                ) : null}

                {/* {this.state.features_component.length > 0 ? ( */}
                <HomeBottomContainer
                  focusImage={PHONE}
                  data={this.state.features_component}
                  featured_component_main_image={
                    this.state.featured_component_main_image
                  }
                />
                {/* ) : null} */}
                <HomeFooterContainer
                  onTermCLick={slug => this.fetchUserCmsData(slug)}
                  iosLink={this.state.iosLink}
                  androidLink={this.state.androidLink}
                  iosDriverLink={this.state.iosDriverLink}
                  androidDriverLink={this.state.androidDriverLink}
                  footer={this.state.footer}
                />
              </div>
            )}
        </div>
      );
    }
  }
}

ScreenOne.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(HomeStyles)(ScreenOne);

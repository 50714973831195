import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Map, Marker, GoogleApiWrapper, Polyline } from "google-maps-react";
import { GOOGLE_API_KEY } from "../../../../constants/AppConstants";
const google = window.google;
const styles = theme => ({
  button: {
    //margin: theme.spacing.unit
  },
  list: {
    width: 300,
    textAlign: "-webkit-center"
  },
  fullList: {
    width: "auto"
  }
});

class MapContainer extends React.PureComponent {
  state = {
    directions: [],
    isShow: true
  };

  getBounds(points) {
    var bounds = new this.props.google.maps.LatLngBounds();
    for (var i = 0; i < points.length; i++) {
      bounds.extend(points[i]);
    }
    return bounds;
  }

  showRoute = () => {
    if (this.props.showRoute) {
      const DirectionsService = new google.maps.DirectionsService();

      DirectionsService.route(
        {
          origin: new google.maps.LatLng(
            this.props.startLocation.lat,
            this.props.startLocation.lng
          ),
          destination: new google.maps.LatLng(
            this.props.dropLocation.lat,
            this.props.dropLocation.lng
          ),
          travelMode: google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            console.log("hhhh>>>>" + JSON.stringify(result));

            this.setState(
              {
                directions: result.routes[0].overview_path
              },
              () => this.props.directionCallback(this.state.directions)
            );
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Map
        className={classes.MapContainercss}
        draggable={true}
        styles={{ width: "100%", height: "100%" }}
        google={this.props.google}
        center={{
          lat:
            this.props.startLocation === ""
              ? 28.4595
              : this.props.startLocation.lat,
          lng:
            this.props.startLocation === ""
              ? 77.0266
              : this.props.startLocation.lng
        }}
        bounds={this.getBounds(this.state.directions)}
      >
        <Polyline
          fillColor="#0000FF"
          fillOpacity={0.35}
          path={this.state.directions}
          strokeColor="#0000FF"
          strokeOpacity={0.8}
          strokeWeight={3}
        />

        <Marker
          icon={{
            url:
              "https://cdn1.iconfinder.com/data/icons/social-messaging-ui-color/254000/67-512.png",
            anchor: new google.maps.Point(28, 28),
            scaledSize: new google.maps.Size(40, 40)
          }}
          className={classes.locationPin}
          position={{
            lat: this.props.dropLocation.lat,
            lng: this.props.dropLocation.lng
          }}
          name={"Current location"}
        />

        <Marker
          icon={{
            url:
              "https://cdn1.iconfinder.com/data/icons/social-messaging-ui-color/254000/67-512.png",
            anchor: new google.maps.Point(28, 28),
            scaledSize: new google.maps.Size(40, 40)
          }}
          position={{
            lat: this.props.startLocation.lat,
            lng: this.props.startLocation.lng
          }}
          name={"Current location"}
        />
        {this.showRoute()}
      </Map>
    );
  }
}

export default withStyles(styles)(
  GoogleApiWrapper({
    apiKey: GOOGLE_API_KEY
  })(MapContainer)
);

import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Grid,
  TextField,
  Button,
  Icon,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox
} from "@material-ui/core";
import { DriverProfileStyles } from "../../../../styles";
import Typography from "@material-ui/core/Typography";
import ChangePassword from "./ChangePassword";
// import { getSessionData } from "../../../../../managers/session/SessionManager";
// import { DRIVER_DATA } from "../../../../../managers/session/SessionKeys";
import * as SessionManager from "../../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../../managers/session/SessionKeys";
import CustomLoader from "../../../../custom/CustomLoader";
import * as ApiManager from "../../../../../managers/api/ApiManager";
import {
  APPORIO_TAXI_APIS,
  TAXI_IMAGE_URL
} from "../../../../../managers/api/ApiEndPoints";
import CustomSnackbar from "../../../../custom/CustomSnackbar";
import DialogForm from "../../../../custom/DialogForm";
import EditPhone from "./EditPhone";
import PhoneField from "./PhoneField";
import EventBus from "eventing-bus";
import { CUSTOM_FONT } from "../../../../../values/Typography";
// import { DRIVER_IMAGE } from "../../../../../values/Icons";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
        textAlign: "left"
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class DriverProfile extends React.Component {
  constructor(props) {
    super(props);
    this.profileImageRef = React.createRef();
  }
  state = {
    wait: true,
    value: 0,
    checked: null,
    drivername: "",
    gender: null,
    number: "",
    open: false,
    openList: false,
    openBankModal: false,
    openAddressModal: false,
    openEditModalId: false,
    openEditModalDl: false,
    image: "",
    changePassword: false,
    mainContent: true,
    DriverData: null,
    DriverNumber: null,
    showSnackbar: false,
    snackbarVariant: "error",
    snakBarMessage: "",
    DRIVER_CONFIG_ROWS: [],
    EditPhone: false
  };
  // handleClickOpen = () => {
  //   this.setState({ openIdmodal: true });
  // };

  handleDriverDataInput = (event, name) => {
    this.setState({
      DriverData: {
        ...this.state.DriverData,
        [name]: event.target.value
      }
    });
  };

  handleDriverDataPhoneInput = (event, name) => {
    this.setState({
      DriverData: {
        ...this.state.DriverData,
        [name]: event.target.value
      },
      DriverNumber: event.target.value
    });
  };

  // componentDidMount() {
  //   console.log(JSON.parse(getSessionData(DRIVER_DATA)))
  //   let data = JSON.parse(getSessionData(DRIVER_DATA));

  //   this.setState({
  //     DriverData: data,
  //     wait: (data) ? false : true,
  //     checked: (data.allow_other_smoker == 1) ? true : false,
  //     image: TAXI_IMAGE_URL + data.profile_image
  //   })
  // }

  componentDidMount() {
    // console.log(JSON.parse(getSessionData(DRIVER_DATA)))
    // let driverdata = JSON.parse(getSessionData(DRIVER_DATA));
    // console.log("qazwsxedcrfvtgbyhnujmikolp", this.state.DRIVER_CONFIG_ROWS.register.email)
    console.log(
      JSON.parse(SessionManager.getSessionData(SessionKeys.DRIVER_CONFIG))
    );
    let data = JSON.parse(
      SessionManager.getSessionData(SessionKeys.DRIVER_CONFIG)
    );
    this.setState({
      DRIVER_CONFIG_ROWS: data
    });
    ApiManager.callPostApi("Driver data", "", APPORIO_TAXI_APIS.DRIVER_DETAILS)

      .then(response => {
        console.log("*****response*******", response);

        this.setState({
          DriverData: response.data,
          DriverNumber: response.data.phoneNumber,
          wait: response.data ? false : true,
          checked: response.data.allow_other_smoker === 1 ? true : false,
          image: TAXI_IMAGE_URL + response.data.profile_image
        });
        console.log(
          "qazwsxedcrfvtgbyhnujmikolp",
          this.state.DRIVER_CONFIG_ROWS
        );
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleChange = (event, name) => {
    this.setState({
      [name]: event.target.value
    });
  };
  handleCheck = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClickOpen = name => {
    this.setState({
      [name]: !this.state[name]
    });
  };

  handleDropChange = value => {
    this.setState({
      selectedDrop: value
    });
  };

  handleSnackBarClose = () => {
    this.setState({
      showSnackbar: false
    });
  };

  openExplorer = () => {
    console.log("click");
    this.profileImageRef.current.click();
  };

  handleClickOpenModal = name => {
    this.setState({
      [name]: !this.state[name]
    });
  };

  openSnack = (message = "", variant = "error") => {
    this.setState({
      showSnackbar: true,
      snakBarMessage: message,
      snackbarVariant: variant
    });
  };

  // --- handle upload ----
  profileUpload = event => {
    let reader = new FileReader();
    reader.onload = e => {
      this.setState({
        image: e.target.result
      });
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  //edit

  saveProfile = () => {
    console.log("image", this.state.image);
    this.setState({
      wait: true
    });

    if (
      this.state.DriverData.first_name === "" ||
      this.state.DriverData.last_name === "" ||
      this.state.DriverData.email === ""
    ) {
      // alert('error field')
      // return
      this.setState({
        showSnackbar: true,
        snakBarMessage: "Please enter Fields",
        wait: false,
        snackbarVariant: "warning"
      });
    } else {
      //  Edit form
      ApiManager.callPostApi(
        "EDIT_PROFILE",
        "",
        APPORIO_TAXI_APIS.EDIT_PROFILE,

        {
          first_name: this.state.DriverData.first_name,
          last_name: this.state.DriverData.last_name,
          email: this.state.DriverData.email,
          driver_gender: this.state.DriverData.driver_gender,
          phone: this.state.DriverNumber,
          // phone: this.state.DriverData.phoneNumber,
          // smoker_type: this.state.DriverData.smoker_type,
          smoker_type: "0",
          // allow_other_smoker: this.state.checked ? "1" : "0",
          allow_other_smoker: "",
          profile_image: this.state.image,
          api_type: 1
        }
      )

        .then(response => {
          console.log("******EDIT PROFILE******", response);
          // this.setState({
          //   wait: false,
          // })
          switch (parseInt(response.result)) {
            case 1:
              //session update
              let tempObj = JSON.parse(
                SessionManager.getSessionData(SessionKeys.DRIVER_DATA)
              );
              tempObj.first_name = response.data.first_name;
              tempObj.last_name = response.data.last_name;
              tempObj.email = response.data.email;
              tempObj.profile_image = response.data.profile_image;
              SessionManager.saveSessionData(
                SessionKeys.DRIVER_DATA,
                JSON.stringify(tempObj)
              );
              this.setState(
                {
                  showSnackbar: true,
                  snakBarMessage: response.message,
                  snackbarVariant: "success",
                  wait: false
                },
                () =>
                  setTimeout(() => {
                    EventBus.publish("REFRESH_SESSION", "1");
                  }, 500)
              );
              // SessionManager.saveSessionData(SessionKeys.DRIVER_LAST_NAME, response.data.last_name)
              // SessionManager.saveSessionData(SessionKeys.DRIVER_EMAIL, response.data.email)
              // SessionManager.saveSessionData(SessionKeys.DRIVER_IMAGE, response.data.profile_image)
              // setTimeout(() => {
              //   console.log("DRIVER_FIRST_NAME", JSON.stringify(SessionManager.getSessionData(SessionKeys.DRIVER_DATA)))
              // //   console.log("DRIVER_LAST_NAME", JSON.stringify(SessionManager.getSessionData(SessionKeys.DRIVER_LAST_NAME)))
              // //   console.log("DRIVER_EMAIL", JSON.stringify(SessionManager.getSessionData(SessionKeys.DRIVER_EMAIL)))
              // //   console.log("DRIVER_IMAGE", JSON.stringify(SessionManager.getSessionData(SessionKeys.DRIVER_IMAGE)))
              // }, 1000)
              break;
            case 0:
              this.setState(
                {
                  showSnackbar: true,
                  snakBarMessage: response.message,
                  wait: false
                },
                setTimeout(() => {
                  EventBus.publish("REFRESH_SESSION", "1");
                }, 500)
              );
              break;
            default:
              return 1;
          }
          // console.log(response)
          //  console.log("DRIVER_DATA long",JSON.parse(SessionManager.getSessionData(SessionKeys.DRIVER_DATA)))
        })
        .catch(error => {});
    }
  };

  setPhoneNumber = number => {
    this.setState(
      {
        DriverNumber: number,
        EditPhone: false
      }
      // , () => { window.location.reload() }
    );
  };

  render() {
    console.log("driver profile render", this.state);
    const { classes } = this.props;
    // const { value } = this.state;
    // const { checked } = this.state;

    if (this.state.wait) {
      return <CustomLoader showLoader={this.state.wait} />;
    } else {
      return (
        <div>
          {/* vaibhav */}
          {this.state.mainContent && (
            <div>
              <Grid container md={12}>
                <CustomSnackbar
                  showSnackbar={this.state.showSnackbar}
                  variant={this.state.snackbarVariant}
                  message={this.state.snakBarMessage}
                  onClose={this.handleSnackBarClose}
                />
                <Grid md={5} style={{ marginTop: 20 }}>
                  <Typography variant="body2" style={{ fontWeight: 600 }}>
                    Personal info
                  </Typography>
                  <Typography variant="display4" style={{ fontSize: "15px" }}>
                    Add your details. We recommend uploading a photo
                  </Typography>
                </Grid>
                <Grid md={5}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Name"
                    value={
                      this.state.DriverData !== ""
                        ? this.state.DriverData.first_name
                        : ""
                    }
                    InputProps={{
                      style: {
                        fontSize: 15,
                        fontFamily: CUSTOM_FONT,
                        fontWeight: "bold"
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 15,
                        fontFamily: CUSTOM_FONT,
                        fontWeight: "bold"
                      }
                    }}
                    className={classes.detailFields}
                    onChange={event =>
                      this.handleDriverDataInput(event, "first_name")
                    }
                    // onChange={text => this.setState({ vehicleColor: text })}
                    margin="normal"
                    variant="outlined"
                  />
                  <TextField
                    style={{ width: "100%" }}
                    label="Surname"
                    value={
                      this.state.DriverData !== ""
                        ? this.state.DriverData.last_name
                        : ""
                    }
                    InputProps={{
                      style: {
                        fontSize: 15,
                        fontFamily: CUSTOM_FONT,
                        fontWeight: "bold"
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 15,
                        fontFamily: CUSTOM_FONT,
                        fontWeight: "bold"
                      }
                    }}
                    className={classes.detailFields}
                    onChange={event =>
                      this.handleDriverDataInput(event, "last_name")
                    }
                    margin="normal"
                    variant="outlined"
                  />
                  {/* <Grid container md={12}>
                    <Grid item md={12} style={{}}> */}

                  {/* <TextField
                    disabled
                    style={{ width: "100%" }}
                    label="Phone Number"
                    value={this.state.DriverData != "" ? this.state.DriverData.phoneNumber : ""}
                    InputProps={{
                      style: {
                        fontSize: 15,
                        fontFamily: CUSTOM_FONT,
                        fontWeight: "bold"
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              this.handleClickOpenModal("EditPhone")}>
                            <Typography className={classes.EditPhone}
                              style={{ color: "#3F51B5" }}>
                              Edit
                               </Typography>
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: 15,
                        fontFamily: CUSTOM_FONT,
                        fontWeight: "bold"
                      }
                    }}
                    className={classes.detailFields}
                    onChange={(event) => this.handleDriverDataInput(event, "phoneNumber")}
                    margin="normal"
                    variant="outlined"
                  /> */}

                  {this.state.DRIVER_CONFIG_ROWS.register.driver_phone_otp ? (
                    <PhoneField
                      DriverData={this.state.DriverData}
                      DriverNumber={this.state.DriverNumber}
                      handleClickOpenModal={this.handleClickOpenModal}
                      handleDriverDataInput={this.handleDriverDataInput}
                    />
                  ) : (
                    <div>
                      <TextField
                        style={{ width: "100%" }}
                        label="Phone Number"
                        value={
                          this.state.DriverData !== ""
                            ? this.state.DriverNumber
                            : ""
                        }
                        InputProps={{
                          style: {
                            fontSize: 15,
                            fontFamily: CUSTOM_FONT,
                            fontWeight: "bold"
                          }
                          // endAdornment: (
                          //   <InputAdornment position="end">
                          //     <IconButton
                          //       onClick={() =>
                          //         this.handleClickOpenModal("EditPhone")}>
                          //       <Typography className={classes.EditPhone}
                          //         style={{ color: "#3F51B5" }}>
                          //         Edit
                          //        </Typography>
                          //     </IconButton>
                          //   </InputAdornment>
                          // )
                        }}
                        InputLabelProps={{
                          style: {
                            fontSize: 15,
                            fontFamily: CUSTOM_FONT,
                            fontWeight: "bold"
                          }
                        }}
                        className={classes.detailFields}
                        onChange={event => {
                          if (
                            !this.state.DRIVER_CONFIG_ROWS.register
                              .driver_phone_otp &&
                            event.target.value.length <=
                              this.state.DRIVER_CONFIG_ROWS.countries[0]
                                .maxNumPhone
                          ) {
                            this.handleDriverDataPhoneInput(
                              event,
                              "phoneNumber"
                            );
                          }
                        }}
                        margin="normal"
                        variant="outlined"
                      />
                      {/* <Typography>
                        <div
                          style={{
                            cursor: "pointer",
                            paddingTop: 5,
                            color: "#3F51B5"
                          }}
                          onClick={() =>
                            this.setState({
                              changePassword: !this.state.changePassword,
                              mainContent: !this.state.mainContent
                            })
                          }
                        >
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Icon>lock</Icon>
                            <div style={{ marginTop: 5 }}>Change password</div>
                          </div>
                        </div>
                      </Typography> */}
                    </div>
                  )}
                  {/* </Grid> */}
                  <DialogForm
                    visible={this.state.EditPhone}
                    onOutsideTouch={() =>
                      this.handleClickOpenModal("EditPhone")
                    }
                  >
                    <EditPhone
                      setPhoneNumber={this.setPhoneNumber}
                      handleSnackBarClose={this.handleSnackBarClose}
                      DRIVER_CONFIG_ROWS={this.state.DRIVER_CONFIG_ROWS}
                      openSnack={this.openSnack}
                      handleModal={this.handleClickOpenModal}
                    />
                  </DialogForm>
                  {/* <Grid item style={{ paddingTop: 20 }}>
                      <IconButton>
                        <Icon>
                          <EditIcon />
                        </Icon>
                      </IconButton>
                    </Grid> */}
                  {/* </Grid> */}
                  <Grid>
                    {/* {imagepreview} */}
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={this.state.image}
                        style={{
                          marginTop: 10,
                          width: 60,
                          height: 60,
                          borderRadius: "50%",
                          objectFit: "scale-down",
                          cursor: "pointer",
                          boxShadow: "0px 0px 11px -5px rgba(0,0,0,0.75)"
                        }}
                        onClick={() => this.openExplorer()}
                      />
                      <div
                        style={{
                          cursor: "pointer",
                          paddingTop: 25,
                          color: "#3F51B5"
                        }}
                        onClick={() => this.openExplorer()}
                      >
                        Change Profile image
                      </div>
                    </div>
                    <input
                      label="change"
                      type="file"
                      style={{ width: 0, overflow: "hidden", display: "none" }}
                      ref={this.profileImageRef}
                      onChange={event => this.profileUpload(event)}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {this.state.DRIVER_CONFIG_ROWS.register.email && (
                <Grid container md={12}>
                  <Grid md={5} style={{ marginTop: 20 }}>
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      Account info
                    </Typography>
                    <Typography
                      variant="display4"
                      style={{ fontSize: "15px", paddingRight: 20 }}
                    >
                      Add your email to set up your account. You'll be able to
                      change it later.
                    </Typography>
                  </Grid>
                  <Grid xs={12} md={5}>
                    <TextField
                      style={{ width: "100%" }}
                      label="Email"
                      value={
                        this.state.DriverData !== ""
                          ? this.state.DriverData.email
                          : ""
                      }
                      InputProps={{
                        style: {
                          fontSize: 15,
                          fontFamily: CUSTOM_FONT,
                          fontWeight: "bold"
                        }
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: 15,
                          fontFamily: CUSTOM_FONT,
                          fontWeight: "bold"
                        }
                      }}
                      className={classes.detailFields}
                      onChange={event =>
                        this.handleDriverDataInput(event, "email")
                      }
                      margin="normal"
                      variant="outlined"
                    />
                    <Typography>
                      <div
                        style={{
                          cursor: "pointer",
                          paddingTop: 5,
                          color: "#3F51B5"
                        }}
                        onClick={() =>
                          this.setState({
                            changePassword: !this.state.changePassword,
                            mainContent: !this.state.mainContent
                          })
                        }
                      >
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Icon>lock</Icon>
                          <div style={{ marginTop: 5 }}>Change password</div>
                        </div>
                      </div>
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {this.state.DRIVER_CONFIG_ROWS.register.gender && (
                <Grid container md={12} style={{ padding: "10px 0" }}>
                  <Grid md={5} style={{ marginTop: 10 }}>
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      Gender
                    </Typography>
                    <Typography
                      variant="display4"
                      style={{ fontSize: "15px", paddingRight: 40 }}
                    >
                      Select gender type from the opitons
                    </Typography>
                  </Grid>

                  <Grid md={5}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <RadioGroup
                        style={{ display: "flex", flexDirection: "row" }}
                        aria-label="Gender"
                        name="gender1"
                        className={classes.group}
                        value={parseInt(this.state.DriverData.driver_gender)}
                        onChange={event =>
                          this.handleDriverDataInput(event, "driver_gender")
                        }
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio color="primary" />}
                          label="Male"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio color="primary" />}
                          label="Female"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              {this.state.DRIVER_CONFIG_ROWS.register.smoker && (
                <Grid container md={12} style={{ padding: "10px 0" }}>
                  <Grid md={5} style={{ marginTop: 10 }}>
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      Type of smoker
                    </Typography>
                    <Typography
                      variant="display4"
                      style={{ fontSize: "15px", paddingRight: 40 }}
                    >
                      Choose if you are a smoker or non-smoker
                    </Typography>
                  </Grid>
                  <Grid md={5}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <RadioGroup
                        style={{ display: "flex", flexDirection: "row" }}
                        aria-label="Gender"
                        name="gender1"
                        className={classes.group}
                        value={parseInt(this.state.DriverData.smoker_type)}
                        onChange={event =>
                          this.handleDriverDataInput(event, "smoker_type")
                        }
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio color="primary" />}
                          label="Smoker"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio color="primary" />}
                          label="Non-Smoker"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              {this.state.DRIVER_CONFIG_ROWS.register.smoker && (
                <Grid container md={12} style={{ padding: "10px 0" }}>
                  <Grid md={5} style={{ marginTop: 10 }}>
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      Permit Rider to Smoke
                    </Typography>
                    <Typography
                      variant="display4"
                      style={{ fontSize: "15px", paddingRight: 40 }}
                    >
                      Allow rider to smoke
                    </Typography>
                  </Grid>
                  {console.log(this.state.DriverData.allow_other_smoker)}
                  <Grid md={5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{ color: "#3F51B5" }}
                          checked={this.state.checked}
                          onChange={this.handleCheck("checked")}
                          value={this.state.DriverData.allow_other_smoker}
                        />
                      }
                      label="Allow User to Smoke"
                    />
                  </Grid>
                </Grid>
              )}
              <Grid md={10} align="right" style={{ paddingRight: 0 }}>
                <Button
                  variant="contained"
                  style={{
                    width: "20%",
                    fontWeight: "800",
                    backgroundColor: "#3F51B5",
                    color: "#fff"
                  }}
                  onClick={() => this.saveProfile()}
                >
                  Save
                </Button>
              </Grid>
            </div>
          )}

          {this.state.changePassword && (
            <ChangePassword
              onBackPress={() =>
                this.setState({
                  changePassword: !this.state.changePassword,
                  mainContent: !this.state.mainContent
                })
              }
            />
          )}

          {/* vaibhav */}
        </div>
      );
    }
  }
}

export default withStyles(DriverProfileStyles)(DriverProfile);

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import TableHeaderColumn from "./TableHeaderColumn";
import { FONT_FAMILY } from "../../utils/AppConstants";

const CustomTableCell = withStyles(theme => ({
  head: {
    // backgroundColor: pureWhite,
    color: theme.palette.common.white
  },
  body: {
    fontFamily: FONT_FAMILY,

    padding: 0,

    borderRight: "1px solid #b5b5b5"
  }
  // align: "justify"
}))(TableCell);

const styles = theme => ({
  root: {
    alignSelf: "center",
    elevation: 4,

    marginTop: theme.spacing.unit * 3
  },
  table: {
    tableLayout: "fixed",
    minWidth: '768px',
    padding: 0
  },
  tableWrapper: {
    overflowX: "auto"
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#ececec"
    }
  }
});

class PresentationTable extends React.Component {
  constructor(props) {
    super(props);
  }

  sortTablesRows = columnIndex => {
    let newArray = [];
    // let newSortedColIndex = this.state.sortedColIndex;

    // if (this.state.sortedColIndex.includes(columnIndex)) {
    //   newArray = ARRAY_FUNC.sortDescendingById(TABLE_ROWS);

    //   const index = newSortedColIndex.indexOf(columnIndex);
    //   newSortedColIndex.splice(index, 1);
    // } else {
    //   newArray = ARRAY_FUNC.sortAscendingById(TABLE_ROWS);
    //   newSortedColIndex.push(columnIndex);
    // }
    // TABLE_ROWS = newArray;
    // this.setState({ sortedColIndex: newSortedColIndex });
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {this.props.columns.map((col, index) => (
                <CustomTableCell
                  style={{
                    color: "#fff",
                    fontWeight: "600",
                    backgroundColor: this.props.tableHeadBackground
                  }}
                  key={index} alignCenter>
                  {
                    <TableHeaderColumn
                      tableHeadFontColor={this.props.tableHeadFontColor}
                      tableheadFontSize={this.props.tableheadFontSize}
                      index={index}
                      sorted={false}
                      data={col}
                      onClick={this.sortTablesRows}
                    />
                  }
                </CustomTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.children.map((singleRow, index) => {
              return (
                <TableRow className={classes.row} key={index}>
                  {singleRow.map((col, i) => {
                    return (
                      <CustomTableCell
                        component="th"
                        scope="row"
                        padding={"none"}
                        key={i}
                        style={{
                          backgroundColor: col.props.color
                        }}
                      >
                        {col}
                      </CustomTableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper >
    );
  }
}

PresentationTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PresentationTable);

import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import PagnationTable from "../../../custom/PaginationTable";
import * as LanguageManager from "../../../../managers/language/LanguageManager";
const columns = [
  LanguageManager.Strings.TABLE_TITLE1,
  LanguageManager.Strings.TABLE_TITLE2,
  LanguageManager.Strings.TABLE_TITLE3,
  LanguageManager.Strings.TABLE_TITLE4,
  LanguageManager.Strings.TABLE_TITLE5
];

const prepareTableData = (data, currency) => {
  let allTableRows = [];

  data.map((row, index) => {
    let singleRow = [];

    singleRow.push(
      <Typography
        style={{
          display: "flex",
          fontSize: "0.8rem",
          justifyContent: "center"
        }}
      >
        {index + 1}
      </Typography>
    );
    singleRow.push(
      <Typography
        style={{
          display: "flex",
          fontSize: "0.8rem",
          justifyContent: "center"
        }}
      >
        {currency} {row.amount}
      </Typography>
    );
    singleRow.push(
      <Typography
        style={{
          display: "flex",
          fontSize: "0.8rem",
          justifyContent: "center"
        }}
      >
        {row.date.substring(0, 10)}
      </Typography>
    );
    singleRow.push(
      <Typography
        style={{
          display: "flex",
          fontSize: "0.8rem",
          justifyContent: "center"
        }}
      >
        {row.type}
      </Typography>
    );
    singleRow.push(
      <Typography
        style={{
          display: "flex",
          fontSize: "0.8rem",
          justifyContent: "center"
        }}
      >
        {row.transaction_name}
      </Typography>
    );

    allTableRows.push(singleRow);
  });

  return allTableRows;
};

class Transactiontable extends Component {
  render() {
    return (
      <div>
        <PagnationTable columns={columns} tableheadFontSize={"0.7rem"}>
          {prepareTableData(this.props.data, this.props.currency)}
        </PagnationTable>
      </div>
    );
  }
}

export default Transactiontable;

import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Grid,
  TextField,
  Button,
  InputAdornment,
  ListItemSecondaryAction,
  Icon,
  Menu,
  MenuItem
} from "@material-ui/core";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { DriverProfileStyles } from "../../../../styles";
import Typography from "@material-ui/core/Typography";
import DialogForm from "../../../../custom/DialogForm";
import * as ApiManager from "../../../../../managers/api/ApiManager";
import { APPORIO_TAXI_APIS } from "../../../../../managers/api/ApiEndPoints";
import CustomLoder from "../../../../../UI/custom/CustomLoader";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SelectAddress from "./SelectAddress";
import NewAddress from "./NewAddress";
import NoAddress from "./NoAddress";
import Autocomplete from "../../../../custom/Autocomplete";



function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
        textAlign: "left"
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class HomeLocation extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {

    value: 0,
    checked: false,
    open: false,
    name: "",
    bankname: "",
    accountname: "",
    accountnumber: "",
    openEditModal: false,
    openAddModal: false,
    DRIVER_DETAILS_ROWS: [],
    loading: true,
    AddNew: false,
    mainContent: true,
    selectAddress: false,
    mainContent: true,

  };


  handleClickOpenModal = name => {
    this.setState({
      [name]: !this.state[name]
    });
  };
  handleSwitch = () => {
    this.setState(state => ({ checked: !state.checked }));
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };
  handleClickShowConfirmPassword = () => {
    this.setState(state => ({
      showConfirmPassword: !state.showConfirmPassword
    }));
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClickOpen = () => {
    this.setState({
      openList: true
    });
  };


  DriverDetail = () => {
    this.setState({

      loading: true

    })
    console.log("**************DRIVER_DETAILS***********", APPORIO_TAXI_APIS.DRIVER_DETAILS)

    ApiManager.callPostApi(
      "Active", "", APPORIO_TAXI_APIS.DRIVER_DETAILS,
    )

      .then((response) => {
        console.log('*****response*******', response)
        // alert(JSON.stringify(response))

        this.setState({
          // ADDRESS_ROWS: response.data,
          // DRIVER_DETAILS_ROWS: response.data.find(obj => obj.check_visibility === true),
          DRIVER_DETAILS_ROWS: response.data,
          loading: false

        })
        console.log(response)
      })
      .catch((error) => {
        console.log(error)

      })
  }

  componentDidMount() {
    this.DriverDetail()

  }

  // ---- remove CustomerSupport from list ---- //
  removeAddressRow = (AddressRowId) => {
    let index = this.state.ADDRESS_ROWS.findIndex(row => parseInt(row.id) == parseInt(AddressRowId))
    let arr = [...this.state.ADDRESS_ROWS]
    arr.splice(index, 1)
    this.setState({
      ADDRESS_ROWS: arr,
      loading: false
    })

  }



  // ---- delete AddressRow ----//
  deleteAddressRow = (AddressRowId) => {
    console.log("AddressROwId", AddressRowId)
    // loading
    this.setState({
      loading: true
    })

    ApiManager.callPostApi(
      "DeleteAddress",
      "",
      APPORIO_TAXI_APIS.DELETE_DRIVER_ADDRESS,
      {
        address_id: AddressRowId
      }
    )

      .then((response) => {

        switch (parseInt(response.result)) {

          case 0:
            console.log("case0", response)
            this.setState({
              loading: false
            })
            break;

          case 1:
            this.removeAddressRow(AddressRowId)

            break

          default:
            break;
        }
      })

      .catch((error) => {
        alert(error)
        this.setState({
          loading: false
        })
      })

  }

  active_deactive = (status) => {
    // start laoding
    this.setState({
      loading: true,
    })
    ApiManager.callPostApi(
      "DRIVER_ADDRESS_ACTIVE_DEACTIVE",
      "",
      APPORIO_TAXI_APIS.DRIVER_ADDRESS_ACTIVE_DEACTIVE,
      {
        status: status,
      }
    )

      .then((response) => {
        console.log("******DRIVER_ADDRESS_ACTIVE_DEACTIVE******", response)
        this.setState({
          DRIVER_DETAILS_ROWS: {
            ...this.state.DRIVER_DETAILS_ROWS,
            home_location_active: (response.data) ? 1 : 2
          },
          loading: false

        })
      })

      .catch((error) => {

      })
  }
  render() {
    console.log("driver home render", this.state.DRIVER_DETAILS_ROWS);
    const { classes } = this.props;
    const { value } = this.state;
    const { checked } = this.state;

    if
      (this.state.loading) { return <CustomLoder showLoader={this.state.loading} /> }
    else {
      return (
        <div>
          {/* vaibhav */}
          {this.state.mainContent && (
            <Grid item md={12} style={{ marginTop: 10 }}>
              <Grid container md={12} style={{
                display: "flex",
                flexDirection: "row",
              }}>

                <Grid item md={6} style={{}}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "600", color: "#26699d", }}
                  >
                    Set Your Destination
                  </Typography>
                </Grid>
              </Grid>
              <hr style={{ color: "#eaeaea" }} />

              {Boolean(this.state.DRIVER_DETAILS_ROWS) &&

                <Grid container md={12} style={{}}>
                  <Grid item xs={12} md={5} style={{ marginTop: 20 }}>
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      Your home Destination
</Typography>
                    <Typography variant="display4" style={{ fontSize: "15px" }}>
                      Turn on your destination
</Typography>
                  </Grid>
                  <Grid item xs={12} md={7} style={{ padding: 5 }}>
                    <Paper style={{ padding: 10 }}>
                      <Grid container xs={12} md={12}>
                        <Grid item xs={6} md={6} style={{ padding: 20 }}>
                          <Typography variant="display4" style={{ fontSize: "15px ", textTransform: "capitalize" }}>
                            {this.state.DRIVER_DETAILS_ROWS.selected_address != "" ? this.state.DRIVER_DETAILS_ROWS.selected_address : "Please Add Home Destination"}
                          </Typography>
                          {/* <Typography variant="display4" style={{ fontSize: "15px" }}>
                        {this.state.DRIVER_DETAILS_ROWS.address_value}
                      </Typography> */}
                        </Grid>
                        {this.state.DRIVER_DETAILS_ROWS.selected_address != "" &&
                          <Grid item xs={6} md={6} align="right" className={classes.click_to_activate} style={{ padding: 5 }}>
                            {this.state.DRIVER_DETAILS_ROWS.home_location_active == 2 ?
                              <Button
                                style={{ backgroundColor: "#2dcc70", }}
                                onClick={() => this.active_deactive("1")}>
                                <Typography variant="caption" className={classes.btnText} style={{ color: "#fff", fontWeight: "900" }}>
                                  CLICK TO ACTIVATE
                        </Typography>
                              </Button>
                              :
                              <Button
                                style={{ backgroundColor: "#f14741", }}
                                onClick={() => this.active_deactive("2")}>
                                <Typography variant="caption" className={classes.btnText} style={{ color: "#fff", fontWeight: "900" }}>
                                  CLICK TO DEACTIVATE
                        </Typography>
                              </Button>
                            }

                            <IconButton aria-label="Edit"
                              onClick={() =>
                                this.setState({
                                  selectAddress: !this.state.selectAddress,
                                  mainContent: !this.state.mainContent
                                })
                              }>
                              <EditIcon />
                            </IconButton>
                          </Grid>
                        }
                        {this.state.DRIVER_DETAILS_ROWS.selected_address == "" &&
                          <Grid item xs={6} md={6} align="right" className={classes.click_to_activate} style={{ padding: 5 }}>
                            <IconButton aria-label="Edit"
                              onClick={() =>
                                this.setState({
                                  selectAddress: !this.state.selectAddress,
                                  mainContent: !this.state.mainContent
                                })
                              }>
                              {

                                <AddIcon />


                              }
                            </IconButton>
                          </Grid>
                        }

                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              }
              {!Boolean(this.state.DRIVER_DETAILS_ROWS) &&
                <NoAddress />
              }
            </Grid>
          )}
          {this.state.selectAddress && (
            <SelectAddress
              onBackPress={() =>
                this.setState({
                  selectAddress: !this.state.selectAddress,
                  mainContent: !this.state.mainContent
                }, () => {
                  this.DriverDetail()
                })
              }
            />
          )}
        </div>
      );
    }
  }
}

export default withStyles(DriverProfileStyles)(HomeLocation);

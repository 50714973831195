import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { StepperContainerStyles } from "../../../styles";
import { withStyles } from "@material-ui/core";
import SignupFormComponent from "./SignupForm";
import UploadDocsComponent from "./UploadDocsComponent";
import VerificationComponent from "./VerificationComponent";
import AddCar from "./AddCar";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import CustomLoader from "../../../custom/CustomLoader";
import { withRouter } from "react-router-dom";
import * as LanguageManager from "../../../../managers/language/LanguageManager";

const docsData = [
  {
    id: 30,
    merchant_id: 2,
    expire_date: 1,
    documentStatus: 1,
    documentNeed: 1,
    created_at: "2019-02-23 13:01:18",
    updated_at: "2019-03-14 09:50:06",
    document_verification_status: "0",
    documentname: "Vehicle R.C."
  },
  {
    id: 46,
    merchant_id: 2,
    expire_date: 2,
    documentStatus: 3,
    documentNeed: 2,
    created_at: "2019-03-01 04:54:55",
    updated_at: "2019-03-07 18:32:53",
    document_verification_status: "1",
    documentname: "ID Proof"
  }
];

const carDocsData = [
  {
    id: 30,
    merchant_id: 2,
    expire_date: 1,
    documentStatus: 1,
    documentNeed: 1,
    created_at: "2019-02-23 13:01:18",
    updated_at: "2019-03-14 09:50:06",
    document_verification_status: "0",
    documentname: " Car Image"
  },
  {
    id: 46,
    merchant_id: 2,
    expire_date: 2,
    documentStatus: 3,
    documentNeed: 2,
    created_at: "2019-03-01 04:54:55",
    updated_at: "2019-03-07 18:32:53",
    document_verification_status: "0",
    documentname: "Insurance copy"
  }
];

const signupSteps = [
  {
    id: 1,
    label: LanguageManager.Strings.STEPPER_INDICATOR1
  },
  {
    id: 2,
    label: LanguageManager.Strings.STEPPER_INDICATOR2
  },
  {
    id: 3,
    label: LanguageManager.Strings.STEPPER_INDICATOR3
  },
  {
    id: 4,
    label: LanguageManager.Strings.STEPPER_INDICATOR4
  },
  {
    id: 5,
    label: LanguageManager.Strings.STEPPER_INDICATOR5
  }
];

class StepperContainer extends React.Component {
  constructor(props) {
    super(props);
    this.getValue = this.getValue.bind(this);

    this.state = {
      showBorder: null,
      activeStep: this.props.stepNumber,
      // maxSteps: tutorialSteps.length,
      direction: "rtl",
      areaCode: null,
      driverID: null,
      driverNumber: null,
      carNumber: null,
      vehicleDocs: false,
      changeData: true,
      selectionMode: false,
      driverDocs: docsData,
      driverCarDocs: [],
      driverPersonalDocs: [],
      isDriverDashBorad: false
    };
  }

  componentDidMount() {
    if (this.props.stepNumber == 2) {
      this.setState({ selectionMode: true });
      console.log("qaqaqaqaq" + JSON.stringify(this.props));
      this.getDocumentList(this.props.driverID, this.props.vehicleID);
    }
  }

  redirectToDriverDashBorad = () => {
    if (this.state.isDriverDashBorad) {
      return this.props.history.push("/driver/home");
    }
  };

  getDocumentList = (driverValue, carfID, activeFlag) => {
    this.setState({ isLoading: true });

    ApiManager.callPostApi(
      "stepperContainer",
      "driver_get_DocsList",
      ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_GET_DOCSLIST,
      {
        driver: driverValue,
        driver_vehicle: carfID
      }
    ).then(res => {
      console.log("driverDocsList./././" + JSON.stringify(res));
      console.log("hyhyhy" + driverValue + "??" + carfID);
      this.setState({ isLoading: true });

      if (res.result == 1) {
        let personalStatusArray = res.data.personal.map(item =>
          item.document_verification_status.toString()
        );
        let carStatusArray = res.data.vehicle.map(item =>
          item.document_verification_status.toString()
        );

        // if(this.state.selectionMode){
        // if(personalStatusArray.indexOf("0")>-1){
        //   this.setState({ isLoading: false,
        //      docsData:res.data.personal,
        //     driverID:driverValue,
        //     docTitle:"DRIVER PERSONAL DOCUMENTS",
        //     activeStep:2
        //   })

        //     console.log("DD")

        //   }else{
        //     if(carStatusArray.indexOf("0")>-1){
        //       console.log("mmm"+JSON.stringify(res.data.vehicle))

        //       this.setState({ isLoading: false,
        //         docsData:res.data.vehicle,
        //        driverID:driverValue,
        //        docTitle:"DRIVER VEHICLE DOCUMENTS",
        //       activeStep:3,
        //       vehicleDocs:true})

        //       console.log("SS")

        //     }else{
        //         this.setState({activeStep:4, isLoading: false,})
        //         console.log("ZZ")

        //     }

        //   }
        // }else{
        //   this.setState({ isLoading: false, docsData:(this.state.vehicleDocs)?res.data.vehicle:res.data.personal, driverID:driverValue, docTitle:(this.state.vehicleDocs)?"DRIVER VEHICLE DOCUMENTS":"DRIVER PERSONAL DOCUMENTS" },()=>{
        //     if(activeFlag){
        //       this.setState({activeStep:activeFlag})
        //     }else{
        //       console.log("lklklklklklkl"+JSON.stringify(this.state.docsData))
        //     }
        //   })
        // }

        this.setState(
          {
            isLoading: false,
            driverPersonalDocs: res.data.personal,
            driverCarDocs: res.data.vehicle,
            driverID: driverValue,
            docTitle: this.state.vehicleDocs
              ? LanguageManager.Strings.V_DOC_LABEL
              : LanguageManager.Strings.P_DOC_LABEL
          },
          () => {
            if (activeFlag) {
              this.setState({ activeStep: activeFlag });
            } else {
              console.log(
                "lklklklklklkl" + JSON.stringify(this.state.docsData)
              );
            }
          }
        );
      } else {
        this.setState({ isLoading: false });
        alert("Add Vehicle Errorass:" + res.message);
      }
      //alert(JSON.stringify(res))
    });
  };

  getValue = val => {
    console.log("kxkxkx" + JSON.stringify(val));
    if (val.areaCode) {
      if (val.id < 5) {
        this.setState({
          activeStep: val.id,
          areaCode: val.areaCode,
          driverID: val.driverID
        });
      }
    } else if (val.carID) {
      if (val.id < 5) {
        console.log("HERES" + this.state.selectionMode);
        this.getDocumentList(val.driverID, val.carID, val.id);
        this.setState({ saveDriverID: val.driverID, saveCarID: val.carID });
      }
    } else if (val.docStatus) {
      console.log(
        "KKKK" + JSON.stringify(this.props) + "_" + JSON.stringify(val)
      );
      if (val.afterLogin == 1) {
        this.getDocumentList(this.props.driverID, this.props.vehicleID);
      } else {
        this.getDocumentList(this.state.saveDriverID, this.state.saveCarID);
      }
    } else {
      if (val < 5) {
        console.log("ffff" + val);
        if (val == 3) {
          this.setState({ vehicleDocs: true, activeStep: val });
          if (this.props.driverID) {
            this.getDocumentList(this.props.driverID, this.props.vehicleID);
          } else {
            this.getDocumentList(this.state.saveDriverID, this.state.saveCarID);
          }
        } else {
          console.log("XXC");
          this.setState({ activeStep: val });
        }
      } else if (val == 5) {
        this.setState({ isDriverDashBorad: true });
      }
    }
  };

  renderCard(index) {
    switch (index + 1) {
      case 1:
        return (
          <SignupFormComponent
            callBack={val => this.getValue(val)}
            indexValue={index}
          />
        );
        // return <AddCar callBack={(val) => this.getValue(val)} indexValue={index} />

        break;

      case 2:
        if (this.props.driverID) {
          return (
            <AddCar
              callBack={val => this.getValue(val)}
              indexValue={index}
              areaCode={this.props.countryID}
              driverID={this.props.driverID}
            />
          );
        } else {
          return (
            <AddCar
              callBack={val => this.getValue(val)}
              indexValue={index}
              areaCode={this.state.areaCode}
              driverID={this.state.driverID}
            />
          );
        }
        break;

      case 3:
        if (this.props.driverID) {
          return (
            <UploadDocsComponent
              getValue={this.getValue}
              indexValue={index}
              docsData={this.state.driverPersonalDocs}
              driverID={this.props.driverID}
              componentTitle={this.state.docTitle}
              changeData={this.state.changeData}
              vehicleDocStage={this.state.vehicleDocs}
              vehicleID={this.props.vehicleID}
              postLoginFlag={true}
            />
          );
        } else {
          return (
            <UploadDocsComponent
              getValue={this.getValue}
              indexValue={index}
              docsData={this.state.driverPersonalDocs}
              driverID={this.state.driverID}
              componentTitle={this.state.docTitle}
              changeData={this.state.changeData}
              vehicleDocStage={this.state.vehicleDocs}
              vehicleID={this.state.saveCarID}
            />
          );
          // return <UploadDocsComponent getValue={this.getValue} indexValue={index} docsData={docsData} driverID={this.state.driverID} componentTitle={LanguageManager.Strings.P_DOC_LABEL} changeData={this.state.changeData} vehicleDocStage={this.state.vehicleDocs} vehicleID={this.state.saveCarID}/>
        }

        break;

      case 4:
        // if(this.props.driverID){
        // if(this.state.vehicleDocs.length>0){
        if (this.props.driverID) {
          return (
            <UploadDocsComponent
              getValue={this.getValue}
              indexValue={index}
              docsData={this.state.driverCarDocs}
              driverID={this.props.driverID}
              componentTitle={this.state.docTitle}
              changeData={this.state.changeData}
              vehicleDocStage={this.state.vehicleDocs}
              vehicleID={this.props.vehicleID}
              postLoginFlag={true}
            />
          );
        } else {
          return (
            <UploadDocsComponent
              getValue={this.getValue}
              indexValue={index}
              docsData={this.state.driverCarDocs}
              driverID={this.state.driverID}
              componentTitle={this.state.docTitle}
              changeData={this.state.changeData}
              vehicleDocStage={this.state.vehicleDocs}
              vehicleID={this.state.saveCarID}
            />
          );
          //return <UploadDocsComponent getValue={this.getValue} indexValue={index} docsData={carDocsData} driverID={this.state.driverID} componentTitle={LanguageManager.Strings.V_DOC_LABEL} changeData={this.state.changeData} vehicleDocStage={this.state.vehicleDocs} vehicleID={this.state.saveCarID}/>
        }
        // }else{
        //   this.setState({activeStep:this.state.activeStep+1})
        // }
        //}

        // return <DriverDocComponent getValue={this.getValue} indexValue={index} docsData={this.state.carDocsData} driverID={this.state.driverID} vehicleID={this.state.saveCarID} />
        break;

      case 5:
        return (
          <VerificationComponent
            callBack={val => this.getValue(val)}
            indexValue={index}
          />
        );
        break;

      default:
        break;
    }
  }

  render() {
    const { classes, countryID } = this.props;

    return (
      <div>
        {this.redirectToDriverDashBorad()}
        <Grid
          item
          xl
          container
          style={{
            overflowY: "scroll",
            position: "absolute",
            width: "100%",
            height: "100%"
          }}
        >
          <CustomLoader showLoader={this.state.isLoading} />
          <div justify="flex-start" className={classes.formVerticalContainer}>
            <Grid
              xl
              container
              alignItems="center"
              className={classes.stepperHolder}
            >
              <Grid item style={{ marginRight: 10 }}>
                <div
                  justify="flex-start"
                  className={classes.formVerticalContainer}
                >
                  <Stepper
                    activeStep={this.state.activeStep}
                    // orientation="horizontal"
                  >
                    {signupSteps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel className={classes.labelContainer} />
                      </Step>
                    ))}
                  </Stepper>
                  <Typography className={classes.stageLabel}>
                    {signupSteps[this.state.activeStep].label}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            {this.renderCard(this.state.activeStep)
            //  this.renderCard(2)
            }
          </div>
        </Grid>
      </div>
    );
  }
}

// export default withStyles(StepperContainerStyles)(StepperContainer);
export default withStyles(StepperContainerStyles)(withRouter(StepperContainer));

// import { getTheme } from "../../../../managers/theme/ThemeManager";
import { CUSTOM_FONT } from "../../../../values/Typography";

const styles = theme => {
  // const configTheme = getTheme();

  return {
    mayaLinks: {
      color: "silver",
      fontSize: 14,
      "&:hover": {
        color: "#FFF"
      }
    },
    bgImageMaya: {
      height: 700,
      justify: "center"
    },
    mayaButton: {
      margin: theme.spacing.unit,
      textTransform: "none",
      backgroundColor: "#FFF",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: "#FFF",
        color: "#3B86B0"
      }
    },
    heroImage: {
      height: "100%",
      direction: "row",
      // backgroundSize: "cover",
      justify: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center"
      },
      // position: "relative",
      paddingTop: theme.spacing.unit * 10,
      paddingBottom: theme.spacing.unit * 10
    },
    root: {
      direction: "column",
      justifyContent: "flex-start"
    },
    backgroundImage: {
      height: "70vh",
      direction: "row",
      justify: "flex-end",
      borderRadius: 10,
      margin: 10
    },
    formViewContainer: {
      //direction:'row',
      justifyContent: "flex-end",
      paddingRight: theme.spacing.unit * 5,
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center"
      }
    },
    listContainer: {
      width: "30%",
      height: "50%",
      marginLeft: "10%",
      marginRight: "50%"
    },
    pageHeading: {
      //marginTop:'50px',
      fontFamily: CUSTOM_FONT,
      //marginTop:theme.spacing.unit*10,
      fontWeight: "bold",
      fontSize: "2rem"
      //   display:'flex',
      // [theme.breakpoints.down('sm')]: {
      //   display:'none'
      // },
    },
    farjiview: {
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: 200
      }
    },
    footerDiv: {
      marginTop: 100,
      backgroundColor: "red"
    },
    TabView: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    image: {
      marginTop: "5%",
      position: "relative",
      height: 50,
      [theme.breakpoints.down("xs")]: {
        width: "100% !important", // Overrides inline-style
        height: 50
      },
      "&:hover, &$focusVisible": {
        zIndex: 1,
        "& $imageBackdrop": {
          opacity: 1,
          backgroundColor: theme.palette.common.white
        },
        // '& $imageMarked': {
        //   opacity: 0,
        // },
        "& $imageTitle": {
          borderBottom: "4px solid currentColor"
        }
      }
    },
    focusVisible: {},
    imageButton: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#f9027e" //theme.palette.common.white,
    },
    imageSrc: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      backgroundPosition: "center 40%"
    },
    imageBackdrop: {
      borderRadius: 100,
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.8,
      transition: theme.transitions.create("opacity")
    },
    imageTitle: {
      position: "relative",
      padding: `${0}px ${0}px ${0}px`
    }
  };
};

export default styles;

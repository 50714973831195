import React from "react";
import { withStyles } from "@material-ui/core";
import { DriverSignUpStyles } from "../../../styles";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import LogoCard from "../login/LogoCard";
import StepperContainer from "./StepperContainer";

class DriverSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentData: {}
    };
  }

  componentDidMount() {
    console.log("AAAAAA" + JSON.stringify(this.props.location.state));
  }

  render() {
    const { classes } = this.props;

    return (
      <CardMedia
        container
        className={classes.backgroundImage}
        justify="center"
        image={JSON.parse(SessionManager.getSessionData(SessionKeys.DRIVER_BG))}
        style={{ overflow: "hidden" }}
      >
        <Grid container className={classes.parentView}>
          <LogoCard />
          <Grid
            container
            className={classes.formContainer}
            style={{
              border: "2px solid white",
              borderWidth: 2,
              borderRadius: 10
            }}
          >
            <Paper
              xl={12}
              item
              elevation={1}
              style={{ position: "absolute", width: "100%", height: "100%" }}
            >
              {this.props.location.state ? (
                <StepperContainer
                  stepNumber={
                    this.props.location.state.targetStep
                      ? this.props.location.state.targetStep
                      : 0
                  }
                  countryID={this.props.location.state.countryCode}
                  driverID={this.props.location.state.driverID}
                  vehicleID={this.props.location.state.vehicleID}
                />
              ) : (
                <StepperContainer stepNumber={0} />
              )}
            </Paper>
          </Grid>
          <Grid
            item
            className={classes.bottomLineElement}
            style={{ borderRight: "2px solid white" }}
          />
        </Grid>
      </CardMedia>
    );
  }
}

export default withStyles(DriverSignUpStyles)(DriverSignUp);

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Grid, IconButton, Icon } from "@material-ui/core";
import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import CustomLoader from "../../../custom/CustomLoader";
import { Link } from "react-router-dom";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import * as SessionManager from "../../../../managers/session/SessionManager";
import { CUSTOM_COLOUR } from "../../../../values/Typography";
import { FB, TWEET, INSTA, MAIL, BACK } from "../../../../values/Icons";

const styles = {
  appBar: {
    position: "relative",
    backgroundColor: CUSTOM_COLOUR
  },
  flex: {
    flex: 1
  },
  dialogContent: {
    margin: "20px",
    fontSize: 16,
    alignText: "center"
  }
};

class CmsUserPage extends React.Component {
  state = {
    open: false,
    contentStyle: "paragraph",
    isLoading: ""
  };

  componentDidMount() {
    let sessiondata = JSON.parse(
      SessionManager.getSessionData(SessionKeys.DRIVER_LOGO)
    );

    console.log("sessiondata", sessiondata);
    if (sessiondata) {
      this.setState({
        logo: sessiondata
      });
    }

    this.setState({ isLoading: true });

    ApiManager.callPostApi(
      "LoginForm",
      "driver_cms_data_api",
      ApiEndPoints.APPORIO_TAXI_APIS.USER_CMSDATA,
      {
        slug: this.props.slug,
        country_id: this.props.userConfig
      }
    )
      .then(res => {
        console.log("*(*(*(*" + JSON.stringify(res));
        if (res.result === "1") {
          this.setState({
            contentBody: res.data.description,
            contentTitle: res.data.title,
            isLoading: false
          });
        } else {
          this.setState({
            isLoading: false,
            showSnackbar: true,
            snakBarMessage: res.message,
            snackbarVariant: "error"
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  setContent(data, layout, style) {
    if (this.isLoading) {
      return;
    }

    switch (layout) {
      case "paragraph":
        return (
          <div
            style={{
              textAlign: "left",
              paddingLeft: "50px",
              paddingRight: "50px",
              marginTop: "20px"
            }}
            dangerouslySetInnerHTML={{ __html: data }}
          />
        );

      case "list":
        return (
          <div
            style={{
              justifyContent: "flex-start",
              paddingLeft: "50px",
              paddingRight: "50px",
              marginTop: "20px"
            }}
          >
            <ListSubheader
              align="left"
              style={{ fontWeight: "bold", fontSize: "2rem" }}
            >
              Personal Documents
            </ListSubheader>

            <List dense={true}>
              {data
                ? data.personal.map((row, index) => (
                    <ListItem>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            type="body2"
                            style={{
                              fontSize: "1rem"
                            }}
                          >
                            {index + 1}. {row.documentname}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))
                : null}
            </List>

            <ListSubheader
              align="left"
              style={{ fontWeight: "bold", fontSize: "2rem" }}
            >
              Vehicle Documents
            </ListSubheader>

            <List dense={true}>
              {data
                ? data.vehicle.map((row, index) => (
                    <ListItem>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            type="body2"
                            style={{
                              fontSize: "1rem"
                            }}
                          >
                            {index + 1}. {row.documentname}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))
                : null}
            </List>
          </div>
        );
      default:
        return 1;
    }
  }

  render() {
    const { classes } = this.props;

    if (this.state.isLoading) {
      return <CustomLoader showLoader={this.state.isLoading} />;
    } else {
      return (
        <div>
          <Grid>
            <AppBar
              className={classes.appBar}
              style={{ top: 0, position: "fixed" }}
            >
              {/* {SessionManager.getSessionData(SessionKeys.DRIVER_LOGO) ? (
                <Grid xs={3}>
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <img
                      alt=""
                      src={
                        ApiEndPoints.TAXI_IMAGE_URL +
                        JSON.parse(
                          SessionManager.getSessionData(SessionKeys.DRIVER_LOGO)
                        )
                      }
                      height="100"
                      width="100"
                    />
                  </Link>
                </Grid>
              ) : (
                <Grid xs={3}>
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <img alt="" src={BACK} height="100" width="100" />
                  </Link>
                </Grid>
              )} */}
              <Toolbar>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <IconButton
                    color="inherit"
                    onClick={this.handleClose}
                    aria-label="Close"
                  >
                    <Icon>keyboard_backspace</Icon>
                  </IconButton>
                </Link>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.flex}
                  align="center"
                >
                  {this.state.contentTitle}
                </Typography>
              </Toolbar>
            </AppBar>
            <br />
            <br />
            <br />
            <br />
            {this.setContent(
              this.state.contentBody,
              this.state.contentStyle,
              classes
            )}
            <br />
            <br />
            <br />

            <div
              className={classes.appBar}
              style={{ width: "100%", position: "fixed", bottom: 0 }}
            >
              <div class="text-center center-block">
                <br />
                {/* <a href="https://www.facebook.com/" style={{ margin: 10 }}>
                  <img alt="Fb" src={FB} />
                </a>
                <a href="https://twitter.com/" style={{ margin: 10 }}>
                  <img alt="Tweet" src={TWEET} />
                </a>
                <a href="https://instagram.com/" style={{ margin: 10 }}>
                  <img alt="Insta" src={INSTA} />
                </a>
                <a href="mailto:" style={{ margin: 10 }}>
                  <img alt="Mail" src={MAIL} />
                </a> */}
                <br />
                <br />
                <br />
              </div>
            </div>
          </Grid>
        </div>
      );
    }
  }
}

CmsUserPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CmsUserPage);

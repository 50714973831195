import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import classNames from "classnames";
import InputBase from "@material-ui/core/InputBase";
import { Redirect, Link } from "react-router-dom";
import { HomeStyles } from "../../../styles";
import { Select, MenuItem, Paper } from "@material-ui/core";
import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import AutoComplete from "../../../../UI/custom/Autocomplete";
import CustomLoader from "../../../custom/CustomLoader";
import CustomSnackbar from "../../../custom/CustomSnackbar";
import {
  CURRENCY,
  CUSTOM_FONT,
  CUSTOM_COLOUR
} from "../../../../values/Typography";
import { connect } from "react-redux";
import { save_addresses, save_destination } from "../../../../actions";

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)

class ImageFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: {
        lat: "",
        lng: "",
        address: ""
      },
      destLocation: {
        lat: "",
        lng: "",
        address: ""
      },
      pickupCoords: {},
      dropOffCoords: {},
      pickupLat: null,
      pickupLng: null,
      showEstimate: false,
      rideValue: 0,
      isLoginRedirect: false,
      rideEstimate: "",
      showSnackbar: false,
      snackbarVariant: "error",
      snakBarMessage: "",
      showSnackbar: false
    };
  }

  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false
    });
  };

  fetchAllVehicles = (place, fullAddress, cityString) => {
    this.setState({ showLoader: true, rideEstimate: "" });
    ApiManager.callPostApi(
      "CarsWithoutLogin",
      "get all cars without Login",
      ApiEndPoints.APPORIO_TAXI_APIS.USER_CARS_NO_LOGIN,
      {
        latitude: String(place.geometry.location.lat()),
        longitude: String(place.geometry.location.lng())
      }
    )
      .then(res => {
        if (res.result === "1") {
          this.setState({ showLoader: false });

          this.setState({
            fullPickUp: fullAddress,
            pickupLat: String(place.geometry.location.lat()),
            pickupLng: String(place.geometry.location.lng()),
            serviceId: res.data.service_types[0].id,
            vehicleId:
              res.data.service_types[0].package.length > 0
                ? res.data.service_types[0].package[0].vehicles[0].id
                : res.data.service_types[0].vehicles[0].id,
            location: {
              ...this.state.location,
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
              address: fullAddress,
              selectedCity: cityString
            }
          });

          console.log(
            "reduxADD::::::" +
              JSON.stringify(this.props.firstReducer.AddressObject)
          );
          let firstAddress = {};
          firstAddress.AddressObject = this.state.location;
          console.log("####mindit>>" + JSON.stringify(firstAddress));
          this.props.save_addresses(firstAddress.AddressObject);
          setTimeout(() => {
            console.log(
              "####reduxJOBS1::::::" +
                JSON.stringify(this.props.firstReducer.AddressObject)
            );
          }, 3000);
        }
      })
      .catch(err => alert(JSON.stringify(err)));
  };

  getStartLocation = (place, textAddress, cityString) => {
    if (place.hasOwnProperty("geometry")) {
      console.log("###@###" + cityString);

      this.setState(
        {
          location: {
            ...this.state.location,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            address: textAddress,
            selectedCity: cityString
          }
        },
        () => {
          let firstAddress = {};
          firstAddress.AddressObject = this.state.location;
          this.props.save_addresses(firstAddress.AddressObject);
          setTimeout(() => {
            console.log(
              "####reduxJOBS2::::::" +
                JSON.stringify(this.props.firstReducer.AddressObject)
            );
          }, 3000);
        }
      );
    } else {
      alert("Does not match");
    }
  };

  getEstimate = () => {
    if (!this.state.pickupLat) {
      this.setState({
        showSnackbar: true,
        snakBarMessage: "Please enter source location.",
        isLoading: false,
        snackbarVariant: "warning"
      });
    } else if (!this.state.location.lat) {
      this.setState({
        showSnackbar: true,
        snakBarMessage: "Please enter destination location.",
        isLoading: false,
        snackbarVariant: "warning"
      });
    } else {
      ApiManager.callPostApi(
        "getEstimate",
        "show Total Estimate",
        ApiEndPoints.APPORIO_TAXI_APIS.USER_GETESTIMATE,
        {
          area: 3,
          service_type: 1,
          vehicle_type: 16,
          pickup_latitude: this.state.pickupLat,
          pickup_longitude: this.state.pickupLng,
          pick_up_locaion: this.state.fullPickUp,
          booking_type: 1,
          total_drop_location: 1,
          drop_location: JSON.stringify([
            {
              stop: "1",
              drop_latitude: String(this.state.location.lat),
              drop_longitude: String(this.state.location.lng),
              drop_location: this.state.location.address,
              status: "1"
            }
          ])
        }
      )
        .then(res => {
          console.log(
            "full response======================>" + JSON.stringify(res)
          );

          this.setState({ showEstimate: true });
          if (res.result === "1") {
            this.setState({ rideEstimate: res.data.amount });
          } else {
            this.setState({
              showSnackbar: true,
              snakBarMessage:
                "Unable to get estimate. Please setup Rate Card for your City.",
              isLoading: false
            });
          }
        })
        .catch(err => alert(JSON.stringify(err)));
    }
  };

  redirectToLogin = () => {
    if (this.state.isLoginRedirect) {
      return <Redirect to="/user_login" />;
    }
  };

  showLoader() {
    return <CustomLoader showLoader={this.state.isLoading} />;
  }

  showPlaceDetails = (place, textAddress, cityString) => {
    if (place.hasOwnProperty("geometry")) {
      console.log("###@###" + cityString);

      this.setState(
        {
          destLocation: {
            ...this.state.destLocation,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            address: textAddress,
            selectedCity: cityString
          }
        },
        () => {
          let secondAddress = {};
          secondAddress.AddressObjects = this.state.destLocation;
          this.props.save_destination(secondAddress.AddressObjects);
          setTimeout(() => {
            console.log(
              "####reduxJOBS2::::::" +
                JSON.stringify(this.props.firstReducer.AddressObjects)
            );
          }, 3000);
        }
      );
    } else {
      alert("Does not match");
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        justify="space-between"
        className={classes.homeFormContainer}
        direction="column"
      >
        {this.showLoader()}
        <CustomSnackbar
          showSnackbar={this.state.showSnackbar}
          variant={this.state.snackbarVariant}
          message={this.state.snakBarMessage}
          onClose={this.handleSnackBarClose}
        />

        {this.redirectToLogin()}

        <Grid container className={classes.pickDropContainer}>
          <Grid
            container
            className={classes.iconContainer}
            direction="column"
            justify="space-between"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 10 / 2,
                  backgroundColor: "#40D590"
                }}
              />

              <AutoComplete
                fieldWidth={300}
                placeHolder={this.props.hintsObject.pick}
                onPlaceChanged={(place, textAddress, cityString) =>
                  this.getStartLocation(place, textAddress, cityString)
                }
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <div
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 10 / 2,
                  backgroundColor: "#EF4742"
                }}
              />

              <AutoComplete
                fieldWidth={300}
                placeHolder={this.props.hintsObject.drop}
                onPlaceChanged={(place, textAddress, cityString) =>
                  this.showPlaceDetails(place, textAddress, cityString)
                }
              />
            </div>
          </Grid>
        </Grid>

        {this.state.rideEstimate !== "" ? (
          <Paper
            justify="center"
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <Grid
              item
              justify="center"
              style={{ paddingTop: "10px", paddingBottom: "10px" }}
            >
              <Typography
                variant="h1"
                style={{
                  fontSize: "2rem",
                  color: "green",
                  fontWeight: "bold"
                }}
              >
                {CURRENCY} {this.state.rideEstimate.slice(3)}
              </Typography>
            </Grid>
          </Paper>
        ) : null}

        <Button
          style={{
            width: "100%",
            fontSize: "0.8rem",
            backgroundColor: CUSTOM_COLOUR,
            color: "white",
            fontWeight: "bold",
            height: "50px",
            fontFamily: CUSTOM_FONT
          }}
          onClick={() => this.setState({ isLoginRedirect: true })}
        >
          {this.props.hintsObject.button}
        </Button>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    firstReducer: state.firstReducer
  };
}

ImageFormComponent.propTypes = {
  classes: PropTypes.object.isRequired
};
export default connect(
  mapStateToProps,
  { save_addresses, save_destination }
)(withStyles(HomeStyles)(ImageFormComponent));

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Modal, Button, CardMedia } from "@material-ui/core";
import { css } from "@emotion/core";
import { BounceLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;

  border-color: red;
`;
const styles = theme => ({
  progress: {
    margin: theme.spacing.unit * 2
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",

    bordeRadius: 10,

    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "50px",
    paddingRight: "50px"
  }
});
const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    borderRadius: "8px",
    transform: `translate(-${top}%, -${left}%)`
  };
};

const RideLoader = props => {
  const { classes } = props;

  return (
    <Modal
      style={{ outline: "none" }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.showLoader}
      disableAutoFocus={true}
      onClose={() => {
        //  this.props.handleModalTouchOutisde();
      }}
    >
      <div style={getModalStyle()} className={classes.modal}>
        <BounceLoader
          css={override}
          sizeUnit={"px"}
          size={300}
          color={"#5AD1EF"}
          loading={true}
        />
        <Typography
          style={{
            fontWeight: "bold",
            fontSize: 22,
            color: "white",
            marginTop: "5px"
          }}
        >
          {props.loadingText ? props.loadingText : "Please Wait..."}
        </Typography>
        <CardMedia
          onClick={() => {
            props.onCancelApiExecution();
          }}
          style={{
            background: "#5AD1EF",

            paddingLeft: "10%",
            paddingRight: "10%",
            paddingTop: "3%",
            paddingBottom: "3%",
            borderRadius: 15,
            fontWeight: "bold",
            margin: "5%"
          }}
        >
          <Typography style={{ color: "white", cursor: "pointer" }}>
            {" "}
            Cancel ride
          </Typography>
        </CardMedia>
      </div>
    </Modal>
  );
};

RideLoader.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RideLoader);

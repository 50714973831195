import React, { Component } from "react";
import {
  CardMedia,
  Typography,
  withStyles,
  Button,
  Grid,
  CircularProgress,
  Tabs,
  Tab,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Chip
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { HomeStyles } from "../../../styles";
import AutoComplete from "../../../../UI/custom/Autocomplete";
import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import { Redirect } from "react-router-dom";
import CustomLoader from "../../../custom/CustomLoader";
import CustomSnackbar from "../../../custom/CustomSnackbar";
import * as LanguageManager from "../../../../managers/language/LanguageManager";
import { CUSTOM_FONT, CUSTOM_COLOUR } from "../../../../values/Typography";

class HomeEstimatecontainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceLat: null,
      sourceLong: null,
      destLat: null,
      destLong: null,
      location: {
        lat: "",
        lng: "",
        address: ""
      },
      pickupCoords: {},
      dropOffCoords: {},
      pickupLat: null,
      pickupLng: null,
      showEstimate: false,
      rideValue: 0,
      isLoginRedirect: false,
      isLoading: false,
      rideEstimate: null,
      showSnackbar: false,
      snackbarVariant: "error",
      snakBarMessage: "",
      loading: false,
      vehicleData: null,
      value: 0,
      userCurrency: null,
      loadEstimate: false,
      expanded: null
    };
  }

  handlePanelChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false
    });
  };

  getEstimate = () => {
    if (!this.state.pickupLat) {
      this.setState({
        showSnackbar: true,
        snakBarMessage: "Please enter source city.",
        isLoading: false,
        snackbarVariant: "warning"
      });
    } else if (!this.state.location.lat) {
      this.setState({
        showSnackbar: true,
        snakBarMessage: "Please enter destination city.",
        isLoading: false,
        snackbarVariant: "warning"
      });
    } else {
      this.setState({ loading: true });
      ApiManager.callPostApi(
        "HomeEstimateContainer",
        "get all cars without Login",
        ApiEndPoints.APPORIO_TAXI_APIS.DASHBOARD_CAR_DETAILS,
        {
          latitude: String(this.state.pickupLat),
          longitude: String(this.state.pickupLng),
          drop_location: JSON.stringify([
            {
              stop: "1",
              drop_latitude: this.state.location.lat,
              drop_longitude: String(this.state.location.lng),
              drop_location: String(this.state.location.address),
              status: "1"
            }
          ])
        }
      )
        .then(res => {
          if (res.result === "1") {
            console.log("####" + JSON.stringify(res));
            this.setState({
              loading: false
            });
            this.setState({
              vehicleData: res.data,
              userCurrency: res.currency
            });
          } else {
            this.setState({
              loading: false,
              showSnackbar: true,
              snakBarMessage: res.message,
              isLoading: false,
              snackbarVariant: "warning"
            });
          }
        })
        .catch(err => alert(JSON.stringify(err)));
    }
  };

  redirectToLogin = () => {
    if (this.state.isLoginRedirect) {
      return <Redirect to="/user_login" />;
    }
  };

  showLoader() {
    return <CustomLoader showLoader={this.state.isLoading} />;
  }

  handleChange(event) {
    this.setState({
      value: event
    });
  }

  handleInnerChange(event) {
    this.setState({
      valueIn: event
    });
  }

  checkEstimate(key, row, package_id) {
    console.log("****>>>", row);
    this.setState(
      { rideEstimate: null, loadEstimate: true, vehicle_data_bg: row.id },
      () => {
        ApiManager.callPostApi(
          "HomeEstimateContainer",
          "check an estimate",
          ApiEndPoints.APPORIO_TAXI_APIS.USER_GETESTIMATE,
          {
            area: this.state.vehicleData.id,
            pick_up_locaion: this.state.fullPickUp,
            pickup_latitude: this.state.pickupLat,
            pickup_longitude: this.state.pickupLng,
            drop_location: JSON.stringify([
              {
                stop: "1",
                drop_latitude: this.state.location.lat,
                drop_longitude: String(this.state.location.lng),
                drop_location: String(this.state.location.address),
                status: "1"
              }
            ]),
            service_type: this.state.vehicleData.service_types[this.state.value]
              .id,
            vehicle_type: row.id,
            package_id: package_id ? package_id : null
          }
        )
          .then(res => {
            if (res.result === "1") {
              this.setState({
                loadEstimate: false,
                rideEstimate: res.data.amount
              });
            } else {
              this.setState({
                loadEstimate: false,
                showSnackbar: true,
                snakBarMessage: res.message,
                isLoading: false,
                snackbarVariant: "warning"
              });
            }
          })
          .catch(err => alert(JSON.stringify(err)));
      }
    );
  }

  showPlaceDetails = (place, textAddress, cityString) => {
    if (place.hasOwnProperty("geometry")) {
      console.log("###@###" + cityString);
      this.setState({
        location: {
          ...this.state.location,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          address: textAddress,
          selectedCity: cityString
        }
      });
    } else {
      alert("Does not match");
    }
  };

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;
    console.log("vehicle_data_bg", this.state.vehicle_data_bg);
    return (
      <div className={classes.parentContainer}>
        {this.showLoader()}
        <CustomSnackbar
          showSnackbar={this.state.showSnackbar}
          variant={this.state.snackbarVariant}
          message={this.state.snakBarMessage}
          onClose={this.handleSnackBarClose}
        />
        <Grid
          style={{ display: "flex", flex: 1, paddingTop: 30 }}
          justify="space-between"
        >
          <CardMedia
            className={classes.carImage}
            image={this.props.user_estimate_image}
          />
          <div className={classes.estimateContainer}>
            <Typography
              style={{
                fontFamily: CUSTOM_FONT,
                fontSize: "1.5rem",
                fontWeight: "bold"
              }}
            >
              {LanguageManager.Strings.FARE_ESTIMATE_TITLE}
            </Typography>

            <Grid
              item
              xs={12}
              style={{
                backgroundColor: "transparent",
                paddingLeft: "1%",
                paddingRight: "1%"
                // flexBasis: "unset"
              }}
            >
              <Grid
                container
                style={{ backgroundColor: "transparent" }}
                justify="center"
              >
                <Grid
                  item
                  style={{
                    flex: 1,
                    marginTop: "10px",
                    height: 50,
                    display: "flex",
                    borderRadius: 25,
                    backgroundColor: "#CCCCCC"
                  }}
                >
                  <AutoComplete
                    placeHolder={this.props.data.start_address_hint}
                    onPlaceChanged={(place, textAddress, cityString) =>
                      this.setState({
                        fullPickUp: textAddress,
                        pickupLat: String(place.geometry.location.lat()),
                        pickupLng: String(place.geometry.location.lng())
                      })
                    }
                  />
                </Grid>
                <Grid
                  item
                  style={{
                    flex: 1,
                    marginTop: "10px",
                    marginLeft: "1%",
                    height: 50,
                    display: "flex",
                    borderRadius: 25,
                    backgroundColor: "#CCCCCC"
                  }}
                >
                  <AutoComplete
                    placeHolder={this.props.data.end_address_hint}
                    onPlaceChanged={(place, textAddress, cityString) =>
                      this.setState({
                        fullDrop: textAddress,
                        dropLat: String(place.geometry.location.lat()),
                        dropLng: String(place.geometry.location.lng()),
                        location: {
                          ...this.state.location,
                          lat: place.geometry.location.lat(),
                          lng: place.geometry.location.lng(),
                          address: textAddress,
                          selectedCity: cityString
                        }
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={{ paddingRight: "10%", paddingLeft: "10%" }}>
              {this.state.loading ? (
                <Grid
                  item
                  style={{
                    marginLeft: "5%",
                    width: "90%",
                    height: 50,
                    fontWeight: "bold",
                    backgroundColor: "#007FF6",
                    marginTop: "5%",
                    borderRadius: 25
                  }}
                >
                  <CircularProgress
                    size={30}
                    style={{
                      color: "white",
                      marginTop: "2%",
                      marginBottom: "2%"
                    }}
                  />
                </Grid>
              ) : (
                <Button
                  style={{
                    fontFamily: CUSTOM_FONT,
                    width: "90%",
                    height: 50,
                    color: this.state.loading ? "#007FF6" : "white",
                    fontWeight: "bold",
                    backgroundColor: CUSTOM_COLOUR,
                    marginTop: "5%",
                    borderRadius: 25
                  }}
                  onClick={() => this.getEstimate()}
                >
                  {this.props.data.book_btn_title}
                </Button>
              )}
            </Grid>
            <Grid
              item
              justify="center"
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                marginTop: "5px"
              }}
            >
              {this.state.rideEstimate ? (
                <Typography
                  variant="h1"
                  style={{
                    fontFamily: CUSTOM_FONT,
                    fontSize: "2rem",
                    color: "green",
                    fontWeight: "bold",
                    marginTop: "10px"
                  }}
                >
                  {this.state.rideEstimate}
                </Typography>
              ) : this.state.loadEstimate ? (
                <CircularProgress
                  size={30}
                  style={{
                    color: "green",
                    marginTop: "2%",
                    marginBottom: "2%"
                  }}
                />
              ) : null}
            </Grid>

            <Grid container justify="center" alignItems="center">
              <Tabs
                value={this.state.value}
                onChange={(event, value) => this.handleChange(value)}
              >
                {this.state.vehicleData &&
                  this.state.vehicleData.service_types.map((row, index) => (
                    <Tab
                      style={{ fontFamily: CUSTOM_FONT }}
                      label={row.serviceName}
                    />
                  ))}
              </Tabs>
            </Grid>

            <Grid
              container
              style={{
                backgroundColor: "white",
                marginTop: 30
                // flexFlow: "initial",
                // overflowX: "scroll",
                // height: "8rem"
              }}
              justify="center"
              spacing={16}
            >
              {this.state.vehicleData &&
                this.state.value == 0 &&
                this.state.vehicleData.service_types[
                  this.state.value
                ].vehicles.map((res, index) => (
                  <Grid
                    style={{ backgroundColor: "white" }}
                    key={index}
                    item
                    onClick={() => this.checkEstimate(index, res)}
                  >
                    <div
                      style={{
                        backgroundColor:
                          this.state.vehicle_data_bg == res.id ? "#efefef" : "",
                        border:
                          this.state.vehicle_data_bg == res.id
                            ? "2px solid black"
                            : "1px solid grey",
                        borderRadius: "100%",
                        marginTop: 5,
                        width: 70,
                        height: 70,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <img
                        alt=""
                        src={res.vehicleTypeImage}
                        style={{
                          width: 50,
                          height: 50,

                          backgroundSize: "cover",
                          cursor: "pointer"
                        }}
                      />
                    </div>
                    <Typography
                      style={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                        textAlign: "center",
                        cursor: "pointer",
                        fontFamily: CUSTOM_FONT
                      }}
                    >
                      {res.vehicleTypeName}
                    </Typography>
                  </Grid>
                ))}
              {/* {this.state.vehicleData &&
                this.state.value == 1 &&
                this.state.vehicleData.service_types[
                  this.state.value
                ].vehicles.map((res, index) => ( */}
              {this.state.vehicleData &&
                this.state.value == 1 &&
                this.state.vehicleData.service_types[
                  this.state.value
                ].package.map(
                  (x, index) => (
                    <Grid
                      container
                      style={{
                        // flexFlow: "initial",
                        display: "flex",
                        flexDirection: "row"
                      }}
                    >
                      <Grid xs={12}>
                        <ExpansionPanel
                          expanded={expanded === index}
                          onChange={this.handlePanelChange(index)}
                        >
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <Chip
                              style={{ paddingRight: 0 }}
                              size="small"
                              label={x.name}
                              clickable
                              className={classes.chip}
                              color="primary"
                            />
                            {/* <Typography className={classes.heading}>
                              {x.name}
                            </Typography> */}
                            {/* <Typography className={classes.secondaryHeading}>
                              I am an expansion panel
                            </Typography> */}
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails
                            style={{
                              overflow: "scroll",
                              width: "100%",
                              height: "100px"
                            }}
                          >
                            {x.vehicles.map((res, index) => (
                              <Grid
                                style={{ backgroundColor: "white", padding: 5 }}
                                key={index}
                                item
                                onClick={() =>
                                  this.checkEstimate(index, res, x.id)
                                }
                              >
                                <div
                                  style={{
                                    backgroundColor:
                                      this.state.vehicle_data_bg == res.id
                                        ? "#efefef"
                                        : "",
                                    border:
                                      this.state.vehicle_data_bg == res.id
                                        ? "2px solid black"
                                        : "1px solid grey",
                                    borderRadius: "100%",
                                    marginTop: 5,
                                    width: 70,
                                    height: 70,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                  }}
                                >
                                  <img
                                    alt=""
                                    src={res.vehicleTypeImage}
                                    style={{
                                      width: 50,
                                      height: 50,

                                      backgroundSize: "cover",
                                      cursor: "pointer"
                                    }}
                                  />
                                </div>
                                <Typography
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    fontFamily: CUSTOM_FONT
                                  }}
                                >
                                  {res.vehicleTypeName}
                                </Typography>
                              </Grid>
                            ))}
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </Grid>
                      {/* 
                      <Grid container>
                        {x.vehicles.map((res, index) => (
                          <Grid
                            style={{ backgroundColor: "white" }}
                            key={index}
                            item
                            onClick={() => this.checkEstimate(index, res)}
                          >
                            <div
                              style={{
                                backgroundColor:
                                  this.state.vehicle_data_bg == res.id
                                    ? "#efefef"
                                    : "",
                                border:
                                  this.state.vehicle_data_bg == res.id
                                    ? "2px solid black"
                                    : "1px solid grey",
                                borderRadius: "100%",
                                marginTop: 5,
                                width: 70,
                                height: 70,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                            >
                              <img
                                alt=""
                                src={res.vehicleTypeImage}
                                style={{
                                  width: 50,
                                  height: 50,

                                  backgroundSize: "cover",
                                  cursor: "pointer"
                                }}
                              />
                            </div>
                            <Typography
                              style={{
                                fontSize: "1rem",
                                fontWeight: "bold",
                                textAlign: "left",
                                cursor: "pointer",
                                fontFamily: CUSTOM_FONT
                              }}
                            >
                              {res.vehicleTypeName}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    */}
                    </Grid>
                  )

                  // x.vehicles.map((res, index) => (
                  // <Grid
                  //   style={{ backgroundColor: "white" }}
                  //   key={index}
                  //   item
                  //   onClick={() => this.checkEstimate(index, res)}
                  // >
                  //   <div
                  //     style={{
                  //       backgroundColor:
                  //         this.state.vehicle_data_bg == res.id
                  //           ? "#efefef"
                  //           : "",
                  //       border:
                  //         this.state.vehicle_data_bg == res.id
                  //           ? "2px solid black"
                  //           : "1px solid grey",
                  //       borderRadius: "100%",
                  //       marginTop: 5,
                  //       width: 70,
                  //       height: 70,
                  //       display: "flex",
                  //       justifyContent: "center",
                  //       alignItems: "center"
                  //     }}
                  //   >
                  //     <img
                  //       src={res.vehicleTypeImage}
                  //       style={{
                  //         width: 50,
                  //         height: 50,

                  //         backgroundSize: "cover",
                  //         cursor: "pointer"
                  //       }}
                  //     />
                  //   </div>
                  //   <Typography
                  //     style={{
                  //       fontFamily: CUSTOM_FONT,
                  //       fontSize: "1rem",
                  //       fontWeight: "bold",
                  //       textAlign: "left",
                  //       cursor: "pointer"
                  //     }}
                  //   >
                  //     {res.vehicleTypeName}
                  //   </Typography>
                  // </Grid>
                )}
              {this.state.vehicleData &&
                this.state.value == 2 &&
                this.state.vehicleData.service_types[
                  this.state.value
                ].vehicles.map((res, index) => (
                  <Grid
                    style={{ backgroundColor: "white" }}
                    key={index}
                    item
                    onClick={() => this.checkEstimate(index, res)}
                  >
                    <div
                      style={{
                        backgroundColor:
                          this.state.vehicle_data_bg == res.id ? "#efefef" : "",
                        border:
                          this.state.vehicle_data_bg == res.id
                            ? "2px solid black"
                            : "1px solid grey",
                        borderRadius: "100%",
                        marginTop: 5,
                        width: 70,
                        height: 70,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <img
                        alt=""
                        src={res.vehicleTypeImage}
                        style={{
                          width: 50,
                          height: 50,

                          backgroundSize: "cover",
                          cursor: "pointer"
                        }}
                      />
                    </div>
                    <Typography
                      style={{
                        fontFamily: CUSTOM_FONT,
                        fontSize: "1rem",
                        fontWeight: "bold",
                        textAlign: "center",
                        cursor: "pointer"
                      }}
                    >
                      {res.vehicleTypeName}
                    </Typography>
                  </Grid>
                ))}
            </Grid>

            <Typography
              style={{
                fontFamily: CUSTOM_FONT,
                fontSize: "0.8rem",
                padding: "2em",
                textAlign: "left"
              }}
            >
              {this.props.data.description}
            </Typography>
          </div>
        </Grid>
      </div>
    );
  }
}

export default withStyles(HomeStyles)(HomeEstimatecontainer);

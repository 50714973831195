// import { getTheme } from "../../../../managers/theme/ThemeManager";
import { CUSTOM_FONT } from "../../../../values/Typography";

const styles = theme => {
  // const configTheme = getTheme();

  return {
    stepLabel: {
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      fontWeight: "bold"
    },
    phoneImage: {
      height: "60%",
      width: "60%"
    },
    stepTitle: {
      fontFamily: CUSTOM_FONT,
      fontSize: 32
    },
    stepDetail: {
      fontFamily: CUSTOM_FONT,
      fontSize: 24
    },
    backgroundImage: {
      height: "70vh",
      direction: "row",
      justify: "flex-end",
      borderRadius: 10,
      margin: 10
    },
    DriverProfileUpload: {
      width: "100%"
      // width: "10vw",
      // height: "45vh",
      // objectFit: "cover",
      // [theme.breakpoints.down("xs")]: {
      //   width: "36vw"
      // },
      // [theme.breakpoints.only("1024px")]: {
      //   width: "29vw"
      // }
    }
  };
};

export default styles;

export const save_addresses =(data) =>{
     
    return {
        type:"AddressData1",
        payload:data
    }

}

export const save_destination =(data) =>{
    
     
    return {
        type:"AddressData2",
        payload:data
    }

}
import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Grid,
  TextField,
  Button,
  InputAdornment,
  Icon
} from "@material-ui/core";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { DriverProfileStyles } from "../../../../styles";
import Typography from "@material-ui/core/Typography";
import { APPORIO_TAXI_APIS } from "../../../../../managers/api/ApiEndPoints";
import * as ApiManager from "../../../../../managers/api/ApiManager";
import CustomSnackbar from "../../../../custom/CustomSnackbar";
import CustomLoader from "../../../../custom/CustomLoader";


function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
        textAlign: "left"
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    value: 0,
    checked: false,
    drivername: "",
    number: "",
    open: false,
    openList: false,
    changePassword: false,
    mainContent: false,
    showNewPassword: false,
    showCurrentPassword: false,
    showConfirmPassword: false,
    currentpassword: "",
    newpassword: "",
    confirmpassword: "",
    showSnackbar: false,
    snackbarVariant: "error",
    snakBarMessage: "",
    loading: false



  };
  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false
    });
  };

  handleSwitch = () => {
    this.setState(state => ({ checked: !state.checked }));
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };
  handleClickShowConfirmPassword = () => {
    this.setState(state => ({
      showConfirmPassword: !state.showConfirmPassword
    }));
  };


  handlePassword = (name, event) => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleShowPassword = name => {
    this.setState({
      [name]: !this.state[name]
    });
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClickOpen = () => {
    this.setState({
      openList: true
    });
  };



  changePassword = () => {
    console.log("**************CHANGE PASSWORD***********", APPORIO_TAXI_APIS.DRIVER_DETAILS)

    this.setState({ loading: true });
    if (this.state.currentpassword === "") {
      this.setState({
        showSnackbar: true,
        snakBarMessage: "Please enter Current Password",
        loading: false
      });
    } else if (this.state.newpassword === "") {

      this.setState({
        showSnackbar: true,
        snakBarMessage: "Please enter New Password",
        loading: false
      });
    }
    else if (this.state.confirmpassword === "") {

      this.setState({
        showSnackbar: true,
        snakBarMessage: "Please enter Confirm Password",
        loading: false
      });
    }
    else if (this.state.confirmpassword != this.state.newpassword) {

      this.setState({
        showSnackbar: true,
        snakBarMessage: "New Password and Confirm Password don't match",
        snackbarVariant: "warning",
        loading: false
      });
    }

    else {
      ApiManager.callPostApi(
        "CHANGE_PASSWORD", "", APPORIO_TAXI_APIS.CHANGE_PASSWORD,
        {
          old_password: this.state.currentpassword,
          new_password: this.state.newpassword
        }
      )

        .then((response) => {
          console.log('*****response*******', response)
          // alert(JSON.stringify(response))

          switch (parseInt(response.result)) {
            case 1:
              this.setState({
                showSnackbar: true,
                snakBarMessage: "Password Changed",
                snackbarVariant: "success",
                currentpassword: "",
                newpassword: "",
                confirmpassword: "",
                CHANGE_PASSWORD_ROWS: response.data,
                loading: false

              })
              break;

            case 0:
              this.setState({
                showSnackbar: true,
                snakBarMessage: response.message,
                CHANGE_PASSWORD_ROWS: response.data,
                loading: false

              })
              break;
          }

          console.log(response)
        })
        .catch((error) => {
          console.log(error)

        })
    }
  }



  render() {
    console.log("driver home render", this.props);
    const { classes } = this.props;
    const { value } = this.state;
    const { checked } = this.state;

    if (this.state.loading) {
      return <CustomLoader showLoader={this.state.loading} />
    }
    else {

      return (

        <Grid item md={12} style={{}}>
          <CustomSnackbar
            showSnackbar={this.state.showSnackbar}
            variant={this.state.snackbarVariant}
            message={this.state.snakBarMessage}
            onClose={this.handleSnackBarClose}
          />

          <Grid container md={12} style={{
            display: "flex",
            flexDirection: "row",


          }}>
            <IconButton
              onClick={() =>
                this.props.onBackPress()
              }
            >
              <Icon style={{ color: "#666" }}>
                arrow_back
              </Icon>
            </IconButton>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "600", color: "#26699d", paddingTop: 10 }}
            >
              Change Password
            </Typography>
          </Grid>
          <hr style={{ color: "#eaeaea" }} />
          <Grid container xs={12} md={12} style={{ padding: 10 }}>
            <Grid item md={5} xs={4} style={{ marginTop: 10 }}>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                Current password

       </Typography>
              <Typography variant="display4" style={{ fontSize: "15px" }}>
                Introduce your current password
         </Typography>
            </Grid>
            <Grid item md={5} xs={8} style={{ padding: 5 }}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-adornment-password"
                className={classNames(classes.margin, classes.textField)}
                variant="outlined"
                type={this.state.showCurrentPassword ? "text" : "password"}
                label="Current Password"
                value={this.state.currentpassword}
                onChange={(event) => this.handlePassword("currentpassword", event)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={() => this.handleShowPassword("showCurrentPassword")}
                      >
                        {this.state.showCurrentPassword ? (
                          <VisibilityOff />
                        ) : (
                            <Visibility />
                          )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container md={12} style={{ padding: 10 }}>
            <Grid item md={5} xs={4} style={{ marginTop: 10 }}>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                New password

       </Typography>
              <Typography variant="display4" style={{ fontSize: "15px" }}>
                Type in your new password
         </Typography>
            </Grid>
            <Grid item md={5} xs={8} style={{ padding: 5 }}>
              <Grid container>
                <Grid item md={12} style={{ paddingBottom: 10 }}>
                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-adornment-newpassword"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    type={this.state.showNewPassword ? "text" : "password"}
                    label="New Password"
                    value={this.state.newpassword}
                    onChange={(event) => this.handlePassword("newpassword", event)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={() => this.handleShowPassword("showNewPassword")}
                          >
                            {this.state.showNewPassword ? (
                              <VisibilityOff />
                            ) : (
                                <Visibility />
                              )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item md={12} style={{ padding: "5px 0", paddingBottom: 30 }}>
                  <TextField
                    style={{ width: "100%" }}
                    id="outlined-adornment-renewpassword"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    type={this.state.showConfirmPassword ? "text" : "password"}
                    label="Confirm Password"
                    value={this.state.confirmpassword}
                    onChange={(event) => this.handlePassword("confirmpassword", event)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={() => this.handleShowPassword("showConfirmPassword")}
                          >
                            {this.state.showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                                <Visibility />
                              )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <br />

            <Grid md={10} align="right" style={{ paddingRight: 0 }}>
              <Button variant="contained" style={{ width: "20%", fontWeight: "800", backgroundColor: "#4990E2", color: "#fff" }}
                onClick={() => this.changePassword()}>
                Save
                </Button>
            </Grid>
          </Grid>
        </Grid>


      );
    }
  }
}

export default withStyles(DriverProfileStyles)(ChangePassword);

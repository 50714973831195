import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Grid,
  Typography
} from "@material-ui/core";
import { callPostApi } from "../../../../managers/api/ApiManager";
import { APPORIO_TAXI_APIS } from "../../../../managers/api/ApiEndPoints";
import DropDown from "../../../custom/DropDown";

class DialogCancelReasons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reasonApiData: [],
      reasonList: [],
      selectedReason: "",
      cancelCharges: 0
    };
  }

  componentDidMount() {
    this._fetchReasons();
  }

  _fetchReasons = () => {
    callPostApi(
      "DialogCancelReasons",
      "Cancel Reason List",
      APPORIO_TAXI_APIS.USER_TRIP_CANCEL_REASONS,
      {
        booking_id: this.props.booking_id
      }
    ).then(res => {
      if (res && res.result == 1) {
        let arr = [];
        res.data.forEach(element => {
          arr.push(element.reason);
        });
        this.setState({
          reasonApiData: res.data,
          cancelCharges: res.cancel_charges,
          reasonList: arr
        });
      } else {
        this.setState({ reasonApiData: [], reasonList: [], cancelCharges: 0 });
      }
    });
  };

  render() {
    const { open, onClose, onOk, showMsg } = this.props;
    return (
      <Dialog
        onClose={() => {}}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => {}}>
          Cancel Reason
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Select any reason to cancel this ride. You may be charged some
            amount to cancel this ride
          </Typography>

          <Grid container spacing={16} style={{ marginTop: "10px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <DropDown
                placeholder="Select reason"
                selectedItem={this.state.selectedReason}
                onSelect={item => {
                  this.setState({ selectedReason: item });
                }}
                data={this.state.reasonList}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              let data = this.state.reasonApiData.find(
                obj => obj.reason == this.state.selectedReason
              );
              data.cancelCharges = this.state.cancelCharges;
              if (data) onOk(data);
              else showMsg("Select a reason", "error");
            }}
            color="primary"
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      //   <Dialog open={open} aria-labelledby="form-dialog-title">
      //     <DialogTitle id="form-dialog-title">Cancel Reason</DialogTitle>
      //     <DialogContent>
      //       <Grid container spacing={16} style={{ marginTop: "10px" }}>
      //         <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      //           <DropDown
      //             placeholder="Select reason"
      //             selectedItem={this.state.selectedReason}
      //             onSelect={item => {
      //               this.setState({ selectedReason: item });
      //             }}
      //             data={this.state.reasonList}
      //           />
      //         </Grid>
      //       </Grid>
      //     </DialogContent>
      //     <DialogActions>
      //       <Button onClick={onClose} color="primary">
      //         Cancel
      //       </Button>
      //       <Button
      //         onClick={() => {
      //           onOk(this.state);
      //         }}
      //         color="primary"
      //       >
      //         Send
      //       </Button>
      //     </DialogActions>
      //   </Dialog>
    );
  }
}

export default DialogCancelReasons;

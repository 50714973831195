import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import { TableHead } from "@material-ui/core";
import TableHeaderColumn from "./TableHeaderColumn";

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary
  }
});

class PagnationTable extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    //alert(this.props.page);
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

PagnationTable.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true
})(PagnationTable);

const styles = theme => ({
  root: {
    minWidth: 300,
    [theme.breakpoints.down("sm")]: {
      width: 285
    },
    //display: "flex",
    //justifyContent: "center",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    width: "100%"

    // tableLayout: "auto"
    //   width: "100vw"
  },
  tableWrapper: {
    //  overflowX: "auto"
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#ececec"
    }
  }
});

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "white",
    color: theme.palette.common.white,

    //padding: "0 2px 0 0"
    padding: "2 2 2 2"
  },
  body: {
    padding: 0,
    //padding: "10px",
    //padding: "0.2em",

    borderRight: "1px solid #b5b5b5"
  }
  //align: "justify"
}))(TableCell);

class PaginationTable extends React.PureComponent {
  state = {
    page: 0,
    rowsPerPage: 5
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { rowsPerPage, page } = this.state;
    // const emptyRows =
    //   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, this.props.children.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {this.props.columns.map((col, index) => (
                <CustomTableCell
                  style={{
                    backgroundColor: this.props.tableHeadBackground
                  }}
                  key={index}
                >
                  {
                    <TableHeaderColumn
                      tableHeadFontColor={this.props.tableHeadFontColor}
                      tableheadFontSize={this.props.tableheadFontSize}
                      index={index}
                      sorted={false}
                      data={col}
                      onClick={() => {}}
                    />
                  }
                </CustomTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.children
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor: this.props.tableRowColor
                      ? index % 2 === 0 && this.props.tableRowColor
                      : null
                  }}
                >
                  {row.map((col, i) => {
                    return (
                      <CustomTableCell
                        component="th"
                        scope="row"
                        //align={"center"}
                        key={i}
                        style={{
                          backgroundColor: col.props.color && col.props.color
                        }}
                      >
                        {col}
                      </CustomTableCell>
                    );
                  })}
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={1} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                //  colSpan={-12}
                count={this.props.children.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: true
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

PaginationTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PaginationTable);

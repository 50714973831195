import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Grid
} from "@material-ui/core";

class DialogSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: ""
    };
  }
  render() {
    const { open, onClose, onOk } = this.props;
    return (
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Customer Support</DialogTitle>
        <DialogContent>
          <Grid container spacing={16} style={{ marginTop: "10px" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                id="name"
                email={this.state.name}
                label="Your Name*"
                type="text"
                fullWidth
                // InputLabelProps={{
                //   shrink: true
                // }}
                variant="outlined"
                onChange={event => this.setState({ name: event.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                margin="dense"
                id="email"
                email={this.state.email}
                label="Your Email*"
                type="text"
                fullWidth
                // InputLabelProps={{
                //   shrink: true
                // }}
                variant="outlined"
                onChange={event => this.setState({ email: event.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                margin="dense"
                id="phone"
                email={this.state.phone}
                label="Your Phone"
                type="number"
                fullWidth
                // InputLabelProps={{
                //   shrink: true
                // }}
                variant="outlined"
                onChange={event => this.setState({ phone: event.target.value })}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                margin="dense"
                id="query"
                email={this.state.message}
                label="Your Query*"
                type="text"
                fullWidth
                multiline
                rows={5}
                // InputLabelProps={{
                //   shrink: true
                // }}
                variant="outlined"
                onChange={event =>
                  this.setState({ message: event.target.value })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              onOk(this.state);
            }}
            color="primary"
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DialogSupport;

export const DRIVER_ROUTES = {
  EARNING: "/driver/earnings",
  TRIPS: "/driver/manage_trips",
  VEHICLES: "/driver/manage_vehicles",
  WALLET: "/driver/wallet",
  PROFILE: "/driver/profile",
  TRACKRIDE: "/driver/track_ride",
  CHANGE_PASSWORD: "/driver/change_password",
  LOGOUT: "/driver/logout",
  HOME: "/driver/home",
  LOGIN: "/driver/login",
  SIGNUP: "/driver/signup"
};

export const USER_ROUTES = {
  TRIPS: "/user_trips",
  PROFILE: "/user_profile",
  MY_ACCOUNT: "/user_booking",
  LOGOUT: "/"
};

export const ADMIN_ROUTES = {
  EDIT_DRIVER_HOME: "/admin/edit_driver_home",
  EDIT_USER_HOME: "/admin/edit_user_home"
};
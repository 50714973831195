import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Grid,
  Fab,
  CardMedia,
  Icon,
  TextField,
  Button
} from "@material-ui/core";
import { ManageTripStyles, DriverWalletStyles } from "../../../styles";
import CustomSnackbar from "../../../custom/CustomSnackbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Switch from "@material-ui/core/Switch";
import Collapse from "@material-ui/core/Collapse";
import UserLayout from "../common/UserLayout";
import Transactiontable from "../userwallet/Transactiontable";
import CustomLoader from "../../../custom/CustomLoader";
import * as ApiManager from "../../../../managers/api/ApiManager";
import { Redirect } from "react-router-dom";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import { APPORIO_TAXI_APIS } from "../../../../managers/api/ApiEndPoints";
import AddMoneyCard from "./AddMoneyCard";
import CardSelectPopup from "../../../../UI/segments/driver/common/CardSelectPopup";
import CardsList from "./CardsList";
import * as LanguageManager from "../../../../managers/language/LanguageManager";
import EventBus from "eventing-bus";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
        textAlign: "left"
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class UserWallet extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    value: 0,
    checked: false,
    money: "",
    totalMoney: "",
    isLoading: false,
    transactionLog: [],
    showAddMoney: false,
    disableButton: true,
    openCCForm: false,
    openDCForm: false,
    showSnackbar: false,
    snackbarVariant: "error",
    snakBarMessage: "",
    showSnackbar: false,
    logOutNow: false,
    currency: ""
  };

  redirectToDashboard = () => {
    if (this.state.logOutNow) {
      return (
        <Redirect
          to={{
            pathname: "/"
          }}
        />
      );
    }
  };

  async componentDidMount() {
    this.fetchTransactionHistory(3);

    console.log(
      "000000000000000000000000000000000",
      JSON.parse(SessionManager.getSessionData(SessionKeys.USER_CONFIG))
    );
    let data = JSON.parse(
      SessionManager.getSessionData(SessionKeys.USER_CONFIG)
    );

    this.setState({
      currency: data.countries[0].isoCode
    });
  }

  handleSwitch = () => {
    this.setState(state => ({ checked: !state.checked }));
  };

  getLogoutResponse(result) {
    EventBus.publish("KILL_HOME", "1");
    if (result) {
      SessionManager.particularKey(SessionKeys.ACCESS_TOKEN);
      this.setState({ logOutNow: true });
    }
  }

  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false
    });
  };

  handleChange = (event, value) => {
    // console.log("zxzx"+value)
    this.setState({ value });

    if (value == 1) {
      this.fetchTransactionHistory(1);
    } else if (value == 2) {
      this.fetchTransactionHistory(2);
    } else if (value == 0) {
      this.fetchTransactionHistory(3);
    }
  };

  handleAddMoney = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  fetchTransactionHistory(filterVal) {
    this.setState({ isLoading: true });
    ApiManager.callPostApi(
      "UserWallet  Component",
      "UserWallet API",
      APPORIO_TAXI_APIS.USER_TRANSACTION_LOG,
      {
        filter: filterVal
      }
    )
      .then(res => {
        if (res.result === "1") {
          console.log("tetete" + JSON.stringify(res));
          this.setState({
            isLoading: false,
            transactionLog: res.data.recent_transactoin,
            totalMoney: res.data.wallet_balance
          });
        } else {
          this.setState({
            showSnackbar: true,
            snakBarMessage: res.message,
            isLoading: false
          });
        }
      })
      .catch(err => alert(JSON.stringify(err)));
  }

  getResponse(result) {
    if (result == 1) {
      this.setState({ openCCForm: true });
    }
  }

  getCash(result) {
    console.log("#F#F" + result);
    this.setState({ totalMoney: parseInt(this.state.totalMoney) + result });
  }

  render() {
    console.log("driver home render", this.props);
    const { classes } = this.props;
    const { value } = this.state;
    const { checked } = this.state;

    return (
      <UserLayout logoutCallBack={this.getLogoutResponse.bind(this)}>
        <div className={classes.godContainer}>
          <CardSelectPopup
            visible={this.state.showAddMoney}
            dismiss={() => this.setState({ showAddMoney: false })}
            callback={this.getResponse.bind(this)}
          />
          <CustomSnackbar
            showSnackbar={this.state.showSnackbar}
            variant={this.state.snackbarVariant}
            message={this.state.snakBarMessage}
            onClose={this.handleSnackBarClose}
          />
          {this.redirectToDashboard()}
          {!this.state.openCCForm ? (
            <div>
              <CustomLoader showLoader={this.state.isLoading} />
              <Grid container md={12} style={{ padding: 10 }}>
                <Grid item xs={6} align="left">
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "600",
                      color: "#26699d"
                      // margin: "15px 0 0 20px"
                    }}
                  >
                    {LanguageManager.Strings.WALLET_LABEL}
                  </Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  {/* <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                    {LanguageManager.Strings.CURRENT_BAL_LABEL}
                  </Typography> */}
                  <Typography
                    variant="h6"
                    style={{ color: "#2dcc70", fontWeight: 700 }}
                  >
                    {this.state.currency} {this.state.totalMoney}
                  </Typography>
                </Grid>
              </Grid>
              <hr style={{ color: "#eaeaea" }} />

              {/* <Paper>
                <Grid container xl style={{ height: 300 }}>
                  <Grid
                    container
                    style={{ backgroundColor: "#f5f5f5", paddingTop: "5%" }}
                    justify="center"
                    xl
                  >
                    <div style={{ justifyContent: "flex-start" }}>
                      <Typography variant="h7" style={{ fontWeight: 600 }}>
                        {LanguageManager.Strings.CURRENT_BAL_LABEL}
                      </Typography>
                      <Typography
                        variant="h4"
                        style={{ color: "#2dcc70", fontWeight: 700 }}
                      >
                        {this.state.currency} {this.state.totalMoney}
                      </Typography>
                    </div>
                  </Grid>
                 <Grid container justify="center" xl>
                <Grid item>
                  <TextField
                    placeholder="Add Money"
                    id="outlined-number"
                    label="Add Money To Wallet"
                    value={this.state.money}
                    onChange={this.handleAddMoney("money")}
                    type="number"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>

                <Grid item style={{margin:'2%'}}>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={classes.margin}
                    style={{ margin: "0 5px" }}
                    onClick={()=>this.setState({money:100,disableButton:false})}
                  >
                    + 100
                  </Button>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={classes.margin}
                    onClick={()=>this.setState({money:500,disableButton:false})}
                  >
                    + 500
                  </Button>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    className={classes.margin}
                    style={{ margin: "0 5px" }}
                    onClick={()=>this.setState({money:1000,disableButton:false})}
                  >
                    + 1000
                  </Button>
                  <Button
                    variant="contained"
                    disabled={this.state.disableButton}
                    size="Large"
                    color="green"
                    className={classes.margin}
                    style={{ margin:'5%' }}
                    onClick={()=>this.setState({showAddMoney:true})}>
                    Add Money
                  </Button>
                </Grid>
              </Grid> 
                </Grid>
              </Paper> */}

              <Paper
                style={{
                  backgroundColor: "#efefef"
                }}
              >
                <Tabs
                  value={this.state.value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={this.handleChange}
                >
                  <Tab
                    label={LanguageManager.Strings.TRANSACTION_LABEL1}
                    className={classes.tabStyle}
                  />
                  <Tab
                    label={LanguageManager.Strings.TRANSACTION_LABEL2}
                    className={classes.tabStyle}
                  />
                  <Tab
                    label={LanguageManager.Strings.TRANSACTION_LABEL3}
                    className={classes.tabStyle}
                  />
                </Tabs>
              </Paper>
              {this.state.transactionLog != "" ? (
                <div>
                  {value === 0 && (
                    <Transactiontable
                      currency={this.state.currency}
                      data={this.state.transactionLog}
                    />
                  )}
                  {value === 1 && (
                    <Transactiontable
                      currency={this.state.currency}
                      data={this.state.transactionLog}
                    />
                  )}
                  {value === 2 && (
                    <Transactiontable
                      currency={this.state.currency}
                      data={this.state.transactionLog}
                    />
                  )}
                </div>
              ) : (
                <Typography
                  variant="h6"
                  style={{
                    display: "flex",
                    /* flex-direction: column; */
                    justifyContent: "center",
                    alignItems: "center",
                    /* text-align: center; */
                    minHeight: "50vh"
                  }}
                >
                  No Transaction yet
                </Typography>
              )}
            </div>
          ) : (
            <CardsList
              dismiss={() => this.setState({ openCCForm: false })}
              addAmount={this.state.money}
              fetchCashAdded={this.getCash.bind(this)}
            />
          )}
        </div>
      </UserLayout>
    );
  }
}

export default withStyles(DriverWalletStyles)(UserWallet);

{
  /* <AddMoneyCard nextCall={()=>this.setState({showAddMoney:false})}/> */
}

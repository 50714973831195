import React from "react";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// import { pureBlack } from "../../../../../../values/Color";
import { FONT_FAMILY } from "../../../../../../utils/AppConstants";

const styles = theme => ({
  root: {
    display: "flex",
    flex: 1,
    width: "100%",
    height: "100%",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center"
  },
  colName: {
    alignSelf: "center",
    fontSize: "16px",

    // color: pureBlack,
    fontFamily: FONT_FAMILY
  }
});

const CsId = props => {
  console.log("props csid", props)
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Typography className={classes.colName}>{props.data}</Typography>
    </div>
  );
};

CsId.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CsId);

import React, { Component, Fragment } from "react";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { DriverDropdownStyles } from '../../../styles';

import {
    FormHelperText,
    withStyles,
    Input,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    NativeSelect,
    OutlinedInput,
    FilledInput,
    InputBase,
    Typography,
    Grid
} from "@material-ui/core";


const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

class Autocomplete extends Component {
    static propTypes = {
        suggestions: PropTypes.instanceOf(Array)
    };

    static defaultProps = {
        suggestions: []
    };

    constructor(props) {
        super(props);

        this.state = {
            // The active selection's index
            activeSuggestion: 0,
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            userInput: "",
           id:''
        };
    }


    renderMenuItem(rowItem,id,styles){
        switch(id){
            case 1:
            return <option value={rowItem.id} className={styles.areaItem}>{rowItem.vehicleTypeName}</option>

            break;

            case 2:
            return <option value={rowItem.id} className={styles.areaItem}>{rowItem.vehicleMakeName}</option>

            break;

            case 3:
            return <option value={rowItem.id} className={styles.areaItem}>{rowItem.vehicleModelName}</option>
            break;

            // case 4:
            // return  <MenuItem value={row.id} className={classes.areaItem}>{row.vehicleTypeName}</MenuItem>
            // break;

            // case 5:
            // return  <MenuItem value={row.id} className={classes.areaItem}>{row.vehicleTypeName}</MenuItem>
            // break;

            default:
            return null
            break;
        }
    }


    handleChanges(event,id) {
        this.props.callback(event.target.value)
        this.setState({id:event.target.value})
        this.setState({
          ...this.state,
          [event.target.name]: event.target.value,
        });
      }


    render() {

   const {classes,listData,indexID,fieldLabel}=this.props

        return (
          <Grid xl container justify="flex-start" alignItems="center" spacing={16} className={classes.selectContainer}>
          <Grid item style={{flex:1}}>
            <Typography variant="subtitle" className={classes.loginTitle}>
            {fieldLabel}
            </Typography>
          </Grid>
          <Grid item style={{flex:1}}>

    <FormControl className={classes.formControl}>
          {/* <InputLabel htmlFor="age-customized-select" className={classes.bootstrapFormLabel}>
          {fieldLabel}
          </InputLabel> */}
          <Select
          native inputProps={{ style: { width: 100 } }}
            value={this.state.age}
            displayEmpty
            onChange={(evt,id) => this.handleChanges(evt,id)}
            className={classes.selectOption}
            input={<BootstrapInput name="age" placeholder="age" id="age-customized-select" />}
          >
            {/* <MenuItem value="">
                <em>None</em>
          </MenuItem> */}
          <option value="" >None</option>
  {this.props.listData.map(row => (
                          this.renderMenuItem(row,indexID,classes)
                      ))}
          </Select>
        </FormControl>
        </Grid>
          </Grid>
                  
        );
    }
}

export default withStyles(DriverDropdownStyles)(Autocomplete);
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { ManageTripStyles } from "../../../styles";
import {
  Avatar,
  Icon,
  IconButton,
  Grid,
  Menu,
  MenuItem
} from "@material-ui/core";
import { PADDING } from "../../../../values/Typography";
import { DRIVER_ROUTES } from "../../../../routes/ScreenRoutes";
import { getSessionData } from "../../../../managers/session/SessionManager";
import { DRIVER_DATA } from "../../../../managers/session/SessionKeys";
import CustomLoader from "../../../custom/CustomLoader";
import { TAXI_IMAGE_URL } from "../../../../managers/api/ApiEndPoints";
import * as LanguageManager from "../../../../managers/language/LanguageManager";

class DriverLayout extends Component {
  state = {
    anchorEl: null,
    wait: true,
    DriverData: null
  };

  componentDidMount() {
    console.log(JSON.parse(getSessionData(DRIVER_DATA)));
    let data = JSON.parse(getSessionData(DRIVER_DATA));
    this.setState({ DriverData: data });
    this.setState({ wait: false });
    // if (this.state.DriverData  !==  null) {
    //   wait: false
    // }
  }

  handleDriverDataInput = (event, name) => {
    this.setState({
      DriverData: {
        ...this.state.DriverData,
        [name]: event.target.value
      }
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    console.log("driver layout render");

    if (this.state.wait) {
      return <CustomLoader showLoader={this.state.wait} />;
    } else {
      return (
        <div className={classes.root}>
          <CssBaseline />
          {/* <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap>
              Permanent drawer
            </Typography>
          </Toolbar>
        </AppBar> */}
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper
            }}
            anchor="left"
          >
            <Grid container className={classes.toolbar}>
              <Grid item md={2}>
                <Avatar
                  src={TAXI_IMAGE_URL + this.state.DriverData.profile_image}
                  style={{
                    margin: 5,
                    width: 50,
                    height: 50,
                    objectFit: "cover"
                  }}
                />
              </Grid>
              <Grid item md={8} align="center">
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  style={{
                    paddingTop: PADDING.SM,
                    textTransform: "capitalize",
                    fontWeight: "600"
                  }}
                >
                  {LanguageManager.Strings.WELCOME_LABEL}{" "}
                  {this.state.DriverData !== ""
                    ? this.state.DriverData.first_name
                    : ""}
                </Typography>

                <Typography variant="caption" gutterBottom>
                  {this.state.DriverData !== ""
                    ? this.state.DriverData.email
                    : ""}
                </Typography>
              </Grid>

              <Grid item md={2}>
                <IconButton onClick={this.handleClick}>
                  <Icon> more_horiz</Icon>
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                >
                  <Link
                    to={DRIVER_ROUTES.PROFILE}
                    style={{ textDecoration: "none" }}
                  >
                    <MenuItem>
                      {LanguageManager.Strings.DRIVER_MENU_OPTION1}
                    </MenuItem>
                  </Link>

                  <Link
                    to={DRIVER_ROUTES.TRACKRIDE}
                    style={{ textDecoration: "none" }}
                  >
                    <MenuItem>Track Ride</MenuItem>
                  </Link>

                  <Link
                    to={DRIVER_ROUTES.DASHBOARD}
                    style={{ textDecoration: "none" }}
                  >
                    <MenuItem>LogoutS</MenuItem>
                  </Link>
                </Menu>
              </Grid>
            </Grid>
            <Divider />
            <div
              xs={12}
              container
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 10,
                height: "50px",
                borderRadius: 5,
                color: "#fff"
              }}
            >
              <Grid
                item
                xs={4}
                style={{
                  backgroundColor: "#2DCC70",
                  borderRadius: "5px 0 0 5px",
                  paddingTop: 5
                }}
              >
                <Typography variant="caption" className={classes.textWhite}>
                  Total Rides
                </Typography>
                <Typography variant="subtitle2" className={classes.textWhite}>
                  {this.state.DriverData !== ""
                    ? this.state.DriverData.totalEarning.total_ride
                    : ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ backgroundColor: "#4990E2", paddingTop: 5 }}
              >
                <Typography variant="caption" className={classes.textWhite}>
                  Your Rating
                </Typography>
                <Typography variant="subtitle2" className={classes.textWhite}>
                  {this.state.DriverData !== ""
                    ? this.state.DriverData.totalEarning.rating
                    : ""}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  backgroundColor: "#F5A623",
                  borderRadius: "0 5px 5px 0",
                  paddingTop: 5
                }}
              >
                <Typography variant="caption" className={classes.textWhite}>
                  Total Earnings
                </Typography>
                <Typography variant="subtitle2" className={classes.textWhite}>
                  ₹{" "}
                  {this.state.DriverData !== ""
                    ? this.state.DriverData.totalEarning.total_earning
                    : ""}
                </Typography>
              </Grid>
            </div>
            <List>
              <Link
                to={DRIVER_ROUTES.VEHICLES}
                style={{ textDecoration: "none" }}
              >
                <ListItem button key="1">
                  <ListItemText primary="Manage Vehicles" />
                </ListItem>
              </Link>

              <Link to={DRIVER_ROUTES.TRIPS} style={{ textDecoration: "none" }}>
                <ListItem button key="2">
                  <ListItemText primary="Manage Trips" />
                </ListItem>
              </Link>

              <Link
                to={DRIVER_ROUTES.WALLET}
                style={{ textDecoration: "none" }}
              >
                <ListItem button key="3">
                  <ListItemText primary="Wallet" />
                </ListItem>
              </Link>

              <Link
                to={DRIVER_ROUTES.EARNING}
                style={{ textDecoration: "none" }}
              >
                <ListItem button key="4">
                  <ListItemText primary="Earnings" />
                </ListItem>
              </Link>
            </List>
            <Divider />
          </Drawer>
          <main className={classes.content}>{this.props.children}</main>
        </div>
      );
    }
  }
}

export default withStyles(ManageTripStyles)(DriverLayout);

import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Grid,
  TextField,
  Button,
  MenuItem,
  Select
} from "@material-ui/core";
import { ForgotPasswordStyles } from "../../../styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { APPORIO_TAXI_APIS } from "../../../../managers/api/ApiEndPoints";
import * as ApiManager from "../../../../managers/api/ApiManager";
import CustomLoader from "../../../custom/CustomLoader";
import DriverTextField from "../common/DriverTextField";
import EnterNewPass from "./EnterNewPass";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
        textAlign: "left"
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class EnterOtp extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    value: 0,
    checked: false,
    drivername: "",
    number: "",
    open: false,
    openList: false,
    changePassword: false,
    otp: "",
    mainOtpContent: true,
    isLoading: false,
    enterNewPass: false
  };

  closeEnterPass = () => {
    this.setState({
      mainOtpContent: false,
      enterNewPass: false
    });
  };

  handleSwitch = () => {
    this.setState(state => ({ checked: !state.checked }));
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  sendOtp = () => {
    console.log("**************OTP MATCH***********", this.props.OTP_ROW.otp);
    console.log("**************OTP state MATCH***********", this.state.otp);

    this.setState({ isLoading: true });
    if (this.state.otp == this.props.OTP_ROW.otp) {
      // alert("OTP MATCH");
      this.setState({
        // showSnackbar: true,
        enterNewPass: true,
        mainOtpContent: false,
        isLoading: false
      });
    } else {
      this.props.openSnack("OTP does not match", "warning");
      this.setState({
        isLoading: false
      });
    }
  };

  fetchOTP(result) {
    this.setState({ otp: result });
  }

  render() {
    console.log("driver home render", this.props);
    const { classes } = this.props;
    const { value } = this.state;
    const { checked } = this.state;

    if (this.state.isLoading) {
      return <CustomLoader showLoader={this.state.isLoading} />;
    } else {
      return (
        <div>
          {this.state.mainOtpContent && (
            <Grid item md={12} style={{}}>
              <Grid
                container
                md={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: -10
                }}
              >
                {/* <IconButton
            onClick={() =>
              this.props.onBackPress()
            }
          >
            <Icon style={{ color: "#666" }}>
              arrow_back
              </Icon>
          </IconButton> */}
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: "600",
                    color: "#26699d",
                    paddingTop: 10
                  }}
                >
                  Enter OTP
                </Typography>
              </Grid>
              <hr style={{ color: "#eaeaea" }} />
              <Grid container md={12} style={{ padding: 10 }}>
                <Grid item md={5} style={{ marginTop: 10 }}>
                  <Typography variant="body2" style={{ fontWeight: 600 }}>
                    Enter OTP
                  </Typography>
                  <Typography variant="display4" style={{ fontSize: "15px" }}>
                    Enter OTP sent to your phone number
                  </Typography>
                </Grid>
                <Grid item md={5} style={{ padding: 5 }}>
                  <div className={classes.OtpText}>
                    <DriverTextField
                      style={{}}
                      placeHolder="Enter OTP"
                      // iconName="assignment_ind"
                      callback={this.fetchOTP.bind(this)}
                    />
                  </div>
                </Grid>
              </Grid>
              <br />
              <Grid container md={12} style={{}}>
                <Grid md={10} xs={12} align="right" style={{ paddingRight: 0 }}>
                  <Button
                    variant="contained"
                    className={classes.OtpSend}
                    onClick={() => this.sendOtp()}
                  >
                    SEND
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* // {alert(JSON.stringify(this.props.user_name))} */}

          {this.state.enterNewPass && (
            <EnterNewPass
              DRIVER_CONFIG_ROWS={this.props.DRIVER_CONFIG_ROWS}
              openSnack={this.props.openSnack}
              handleModal={this.props.handleModal}
              user_name={this.props.user_name}
              // closeEnterPass={this.closeEnterPass}
              // onBackPress={() =>
              //   this.setState({
              //     enterNewPass: !this.state.enterNewPass,
              //     mainOtpContent: !this.state.mainContent
              //   })
              // }
            />
          )}
        </div>
      );
    }
  }
}

export default withStyles(ForgotPasswordStyles)(EnterOtp);

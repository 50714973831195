import React from "react";
import PropTypes from "prop-types";
import { withStyles, Grid, Button } from "@material-ui/core";
import { ManageVehicleStyles } from "../../../../styles";
import DrawerLayout from "../../common/DrawerLayout";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import * as ApiManager from "../../../../../managers/api/ApiManager";
import {
  APPORIO_TAXI_APIS,
  TAXI_IMAGE_URL
} from "../../../../../managers/api/ApiEndPoints";
import CustomLoader from "../../../../custom/CustomLoader";
import { getSessionData } from "../../../../../managers/session/SessionManager";
import { DRIVER_DATA } from "../../../../../managers/session/SessionKeys";
import AddDriverCar from "./AddDriverCar";
import VehicleListing from "./tabs/VehicleListing";
import XLoader from "../../../../custom/XLoader";
import CustomSnackbar from "../../../../custom/CustomSnackbar";
import DialogFormVehicle from "../../../../custom/DialogFormVehicle";
import * as SessionManager from "../../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../../managers/session/SessionKeys";
import * as LanguageManager from "../../../../../managers/language/LanguageManager";

function TabContainer(props) {
  return (
    <Typography component="div" style={{}}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

// const styles = theme => ({
//   root: {
//     flexGrow: 1
//   },
//   // carImg: {
//   //   [theme.breakpoints.between('xs', 'sm')]: {
//   //     marginLeft: "15px"
//   //   },
//   // },
//   // carImg: {
//   //   padding: theme.spacing.unit,
//   //   [theme.breakpoints.down('sm')]: {
//   //     backgroundColor: theme.palette.secondary.main,
//   //   },
//   //   [theme.breakpoints.up('md')]: {
//   //     backgroundColor: theme.palette.primary.main,
//   //   },
//   //   [theme.breakpoints.up('lg')]: {
//   //     backgroundColor: green[500],
//   //   },
//   // },
//   carImg: {
//     backgroundColor: 'blue',

//     [theme.breakpoints.up('md')]: {
//       backgroundColor: 'red',
//     },
//     [theme.breakpoints.down('sm')]: {
//       backgroundColor: 'green',
//     },
//   },
//   viewDocButton: {
//     fontSize: 5,
//     [theme.breakpoints.up('md')]: {
//       backgroundColor: 'red',
//     },
//     [theme.breakpoints.down('sm')]: {
//       backgroundColor: 'green',
//     },
//   }
// });

class ManageVehicles extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    value: 0,
    wait: true,
    openAddVehicle: false,
    VEHICLE_ROWS: [],
    ACTIVE_VEHICLE: [],
    AreaData: null,
    driverID: null,
    loading: false,
    showSnackbar: false,
    snackbarVariant: "error",
    snakBarMessage: "",
    DRIVER_CONFIG_ROWS: {}
  };

  onAfterAddVehicleCallback = () => {
    this.executeManageVehicleListApi();
  };

  executeManageVehicleListApi = () => {
    console.log("qwertyuiop", JSON.parse(getSessionData(DRIVER_DATA)));
    let area_data = JSON.parse(getSessionData(DRIVER_DATA));
    this.setState({
      AreaData: area_data.country_area_id,
      driverID: area_data.id,
      wait: true,
      loading: true
    });
    // const {foo} = this.props.location.state
    // console.log('component did mountssss'+JSON.stringify(this.props.location.state));
    ApiManager.callPostApi(
      "ManageVehicles",
      "",
      APPORIO_TAXI_APIS.MANAGE_VEHICLES
    )

      .then(response => {
        console.log("*******" + JSON.stringify(response));
        console.log(response);
        this.setState({
          VEHICLE_ROWS: response.data.filter(
            obj => obj.vehicle_active_status != 1
          ),
          ACTIVE_VEHICLE: response.data.find(
            obj => obj.vehicle_active_status == 1
          ),
          VERIFIED_VEHICLE: response.data.filter(
            obj =>
              obj.vehicle_verification_status == 1 &&
              obj.vehicle_active_status != 1
          ),
          PENDING_FOR_APPROVAL: response.data.filter(
            obj => obj.vehicle_verification_status == 2
          ),

          wait: false,
          loading: false
        });
      })

      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.executeManageVehicleListApi();
    console.log(
      JSON.parse(SessionManager.getSessionData(SessionKeys.DRIVER_CONFIG))
    );
    let config_data = JSON.parse(
      SessionManager.getSessionData(SessionKeys.DRIVER_CONFIG)
    );
    this.setState({
      DRIVER_CONFIG_ROWS: config_data
    });
  }

  getResponse(result) {
    if (result) {
      this.props.history.index = 0;
    }
  }

  handleClickOpen = name => {
    this.setState(
      {
        [name]: !this.state[name]
      },
      () => !this.state.openAddVehicle && this.executeManageVehicleListApi()
    );
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  openSnack = (message = "", variant = "error") => {
    this.setState({
      showSnackbar: true,
      snakBarMessage: message,
      snackbarVariant: variant
    });
  };

  finalSnack = (message = "", variant = "error") => {
    this.setState(
      {
        showSnackbar: true,
        snakBarMessage: message,
        snackbarVariant: variant,
        openAddModal: false
      },
      () => {
        this.executeManageVehicleListApi();
      }
    );
  };

  handleSnackBarClose = () => {
    this.setState({
      showSnackbar: false
    });
  };

  render() {
    // console.log("driver home render", this.props);
    const { classes } = this.props;
    const { value } = this.state;
    console.log("ACTIVE_VEHICLE", this.state.ACTIVE_VEHICLE);

    if (this.state.wait) {
      return <CustomLoader showLoader={this.state.wait} />;
    } else {
      // console.log("add multiple vehicle", this.state.DRIVER_CONFIG_ROWS.general_config.add_multiple_vehicle );
      return (
        <DrawerLayout logoutCallback={this.getResponse.bind(this)}>
          <Grid container xs={12}>
            <CustomSnackbar
              showSnackbar={this.state.showSnackbar}
              variant={this.state.snackbarVariant}
              message={this.state.snakBarMessage}
              onClose={this.handleSnackBarClose}
            />
            <Grid item xs={6} align="left">
              <Typography
                variant="subtitle1"
                className={classes.ActivatedVehicle}
              >
                {LanguageManager.Strings.ACTIVATED_VEHICLES_LABEL}
              </Typography>
            </Grid>

            {this.state.DRIVER_CONFIG_ROWS.general_config
              .add_multiple_vehicle && (
              <Grid item xs={6} xs={6} align="right" style={{}}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.AddNewVeh}
                  onClick={() => this.handleClickOpen("openAddVehicle")}
                >
                  {LanguageManager.Strings.ADD_NEWCAR_LABEL}
                </Button>

                <DialogFormVehicle
                  style={{}}
                  visible={this.state.openAddVehicle}
                  onOutsideTouch={() => this.handleClickOpen("openAddVehicle")}
                >
                  <AddDriverCar
                    areaCode={this.state.AreaData}
                    driverID={this.state.driverID}
                    handleClickOpen={this.handleClickOpen}
                  />
                </DialogFormVehicle>
              </Grid>
            )}
          </Grid>
          <hr style={{ color: "#eaeaea" }} />
          <Grid>
            {this.state.ACTIVE_VEHICLE != "" ? (
              <Paper>
                <Grid
                  container
                  xs={12}
                  style={{
                    // border: "1px solid grey",
                    padding: 10,
                    backgroundImage:
                      "linear-gradient(to right bottom,#dbe9ff, #e7eeff, #ffffff, #f9f9ff, #ffffff)"
                  }}
                >
                  <Grid
                    item
                    md={1}
                    xs={2}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                    className={classes.carImg}
                  >
                    <img
                      src={
                        this.state.ACTIVE_VEHICLE
                          ? TAXI_IMAGE_URL +
                            this.state.ACTIVE_VEHICLE.vehicleTypeImage
                          : ""
                      }
                      height="50"
                      width="50"
                    />
                  </Grid>
                  <Grid
                    item
                    align="left"
                    md={7}
                    xs={4}
                    style={{ paddingLeft: 10 }}
                  >
                    <Typography
                      className={classes.ActiveVehicleName}
                      style={{}}
                    >
                      {this.state.ACTIVE_VEHICLE
                        ? this.state.ACTIVE_VEHICLE.vehicle_type
                        : ""}
                    </Typography>
                    <Typography
                      variant="body2"
                      className={classes.ActiveVehicleNumber}
                      style={{ color: "#4A4A4A" }}
                    >
                      {this.state.ACTIVE_VEHICLE
                        ? this.state.ACTIVE_VEHICLE.vehicle_number
                        : ""}
                    </Typography>
                  </Grid>
                  {/* <Grid item md={2} xs={3} classeName={classes.PaddingViewBtton}>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.viewDocButton}
                        // className={classNames(classes.viewDocButton, classes.Button)}
                        style={{
                          backgroundColor: "#F5A82A",
                          textTransform: "capitalize"
                        }}
                      >
                        View Documents
                     </Button>
                    </Grid> */}

                  <Grid item md={4} xs={3} style={{}}>
                    <Paper
                      variant="contained"
                      className={classes.LiveStatusButton}
                      style={{}}
                    >
                      <Typography
                        variant="caption"
                        gutterBottom
                        className={classes.LiveMessage}
                        style={{
                          color: "#666",
                          textTransform: "uppercase",
                          fontWeight: "600"
                        }}
                      >
                        {this.state.ACTIVE_VEHICLE
                          ? this.state.ACTIVE_VEHICLE.show_msg
                          : ""}
                      </Typography>

                      <Typography
                        variant="caption"
                        gutterBottom
                        className={classes.LiveText}
                        style={{ color: "#76abe9" }}
                      >
                        {LanguageManager.Strings.UPDATED_AT_LABEL}{" "}
                        {this.state.ACTIVE_VEHICLE
                          ? this.state.ACTIVE_VEHICLE.updated_at
                          : ""}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>

                <Grid container md={8} xs={12} align="left" style={{}}>
                  <Grid item xs={6} style={{ marginTop: "-2%", padding: 10 }}>
                    <Grid style={{ padding: 5 }}>
                      <Typography align="left" className={classes.Headings}>
                        {LanguageManager.Strings.SERVICES_LABEL}
                      </Typography>
                      <Typography
                        className={classes.ServiceTypes}
                        align="left"
                        style={{}}
                      >
                        {this.state.ACTIVE_VEHICLE
                          ? this.state.ACTIVE_VEHICLE.service_types
                          : ""}
                      </Typography>
                    </Grid>

                    {/* <Grid style={{ padding: 5 }}>
                        <Typography
                          // variant="subtitle1"
                          align="left"
                          className={classes.Headings}
                        >
                          Total Online Time
                  </Typography>
                        <Typography
                          // variant="body2"
                          align="left"
                          className={classes.ServiceTypes}
                        >
                          14 Days and 13 Hr 22 Min
                  </Typography>
                      </Grid> */}
                  </Grid>
                  {/* <Grid item xs={6}>
                      <Grid container xs={12}>
                        <Grid
                          item
                          xs={8}
                          style={{
                            borderLeft: "1px solid #4A4A4A",
                            padding: "10px"
                          }}
                        >
                          <Typography
                            align="left"
                            className={classes.ActiveVehicleDetails}
                            style={{ color: "#6EDB9C" }}
                          >
                            Completed Rides : 543
                    </Typography>
                          <Typography
                            align="left"
                            style={{ color: "#EB6254" }}
                            className={classes.ActiveVehicleDetails}

                          >
                            Cancelled Rides : 132
                    </Typography>
                          <Typography
                            align="left"
                            style={{ color: "#F6B344" }}
                            className={classes.ActiveVehicleDetails}

                          >
                            Total Rides : 1121
                    </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "center" }} >
                          <img src={VEHICLES_GRAPH} height="100" width="100" className={classes.vehicleGraph} />
                        </Grid>
                      </Grid>
                    </Grid> */}
                </Grid>
              </Paper>
            ) : (
              <Paper>{LanguageManager.Strings.NO_ACTIVE_VEHICLE_MSG}</Paper>
            )}
            {this.state.VEHICLE_ROWS.length == 0 && (
              <Grid container xs={12} style={{ paddingTop: 10 }}>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "600", color: "#4A4A4A" }}
                >
                  {LanguageManager.Strings.NO_OTHER_VEHICLES_MSG}
                </Typography>
              </Grid>
            )}
            {this.state.VEHICLE_ROWS.length > 0 && (
              <Grid container xs={12} style={{ paddingTop: 10 }}>
                <Typography variant="h6" className={classes.OtherVehicle}>
                  {LanguageManager.Strings.OTHER_VEHICLES_LABEL}
                </Typography>
              </Grid>
            )}

            <Grid
              md={12}
              xs={12}
              style={{
                backgroundImage:
                  "linear-gradient(to bottom, #efefef, #ffffff, #f1f4ff, #f9f9ff, #ffffff)"
              }}
            >
              <Tabs
                value={this.state.value}
                indicatorColor="primary"
                textColor="default"
                onChange={this.handleChange}
              >
                <Tab
                  className={classes.Statustab}
                  label={LanguageManager.Strings.VERIFIED_LABEL}
                />
                <Tab
                  className={classes.Statustab}
                  label={LanguageManager.Strings.APPROVAL_PENDING_LABEL}
                />
                {/* <Tab label="" /> */}
              </Tabs>

              <div style={{ position: "relative" }}>
                {this.state.loading && <XLoader />}
                {value === 0 && (
                  <TabContainer>
                    <VehicleListing
                      openSnack={this.openSnack}
                      finalSnack={this.finalSnack}
                      // executeManageVehicleListApi={this.executeManageVehicleListApi}
                      VEHICLE_ROWS={this.state.VERIFIED_VEHICLE}
                    />
                  </TabContainer>
                )}
                {value === 1 && (
                  <TabContainer>
                    <VehicleListing
                      VEHICLE_ROWS={this.state.PENDING_FOR_APPROVAL}
                    />
                  </TabContainer>
                )}
                {/* {value === 2 && (
                  <TabContainer>
                    <PaginationTable columns={columns}
                        tableRowColor={"#efefef"}
                        tableHeadBackground={"#2ECC71"}
                        tableHeadFontColor={"#ffffff"}
                      >
                        {this.prepareTableData(this.state.DRIVER_WALLET_TABLE)}
                      </PaginationTable>
                  </TabContainer>
                )} */}
              </div>
            </Grid>

            {/* {this.state.VEHICLE_ROWS.map(row => {
              return (
                <Paper style={{ marginBottom: 10 }}>
                  <Grid
                    container
                    xs={12}
                    style={{ border: "1px solid lightgrey", }}
                  >
                    <Grid item xs={2} style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}>
                      <img src={"http://app.apporiotaxi.com/v11/public/" + row.vehicleTypeImage}
                        className={classes.vehicleTypeImage}
                      />
                    </Grid>
                    <Grid item md={2} xs={3}>
                      <Typography
                        className={classes.vehicle_type}
                        variant="subtitle1"
                        style={{}}
                      >
                        {row.vehicle_type}
                      </Typography>
                      <Paper className={classes.vehicle_number}
                        style={{}}>
                        {row.vehicle_number}
                      </Paper>
                    </Grid>
                    <Grid item md={6} xs={4} className={classes.ListPadService}
                    >
                      <Typography
                        variant="body2"
                        align="left"
                        className={classes.ServiceHead}

                      >
                        Services
              </Typography>
                      <Typography
                        variant="body2"
                        align="left"
                        className={classes.service_types_list}
                      >
                        {row.service_types}
                      </Typography>
                    </Grid>
                    <Grid item md={2} xs={2}>
                      <Button
                        variant="contained"
                        className={classes.manage_vehicle_stat}
                        style={{}}
                      >
                        {row.show_msg}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              )
            }
            )
            } */}
            {/*FOR Mapping*/}
          </Grid>
        </DrawerLayout>
      );
    }
  }
}

export default withStyles(ManageVehicleStyles)(ManageVehicles);

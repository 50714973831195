import React from "react";
import PropTypes from "prop-types";
import { withStyles, Grid, TextField, Button, Icon } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { DriverProfileStyles } from "../../../../styles";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "../../../../custom/Autocomplete";
import { APPORIO_TAXI_APIS } from "../../../../../managers/api/ApiEndPoints";
import * as ApiManager from "../../../../../managers/api/ApiManager";
import CustomLoader from "../../../../custom/CustomLoader";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
        textAlign: "left"
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class NewAddress extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    loading: false,
    location: {
      lat: "",
      lng: "",
      address: "",
      location: ""
    },

    value: 0,
    checked: false,
    open: false,
    name: "",
    bankname: "",
    addressname: ""
  };
  handleSwitch = () => {
    this.setState(state => ({ checked: !state.checked }));
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };
  handleClickShowConfirmPassword = () => {
    this.setState(state => ({
      showConfirmPassword: !state.showConfirmPassword
    }));
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClickOpen = () => {
    this.setState({
      openList: true
    });
  };

  showPlaceDetails = (place, textAddress, cityString) => {
    console.log("qghqsgyhgqhgwyxghgsw", place);
    if (place.hasOwnProperty("geometry")) {
      this.setState({
        location: {
          ...this.state.location,
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          address: textAddress,
          selectedCity: cityString
        }
      });
    } else {
      alert("Does not match");
    }
  };

  //add address

  addAddress = () => {
    console.log(this.state.location);

    if (
      this.state.location.address === "" ||
      this.state.addressname === "" ||
      this.state.location.lat === "" ||
      this.state.location.long === ""
    ) {
      alert("error field");
      return;
    }

    // start laoding
    this.setState({
      loading: true
    });

    // add form
    ApiManager.callPostApi(
      "ADD_ADDRESS",
      "",
      APPORIO_TAXI_APIS.ADD_ADDRESS,

      {
        address_name: this.state.addressname,
        location: this.state.location.address,
        latitude: String(this.state.location.lat),
        longitude: String(this.state.location.long)
      }
    )

      .then(response => {
        console.log("******add address******", response);
        this.setState(
          {
            loading: false
          },
          () => this.props.onBackPress()
        );
      })

      .catch(error => {});
  };
  render() {
    console.log("driver home render", this.props);
    const { classes } = this.props;
    // const { value } = this.state;
    // const { checked } = this.state;
    if (this.state.loading) {
      return <CustomLoader showLoader={this.state.loading} />;
    } else {
      return (
        <Grid item md={12} style={{ marginTop: 10 }}>
          <Grid
            container
            md={12}
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: -10
            }}
          >
            <IconButton onClick={() => this.props.onBackPress()}>
              <Icon style={{ color: "#666" }}>arrow_back</Icon>
            </IconButton>
            <Typography
              variant="subtitle1"
              style={{ fontWeight: "600", color: "#26699d", paddingTop: 10 }}
            >
              Add New Address
            </Typography>
          </Grid>
          <hr style={{ color: "#eaeaea" }} />
          <Grid container md={12} style={{}}>
            <Grid item md={5} style={{ marginTop: 20 }}>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                Enter Title and Location
              </Typography>
              <Typography variant="display4" style={{ fontSize: "15px" }}>
                Add New Address
              </Typography>
            </Grid>
            <Grid item md={5} style={{}}>
              <Grid md={12} style={{ padding: "5px" }}>
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-locality"
                  label="Address Title"
                  className={classes.textField}
                  value={this.state.addressname}
                  onChange={this.handleInputChange("addressname")}
                  // onChange={(event)=> this.handleInputChange(event,"addressname")}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid md={12}>
                <Autocomplete
                  placeHolder="Address Location"
                  onPlaceChanged={(address, textAddress, cityString) =>
                    this.showPlaceDetails(address, textAddress, cityString)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid md={12} align="right" style={{}}>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className={classes.margin}
              style={{ margin: "0 5px" }}
              onClick={() => this.addAddress()}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      );
    }
  }
}

export default withStyles(DriverProfileStyles)(NewAddress);

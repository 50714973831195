import React from "react";
import DialogForm from "../../driver/signUp/DialogForm";
import { withStyles, Paper, InputBase } from "@material-ui/core";
import {
  Button,
  Grid,
  Typography,
  TextField,
  Checkbox
} from "@material-ui/core";
import { PADDING, MARGIN, CUSTOM_FONT } from "../../../../values/Typography";

import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import CustomLoader from "../../../custom/CustomLoader";
const selecteditems = [];

const today = new Date();

class ServiceSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();
    this.state = {
      image: props.imageUrl ? props.imageUrl : null,
      date: props.date ? props.date : "",
      dateRequired:
        props.dateRequired == undefined || props.dateRequired == true
          ? true
          : false,
      disabled: Boolean(props.disabled) ? true : false,
      snack: {
        open: false,
        variant: "error",
        message: "Please select required fields"
      },
      active: 1,
      expiry: 2,
      isLoading: false,
      newArray: []
    };
  }

  openExplorer = () => {
    this.imageRef.current.click();
  };

  // --- handle upload ----
  handleUpload = event => {
    let reader = new FileReader();
    reader.onload = e => {
      this.setState({
        image: e.target.result
      });
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  // ---- handle date ----
  handleDate = event => {
    this.setState({
      date: event.target.value
    });
  };

  handleChange(event) {
    this.setState({ expiryDate: event.target.value });
  }

  submitDoc() {
    this.setState({ isLoading: true });

    ApiManager.callPostApi(
      "DocumentUpload",
      "driver document add api",
      ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_DOCUPLOAD,
      {
        api_type: 1,
        driver: 2,
        document: 1,
        expire_date: this.state.expiryDate,
        document_image: "data:image/png;base64," + this.state.driverImage
      }
    ).then(res => {
      console.log("response..." + JSON.stringify(res));
      this.setState({ isLoading: false });

      let resp = {
        result: res.result,
        id: this.props.keyValue
      };
      this.props.callback(resp);
      // this.props.callBack(idVal+1)
    });
  }

  // ---- upload doc ----
  uploadDoc = () => {
    if (
      this.state.image == null ||
      (Boolean(this.state.dateRequired) && this.state.date == "")
    ) {
      this.setState({
        snack: {
          ...this.state.snack,
          open: true
        }
      });
      return;
    }
    this.props.handleDocs(this.state.image, this.state.date);
  };

  getObject(index, inputArray) {
    // console.log("@@@@"+index+" ^^^^ "+JSON.stringify(inputArray))
    if (index == 4) {
      let arrObj = {};
      arrObj["id"] = 3;
      arrObj["name"] = inputArray[2].serviceName;
      return arrObj;
    } else {
      let arrObj = {};
      arrObj["id"] = index;
      arrObj["name"] = inputArray[index - 1].serviceName;
      return arrObj;
    }
  }

  getSelectedOptions(evt) {
    console.log("hohoho" + evt.target.value);
    let sourceArray = this.props.inputList.vehicle_type[0].services;

    let checkItem; // for_simple_array
    if (evt.target.value === "4") {
      console.log(
        "hanji::" +
          this.state.newArray
            .map(function(e) {
              return e.id;
            })
            .indexOf("3")
      );
      checkItem = this.state.newArray
        .map(function(e) {
          return e.id;
        })
        .indexOf(3);
    } else {
      checkItem = this.state.newArray
        .map(function(e) {
          return e.id;
        })
        .indexOf(evt.target.value);
    }

    if (evt.target.checked) {
      if (checkItem > -1) {
        console.log("qq--" + checkItem);
        let voice;
        if (evt.target.value === "4") {
          voice = this.state.newArray.filter(item => item.id !== "3");
        } else {
          voice = this.state.newArray.filter(
            item => item.id !== evt.target.value
          );
        }

        this.setState({ newArray: voice });
      } else {
        this.state.newArray.push(this.getObject(evt.target.value, sourceArray));
      }
    } else {
      if (checkItem > -1) {
        console.log("xx--" + checkItem);
        let voice = 0;

        console.log(
          "!C!C!" +
            evt.target.value +
            "*^*^" +
            JSON.stringify(this.state.newArray)
        );
        if (evt.target.value === "4") {
          voice = this.state.newArray.filter(item => item.id !== 3);
        } else {
          voice = this.state.newArray.filter(
            item => item.id !== evt.target.value
          );
        }

        this.setState({ newArray: voice });
      }
    }
    setTimeout(
      () => console.log("rrr" + JSON.stringify(this.state.newArray)),
      1000
    );
  }

  async doneSelection() {
    //console.log("vvv"+this.state.newArray)
    await this.props.callback(this.state.newArray);
    this.setState({ newArray: [] });
    this.props.onClose();
  }

  async closeTask(propClose) {
    await this.setState({ newArray: [] });
    propClose();
  }

  GetService = Vehicleid => {
    let vehicleTypes = this.props.inputList.vehicle_type;
    let vehicle_type = vehicleTypes
      ? vehicleTypes.find(obj => obj.id == Vehicleid)
      : "";
    return vehicle_type;
  };

  render() {
    console.log("document upload props" + JSON.stringify(this.props.inputList));
    const { classes, keyValue, inputList, onClose } = this.props;
    return (
      <div>
        <CustomLoader showLoader={this.state.isLoading} />

        <DialogForm
          centerColor={this.props.color ? this.props.color : "#666666"}
          visible={this.props.visible}
          onOutsideTouch={() => {
            this.closeTask(onClose);
          }}
        >
          <div className={classes.uploader}>
            <Typography className={classes.heading} align="center">
              Select Service Type
            </Typography>

            <Grid container xl className={classes.containerStyle}>
              {this.GetService(this.props.carTypeValue)
                ? this.GetService(this.props.carTypeValue).services.map(row => (
                    <Grid
                      xl
                      container
                      justify="flex-start"
                      alignItems="center"
                      className={classes.genderContainer}
                    >
                      <Checkbox
                        checked={this.state.serviceSelected}
                        value={row.id}
                        onChange={evt => this.getSelectedOptions(evt)}
                        // onChange={()=>this.setState({userSmoker:!this.state.userSmoker})}
                        color="primary"
                      />

                      <Typography
                        variant="body1"
                        className={classes.genderTitle}
                      >
                        {row ? row.serviceName : ""}
                      </Typography>
                    </Grid>
                  ))
                : null}
            </Grid>

            <Button
              variant="contained"
              fullWidth
              disableTouchRipple={true}
              disableFocusRipple={true}
              className={classes.SignupButton}
              onClick={() => this.doneSelection()}
            >
              Done
            </Button>
          </div>
        </DialogForm>
      </div>
    );
  }
}

const styles = theme => ({
  uploader: {
    width: 400,
    [theme.breakpoints.down("sm")]: {
      width: 200
    }
    // [theme.breakpoints.down('md')]: {
    //     width: 'calc(100vw - 140px)'
    // }
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  expiryContainer: {
    marginTop: theme.spacing.unit * 10
  },
  defaultImage: {
    width: 100,
    height: 100
  },
  validLabel: {
    fontWeight: "bold",
    fontFamily: "Montserrat"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  heading: {
    marginTop: theme.spacing.unit * 5,
    fontFamily: "Montserrat", //FONT_FAMILY,
    fontWeight: "bold",
    fontSize: 22,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16
    }
  },
  detail: {
    fontFamily: "Montserrat",
    paddingRight: "20%",
    paddingLeft: "20%", //FONT_FAMILY,
    fontWeight: "bold"
  },
  footer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  addButton: {
    textTransform: "capitalize",
    backgroundColor: "green", //colorMutedGreen,
    color: "white", // pureWhite,
    padding: "3px 10px",
    fontWeight: 600
  },
  box: {
    // padding: '10px',
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  imageBox: {
    backgroundSize: "contain",
    display: "flex",
    backgroundColor: "#EBEBEB",
    justifyContent: "center",
    alignItems: "center",
    width: "200px",
    height: "250px",
    marginBottom: "15px",
    overflow: "hidden",
    "& button": {
      width: "100%",
      height: "100%",
      boxShadow: "none",
      border: "none"
    }
  },
  inputBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: "400px",
    boxShadow: "none",
    marginBottom: "15px"
  },
  label: {
    color: "white", // pureWhite,
    alignSelf: "start",
    fontWeight: 600,
    fontFamily: "Montserrat" //FONT_FAMILY
  },
  SignupButton: {
    backgroundColor: "#23669B",
    fontFamily: "Montserrat", //CUSTOM_FONT,
    fontSize: 18,
    textTransform: "none",
    width: "100%",
    marginTop: 20,
    color: "white",
    fontWeight: "bold"
  },
  containerStyle: {
    marginTop: theme.spacing.unit * 10,
    marginBottom: theme.spacing.unit * 10
  },
  genderTitle: {
    fontFamily: CUSTOM_FONT,
    fontWeight: "bold"
  },
  genderContainer: {
    marginTop: 10
  }
});

export default withStyles(styles)(ServiceSelector);

import React from "react";
import { withStyles } from "@material-ui/core";
import { DriverLoginStyles } from "../../../styles";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import LogoCard from "./LogoCard";
import LoginForm from "./LoginForm";
import { withRouter } from "react-router-dom";
import EventBus from "eventing-bus";

class DriverLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentData: {}
    };
  }

  navigateFunction = () => {
    console.log("**************************************************");
    // EventBus.publish("GOOO", "1");
    return this.props.history.push("/driver/manage_vehicles");
  };

  render() {
    console.log("driver home render", this.props);
    const { classes } = this.props;

    return (
      <CardMedia
        container
        className={classes.backgroundImage}
        justify="flex-end"
        alignItems="center"
        // image={DRIVER_HERO_IMAGE_MAYA}
        image={JSON.parse(SessionManager.getSessionData(SessionKeys.DRIVER_BG))}
        style={{ overflow: "hidden" }}
      >
        <Grid container className={classes.parentView}>
          <LogoCard />
          <LoginForm navigateToHome={this.navigateFunction} />
        </Grid>
        <Grid
          item
          className={classes.bottomLineElement}
          style={{ borderRight: "2px solid white" }}
        />
      </CardMedia>
    );
  }
}

export default withStyles(DriverLoginStyles)(withRouter(DriverLogin));

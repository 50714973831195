import React from 'react'
import { withStyles, Button } from "@material-ui/core";
import { ButtonStyles } from "../../styles";

const PrimaryVariantButton = (props) => {
    const { classes, className, style } = props

    return (
        <Button className={classes.primaryVariantButton + " " + className}
            style={style}
        >
            {props.children}
        </Button>
    )
}

export default withStyles(ButtonStyles)(PrimaryVariantButton)
import React from "react";
import { withStyles, Grid, TextField } from "@material-ui/core";
import { DriverEarningStyles } from "../../../../styles";

import Typography from "@material-ui/core/Typography";
import { APPORIO_TAXI_APIS } from "../../../../../managers/api/ApiEndPoints";
import * as ApiManager from "../../../../../managers/api/ApiManager";
import "react-datepicker/dist/react-datepicker.css";
import PresentationTable from "../../../../custom/PresentationTable";
import CsId from "./tableCols/CsId";
import CsText from "./tableCols/CsText";
import CustomLoader from "../../../../custom/CustomLoader";
import DrawerLayout from "../../common/DrawerLayout";
import XLoader from "../../../../custom/XLoader";
import * as LanguageManager from "../../../../../managers/language/LanguageManager";
import * as SessionManager from "../../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../../managers/session/SessionKeys";

const columns = [
  LanguageManager.Strings.EARNING_COL_TITLE1,
  LanguageManager.Strings.EARNING_COL_TITLE2,
  LanguageManager.Strings.EARNING_COL_TITLE3,
  LanguageManager.Strings.EARNING_COL_TITLE4
];

// const Trips = [
//   {
//     strip_color: "#2dcc70",
//     strip_max_height: "10%",
//     text: "Mon"
//   },
//   {
//     strip_color: "#2dcc70",
//     strip_max_height: "20%",
//     text: "Tue"
//   },
//   {
//     strip_color: "#2dcc70",
//     strip_max_height: "30%",
//     text: "Wed"
//   },
//   {
//     strip_color: "#2dcc70",
//     strip_max_height: "50%",
//     text: "Thu"
//   },
//   {
//     strip_color: "#2dcc70",
//     strip_max_height: "70%",
//     text: "Fri"
//   },
//   {
//     strip_color: "#2dcc70",
//     strip_max_height: "40%",
//     text: "Sat"
//   },
//   {
//     strip_color: "#2dcc70",
//     strip_max_height: "50%",
//     text: "Sun"
//   }
// ];

class DriverEarning extends React.Component {
  constructor(props) {
    super(props);
    this.LaterDate = React.createRef();
  }
  state = {
    selectedDrop: "",
    EARNINGS_ROWS: [],
    date: new Date().toISOString().substring(0, 10),
    laterDate: "",
    wait: true
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleDropChange = value => {
    this.setState({
      selectedDrop: value
    });
  };

  handleDateChange = event => {
    this.setState({ date: event.target.value }, () => this.callApiEarning());
  };

  callApiEarning = () => {
    this.setState({
      loading: true
    });

    ApiManager.callPostApi("Earnings", "", APPORIO_TAXI_APIS.DRIVER_EARNINGS, {
      date: this.state.date
    })

      .then(response => {
        // console.log('*******', 'success')
        // alert(JSON.stringify(response))

        this.setState({
          EARNINGS_ROWS: response.data,
          loading: false,
          wait: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
  componentDidMount() {
    console.log(APPORIO_TAXI_APIS.DRIVER_EARNINGS);
    this.callApiEarning();
    console.log(
      "session1234" +
        JSON.stringify(
          JSON.parse(SessionManager.getSessionData(SessionKeys.DRIVER_CONFIG))
        )
    );
    let data = JSON.parse(
      SessionManager.getSessionData(SessionKeys.DRIVER_CONFIG)
    );
    this.setState({
      DRIVER_CONFIG_ROWS: data,
      currency: data.countries[0].isoCode
    });
  }

  prepareTableData = EARNINGS_ROWS => {
    // console.log('prepare table data', );
    let allTableRows = [];
    {
      EARNINGS_ROWS.detail.length > 0 &&
        EARNINGS_ROWS.detail.map((row, index) => {
          let singleRow = [];
          singleRow.push(<CsId data={row.day} />);
          singleRow.push(<CsText data={row.details.rides} />);
          singleRow.push(<CsText data={row.details.driver_amount} />);
          singleRow.push(<CsText data={row.details.date} />);

          // singleRow.push(<ActionBtn data={row.id} deleteCustomerSupportRow={this.deleteCustomerSupportRow} />);
          allTableRows.push(singleRow);
        });
    }

    return allTableRows;
  };

  render() {
    // console.log("driver home render", this.state.EARNINGS_ROWS);
    const { classes } = this.props;
    // const { value } = this.state;

    if (this.state.wait) {
      return <CustomLoader showLoader={this.state.wait} />;
    } else {
      return (
        <DrawerLayout>
          <Grid container md={12}>
            <Typography variant="subtitle1" className={classes.YourEarn}>
              {LanguageManager.Strings.YOUR_EARNINGS_LABEL}
            </Typography>
          </Grid>
          <hr className={classes.hrOpacity} />
          <Grid container md={12} className={classes.SelectAnyDate}>
            <Grid item align="center" xs={7} md={5}>
              {/* <TripChart data={Trips} /> */}
              <Typography
                variant="subtitle1"
                className={classes.SelectAnyDateText}
              >
                {LanguageManager.Strings.SELECT_DOW_LABEL}
              </Typography>
              <TextField
                id="date"
                // label="Select Week"
                // variant="outlined"
                type="date"
                margin="normal"
                defaultValue={new Date().toISOString().substring(0, 10)}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={event => this.handleDateChange(event)}
              />
            </Grid>

            <Grid item xs={5} md={6}>
              <Grid item md={12} className={classes.FirstClass}>
                {/* <DatePicker
                dateFormat="yyyy/MM/dd1"
                ref={this.LaterDate}
                minDate={new Date()}
                showDisabledMonthNavigation
                selected={this.state.laterDate}
                onChange={this.handleChangeDate.bind(this)}
              /> */}
                {/* <Paper>
                  <TextField
                    id="date"
                    label="Select Week"
                    variant="outlined"
                    type="date"
                    defaultValue="2019-03-18"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => this.handleDateChange(event)}
                  />
                </Paper> */}
                {/* <EarnDropdown
                  items={[
                    "10 JAN 2018 - 15 JAN 2018",
                    "16 JAN 2018 - 31 JAN 2018",
                    "1 FEB 2018 - 15 FEB 2018"
                  ]}
                  selectedFilter={this.state.selectedDrop}
                  onFilterChange={this.handleDropChange}
                /> */}
              </Grid>
              <Grid item md={12}>
                <Typography
                  variant="subtitle1"
                  className={classes.outstandingAmt}
                >
                  {LanguageManager.Strings.TOTAL_OUTSTANDING_LABEL} {}
                  <span className={classes.colorEarn}>
                    {this.state.EARNINGS_ROWS.outstand_amount}
                  </span>
                </Typography>
              </Grid>
              <hr className={classes.hrUP} />
              <Grid item xs={12} md={12}>
                <Grid container xs={12} md={12} className={classes.earnPad}>
                  <Grid item xs={6} md={6} className={classes.borderRightEarn}>
                    <Typography
                      variant="subtitle2"
                      className={classes.TotalTrip}
                    >
                      {LanguageManager.Strings.TOTAL_TRIPS_LABEL}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.TotalWeeklyRide}
                    >
                      {this.state.EARNINGS_ROWS.total_weekly_rides}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography
                      variant="subtitle2"
                      className={classes.DrawerOption}
                    >
                      {LanguageManager.Strings.DRIVER_DRAWER_OPTION4}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.TotalWeeklyAmt}
                    >
                      {this.state.EARNINGS_ROWS.total_weekly_amount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container md={12}>
            <Typography variant="subtitle1" className={classes.StatementLabel}>
              {LanguageManager.Strings.STATEMT_LABEL}
            </Typography>
          </Grid>
          <hr className={classes.horizontalColor} />
          <Grid md={12}>
            <div style={{ position: "relative" }}>
              {this.state.loading && <XLoader />}
              <PresentationTable
                columns={columns}
                tableRowColor={"#efefef"}
                tableHeadBackground={"#2ECC71"}
                tableHeadFontColor={"#ffffff"}
              >
                {this.prepareTableData(this.state.EARNINGS_ROWS)}
              </PresentationTable>
            </div>
          </Grid>
        </DrawerLayout>
      );
    }
  }
}

export default withStyles(DriverEarningStyles)(DriverEarning);

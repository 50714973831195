import React, { Component } from "react";
import { Typography, withStyles } from "@material-ui/core";
import BottomContainerItem from "./BottomContainerItems";
import { HomeStyles } from "../../../styles";
import { CUSTOM_FONT } from "../../../../values/Typography";

class HomeBottomContainer extends Component {
  render() {
    const { classes, data } = this.props;
    console.log("props", data);
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "10%",
          marginRight: "10%",
          justifyContent: "center"
        }}
      >
        <Typography
          style={{
            fontFamily: CUSTOM_FONT,
            fontSize: "1.5rem",
            fontWeight: "bolder",
            padding: "0.5em"
          }}
        >
          How it Works
        </Typography>

        <div className={classes.featuresView}>
          <div
            style={{
              flex: 1,
              display: "flex",
              marginTop: "10%",
              height: "100%",
              flexDirection: "column"
            }}
          >
            <BottomContainerItem
              align={data[1].align}
              image={data[1].image}
              title={data[1].title}
              des={data[1].description}
            />

            <BottomContainerItem
              align={data[0].align}
              image={data[0].image}
              title={data[0].title}
              des={data[0].description}
            />
          </div>
          <div
            style={{
              height: "100%",

              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <BottomContainerItem
              align={data[2].align}
              image={data[2].image}
              title={data[2].title}
              des={data[2].description}
            />

            <img
              src={this.props.featured_component_main_image}
              className={classes.phoneImage}
            />
          </div>
          <div
            style={{
              flex: 1,
              height: "100%",
              display: "flex",
              marginTop: "10%",
              flexDirection: "column"
            }}
          >
            <BottomContainerItem
              align={data[3].align}
              image={data[3].image}
              title={data[3].title}
              des={data[3].description}
            />

            <BottomContainerItem
              align={data[4].align}
              image={data[4].image}
              title={data[4].title}
              des={data[4].description}
            />
          </div>
        </div>

        <div className={classes.phoneCompatibleFeatureView}>
          <BottomContainerItem
            align={2}
            image={data[0].image}
            title={data[0].title}
            des={data[0].description}
          />

          <BottomContainerItem
            align={2}
            image={data[1].image}
            title={data[1].title}
            des={data[1].description}
          />

          <BottomContainerItem
            align={data[2].align}
            image={data[2].image}
            title={data[2].title}
            des={data[2].description}
          />

          <img
            src={this.props.featured_component_main_image}
            className={classes.phoneImage}
          />
          <BottomContainerItem
            align={2}
            image={data[3].image}
            title={data[3].title}
            des={data[3].description}
          />

          <BottomContainerItem
            align={2}
            image={data[4].image}
            title={data[4].title}
            des={data[4].description}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(HomeStyles)(HomeBottomContainer);

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { TextField, InputAdornment, Typography } from "@material-ui/core";
import { DriverProfileStyles } from "../../../../styles";

const styles = theme => ({
  fab: {
    margin: theme.spacing.unit * 2
  },
  absolute: {
    position: "absolute",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 3
  }
});

function PhoneField(props) {
  console.log("qwertyuioppoiuytrewq", props.DriverNumber);
  const { classes } = props;
  return (
    <div>
      <TextField
        disabled
        style={{ width: "100%" }}
        label="Phone Number"
        value={props.DriverData != "" ? props.DriverNumber : ""}
        InputProps={{
          style: {
            fontSize: 15,
            fontFamily: "Montserrat",
            fontWeight: "bold"
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => props.handleClickOpenModal("EditPhone")}
              >
                <Typography
                  className={classes.EditPhone}
                  style={{ color: "#3F51B5" }}
                >
                  Edit
                </Typography>
              </IconButton>
            </InputAdornment>
          )
        }}
        InputLabelProps={{
          style: {
            fontSize: 15,
            fontFamily: "Montserrat",
            fontWeight: "bold"
          }
        }}
        className={classes.detailFields}
        onChange={event => props.handleDriverDataInput(event, "phoneNumber")}
        margin="normal"
        variant="outlined"
      />
    </div>
  );
}

PhoneField.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(DriverProfileStyles)(PhoneField);

import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import { MuiThemeProvider, createMuiTheme, Grid } from "@material-ui/core";

import * as ApiManager from "./managers/api/ApiManager";
import * as LanguageManager from "./managers/language/LanguageManager";
import * as ApiEndPoints from "./managers/api/ApiEndPoints";
import { saveSessionData } from "./managers/session/SessionManager";

import Home from "./UI/segments/home/Home";
import DriverHome from "./UI/segments/driver/home/DriverHome";
import DriverLayout from "./UI/segments/driver/common/DriverLayout";
import DriverWallet from "./UI/segments/driver/Dashboard/DriverWallet/DriverWallet";
import ManageTrips from "./UI/segments/driver/Dashboard/ManageTrips/ManageTrips";
import ManageVehicles from "./UI/segments/driver/Dashboard/ManageVehicles/ManageVehicles";
import UserBooking from "./UI/segments/user/UserBooking";
import UserLogin from "./UI/segments/user/login/UserLogin";
import DriverLogin from "./UI/segments/driver/login/DriverLogin";
import DriverSignUp from "./UI/segments/driver/signUp/DriverSignUp";
import DriverEarning from "./UI/segments/driver/Dashboard/DriverEarnings/DriverEarning";
import Usertrips from "./UI/segments/user/usertrips/Usertrips";
import FavouriteDriver from "./UI/segments/user/favouritedriver/FavouriteDriver";
import UserWallet from "./UI/segments/user/userwallet/UserWallet";
import { DRIVER_ROUTES } from "./routes/ScreenRoutes";

import DriverTab from "./UI/segments/driver/Dashboard/DriverProfile/DriverTab";
import TrackRide from "./UI/segments/driver/Dashboard/TrackRide/TrackRide";

import UserProfileScreen from "./UI/segments/user/userProfile/UserProfile";

import ChangePassword from "./UI/segments/driver/Dashboard/DriverProfile/ChangePassword";

import EventBus from "eventing-bus";
import CustomLoader from "./UI/custom/CustomLoader";

import AuthenticatedRoute from "./AuthenticatedRoute";
import UnauthenticatedRoute from "./UnauthenticatedRoute";
import * as SessionManager from "./managers/session/SessionManager";
import * as SessionKeys from "./managers/session/SessionKeys";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import CmsUserPage from "./UI/segments/home/common/CmsUserPage";
import { CUSTOM_COLOUR } from "./values/Typography";

library.add(faSpinner);

const theme = createMuiTheme({
  // stepper: {
  //   iconColor: "green" // or logic to change color
  // },
  palette: {
    // type: "dark",
    primary: {
      // light: will be calculated from palette.primary.main,
      main: CUSTOM_COLOUR,

      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      //light: "#ffffff",
      main: CUSTOM_COLOUR,

      // dark: will be calculated from palette.secondary.main,
      // contrastText: "#ffffff"
    },
    // error: will use the default color
  },
  // typography: {
  //   // Use the system font instead of the default Roboto font.
  //   useNextVariants: true,
  //   fontFamily: [FONT_FAMILY].join(",")
  // },
  // stepper: {
  //   iconColor: "green" // or logic to change color
  // }
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wait: true,
      isAuthenticated: false,
      isBookingActive: false,
      isManageCarActive: false,
      isRender: false,
    };
  }

  checkSession = (val) => {
    if (
      SessionManager.getSessionData(SessionKeys.ACCESS_TOKEN) === null ||
      SessionManager.getSessionData(SessionKeys.ACCESS_TOKEN) === undefined ||
      SessionManager.getSessionData(SessionKeys.ACCESS_TOKEN) === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  componentDidMount() {
    window.console.log = () => null;
    if (SessionManager.getSessionData(SessionKeys.LANGUAGE_LOCALE)) {
      LanguageManager.saveLanguage(
        SessionManager.getSessionData(SessionKeys.LANGUAGE_LOCALE)
      );
    }
    this.fetchUserConfigs();
  }

  fetchUserConfigs() {
    this.setState({ isLoading: true });

    ApiManager.callPostApi(
      "Home.js",
      "UserConfig API",
      ApiEndPoints.APPORIO_TAXI_APIS.USER_CONFIG,
      {
        unique_no: Math.random(),
        c_lat: "72.342323",
        c_long: "28.090293",
        apk_version: "122.1121",
        language_code: "en",
        manufacture: "Apple",
        model: "WE1211",
        device: 1,
        operating_system: "MAC",
        package_name: "com.apporio.website",
        player_id: "c372814e-c427-45ee-8c73-e8254c9a4a8a",
      },
      true
    )

      .then(async (response) => {
        console.log("GQGQGQGQ" + JSON.stringify(response));

        if (response.result == "1") {
          await saveSessionData(
            SessionKeys.USER_CONFIG,
            JSON.stringify(response.data)
          );
          this.setState({
            userConfig: response.data,
            isLoading: false,
            isRender: true,
          });

          // setTimeout(()=>{ console.log("fififi"+JSON.stringify(JSON.parse(
          //   SessionManager.getSessionData(SessionKeys.USER_CONFIG)
          // )));},1000)
        } else {
          this.setState({ isLoading: false });
          console.log("HOME CONFIG ERR:" + response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <CustomLoader showLoader={this.state.isLoading} userHome={true} />
          {this.state.isRender && (
            <BrowserRouter basename="">
              <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/user_login" exact render={() => <UserLogin />} />
                <Route
                  path="/user_wallet"
                  exact
                  render={() =>
                    this.checkSession() ? <UserWallet /> : <Redirect to="/" />
                  }
                />
                <Route
                  path="/fav_driver"
                  exact
                  render={() =>
                    this.checkSession() ? (
                      <FavouriteDriver />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/user_trips"
                  exact
                  render={() =>
                    this.checkSession() ? <Usertrips /> : <Redirect to="/" />
                  }
                />
                <Route
                  path="/user_profile"
                  exact
                  render={() =>
                    this.checkSession() ? (
                      <UserProfileScreen />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/user_booking"
                  exact
                  render={() =>
                    this.checkSession() ? <UserBooking /> : <Redirect to="/" />
                  }
                />
                <Route
                  path={DRIVER_ROUTES.LOGIN}
                  exact
                  render={() => <DriverLogin />}
                />
                <Route
                  path="/driver/layout"
                  exact
                  render={() =>
                    this.checkSession() ? (
                      <DriverLayout />
                    ) : (
                      <Redirect to={DRIVER_ROUTES.HOME} />
                    )
                  }
                />
                <Route
                  path={DRIVER_ROUTES.EARNING}
                  exact
                  render={() =>
                    this.checkSession() ? (
                      <DriverEarning />
                    ) : (
                      <Redirect to={DRIVER_ROUTES.HOME} />
                    )
                  }
                />
                <Route
                  path={DRIVER_ROUTES.WALLET}
                  exact
                  render={() =>
                    this.checkSession() ? (
                      <DriverWallet />
                    ) : (
                      <Redirect to={DRIVER_ROUTES.HOME} />
                    )
                  }
                />
                <Route
                  path={DRIVER_ROUTES.SIGNUP}
                  component={DriverSignUp}
                  exact
                />
                <Route
                  path={DRIVER_ROUTES.TRIPS}
                  exact
                  render={() =>
                    this.checkSession() ? (
                      <ManageTrips />
                    ) : (
                      <Redirect to={DRIVER_ROUTES.HOME} />
                    )
                  }
                />
                <Route
                  path={DRIVER_ROUTES.VEHICLES}
                  exact
                  render={() =>
                    this.checkSession() ? (
                      <ManageVehicles />
                    ) : (
                      <Redirect to={DRIVER_ROUTES.HOME} />
                    )
                  }
                />
                <Route
                  path={DRIVER_ROUTES.PROFILE}
                  exact
                  render={() =>
                    this.checkSession() ? (
                      <DriverTab />
                    ) : (
                      <Redirect to={DRIVER_ROUTES.HOME} />
                    )
                  }
                />
                <Route
                  path={DRIVER_ROUTES.TRACKRIDE}
                  exact
                  render={() =>
                    this.checkSession() ? (
                      <TrackRide />
                    ) : (
                      <Redirect to={DRIVER_ROUTES.HOME} />
                    )
                  }
                />
                <Route
                  path={DRIVER_ROUTES.LOGIN}
                  exact
                  render={() => <DriverLogin />}
                />
                <Route
                  path={DRIVER_ROUTES.HOME}
                  exact
                  render={() => <DriverHome />}
                />
                <Route
                  path="/terms_and_Conditions"
                  exact
                  render={() => (
                    <CmsUserPage
                      slug="terms_and_Conditions"
                      userConfig={this.state.userConfig.countries[0].id}
                    />
                  )}
                />

                <Route
                  path="/privacy_policy"
                  exact
                  render={() => (
                    <CmsUserPage
                      slug="privacy_policy"
                      userConfig={this.state.userConfig.countries[0].id}
                    />
                  )}
                />

                <Route
                  path="/help_center"
                  exact
                  render={() => (
                    <CmsUserPage
                      slug="help_center"
                      userConfig={this.state.userConfig.countries[0].id}
                    />
                  )}
                />

                <Route
                  path="/about_us"
                  exact
                  render={() => (
                    <CmsUserPage
                      slug="about_us"
                      userConfig={this.state.userConfig.countries[0].id}
                    />
                  )}
                />
              </Switch>
            </BrowserRouter>
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;

// import React, { Component } from "react";
import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import StarRate from '@material-ui/icons/StarRate';
import EventBus from "eventing-bus";


import { Link } from "react-router-dom";
import {
    Avatar,
    Icon,
    IconButton,
    Grid,
    Menu,
    MenuItem
} from "@material-ui/core";
import { PADDING } from "../../../../values/Typography";
import { DRIVER_ROUTES } from "../../../../routes/ScreenRoutes";
import { getSessionData } from "../../../../managers/session/SessionManager";
import { DRIVER_DATA } from "../../../../managers/session/SessionKeys";
import CustomLoader from "../../../custom/CustomLoader";
import { TAXI_IMAGE_URL } from "../../../../managers/api/ApiEndPoints";
import GetApp from "@material-ui/icons/GetApp";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import * as LanguageManager from '../../../../managers/language/LanguageManager'

const drawerWidth = 300;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('md')]:
        {
            // display: "none"
            visibility: "hidden"
        }
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),

    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        marginTop: "49px",
        [theme.breakpoints.down("sm")]: {
            marginTop: "45px",
            // marginLeft: `calc( ${-drawerWidth}px - 10px)`
        },
        [theme.breakpoints.up('md')]:
        {
            margin: "0px",
            padding: 10
        }
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    textWhite: {
        color: "#fff !important"
    },
    leftCloseIcon:
    {
        [theme.breakpoints.up('md')]:
        {
            display: "none"
        }

    },
    DriverAvatar:
    {
        display: "none",
        paddingLeft: 10,
        [theme.breakpoints.up('md')]:
        {
            display: "block"
        }
    },
    BottomDetails:
    {
        marginTop: "30vh",
        [theme.breakpoints.down('sm')]:
        {
            marginTop: "24vh"
        }
    },
    DarkBottomDetails:
    {
        backgroundColor: "#7E7E7E", color: "#fff"
    }
});

class PersistentDrawerLeft extends React.Component {
    state = {
        open: false,
        anchorEl: null,
        wait: true,
        DriverData: null,
        DRIVER_CONFIG_ROWS: {}
    };

    updateSession = () => {
        let data = JSON.parse(getSessionData(DRIVER_DATA));
        this.setState({ DriverData: data });
        this.setState({ wait: false, open: (window.innerWidth >= 960) ? true : false })
        // if (this.state.DriverData  !==  null) {
        //   wait: false
        // }
        console.log(JSON.parse(SessionManager.getSessionData(SessionKeys.DRIVER_CONFIG)))
        let DRIVER_CONFIG = JSON.parse(SessionManager.getSessionData(SessionKeys.DRIVER_CONFIG));
        this.setState({
            DRIVER_CONFIG_ROWS: DRIVER_CONFIG
        })

    }

    componentDidMount() {
        //  console.log("qwertyuiop", JSON.parse(getSessionData(DRIVER_DATA)))
        this.updateSession();

    }


    // reRender = () =>
    // {
    //     this.setState({
    //         anchorEl :null
    //     })
    // }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    handleDriverDataInput = (event, name) => {
        this.setState({
            DriverData: {
                ...this.state.DriverData,
                [name]: event.target.value
            }

        })
    }
    componentWillMount() {
        EventBus.on("REFRESH_SESSION", data => {
            // alert("fsdfsdf")
            this.updateSession();

        });
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    render() {
        const { classes, theme } = this.props;
        const { open } = this.state;
        const { anchorEl } = this.state;

        if (this.state.wait) {
            return <CustomLoader showLoader={this.state.wait} />
        }

        else {
            return (
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        className={classes.appBar}
                    >

                        <Toolbar disableGutters={!open}>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerOpen}
                                className={classNames(classes.menuButton, open && classes.hide)}
                            >
                                <MenuIcon />
                            </IconButton>
                            {/* <Avatar
                                src={TAXI_IMAGE_URL + this.state.DriverData.profile_image}
                                style={{ margin: 5, width: 50, height: 50, objectFit: "cover" }}
                            /> */}
                            <Typography variant="h6" color="inherit" noWrap>
                            {LanguageManager.Strings.WELCOME_LABEL} {this.state.DriverData  !==  "" ? this.state.DriverData.first_name : ""}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={open}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        {/* <div className={classes.drawerHeader}>
                            <IconButton onClick={this.handleDrawerClose}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </div>
                        <Divider /> */}

                        <Grid container className={classes.toolbar}>
                            <Grid item xs={2} className={classes.leftCloseIcon} >
                                <IconButton onClick={this.handleDrawerClose} >
                                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                </IconButton>
                            </Grid>

                            <Grid item xs={2} className={classes.DriverAvatar}>
                                <Avatar
                                    src={TAXI_IMAGE_URL + this.state.DriverData.profile_image}
                                    style={{ margin: 5, width: 50, height: 50, objectFit: "cover", }}
                                />
                            </Grid>
                            <Grid item xs={8} align="center">
                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    style={{ paddingTop: PADDING.SM, textTransform: "capitalize", fontWeight: "600" }}
                                >
                                    {LanguageManager.Strings.WELCOME_LABEL} {this.state.DriverData  !==  "" ? this.state.DriverData.first_name : ""}
                                </Typography>

                                <Typography variant="caption" gutterBottom>
                                    {this.state.DriverData  !==  "" ? this.state.DriverData.email : ""}
                                </Typography>
                            </Grid>

                            <Grid item xs={2}>
                                <IconButton onClick={this.handleClick}>
                                    <Icon> more_horiz</Icon>
                                </IconButton>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleClose}
                                >
                                    <Link
                                        to={DRIVER_ROUTES.PROFILE}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <MenuItem>{LanguageManager.Strings.DRIVER_MENU_OPTION1}</MenuItem>
                                    </Link>

                                    {/* <Link
                                        to={DRIVER_ROUTES.TRACKRIDE}
                                        style={{ textDecoration: "none" }}
                                    > */}
                                    {/* <MenuItem>Track Ride</MenuItem> */}
                                    {/* </Link> */}

                                    <Link
                                        to={DRIVER_ROUTES.HOME}
                                        style={{ textDecoration: "none" }}
                                    >

                                        <MenuItem>{LanguageManager.Strings.DRIVER_MENU_OPTION3}</MenuItem>
                                    </Link>
                                </Menu>
                            </Grid>
                        </Grid>
                        <Divider />
                        <div
                            xs={12}
                            container
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: 10,
                                height: "50px",
                                borderRadius: 5,
                                color: "#fff"
                            }}
                        >
                            <Grid
                                item
                                xs={4}
                                style={{
                                    backgroundColor: "#2DCC70",
                                    borderRadius: "5px 0 0 5px",
                                    paddingTop: 5
                                }}
                            >
                                <Typography variant="caption" className={classes.textWhite}>
                                {LanguageManager.Strings.TOTAL_RIDE_LABEL}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.textWhite}>
                                    {this.state.DriverData  !==  "" ? this.state.DriverData.totalEarning.total_ride : ""}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{ backgroundColor: "#4990E2", paddingTop: 5 }}
                            >
                                <Typography variant="caption" className={classes.textWhite}>
                                {LanguageManager.Strings.YOUR_RATING_LABEL}
                             </Typography>
                                <Typography variant="subtitle2" className={classes.textWhite}>
                                    {/* {this.state.DriverData  !==  "" ? this.state.DriverData.rating : ""} */}
                                    {this.state.DriverData.rating  !==  0 ?
                                        <Grid align="center" justify="center" style={{ display: "flex", flexDirection: "row", textAlign: "center" }}>
                                            {this.state.DriverData.rating} <StarRate />
                                        </Grid>
                                        : "0"}

                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                style={{
                                    backgroundColor: "#F5A623",
                                    borderRadius: "0 5px 5px 0",
                                    paddingTop: 5
                                }}
                            >
                                <Typography variant="caption" className={classes.textWhite}>
                                {LanguageManager.Strings.TOTAL_EARNING_LABEL}
                                     </Typography>
                                <Typography variant="subtitle2" className={classes.textWhite}>
                                    {this.state.DriverData  !==  "" ? this.state.DriverData.totalEarning.total_earning : ""}
                                </Typography>
                            </Grid>
                        </div>
                        <List>
                            <Link
                                to={DRIVER_ROUTES.VEHICLES}
                                style={{ textDecoration: "none" }}
                            >
                                <ListItem button key="1" >
                                    <ListItemText primary={LanguageManager.Strings.DRIVER_DRAWER_OPTION1}/>
                                </ListItem>
                            </Link>

                            <Link to={DRIVER_ROUTES.TRIPS} style={{ textDecoration: "none" }}>
                                <ListItem button key="2">
                                    <ListItemText primary={LanguageManager.Strings.DRIVER_DRAWER_OPTION2} />
                                </ListItem>
                            </Link>

                            {this.state.DRIVER_CONFIG_ROWS.navigation_drawer.wallet &&
                            <Link to={DRIVER_ROUTES.WALLET} style={{ textDecoration: "none" }}>
                                <ListItem button key="3">
                                    <ListItemText primary={LanguageManager.Strings.DRIVER_DRAWER_OPTION3}/>
                                </ListItem>
                            </Link>
                             }

                            <Link to={DRIVER_ROUTES.EARNING} style={{ textDecoration: "none" }}>
                                <ListItem button key="4">
                                    <ListItemText primary={LanguageManager.Strings.DRIVER_DRAWER_OPTION4}/>
                                </ListItem>
                            </Link>
                        </List>
                        <Divider />
{/* <div  style={{width:"100%",position:"absolute",bottom:0}}>
                        <Grid container className={classes.BottomDetails} style={{padding:5}} >
                            <Grid item xs={6} align="left" style={{}} >
                            {LanguageManager.Strings.SERVICE_TERMS_LABEL}
                                    </Grid>

                            <Grid item xs={6} align="right" style={{}} >
                            {LanguageManager.Strings.HELP_LABEL}
                                            </Grid>

                        </Grid>

                        <Grid container className={classes.DarkBottomDetails} >
                            <Grid item xs={6} align="left" style={{  }} >

                                <Icon>
                                    <GetApp />
                                </Icon>

                            </Grid>

                            <Grid item xs={6} align="right" style={{ padding: 10, color: "#fff" }} >
                            {LanguageManager.Strings.DOWNLOAD_LABEL}
                                            </Grid>
                        </Grid>
                        </div> */}

                    </Drawer>
                    {/* <main className={classNames(classes.content, {
                        [classes.contentShift]: open,
                    })}
                    >{this.props.children}</main> */}
                    <main className={classes.content}>{this.props.children}</main>
                </div>
            );
        }
    }
}

PersistentDrawerLeft.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PersistentDrawerLeft);

import React from "react";
import PropTypes from "prop-types";
import {
    withStyles,
    Grid,
    TextField,
    Button,
    InputAdornment
} from "@material-ui/core";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { DriverProfileStyles } from "../../../../styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

function TabContainer(props) {
    return (
        <Typography
            component="div"
            style={{
                padding: 8 * 3,
                textAlign: "left"
            }}
        >
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

class EditAddress extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        value: 0,
        checked: false,
        open: false,
        name: "",
        bankname: "",
        accountname: "",
        accountnumber: ""
    };
    handleSwitch = () => {
        this.setState(state => ({ checked: !state.checked }));
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleInputChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };
    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };
    handleClickShowConfirmPassword = () => {
        this.setState(state => ({
            showConfirmPassword: !state.showConfirmPassword
        }));
    };

    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    handleClickOpen = () => {
        this.setState({
            openList: true
        });
    };
    render() {
        console.log("driver home render", this.props);
        const { classes } = this.props;
        const { value } = this.state;
        const { checked } = this.state;

        return (
            <Grid item md={12} style={{}}>
                <Grid md={12} style={{ padding: "0px 5px" }}>
                    <Paper
                        style={{
                            backgroundColor: "#26699D",
                            padding: 10
                        }}
                    >
                        <Typography variant="subtitle1" style={{ color: "#fff" }}>
                            Edit Details
            </Typography>
                        <Typography
                            variant="caption"
                            style={{ color: "#fff", opacity: "0.5" }}
                        >
                            Manage your Details
            </Typography>
                    </Paper>
                </Grid>

                <Grid container md={12} style={{ padding: "0 10px" }}>

                    <Grid md={12} style={{ padding: "5px 0" }}>
                        <TextField

                            style={{ width: "100%" }}
                            id="outlined-locality"
                            label="Address Line 1"
                            className={classes.textField}
                            value={this.state.locality}
                            onChange={this.handleInputChange("locality")}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid md={12} style={{ padding: "5px 0" }}>
                        <TextField

                            style={{ width: "100%" }}
                            id="outlined-locality"
                            label="Address Line 2"
                            className={classes.textField}
                            value={this.state.locality}
                            onChange={this.handleInputChange("addressLine1")}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid md={12} style={{ padding: "5px 0" }}>
                        <TextField
                            style={{ width: "100%" }}
                            id="outlined-city"
                            label="City"
                            className={classes.textField}
                            value={this.state.city}
                            onChange={this.handleInputChange("addressLine2")}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid container md={12}>
                        <Grid item md={6} style={{ padding: "5px 0" }}>
                            <TextField
                                style={{ width: "100%" }}
                                id="outlined-city"
                                label="State"
                                className={classes.textField}
                                value={this.state.city}
                                onChange={this.handleInputChange("city")}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item md={6} style={{ padding: "5px 0" }}>
                            <TextField
                                style={{ width: "100%" }}
                                id="outlined-city"
                                label="Postal Code"
                                className={classes.textField}
                                value={this.state.city}
                                onChange={this.handleInputChange("postal")}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                    <Grid md={12} style={{ padding: "5px 0" }}>
                        <TextField
                            style={{ width: "100%" }}
                            id="outlined-city"
                            label="Country"
                            className={classes.textField}
                            value={this.state.city}
                            onChange={this.handleInputChange("country")}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                <Grid md={12} align="right" style={{}}>
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        className={classes.margin}
                        style={{ margin: "0 5px" }}
                    >
                        Update
          </Button>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(DriverProfileStyles)(EditAddress);

import React from "react";
import {
    withStyles,
    AppBar,
    Toolbar,
    Typography,
    Button,
    Grid,
    Icon,
    Paper,
    CardMedia,
    ButtonBase 
} from "@material-ui/core";
import { DocVerificationStyles } from '../../../styles';
import DocBarComponent from '../common/DocBarComponent';
import LinearTimeline from '../signUp/LinearTimeline';//'../common/TimelineComponent';
import { Redirect, Link, withRouter } from "react-router-dom";
import * as LanguageManager from '../../../../managers/language/LanguageManager'

class VerifyDocComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showBorder: null
        };
    }

    submitFunction=(value)=>{
        this.props.callBack(value+1)
    }

    render() {
        const { classes, callback, indexValue  } = this.props;

        return (
<Grid item xl justify='flex-start' className={classes.docBarContainer}>
    <CardMedia
    className={classes.actionIcon}
    image="https://image.flaticon.com/icons/svg/1242/1242455.svg"/>

    <Typography variant="body1" component="h3" className={classes.loginTitle}>
       {LanguageManager.Strings.VERIFICATION_MESSAGE}
    </Typography>

    <Button variant="contained" disableTouchRipple={true} disableFocusRipple={true} className={classes.SignupButton} onClick={()=>this.submitFunction(indexValue)}>
    {LanguageManager.Strings.OK_BTN_TITLE}
    </Button>
</Grid>

        );
    }
}

export default withStyles(DocVerificationStyles)(VerifyDocComponent);

import React from 'react'
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const XLoader = (props) => {
    const { classes } = props
    return (
        <div className={classes.loader}>
            <div className={classes.XLoader}>
                <div className={classes.overlay}></div>
                <CircularProgress className={classes.progress} color="secondary" />
            </div>
        </div>
    )
}

const styles = (theme) => ({
    loader: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        // maxWidth: '100vw',
        // maxHeight: '100vh',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        background: 'transparent',
        zIndex: '9999999999999 !important'
    },
    XLoader: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'transparent'
    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: '0',
        backgroundColor: 'black',
        opacity: '0.5'
    }
})


export default withStyles(styles)(XLoader)
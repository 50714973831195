import React, { Component } from "react";
import {
  CardMedia,
  Paper,
  CardActionArea,
  Typography,
  InputBase,
  Button,
  withStyles,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import CustomLoader from "../../../custom/CustomLoader";
import UserRegister from "../register/UserRegister";
import SocialRegister from "../register/SocialRegister";
import LoginComponent from "./LoginComponent";
import { saveSessionData } from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import CustomSnackbar from "../../../custom/CustomSnackbar";
import * as SessionManager from "../../../../managers/session/SessionManager";
import FullScreenDialog from "../../../custom/FullScreenDialog";
import * as LanguageManager from "../../../../managers/language/LanguageManager";
import { Link } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import DialogForm from "../../../custom/DialogForm";

class UserLogin extends Component {
  state = {
    loginVisible: false,
    registerVisible: false,
    loginBg: "",
    regBg: "",
    showSnackbar: false,
    snackbarVariant: "error",
    snakBarMessage: "",
    enableSocialRegister: false,
    profileData: {},
    platFormVal: 0,
    country_code: [],
    phonecode: "",
    showForm: false,
    loginConfig: {},
    signupConfig: {},
    isLoading: true,
    sessionData: null,
    forgotpass: null,
  };

  async componentDidMount() {
    let sessiondata = await JSON.parse(
      SessionManager.getSessionData(SessionKeys.USER_CONFIG)
    );
    let user_bg = await JSON.parse(
      SessionManager.getSessionData(SessionKeys.USER_BG)
    );

    console.log("sessiondata", sessiondata);
    if (sessiondata) {
      this.setState(
        {
          sessionData: sessiondata,
          loginConfig: sessiondata.login,
          signupConfig: sessiondata.register,
          country_code: sessiondata.countries,
          phonecode: sessiondata.countries[0].phonecode,
          user_bg: user_bg,
        },
        () => this.setState({ loginVisible: true })
      );
    }
  }

  responseFacebook = (response) => {
    console.log("@#@#@" + JSON.stringify(response));
    this.setState({ profileData: response, platFormVal: 3 }, () =>
      this.setState({
        registerVisible: false,
        loginVisible: false,
        enableSocialRegister: true,
      })
    );
  };

  responseGoogle = (response) => {
    console.log("!.!.!.!.!" + JSON.stringify(response));
    this.setState({ profileData: response.profileObj, platFormVal: 2 }, () =>
      this.setState({
        registerVisible: false,
        loginVisible: false,
        enableSocialRegister: true,
      })
    );
  };

  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false,
    });
  };

  handleClickOpenModal = (name) => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  openSnack = (message = "", variant = "error") => {
    this.setState({
      showSnackbar: true,
      snakBarMessage: message,
      snackbarVariant: variant,
    });
  };

  getDocumentList = () => {
    this.setState({ isLoading: true });

    ApiManager.callPostApi(
      "LoginForm",
      "driver_get_DocsList",
      ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_GET_DOCSLIST,
      {
        driver: 111,
        driver_vehicle: 100,
      }
    ).then((res) => {
      console.log("driverDocsList./././" + JSON.stringify(res));
      this.setState({ isLoading: true });

      if (res.result === "1") {
        this.setState(
          {
            contentBody: res.data,
            contentTitle: res.message,
            isLoading: false,
            contentStyle: "list",
          },
          () => {
            this.setState({ openDialog: true });
          }
        );
      } else {
        this.setState({
          isLoading: false,
          showSnackbar: true,
          snakBarMessage: res.message,
          snackbarVariant: "error",
        });
      }
      //alert(JSON.stringify(res))
    });
  };

  fetchDriverCmsData = (slugValue) => {
    this.setState({ isLoading: true });

    ApiManager.callPostApi(
      "LoginForm",
      "driver_cms_data_api",
      ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_CMSDATA,
      {
        slug: slugValue,
        country_id: 1,
      }
    ).then((res) => {
      console.log("*(*(*(*" + JSON.stringify(res));
      if (res.result === "1") {
        this.setState(
          {
            contentStyle: "paragraph",
            contentBody: res.data.description,
            contentTitle: res.data.title,
            isLoading: false,
          },
          () => {
            this.setState({ openDialog: true });
          }
        );
      } else {
        this.setState({
          isLoading: false,
          showSnackbar: true,
          snakBarMessage: res.message,
          snackbarVariant: "error",
        });
      }
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ width: "100vw", height: "100vh", overflowY: "scroll" }}>
        <CustomSnackbar
          showSnackbar={this.state.showSnackbar}
          variant={this.state.snackbarVariant}
          message={this.state.snakBarMessage}
          onClose={this.handleSnackBarClose}
        />

        <FullScreenDialog
          contentStyle={this.state.contentStyle}
          showDialog={this.state.openDialog}
          handleClose={() =>
            this.state.contentStyle == "list"
              ? this.setState({
                  openDialog: false,
                  contentStyle: "paragraph",
                  contentBody: "",
                })
              : this.setState({ openDialog: false })
          }
          contentTitle={this.state.contentTitle}
          contentBody={this.state.contentBody}
        />

        <CardMedia
          image={this.state.user_bg}
          style={{
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div style={{ display: "flex", width: "100%", height: "100%" }}>
            <div className={classes.stripesContainer}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <CardMedia
                  style={{
                    height: 150,
                    margin: 20,
                    backgroundSize: "contain",
                  }}
                  image={JSON.parse(
                    SessionManager.getSessionData(SessionKeys.DRIVER_LOGO)
                  )}
                  title="Logo"
                />
              </Link>
              <div className={classes.leftStripesView} />
            </div>

            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className={classes.formContainer}>
                <Paper
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: SessionManager.getSessionData(
                      SessionKeys.USER_CONFIG
                    )
                      ? null
                      : "center",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    image={require("../../../../assets/images/buildings.png")}
                    style={{
                      width: "100%",
                      height: "25%",
                      bottom: 0,
                      left: 0,
                      backgroundPosition: "left",
                      position: "absolute",
                      backgroundSize: "contain",
                    }}
                  />
                  {this.state.sessionData ? (
                    <div className={classes.formBox}>
                      <div
                        style={{
                          display: "flex",
                          padding: "0.5em",
                          paddingTop: "15%",
                        }}
                      >
                        <CardActionArea
                          onClick={() =>
                            this.setState({
                              loginVisible: true,
                              loginBg: "#d8d8d8",
                              regBg: "#ffffff",
                              registerVisible: false,
                              enableSocialRegister: false,
                            })
                          }
                          style={{
                            borderRadius: 10,
                            backgroundColor:
                              this.state.loginBg == ""
                                ? "#d8d8d8"
                                : this.state.loginBg,
                            // textAlign: "left"
                          }}
                        >
                          <Typography style={{ fontSize: "1.2rem" }}>
                            {LanguageManager.Strings.USER_LOGIN_TITLE}
                          </Typography>
                        </CardActionArea>
                        <CardActionArea
                          onClick={() =>
                            this.setState({
                              loginVisible: false,
                              loginBg: "#ffffff",
                              regBg: "#d8d8d8",
                              registerVisible: true,
                              enableSocialRegister: false,
                            })
                          }
                          style={{
                            borderRadius: 10,
                            backgroundColor: this.state.regBg,
                            // textAlign: "left"
                          }}
                        >
                          <Typography style={{ fontSize: "1.2rem" }}>
                            {LanguageManager.Strings.USER_REGISTER_TITLE}
                          </Typography>
                        </CardActionArea>
                      </div>
                      {/* {this.state.loginVisible && <LoginComponent countrySet={this.state.country_code} loginConfiguration={this.state.loginConfig}/>}
                    {this.state.registerVisible && <UserRegister countrySet={this.state.country_code} registerConfiguration={this.state.signupConfig}/>} */}
                      {this.state.sessionData && this.state.loginVisible && (
                        <LoginComponent configData={this.state.sessionData} />
                      )}
                      {this.state.sessionData && this.state.registerVisible && (
                        <UserRegister
                          country_code={this.state.country_code}
                          configData={this.state.sessionData}
                        />
                      )}
                      {this.state.enableSocialRegister && (
                        <SocialRegister
                          profileData={this.state.profileData}
                          platFormId={this.state.platFormVal}
                        />
                      )}
                      <div style={{ display: "flex", alignItems: "center" }} />,
                      <div
                        style={{
                          display: "flex",
                          margin: "5%",

                          justifyContent: "center",
                        }}
                      />
                      <div
                        style={{
                          width: "100%",
                          height: 1,
                          backgroundColor: "grey",
                        }}
                      />
                      <div
                        style={{
                          margin: "2%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item>
                          {/* <Button
                            color="primary"
                            className={classes.formLink}
                            onClick={() => this.getDocumentList()}
                          >
                            {LanguageManager.Strings.VIEW_DOCS_LABEL}
                          </Button> */}

                          <Button
                            color="primary"
                            className={classes.formLink}
                            onClick={() =>
                              this.handleClickOpenModal("forgotpass")
                            }
                          >
                            {LanguageManager.Strings.FORGOT_PASSWORD_LABEL}
                          </Button>
                        </Grid>
                        <DialogForm
                          visible={this.state.forgotpass}
                          onOutsideTouch={() =>
                            this.handleClickOpenModal("forgotpass")
                          }
                        >
                          <ForgotPassword
                            handleSnackBarClose={this.handleSnackBarClose}
                            configData={this.state.sessionData}
                            openSnack={this.openSnack}
                            handleModal={this.handleClickOpenModal}
                          />
                        </DialogForm>
                        <Grid item>
                          <Button
                            color="primary"
                            className={classes.formLink}
                            onClick={() =>
                              this.fetchDriverCmsData("terms_and_conditions")
                            }
                          >
                            {LanguageManager.Strings.TERMSCONDITION_LABEL}
                          </Button>
                        </Grid>
                      </div>
                    </div>
                  ) : (
                    <CircularProgress
                      className={classes.progress}
                      color="primary"
                    />
                  )}
                </Paper>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "25%",
                }}
              >
                <div className={classes.bottomStripesView} />
              </div>
            </div>
          </div>
        </CardMedia>
      </div>
    );
  }
}

const styles = (theme) => ({
  leftStripesView: {
    marginTop: "20vh",
    width: "100%",
    height: 40,
    borderBottom: "2px solid white",
    borderTop: "2px solid white",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  bottomStripesView: {
    marginLeft: "10%",
    width: 40,
    height: "100%",
    borderLeft: "2px solid white",
    borderRight: "2px solid white",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  formContainer: {
    width: "50%",
    height: "75%",
    border: "2px solid white",
    marginTop: "6vh",
    display: "flex",
    padding: "0.5%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "100%",
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
    },
  },
  stripesContainer: {
    width: "10%",
    height: "100%",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  formBox: {
    display: "flex",
    marginLeft: "25%",
    marginRight: "20%",

    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
  },
  formLink: {
    textTransform: "none",
    fontSize: "0.8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
    },
  },
});

export default withStyles(styles)(UserLogin);
// export default UserLogin;

import { getTheme } from "../../../../managers/theme/ThemeManager";
import { PADDING, MARGIN } from "../../../../values/Typography";

const styles = (theme) => {
    const configTheme = getTheme()

    return (
        {
            root: {
                display: 'flex',
                justifyContent: 'center',
                padding: PADDING.LG,
                marginTop: MARGIN,
                [theme.breakpoints.down('lg')]: {
                    padding: PADDING.XS
                }
            },
            imageBox: {
                'max-height': '500px',
                overflow: 'hidden',
                'border-radius': '20px'
            }

        }
    )
}



export default styles
import { getTheme } from "../../../../managers/theme/ThemeManager";
import {
  PADDING,
  MARGIN,
  CUSTOM_FONT,
  CUSTOM_COLOUR,
  CUSTOM_DRIVER_COLOUR
} from "../../../../values/Typography";

const styles = theme => {
  const configTheme = getTheme();

  return {
    docBarContainer: {
      // display: 'inline',
      // width: '50vw',
      // height: '70vh'
    },
    root: {
      flexGrow: 1,
      // backgroundColor:'red',
      "&$tabSelected": {
        color: "red",
        fontWeight: "bold" //theme.typography.fontWeightMedium,
      }
    },

    tabRoot: {
      textTransform: "initial",
      fontFamily: CUSTOM_FONT,
      fontSize: 22,
      [theme.breakpoints.down("xs")]: {
        fontSize: "x-small"
      },
      "&$tabSelected": {
        fontWeight: "bold" //theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: "black"
      }
    },
    tabSelected: {},
    tabStyle: {
      textTransform: "none",
      fontFamily: CUSTOM_FONT,
      fontSize: 22
    },
    tabsIndicator: {
      backgroundColor: "black"
    },
    listItem: {
      fontFamily: CUSTOM_FONT,
      fontSize: 22
    },
    SignupButton: {
      backgroundColor: CUSTOM_DRIVER_COLOUR,
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      textTransform: "none",
      width: "40%",
      marginBottom: 30,
      marginTop: 30,
      height: "5vh",
      color: "white",
      fontWeight: "bold"
    },
    nextButton: {
      backgroundColor: "#23669B",
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      textTransform: "none",
      color: "white",
      fontWeight: "bold",
      height: "70px"
    },
    servicesButton: {
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      textTransform: "none",
      width: "35%",
      marginBottom: "5px",
      marginTop: "5px",
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        fontSize: "x-small"
      }
      //  marginLeft:theme.spacing.unit*4
    },
    detailFields: {},
    cardDetailFields: {
      height: "80px"
    },
    materialFieldStyle: {
      fontSize: 12,
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold"
    },
    sectionHead: {
      backgroundColor: "silver",
      paddingLeft: 10,
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        fontSize: "x-small"
      }
    },
    serviceContainer: {
      marginTop: "3%",
      marginRight: "5%",
      marginLeft: "5%",
      alignItems: "center"
    },
    itemSet: {
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      marginRight: theme.spacing.unit
    },
    uploadImageLabel: {
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      paddingRight: theme.spacing.unit * 13,
      paddingLeft: theme.spacing.unit * 13
    },
    avatarContainer: {
      backgroundColor: "silver",
      border: "1px solid gray",
      borderRadius: 5,
      padding: 10
      //height:'30vh'
    },
    formControl: {
      margin: theme.spacing.unit,
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing.unit * 2
    },
    areaItem: {
      fontFamily: CUSTOM_FONT,
      fontSize: 14
    },
    loginTitle: {
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        fontSize: "x-small"
      }
    },
    ownerDetailStyle: {
      fontFamily: CUSTOM_FONT,
      fontSize: "1.3rem",
      fontWeight: "bold"
    },
    multiRoot: {
      display: "flex",
      flexWrap: "wrap"
    },
    multiFormControl: {
      margin: theme.spacing.unit,
      minWidth: 120,
      maxWidth: 300
    },
    chips: {
      display: "flex",
      flexWrap: "wrap"
    },
    chip: {
      margin: theme.spacing.unit / 4
    },
    noLabel: {
      marginTop: theme.spacing.unit * 3
    },
    addDriverVehicleBtn: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "x-small",
        textAlign: "center",
        padding: "12px 12px 12px 0px"
      }
    },
    AddVehiclebutton: {
      backgroundColor: "#23669B",
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      color: "white",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: "x-small"
      }
    }
  };
};

export default styles;

import React from "react";
import PropTypes from "prop-types";
import {
    withStyles,
    Grid,
    TextField,
    Button,
    InputAdornment,
    ListItemSecondaryAction,
    Icon,
    Radio
} from "@material-ui/core";
import classNames from "classnames";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { DriverProfileStyles } from "../../../../styles";
import Typography from "@material-ui/core/Typography";
import DialogForm from "../../../../custom/DialogForm";
import * as ApiManager from "../../../../../managers/api/ApiManager";
import { APPORIO_TAXI_APIS } from "../../../../../managers/api/ApiEndPoints";
import CustomLoder from "../../../../../UI/custom/CustomLoader";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EditAddress from "./EditAddress";
import NewAddress from "./NewAddress";
import NoAddress from "./NoAddress";
import Autocomplete from "../../../../custom/Autocomplete";



function TabContainer(props) {
    return (
        <Typography
            component="div"
            style={{
                padding: 8 * 3,
                textAlign: "left"
            }}
        >
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

class SelectAddress extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {

        value: 0,
        checked: false,
        open: false,
        name: "",
        bankname: "",
        accountname: "",
        accountnumber: "",
        openEditModal: false,
        openAddModal: false,
        ADDRESS_ROWS: [],
        loading: true,
        AddNew: false,
        mainContent: true,
        selectedValue: null,

    };


    handleClickOpenModal = name => {
        this.setState({
            [name]: !this.state[name]
        });
    };
    handleSwitch = () => {
        this.setState(state => ({ checked: !state.checked }));
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    handleRadioChange = event => {
        this.setState({ selectedValue: event.target.value });
    };

    handleInputChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };
    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };
    handleClickShowConfirmPassword = () => {
        this.setState(state => ({
            showConfirmPassword: !state.showConfirmPassword
        }));
    };

    handleClick = () => {
        this.setState(state => ({ open: !state.open }));
    };

    handleClickOpen = () => {
        this.setState({
            openList: true
        });
    };


    onAfterAddAddressCallback = () => {
        this.ListAddress()
    }

    ListAddress = () => {
        console.log("hahahahahahahahahahaha", APPORIO_TAXI_APIS.DRIVER_ADDRESS)

        ApiManager.callPostApi(
            "Active", "", APPORIO_TAXI_APIS.DRIVER_ADDRESS,
        )

            .then((response) => {
                console.log('*****response*******', response)
                // alert(JSON.stringify(response))

                this.setState({
                    ADDRESS_ROWS: response.data,
                    loading: false

                })
                console.log(response)
            })


            .catch((error) => {
                console.log(error)

            })
    }

    componentDidMount() {
        this.ListAddress()
    }

    // ---- remove CustomerSupport from list ---- //
    removeAddressRow = (AddressRowId) => {
        let index = this.state.ADDRESS_ROWS.findIndex(row => parseInt(row.id) == parseInt(AddressRowId))
        let arr = [...this.state.ADDRESS_ROWS]
        arr.splice(index, 1)
        this.setState({
            ADDRESS_ROWS: arr,
            loading: false
        })

    }


    // ---- delete AddressRow ----//
    deleteAddressRow = (AddressRowId) => {
        console.log("AddressROwId", AddressRowId)
        // loading
        this.setState({
            loading: true
        })

        ApiManager.callPostApi(
            "DeleteAddress",
            "",
            APPORIO_TAXI_APIS.DELETE_DRIVER_ADDRESS,
            {
                address_id: AddressRowId
            }
        )

            .then((response) => {

                switch (parseInt(response.result)) {

                    case 0:
                        console.log("case0", response)
                        this.setState({
                            loading: false
                        })
                        break;

                    case 1:
                        this.removeAddressRow(AddressRowId)

                        break

                    default:
                        break;
                }
            })

            .catch((error) => {
                alert(error)
                this.setState({
                    loading: false
                })
            })

    }



    //add address

    check_visibility = (AddressRowId) => {


        // start laoding
        this.setState({
            loading: true,
        })

        // add form
        ApiManager.callPostApi(
            "SELECT_ADDRESS",
            "",
            APPORIO_TAXI_APIS.SELECT_DRIVER_HOME_ADDRESS,

            {
                address_id: AddressRowId,
                status: "1",

            }
        )

            .then((response) => {
                console.log("******SELECT_ADDRESS******", response)
                this.setState({
                    ADDRESS_ROWS: response.data,
                    loading: false
                },
                    () =>
                        this.props.onBackPress()
                )
            })

            .catch((error) => {

            })
    }



    render() {
        console.log("driver home render", this.props);
        const { classes } = this.props;
        const { value } = this.state;
        const { checked } = this.state;

        if
            (this.state.loading) { return <CustomLoder showLoader={this.state.loading} /> }
        else {
            return (
                <div>
                    {/* vaibhav */}
                    {this.state.mainContent && (
                        <Grid item md={12} style={{ marginTop: 10 }}>
                            <Grid container md={12} style={{}}>
                                <Grid item md={6} xs={6} style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: -10
                                }}>
                                    <IconButton
                                        onClick={() =>
                                            this.props.onBackPress()
                                        }
                                    >
                                        <Icon style={{ color: "#666" }}>
                                            arrow_back
                                      </Icon>
                                    </IconButton>
                                    <Typography
                                        variant="subtitle1"
                                        style={{ marginTop: 10, fontWeight: "600", color: "#26699d", }}
                                    >
                                        Select Address Detail
                                  </Typography>
                                </Grid>
                                <Grid item md={6} xs={6} align="right" style={{}}>
                                    <Button
                                        style={{ backgroundColor: "#2dcc70", }}
                                        onClick={() =>
                                            this.setState({
                                                AddNew: !this.state.AddNew,
                                                mainContent: !this.state.mainContent
                                            })
                                        }>
                                        <Typography variant="caption" className={classes.btnText} style={{ color: "#fff" }}>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <Icon>add</Icon>
                                                <div style={{ marginTop: 5 }}> Add new Address </div>
                                            </div>
                                        </Typography>
                                    </Button>
                                    <DialogForm
                                        visible={this.state.openAddModal}
                                        onOutsideTouch={() =>
                                            this.handleClickOpenModal("openAddModal")}>
                                        <NewAddress />
                                    </DialogForm>
                                </Grid>
                            </Grid>
                            <hr style={{ color: "#eaeaea" }} />

                            {this.state.ADDRESS_ROWS.length > 0 ? this.state.ADDRESS_ROWS
                                .map(row => {
                                    return (
                                        <Paper style={{ marginBottom: 10 }}>
                                            <Grid container xs={12} md={12} style={{}}>
                                                <Grid item xs={8} md={8} style={{ padding: 20 }}>
                                                    <Grid container xs={12} md={12}>
                                                        <Grid item>
                                                            <Radio
                                                                checked={row.check_visibility}
                                                                onChange={() => this.check_visibility(row.id)}
                                                                value="a"
                                                                name="radio-button-demo"
                                                                aria-label="A"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={8} md={11}>
                                                            <Typography variant="body2" style={{ fontWeight: 600 }}>
                                                                {row.address_name}
                                                            </Typography>
                                                            <Typography variant="display4" style={{ fontSize: "15px" }}>
                                                                {row.address_value}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={4} md={4} align="right" style={{ padding: 20 }}>
                                                    <IconButton aria-label="Delete"
                                                        onClick={() => this.deleteAddressRow(row.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>

                                            </Grid>
                                        </Paper>
                                    );
                                })

                                :
                                <NoAddress />

                            }
                        </Grid>
                    )}
                    {this.state.AddNew && (
                        <NewAddress
                            onBackPress={() =>
                                this.setState({
                                    AddNew: !this.state.AddNew,
                                    mainContent: !this.state.mainContent
                                }, () => {
                                    this.ListAddress()
                                })
                            }
                        />
                    )}
                </div>
            );
        }
    }
}

export default withStyles(DriverProfileStyles)(SelectAddress);

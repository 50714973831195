import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Typography, InputBase } from "@material-ui/core";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import * as ApiManager from "../../../../managers/api/ApiManager";
const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing.unit * 3
  },
  group: {
    display: "flex",

    margin: `${theme.spacing.unit}px 0`
  }
});

class AddFavouriteLocation extends React.Component {
  state = {
    open: this.props.open,
    selectedRadioBtn: "1",
    otherText: ""
  };
  executeAddFavLocationApi = () => {
    ApiManager.callPostApi(
      "AddFavouriteLocation",
      "add fav location",
      ApiEndPoints.APPORIO_TAXI_APIS.ADD_FAVOURITE_LOCATION,
      {
        latitude: String(this.props.address.lat),
        location: this.props.address.address,
        longitude: String(this.props.address.lng),
        category: this.state.selectedRadioBtn,
        other_name: this.state.otherText
      }
    )
      .then(res => {
        if (res.result === "1") {
          this.props.close();
        } else {
          alert(JSON.stringify(res.message));
        }
      })
      .catch(err => alert(JSON.stringify(err)));
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.props.close();
  };
  handleChange = event => {
    this.setState({ selectedRadioBtn: event.target.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <RadioGroup
              style={{ flexDirection: "row", display: "flex" }}
              className={classes.group}
              value={this.state.selectedRadioBtn}
              onChange={this.handleChange}
            >
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label="Home"
                labelPlacement="end"
              />

              <FormControlLabel
                value="2"
                control={<Radio color="primary" />}
                label="Work"
                labelPlacement="end"
              />
              <FormControlLabel
                value="3"
                control={<Radio color="primary" />}
                label="Other"
                labelPlacement="end"
              />
            </RadioGroup>

            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="address"
                src="https://cdn0.iconfinder.com/data/icons/lineo-internet/100/romzicon_home-512.png"
                style={{ width: 40, height: 40 }}
              />
              <Typography style={{ fontSize: 14 }}>
                {this.props.address === "" ? "" : this.props.address.address}
              </Typography>
            </div>
            {this.state.selectedRadioBtn === "3" && (
              <InputBase
                onChange={event =>
                  this.setState({ otherText: event.target.value })
                }
                placeholder="Please Enter Address Name"
                style={{
                  width: "100%",
                  padding: 5,
                  border: "1px solid black",
                  borderRadius: 5
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => this.executeAddFavLocationApi()}
              color="primary"
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(AddFavouriteLocation);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Slide from '@material-ui/core/Slide';
import { Grid, CardMedia, Icon, Paper, Avatar } from "@material-ui/core";
import * as ApiManager from "../../../../../../managers/api/ApiManager";
import { APPORIO_TAXI_APIS, TAXI_IMAGE_URL } from "../../../../../../managers/api/ApiEndPoints";
import CustomLoader from '../../../../../custom/CustomLoader';
import * as LanguageManager from '../../../../../../managers/language/LanguageManager'
import classNames from 'classnames';


const styles = theme => {

    return {
        appBar: {
            position: 'relative',
        },
        flex: {
            flex: 1,
        },
        ridingwithModal: {
            padding: 10, marginTop: "20px",
            [theme.breakpoints.down('sm')]:
            {
                marginTop: "100px !important"
            },
            ['@media (max-width:320px)']:
                { marginTop: "130px !important" }

        },
        CardMap:
        {
            backgroundSize: "75% 105%",
            // backgroundPosition: "50px 150px",
            backgroundPosition: "center",
            height: "70%",
            borderRadius: 5,
            margin: 5,
            [theme.breakpoints.down('md')]:
            {
                height: "60% !important",
                backgroundSize: "70% 150%"
            }

        },
        CardGridPadding: {
            padding: 10
        },
        CardGridPaddingTwo: {
            padding: 20
        },
        MarginPaper: { margin: 5 },
        pick_text: {

            borderLeft: "10px solid #2DCC70",
            padding: 15,
            fontSize: 15

        },
        drop_text: {
            borderLeft: "10px solid #E84C3D",
            padding: 15,
            fontSize: 15
        },
        ridingwithModalTypo: {
            fontWeight: "600"
        },
        GridAvatar: {
            padding: 8
        },
        AvatarGrid: {
            height: "70px", width: "70px"
        },
        UserDataHighlight: {

            fontWeight: "Bold", color: "#000", textTransform: "capitalize"

        },
        UserDataRating: {
            color: "#76abe9"
        },
        Gridthreex: {
            padding: 10, marginTop: 10
        },
        Gridthirdx: {
            padding: 5, borderRight: "1px solid lightgrey"

        },
        cashlabel: {
            fontWeight: "600", color: "#2dcc70"
        },
        DISTANCE_TRAVEL_LABEL: {
            padding: 5, borderRight: "1px solid lightgrey"
        },
        holderMeteringDataTexttwo: {
            fontWeight: "600", color: "#666"
        },
        TimeTravelLabel: {
            padding: 5
        },
        holderMeteringDataTextthree: {
            fontWeight: "600", color: "#F14741"
        },
        hrOpacity: {
            opacity: "0.5"
        },
        fontsx: {
            marginTop: 10
        },
        receipt_highlighted_text: {
            borderRight: "1px solid lightgrey"
        },
        testClass: {
            borderBottom: "1px solid lightgrey", padding: 10
        },















    }
}

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class FullScreenDialog extends React.Component {
    state = {
        wait: true,
        open: true,
        HISTORY_ROWS: [],
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };


    componentDidMount() {

        ApiManager.callPostApi(
            "Past", "", APPORIO_TAXI_APIS.HISTORY_DETAIL,
            {
                booking_id: this.props.TripRow.booking_id
            }
        )

            .then((response) => {

                // alert(JSON.stringify(response))
                this.setState({

                    HISTORY_ROWS: response.data,
                    wait: false

                })

            }).catch((error) => {
                console.log(error)
            })
    }

    render() {
        const { classes } = this.props;
        // console.log("!@#$%^&&", this.props.TripRows)
        console.log("full screen dialog", this.state)

        if (this.state.wait) { return <CustomLoader showLoader={this.state.wait} /> }

        else {
            return (

                <div>
                    {/* <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
                        Details
                        </Button> */}
                    <Dialog
                        fullScreen
                        open={this.props.open}
                        onClose={this.props.onClose}
                        TransitionComponent={Transition}
                    >

                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                {/* <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                                    <CloseIcon />
                                </IconButton> */}
                                <Typography variant="h6" color="inherit" className={classes.flex}>

                                    {LanguageManager.Strings.RIDE_ID_LABEL} #{this.props.TripRow.booking_id}  {this.state.HISTORY_ROWS.holder_booking_description.data.small_left_text}
                                    {/* {alert(this.props.TripRow.booking_id)} */}
                                </Typography>

                                <IconButton color="inherit" onClick={this.props.onClose} aria-label="Close">
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>


                        <Grid container md={12} className={classes.CardGridPadding}>
                            <Grid item md={6} xs={12} className={classes.CardGridPadding}>

                                <CardMedia
                                    // className={classes.cover}
                                    // style={{
                                    //     // backgroundAttachment: "fixed",
                                    //     backgroundSize: "75% 105%",
                                    //     // backgroundPosition: "50px 150px",
                                    //     backgroundPosition: "center",
                                    //     height: "70%",
                                    //     borderRadius: 5,
                                    //     margin: 5
                                    // }}
                                    className={classes.CardMap}
                                    // image={require("../../../../../../assets/images/staticmap.png")}
                                    image={this.state.HISTORY_ROWS.holder_map_image.data.map_image}
                                    title="map"
                                />

                                <Grid container md={12} className={classes.CardGridPaddingTwo}>
                                    <Grid item md={12}>
                                        <Grid md={12} className={classes.MarginPaper}>
                                            <Paper
                                                className={classes.pick_text}
                                            >
                                                {this.state.HISTORY_ROWS.holder_pickdrop_location.data.pick_text}
                                            </Paper>
                                        </Grid>
                                        <Grid md={12} className={classes.MarginPaper}>
                                            <Paper

                                                className={classes.drop_text}

                                            >
                                                {/* {this.props.TripRow.drop_location} */}
                                                {this.state.HISTORY_ROWS.holder_pickdrop_location.data.drop_text}
                                            </Paper>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12} >
                                <Grid container md={12}>

                                    <Grid md={12} xs={12} >
                                        <Paper className={classes.ridingwithModal}>
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                                className={classes.ridingwithModalTypo}
                                            >
                                                {LanguageManager.Strings.RIDE_WITH_LABEL}
                                            </Typography>
                                            <Grid container md={12} >
                                                <Grid item md={4} className={classes.GridAvatar}>
                                                    <Avatar className={classes.AvatarGrid}>
                                                        <img src={TAXI_IMAGE_URL + this.state.HISTORY_ROWS.holder_user.data.circular_image} height="100" width="100" />
                                                    </Avatar>
                                                </Grid>
                                                <Grid item md={8}>
                                                    <Typography
                                                        variant="caption"
                                                        gutterBottom
                                                        className={classes.UserDataHighlight}

                                                    >
                                                        {this.state.HISTORY_ROWS.holder_user.data.highlighted_text}
                                                    </Typography>
                                                    <Typography variant="caption" gutterBottom >
                                                        {this.state.HISTORY_ROWS.holder_user.data.small_text}
                                                    </Typography>
                                                    <Grid md={12}>
                                                        <Typography
                                                            variant="caption"
                                                            gutterBottom
                                                            className={classes.UserDataRating}

                                                        >
                                                            {this.state.HISTORY_ROWS.holder_user.data.rating_button_text}: {this.state.HISTORY_ROWS.holder_user.data.rating}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid md={12}>
                                                        <Icon>star_rate</Icon>
                                                        <Icon>star_rate</Icon>
                                                        <Icon>star_rate</Icon>
                                                        <Icon>star_rate</Icon>
                                                        <Icon>star_rate</Icon>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={12} className={classes.Gridthreex} >
                                        <Grid container xs={12} md={12} align="center">
                                            <Grid
                                                item
                                                xs={4}
                                                md={4}
                                                className={classes.Gridthirdx}
                                            >
                                                <Typography variant="body1">{LanguageManager.Strings.PAY_METHD_LABEL}</Typography>
                                                <Typography
                                                    variant="h5"
                                                    className={classes.cashlabel}
                                                >
                                                    {LanguageManager.Strings.CASH_LABEL}
                                                    {/* {this.state.HISTORY_ROWS.holder_metering.data.text_one} */}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={4}
                                                md={4}
                                                className={classes.DISTANCE_TRAVEL_LABEL}
                                            >
                                                <Typography variant="body1">{LanguageManager.Strings.DISTANCE_TRAVEL_LABEL}</Typography>
                                                <Typography
                                                    variant="h5"
                                                >
                                                    {this.state.HISTORY_ROWS.holder_metering.data.text_two}
                                                </Typography>
                                            </Grid>
                                            <Grid item md={4} xs={4} >
                                                <Typography variant="body1">{LanguageManager.Strings.TIME_TRAVEL_LABEL}</Typography>
                                                <Typography
                                                    variant="h5"
                                                    className={classes.holderMeteringDataTextthree}

                                                >
                                                    {this.state.HISTORY_ROWS.holder_metering.data.text_three}

                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <hr className={classes.hrOpacity} />
                                    </Grid>

                                    <Grid item xs={12} className={classes.fontsx}>
                                        {/* <Typography
                                            variant="body1"
                                            gutterBottom
                                            className={classes.ridingwithModalTypo}
                                        >
                                            {LanguageManager.Strings.BILL_DETAILS_LABEL}
                                        </Typography> */}
                                        <Paper>
                                            {this.state.HISTORY_ROWS.holder_receipt.data.map(receipt => {
                                                return (
                                                    <Grid container xs={12}
                                                        className={classNames(classes.tablecontent, classes.testClass)}>
                                                        <Grid item xs={1}>
                                                            {/* <img src={TAXI_IMAGE_URL + this.state.HISTORY_ROWS.holder_user.data.circular_image}
                                                                className="img-avatar"
                                                                height="15"
                                                                width="15"
                                                            /> */}
                                                            <Icon>
                                                                <KeyboardArrowRight />
                                                            </Icon>
                                                        </Grid>
                                                        <Grid item md={6} xs={6} className={classes.receipt_highlighted_text}>
                                                            {receipt.highlighted_text}
                                                        </Grid>
                                                        <Grid item md={5} xs={5} className={classes.tablevalue}>
                                                            {receipt.value_text}
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                            {/* <Grid container xs={12} style={{ borderBottom: "1px solid lightgrey", padding: 10, fontWeight: "bold", color: "#000" }} className={classes.tablecontent}>
                                                <Grid item xs={1}>
                                                    <img src={TAXI_IMAGE_URL + this.state.HISTORY_ROWS.holder_user.data.circular_image}
                                                        className="img-avatar"
                                                        height="15"
                                                        width="15"
                                                    />
                                                      <Icon>
                                                                <KeyboardArrowRight />
                                                            </Icon>
                                                </Grid>
                                                <Grid item md={6} xs={6} className={classes.receipt_highlighted_text}>
                                                    {LanguageManager.Strings.TOTAL_LABEL}
                                                </Grid>
                                                <Grid item md={5} xs={5} className={classes.tablevalue}>
                                                    {this.state.HISTORY_ROWS.holder_metering.data.text_one}
                                                </Grid>
                                            </Grid> */}
                                        </Paper>


                                    </Grid>


                                </Grid>
                            </Grid>
                        </Grid>
                    </Dialog>
                </div>
            );
        }

    }
}

FullScreenDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullScreenDialog);

import React from "react";
import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent
} from "@material-ui/core";
import { FooterStyles } from "../../../styles";
import PrimaryVariantButton from "../../../custom/buttons/PrimaryVariantButton";
import { APPORIO_LOGO, APPSTORE, GOOGLEPLAY } from "../../../../values/Icons";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
// import CardMedia from '@material-ui/core/CardMedia';
import { FOOTER_LOGO_HOME } from "../../../../values/Icons";
import * as LanguageManager from "../../../../managers/language/LanguageManager";
import { TAXI_IMAGE_URL } from "../../../../managers/api/ApiEndPoints";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";

class IntroTextLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBorder: null
    };
  }

  render() {
    const { classes } = this.props;

    return (
      /* <Grid container xl justify="flex-start" alignItems="center" className={classes.parentView}>

 <Grid item xs={4} className={classes.imageView}>
        <img
        id="footer"
          //className={classes.media}
          src={FOOTER_LOGO_HOME}
          title="Footer image" 
          className={classes.imageView}
        />
 </Grid>
<Grid container xs={5} justify="flex-start" direction="column">

<Typography variant="h4" gutterBottom className={classes.footerTitle} align="left">
   <b> Any Questions ?</b> Take a look at our FAQs
</Typography>
<Typography variant="h5" align="left" gutterBottom className={classes.footerText} align="left">
            What are the requirements to work with apporio Taxi ? How is apporio taxi safer than others? How does the app work ? How will I receive my earnings? Find answers to all your queries at our help center.
</Typography>

</Grid>
</Grid> */

      <Grid
        container
        justify="space-between"
        className={classes.footerContainer}
        style={{ backgroundColor: this.props.DRIVER_LIST.bgColor }}
      >
        <Grid
          item
          style={{ textAlign: "center" }}
          align="center"
          className={classes.imageContainer}
          style={{ padding: 30 }}
        >
          <img
            style={{ borderRadius: "10px" }}
            id="footer"
            //className={classes.media}
            src={this.props.DRIVER_LIST.image}
            title="Footer image"
            className={classes.imageView}
          />
        </Grid>
        <Grid item style={{ flex: 5 }} align="center">
          {/* <div className={classes.footerTextWrapper}>

        <Typography  gutterBottom className={classes.footerTitle} align="left">
        <b>{LanguageManager.Strings.DRIVER_FOOTER_QUESTN}</b>{LanguageManager.Strings.TAKE_LOOK_PHRASE}
        </Typography>
        <Typography  align="left" gutterBottom className={classes.footerText} align="left">
        {LanguageManager.Strings.DRIVER_FOOTER_SENTENCE}
        </Typography>
        </div>     */}
          <div className={classes.footerTextWrapper}>
            <Typography
              gutterBottom
              className={classes.footerTitle}
              style={{ color: this.props.DRIVER_LIST.textColor }}
              align="left"
            >
              <b>{this.props.DRIVER_LIST.heading}</b>
            </Typography>
            <Typography
              align="left"
              gutterBottom
              className={classes.footerText}
              style={{ color: this.props.DRIVER_LIST.textColor }}
              align="left"
            >
              {this.props.DRIVER_LIST.subHeading}
            </Typography>
            <a
              href={JSON.parse(
                SessionManager.getSessionData(SessionKeys.IOS_LINK)
              )}
              // href="https://apps.apple.com/us/app/exicab-driver/id1470600950?ls=1"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                className={classes.appleImg}
                style={{ height: 100 }}
                alt="complex"
                src={APPSTORE}
              />
            </a>
            <a
              href={JSON.parse(
                SessionManager.getSessionData(SessionKeys.ANDROID_LINK)
              )}
              // href="https://play.google.com/store/apps/details?id=com.exicab.driver"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                style={{ height: 100 }}
                className={classes.androidImg}
                alt="complex"
                src={GOOGLEPLAY}
              />
            </a>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(FooterStyles)(IntroTextLayer);

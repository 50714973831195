import React from "react";
import PropTypes from "prop-types";
import { withStyles, Grid, TextField, Button } from "@material-ui/core";
import { DriverProfileStyles } from "../../../../styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { APPORIO_TAXI_APIS } from "../../../../../managers/api/ApiEndPoints";
import * as ApiManager from "../../../../../managers/api/ApiManager";
import { getSessionData } from "../../../../../managers/session/SessionManager";
import { DRIVER_DATA } from "../../../../../managers/session/SessionKeys";
import CustomLoader from "../../../../custom/CustomLoader";

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.profileImageRef = React.createRef();
  }

  state = {
    value: 0,
    checked: false,
    open: false,
    name: "",
    bankname: "",
    accountname: "",
    accountnumber: "",
    image: "",
    expireDate: "",

    data: JSON.parse(getSessionData(DRIVER_DATA))
  };

  openExplorer = () => {
    console.log("click");
    this.profileImageRef.current.click();
  };

  // --- handle upload ----
  profileUpload = event => {
    let reader = new FileReader();
    reader.onload = e => {
      this.setState({
        image: e.target.result
      });
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  handleDateChange = event => {
    this.setState({ expireDate: event.target.value });
  };

  handleSwitch = () => {
    this.setState(state => ({ checked: !state.checked }));
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };
  handleClickShowConfirmPassword = () => {
    this.setState(state => ({
      showConfirmPassword: !state.showConfirmPassword
    }));
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClickOpen = () => {
    this.setState({
      openList: true
    });
  };

  addDocumentImage = () => {
    console.log("QRTYUIOP", this.state.image);

    // start laoding
    this.setState({
      loading: true
    });

    if (this.state.image == "") {
      this.setState(
        {
          loading: false
        },
        () => {
          this.props.openSnack("Please Select Image", "warning");
        }
      );
    } else if (this.state.expireDate == "") {
      // this.state.image == "")
      this.setState(
        {
          loading: false
        },
        () => {
          this.props.openSnack("Please Select Date", "warning");
        }
      );
    } else {
      // add form
      ApiManager.callPostApi(
        "ADD_Edit",
        "",
        APPORIO_TAXI_APIS.ADD_DRIVER_DOCUMENT,

        {
          api_type: 1,
          type: 1,
          driver: this.state.data.id,
          expire_date: this.state.expireDate,
          document: this.props.docId,
          document_image: this.state.image
        }
      )
        // .then((response) => {
        //   console.log("******add Document******", response)
        //   this.setState({
        //     loading: false

        //   })
        // })

        // .then((response) => {
        //   console.log("******add Document******", response)
        //   // this.setState({
        //   //   wait: false,
        //   // })

        //   switch (parseInt(response.result)) {
        //     case 1:
        //       this.setState({
        //         showSnackbar: true,
        //         snakBarMessage: response.message,
        //         snackbarVariant: "success",
        //         wait: false

        //       })
        //       break;

        //     case 0:
        //       this.setState({
        //         showSnackbar: true,
        //         snakBarMessage: response.message,
        //         wait: false

        //       })
        //       break;
        //   }

        //   console.log(response)

        // })
        .then(response => {
          console.log("*****response*******", response);
          // alert(JSON.stringify(response))
          switch (parseInt(response.result)) {
            case 1:
              this.setState(
                {
                  loading: false
                },
                () => {
                  this.props.finalSnack(response.message, "success");
                }
              );

              // this.setState({
              //   loading: false,
              // });
              // this.props.openSnack(response.message, "success")
              // this.props.ManageDocList();
              // this.handleClickOpenModal("openAddModal");

              break;

            case 0:
              this.setState(
                {
                  loading: false
                },
                () => {
                  this.props.openSnack(response.message, "warning");
                }
              );
              break;
          }

          console.log(response);
        })

        .catch(error => {});
    }
  };

  render() {
    console.log("driver home render", this.props);
    const { classes } = this.props;
    const { value } = this.state;
    const { checked } = this.state;
    if (this.state.loading) {
      return <CustomLoader showLoader={this.state.loading} />;
    } else {
      return (
        <Grid item md={12} style={{}}>
          <Grid md={12} style={{ padding: "0px 5px" }}>
            <Paper
              style={{
                backgroundColor: "#26699D",
                padding: 10
              }}
            >
              <Typography variant="subtitle1" style={{ color: "#fff" }}>
                Add Details :{this.props.docId}
              </Typography>
              <Typography
                variant="caption"
                style={{ color: "#fff", opacity: "0.5" }}
              >
                Manage your Details
              </Typography>
            </Paper>
          </Grid>

          <Grid container md={12} style={{ padding: "0 10px" }}>
            <Grid item md={6} style={{ padding: 20 }}>
              <Grid style={{ padding: 5 }}>
                <Paper style={{ background: "#efefef" }}>
                  <img
                    src={this.state.image}
                    style={{
                      width: "100%",
                      height: 100,
                      cursor: "pointer"
                    }}
                    onClick={() => this.openExplorer()}
                  />
                </Paper>
                <input
                  label="change"
                  type="file"
                  style={{ width: 0, overflow: "hidden", display: "none" }}
                  ref={this.profileImageRef}
                  onChange={event => this.profileUpload(event)}
                />
              </Grid>
              <Grid style={{ padding: 5 }}>
                <Paper>
                  <TextField
                    style={{ width: "100%" }}
                    id="expireDate"
                    label="Select Expiry Date"
                    type="date"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={event => this.handleDateChange(event)}
                  />
                </Paper>
              </Grid>
            </Grid>
            <Grid item md={6} style={{ padding: "20px" }}>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                Upload Image
              </Typography>
              <Typography variant="display4" style={{ fontSize: "15px" }}>
                We recommend uploading a photo
              </Typography>
            </Grid>
          </Grid>
          <Grid md={12} align="right" style={{}}>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              className={classes.margin}
              style={{ margin: "0 5px" }}
              onClick={() => this.addDocumentImage()}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      );
    }
  }
}

export default withStyles(DriverProfileStyles)(Edit);

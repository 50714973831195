// import { getTheme } from "../../../../managers/theme/ThemeManager";
// import { PADDING, MARGIN } from "../../../../values/Typography";

const styles = theme => {
  // const configTheme = getTheme();

  const drawerWidth = 300;

  return {
    root: {
      width: "100%",
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    nested: {
      paddingLeft: theme.spacing.unit * 4
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      margin: -10,
      // marginTop: "64px",
      [theme.breakpoints.down("sm")]: {
        // width: `calc(100% - ${drawerWidth}px)`
        // marginTop: "59px",
      }
    },
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing.unit * 3
    },
    textWhite: {
      color: "#fff !important"
    },
    click_to_activate: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexdirection: "row"
      }
    },
    OtpText: {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        marginTop: "0px !important"
      }
    },
    OtpSend: {
      width: "40%",
      fontWeight: "800",
      backgroundColor: "#4990E2 !important",
      color: "#fff",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    }
    // TabCont:
    // {
    //   paddingTop: "30px !important"
    // }
  };
};

export default styles;

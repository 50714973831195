// import { getTheme } from "../../../../managers/theme/ThemeManager";
// import { PADDING, MARGIN } from "../../../../values/Typography";

const styles = theme => {
  // const configTheme = getTheme();

  const drawerWidth = 300;

  return {
    root: {
      width: "100%",
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    nested: {
      paddingLeft: theme.spacing.unit * 4
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      margin: -10,
      // marginTop: "64px",
      [theme.breakpoints.down("sm")]: {
        // width: `calc(100% - ${drawerWidth}px)`
        // marginTop: "59px",
      }
    },
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing.unit * 3
    },
    textWhite: {
      color: "#fff !important"
    },
    click_to_activate: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexdirection: "row"
      }
    },
    Statustab: {
      [theme.breakpoints.down("sm")]: {
        fontSize: 10
      }
    },
    TabHead: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "10px"
      }
    },
    btnText: {
      color: "#fff",
      fontWeight: "900",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        fontWeight: "500"
      }
    },
    EditPhone: {
      "&:hover": {
        textDecoration: "underline"
      }
    }
    // TabCont:
    // {
    //   paddingTop: "30px !important"
    // }
  };
};

export default styles;

// import { getTheme } from "../../../managers/theme/ThemeManager";
import { CUSTOM_FONT } from "../../../values/Typography";

const styles = theme => {
  // const configTheme = getTheme();

  return {
    // selectOption:{
    //   width:'50%'
    // },
    selectContainer: {
      marginTop: 1
    },

    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    textField: {
      // width:300,
      height: 40
    },
    dense: {
      marginTop: 16
    },
    menu: {
      width: 200
    },
    suggestions: {
      border: "1px solid #999",
      borderTopWidth: 0,
      listStyle: "none",
      marginTop: 0,
      // maxHeight: '143px',
      overflowY: "hidden",
      paddingLeft: 0,
      width: 250,
      backgroundColor: "white"
    },
    formControl: {
      margin: theme.spacing.unit,
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing.unit * 2
    },
    areaItem: {
      fontFamily: CUSTOM_FONT,
      fontSize: 14
    },
    dropContainer: {
      width: 300,
      marginTop: 10,
      height: 40
    },
    textContainer: {
      fontFamily: CUSTOM_FONT,
      width: 100
    },
    loginTitle: {
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14
      }
    },
    AddVehicleSelect: {
      [theme.breakpoints.down("sm")]: {
        padding: 0
      }
    }
  };
};

export default styles;

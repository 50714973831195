import { getTheme } from "../../../../managers/theme/ThemeManager";
import {
  PADDING,
  MARGIN,
  CUSTOM_FONT,
  CUSTOM_COLOUR,
  CUSTOM_DRIVER_COLOUR
} from "../../../../values/Typography";

const styles = theme => {
  const configTheme = getTheme();

  return {
    card: {
      minWidth: 275,
      height: 400
    },

    root: {
      flexGrow: 1,
      // backgroundColor:'red',
      "&$tabSelected": {
        color: "red",
        fontWeight: "bold" //theme.typography.fontWeightMedium,
      }
    },

    tabRoot: {
      textTransform: "initial",
      fontFamily: CUSTOM_FONT,
      fontSize: 22,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14
      },
      "&$tabSelected": {
        fontWeight: "bold" //theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: "black"
      }
    },
    tabSelected: {},
    tabStyle: {
      textTransform: "none",
      fontFamily: CUSTOM_FONT,
      fontSize: 22
    },
    tabsIndicator: {
      backgroundColor: "black"
    },
    listItem: {
      fontFamily: CUSTOM_FONT,
      fontSize: 22
    },
    SignupButton: {
      backgroundColor: CUSTOM_DRIVER_COLOUR,
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      textTransform: "none",
      marginBottom: 30,
      marginTop: 30,
      color: "white",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14
      }
    },
    nextButton: {
      backgroundColor: "#23669B",
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      textTransform: "none",
      color: "white",
      fontWeight: "bold",
      height: "70px"
    },
    servicesButton: {
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      textTransform: "none",
      width: "35%",
      marginBottom: "5px",
      marginTop: "5px",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,

        width: "100%"
      }
      //  marginLeft:theme.spacing.unit*4
    },
    detailFields: {
      height: 60,
      marginTop: theme.spacing.unit * 4,
      marginBottom: theme.spacing.unit * 4
    },
    cardDetailFields: {
      height: "80px"
    },
    materialFieldStyle: {
      fontSize: 12,
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold"
    },
    sectionHead: {
      marginTop: "3%",
      backgroundColor: "silver",
      paddingLeft: 10,
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14
      }
    },
    serviceContainer: {
      marginTop: "3%",
      marginRight: "5%",
      marginLeft: "5%",
      alignItems: "center"
    },
    itemSet: {
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      marginRight: theme.spacing.unit
    },
    uploadImageLabel: {
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      // paddingRight: theme.spacing.unit * 13,
      // paddingLeft: theme.spacing.unit * 13,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        paddingRight: theme.spacing.unit * 1,
        paddingLeft: theme.spacing.unit * 1
      }
    },
    avatarContainer: {
      backgroundColor: "silver",
      border: "1px solid gray",
      borderRadius: 5,
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingLeft: theme.spacing.unit
      //height:'30vh'
    },
    avatarStyle: {
      height: "15vh",
      width: "10vw"
    },
    formControl: {
      margin: theme.spacing.unit,
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing.unit * 2
    },
    areaItem: {
      fontFamily: CUSTOM_FONT,
      fontSize: 14
    },
    loginTitle: {
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      marginTop: 20
    },
    ownerDetailStyle: {
      fontFamily: CUSTOM_FONT,
      fontSize: "1.3rem",
      fontWeight: "bold"
    },
    multiRoot: {
      display: "flex",
      flexWrap: "wrap"
    },
    multiFormControl: {
      margin: theme.spacing.unit,
      minWidth: 120,
      maxWidth: 300
    },
    chips: {
      display: "flex",
      flexWrap: "wrap"
    },
    chip: {
      margin: theme.spacing.unit / 4
    },
    noLabel: {
      marginTop: theme.spacing.unit * 3
    },
    addDriverVehicleBtn: {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        padding: "12px 12px 12px 0px"
      }
    },
    optionsContainer: {
      width: "40%",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    detailsContainer: {
      justifyContent: "flex-start",
      width: "50%"
    },
    selectContainer: {
      marginTop: 1
    },

    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    textField: {
      // width:300,
      height: 40
    },
    dense: {
      marginTop: 16
    },
    menu: {
      width: 200
    },
    suggestions: {
      border: "1px solid #999",
      borderTopWidth: 0,
      listStyle: "none",
      marginTop: 0,
      // maxHeight: '143px',
      overflowY: "hidden",
      paddingLeft: 0,
      width: 250,
      backgroundColor: "white"
    },
    formControl: {
      margin: theme.spacing.unit,
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing.unit * 2
    },
    areaItem: {
      fontFamily: CUSTOM_FONT,
      fontSize: 14
    },
    dropContainer: {
      width: 300,
      marginTop: 10,
      height: 40
    },
    textContainer: {
      fontFamily: CUSTOM_FONT,
      width: 100
    },

    detailroot: {
      display: "flex",
      flexWrap: "wrap"
    },
    detailTitle: {
      fontFamily: CUSTOM_FONT,
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14
      }
    }
  };
};

export default styles;

import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Grid,
  TextField,
  Button,
  MenuItem,
  Select,
  IconButton,
} from "@material-ui/core";
import { ForgotPasswordStyles } from "../../../styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { APPORIO_TAXI_APIS } from "../../../../managers/api/ApiEndPoints";
import * as ApiManager from "../../../../managers/api/ApiManager";
import EnterOtp from "./EnterOtp";
import EnterNewPass from "./EnterNewPass";
import Icon from "@material-ui/core/Icon";
// import DriverTextField from "../common/DriverTextField";
import CustomLoader from "../../../custom/CustomLoader";
import { MAX_CHARACTERS, PHONE_REGEX } from "../../../../values/Typography";
import DriverTextField from "../../driver/common/DriverTextField";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
        textAlign: "left",
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class GetOtp extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    value: 0,
    checked: false,
    drivername: "",
    number: "",
    open: false,
    openList: false,
    changePassword: false,
    mainContent: true,
    phonecode: this.props.configData.countries[0].phonecode,
    country_code: this.props.configData.countries,
    maxNum: this.props.configData.countries[0].maxNumPhone,
    minNum: this.props.configData.countries[0].minNumPhone,

    phoneNumber: "",
    user_name: "",
    disableButton: true,
    buttonColor: "silver",
    enterNewPass: "",
  };

  fetchPhoneNumber(result) {
    this.setState({ phoneNumber: result });
  }

  handlePhoneCode = (event) => {
    let index = this.state.country_code.findIndex(
      (res) => res.phonecode === event.target.value
    );
    this.setState({
      maxNum: this.state.country_code[index].maxNumPhone,
      minNum: this.state.country_code[index].minNumPhone,
      phonecode: event.target.value,
    });
  };

  handleSwitch = () => {
    this.setState((state) => ({ checked: !state.checked }));
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleInputChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  getOtp = () => {
    console.log(
      "**************GET_OTP_USER***********",
      APPORIO_TAXI_APIS.GET_OTP
    );

    this.setState({
      loading: true,
    });
    ApiManager.callPostApi("GET_OTP", "", APPORIO_TAXI_APIS.GET_OTP, {
      type: "2",
      for: this.props.configData.login.email ? "EMAIL" : "PHONE",
      user_name: this.state.phoneNumber
        ? this.state.phonecode + this.state.phoneNumber
        : this.state.driverEmail,
    })

      .then((response) => {
        console.log("*****response*******", response);
        // alert(JSON.stringify(response))
        switch (parseInt(response.result)) {
          case 1:
            this.setState(
              {
                OTP_ROW: response.data,
                loading: false,
                enterotp: true,
                mainContent: false,
              },
              () => {
                this.props.openSnack(response.message, "success");
              }
            );
            break;

          case 0:
            this.setState(
              {
                OTP_ROW: response.data,
                loading: false,
                enterotp: false,
                mainContent: true,
              },
              () => {
                this.props.openSnack(response.message, "warning");
              }
            );
            break;
        }

        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  disableOTPButton() {
    // (this.state.phoneNumber.length==10)?false:true
    //console.log("***>>>", this.props.configData);
    if (this.props.configData.login.email) {
      return "flex";
    } else {
      if (
        this.state.phoneNumber.length >=
        // this.props.configData.countries[0].maxNumPhone
        this.state.minNum
      ) {
        // if (PHONE_REGEX.test(this.state.phoneNumber)) {
        if (this.state.phoneNumber) {
          return "flex";
        } else {
          return "none";
        }
      } else {
        return "none";
      }
    }
  }

  fetchDriverEmail(result) {
    this.setState({ driverEmail: result });
  }

  render() {
    console.log("driver home render", this.props);
    const { classes } = this.props;
    const { value } = this.state;
    const { checked } = this.state;

    if (this.state.loading) {
      return <CustomLoader showLoader={this.state.loading} />;
    } else {
      console.log("driver home render", this.props.configData);
      return (
        <div style={{ height: "100%" }}>
          {this.state.mainContent && (
            <div>
              <Typography
                variant="h5"
                style={{ color: "#26699d", marginTop: "10px" }}
              >
                Forgot Password
              </Typography>

              {/* {this.props.configData.login.email && (
                <DriverTextField
                  type={"email"}
                  placeHolder="Email"
                  iconName="email"
                  callback={this.fetchDriverEmail.bind(this)}
                />
              )} */}

              {this.props.configData.login.phone && (
                <div>
                  <Grid
                    container
                    xl
                    justify="flex-start"
                    alignItems="center"
                    spacing={8}
                    style={{ marginTop: "10px" }}
                  >
                    <Grid item xl>
                      <Select
                        value={this.state.phonecode}
                        onChange={(event) => this.handlePhoneCode(event)}
                        inputProps={{
                          name: "phonecode",
                          id: "prefix-simple",
                        }}
                      >
                        {this.props.configData.countries.map((country) => {
                          return (
                            <MenuItem value={country.phonecode}>
                              {country.phonecode}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>

                    <Grid item>
                      {/* <DriverTextField
                        placeHolder="Mobile number"
                        // iconName="assignment_ind"
                        callback={this.fetchPhoneNumber.bind(this)}
                      /> */}
                      <input
                        type="tel"
                        // maxlength={
                        //   this.props.configData.countries[0].maxNumPhone
                        // }
                        maxlength={this.state.maxNum}
                        className={classes.textFeild}
                        onChange={(evt) =>
                          this.setState({ phoneNumber: evt.target.value })
                        }
                        placeholder="Phone Number"
                        style={{
                          width: "auto",
                          borderBottom: "1px solid silver",
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Typography
                    variant="body2"
                    style={{ fontWeight: 600, marginTop: "40px" }}
                  >
                    Enter your phone number
                  </Typography>
                  <Typography variant="display4" style={{ fontSize: "15px" }}>
                    we will send OTP to this number
                  </Typography>
                </div>
              )}

              <Grid item style={{ marginTop: "40px" }}>
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    display: this.disableOTPButton(),
                    backgroundColor: "#4990E2",
                    color: "#fff",
                  }}
                  onClick={() => this.getOtp()}
                >
                  Send
                </Button>
              </Grid>
            </div>
          )}
          {this.state.enterotp && (
            <EnterOtp
              configData={this.props.configData}
              openSnack={this.props.openSnack}
              handleModal={this.props.handleModal}
              OTP_ROW={this.state.OTP_ROW}
              user_name={
                this.state.phoneNumber
                  ? this.state.phonecode + this.state.phoneNumber
                  : this.state.driverEmail
              }
              // onBackPress={() =>
              //   this.setState({
              //     enterotp: !this.state.enterotp,
              //     mainContent: !this.state.mainContent
              //   })
              // }
            />
          )}
        </div>
      );
    }
  }
}

export default withStyles(ForgotPasswordStyles)(GetOtp);

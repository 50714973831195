// import { getTheme } from "../../../../managers/theme/ThemeManager";
// import { PADDING, MARGIN } from "../../../../values/Typography";

const styles = theme => {
  // const configTheme = getTheme();

  const drawerWidth = 300;

  return {
    headerBar: {
      color: "white",
      fontSize: "1rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem"
      }
    },

    tabStyle: {
      fontSize: "0.8rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.6rem"
      }
    },
    godContainer: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "50px"
      }
    },
    dotContainerList: {
      marginTop: "5px"
    },
    cardPaper: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      marginRight: "5%",
      marginLeft: "5%",
      marginTop: theme.spacing.unit * 2
    },
    cardInfo: {
      display: "flex"
    },
    root: {
      display: "flex"
    },
    icon: {
      colorPrimary: "white"
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      marginLeft: drawerWidth,
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`
      }
    },
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing.unit * 3
    },
    textWhite: {
      color: "#fff !important"
    },
    cardHeader: {
      minWidth: 250,
      // paddingRight: theme.spacing.unit * 5,
      // paddingLeft: theme.spacing.unit * 5,
      backgroundColor: "rgba(0,0,0,0.7)"
      // paddingTop: theme.spacing.unit * 2,
      // paddingBottom: theme.spacing.unit * 2,
      // [theme.breakpoints.down('sm')]:
      // {
      //   paddingRight:null,
      //   paddingLeft: null,
      //   paddingTop:null,
      //   paddingBottom: null,
      // }
    },
    topRightButton: {
      color: "#13A7F7",
      // fontSize: 18,
      textTransform: "none",
      fontSize: "1rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.6rem"
      }
    },
    AddMoney: {
      margin: "0 5px",
      backgroundColor: "#2DCC70",
      color: "#fff",
      fontWeight: "700",
      [theme.breakpoints.down("sm")]: {
        marginTop: 0
      }
    },
    driverWalletLabel: {
      fontWeight: "600",
      color: "#26699d",
      marginTop: 10
    },
    CurrentBal: {
      color: "#2dcc70",
      fontWeight: 600,
      marginTop: 10
    },
    hrProps: {
      color: "#eaeaea"
    },
    LinearGradient: {
      backgroundImage:
        "linear-gradient(to bottom, #e8dfff, #c8b1ff, #a682ff, #8050ff, #4e00ff)"
    }
  };
};

export default styles;

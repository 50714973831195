import { getTheme } from "../../../../managers/theme/ThemeManager";
import {
  PADDING,
  MARGIN,
  CUSTOM_FONT,
  CUSTOM_COLOUR
} from "../../../../values/Typography";

const styles = theme => {
  const configTheme = getTheme();

  return {
    introTextTitle: {
      fontFamily: CUSTOM_FONT,
      align: "left",
      color: "white",
      fontSize: "3rem"
    },
    introTextDetail: {
      fontFamily: CUSTOM_FONT,
      color: "white",
      fontSize: "1rem"
    },
    SignupButton: {
      backgroundColor: CUSTOM_COLOUR,
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      textTransform: "none",
      height: "10vh",
      color: "white",
      fontWeight: "bold"
    },
    buttonContainer: {
      justify: "flex-end",
      paddingRight: theme.spacing.unit * 5
    }
  };
};

export default styles;

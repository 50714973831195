// import { getTheme } from "../../../managers/theme/ThemeManager";
import { CUSTOM_FONT } from "../../../values/Typography";

const styles = theme => {
  // const configTheme = getTheme()

  return {
    textFeild: {
      fontFamily: CUSTOM_FONT,
      fontSize: 22,
      border: "0px solid",
      outline: "none !important"
    },
    fieldContainer: {
      marginTop: 20,
      borderBottom: "1px solid silver",
      flex: 1
    },
    button: {
      textTransform: "none",
      fontSize: 22,
      fontFamily: CUSTOM_FONT,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12
      }
    },
    barLabel: {
      fontFamily: CUSTOM_FONT,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16
      }
    }
  };
};

export default styles;

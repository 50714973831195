import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { FeatureStyles } from "../../../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TAXI_IMAGE_URL } from "../../../../managers/api/ApiEndPoints";

class FeatureCard extends React.Component {
  state = {
    spacing: "16",
    container: true
  };

  render() {
    const { classes, data, mayaMode } = this.props;
    const { spacing } = this.state;

    if (mayaMode) {
      return (
        <Grid item md={4} spacing={16}>
          {/* <img className={classes.imgMaya} alt="complex" src={data.iconUrl} /> */}
          <FontAwesomeIcon
            icon={data.iconUrl}
            style={{ color: "#FF008E", width: "25%", height: "25%" }}
          />
          <Typography
            gutterBottom
            variant="subtitle"
            style={{ marginTop: "10%" }}
            className={classes.featureTitleMaya}
          >
            {data.title}
          </Typography>
          <Typography variant="body1" className={classes.featureTextMaya}>
            {data.description}
          </Typography>
        </Grid>
      );
    }

    return (
      <Grid item md={4}>
        <Grid container spacing={16}>
          <Grid item xs={3}>
            {/* <ButtonBase className={classes.image}> */}
            {/* <img className={classes.img} alt="" src={data.iconUrl} /> */}

            {/* </ButtonBase> */}
          </Grid>
          <Grid item xs={9} container>
            <Grid container direction="column" spacing={16}>
              <Grid item xs className={classes.textContainer}>
                <Typography
                  gutterBottom
                  variant="h6"
                  align="left"
                  className={classes.featureTitle}
                >
                  {data.title}
                </Typography>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  align="left"
                  className={classes.featureText}
                >
                  {data.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
FeatureCard.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(FeatureStyles)(FeatureCard);

import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import { HomeStyles } from "../../../styles";
import { AppBar, Select, MenuItem } from "@material-ui/core";
import * as LanguageManager from "../../../../managers/language/LanguageManager";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import EventBus from "eventing-bus";
import CustomLoader from "../../../custom/CustomLoader";
import { CUSTOM_FONT } from "../../../../values/Typography";

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)

class HomeHeader extends React.Component {
  state = {
    showBorder: null,
    showBorder1: null,
    isLoginRedirect: false,
    isDriverDashBorad: false,
    isBookingRedirect: false,
    locale: SessionManager.getSessionData(SessionKeys.LANGUAGE_LOCALE)
      ? SessionManager.getSessionData(SessionKeys.LANGUAGE_LOCALE)
      : this.props.userConfig[0].locale,
    open: false,
    isLoading: false,
    redirectTo: null
  };

  handleChange = event => {
    this.setState(
      { [event.target.name]: event.target.value, isLoading: true },
      () => {
        LanguageManager.saveLanguage(event.target.value);
        SessionManager.saveSessionData(
          SessionKeys.LANGUAGE_LOCALE,
          event.target.value
        );
        this.setState({
          isLoading: false,
          redirectTo: window.location.pathname
        });
      }
    );
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  redirectToDriverDashBorad = () => {
    if (this.state.isDriverDashBorad) {
      return this.props.history.push("/driver/home");
    }
  };
  redirectToLogin = () => {
    if (this.state.isLoginRedirect) {
      return this.props.history.push("/user_login");
    }
  };

  redirectToBooking = () => {
    if (this.state.isBookingRedirect) {
      return this.props.history.push("/user_booking");
    }
  };

  render() {
    console.log("propsLog", this.props);
    const { languages, classes } = this.props;

    if (this.state.redirectTo) {
      window.location = this.state.redirectTo;
    }

    return (
      <AppBar
        elevation={2}
        style={{ backgroundColor: "white" }}
        position="static"
      >
        <CustomLoader showLoader={this.state.isLoading} />
        <Grid container className={classes.headerContainer}>
          {this.redirectToDriverDashBorad()}
          {this.redirectToLogin()}
          {this.redirectToBooking()}

          <CardMedia className={classes.unitLogo} image={this.props.appLogo} />

          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <Select
              style={{ fontFamily: CUSTOM_FONT }}
              open={this.state.open}
              onClose={this.handleClose}
              onOpen={this.handleOpen}
              value={this.state.locale}
              onChange={event => this.handleChange(event)}
              inputProps={{
                name: "locale",
                id: "demo-controlled-open-select"
              }}
            >
              {this.props.userConfig.map((item, i) => (
                <MenuItem
                  key={i}
                  style={{ fontFamily: CUSTOM_FONT }}
                  value={item.locale}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>

            {SessionManager.getSessionData(SessionKeys.ACCESS_TOKEN) ? (
              <Button
                onClick={() =>
                  this.setState({ isBookingRedirect: true }, () =>
                    EventBus.publish("SET_HOME_ACTIVE", "1")
                  )
                }
                className={classes.resumeButton}
              >
                Go back to booking
              </Button>
            ) : null}

            <Button
              onClick={() => this.setState({ isDriverDashBorad: true })}
              variant={this.state.showBorder1}
              className={classes.headerLink}
              onMouseOver={() => {
                this.setState({ showBorder1: "outlined" });
              }}
              onMouseOut={() => {
                this.setState({ showBorder1: null });
              }}
            >
              {LanguageManager.Strings.NAVIGATE_TO_DRIVER_BTN}
            </Button>
            <div className={classes.headerButtonView}>
              <Button
                onClick={() => this.setState({ isLoginRedirect: true })}
                className={classes.headerButton}
              >
                {LanguageManager.Strings.LOGIN_BTN}
              </Button>
            </div>
          </Grid>
        </Grid>
      </AppBar>
    );
  }
}

HomeHeader.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(HomeStyles)(withRouter(HomeHeader));

import React from "react";
import { CardActionArea, Typography } from "@material-ui/core";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    // paddingTop: "5px",
    // paddingBottom: "5px",
    alignSelf: "center",
    // borderRadius: 5,
    display: "flex"
    //backgroundColor: "red"
    //flexDirection: "row"
  },
  colName: {
    alignSelf: "center",
    color: "black"
  },
  sortIcon: {
    width: "10px",
    height: "10px",
    marginLeft: "5px",

    transition: "all .3s ease-out"
  }
});

const TableHeaderColumn = props => {
  const { classes } = props;
  return (
    <CardActionArea
      className={classes.root}
      onClick={() => props.onClick(props.index)}
    >
      <Typography
        className={classes.colName}
        style={{
          color: props.tableHeadFontColor,
          fontSize: props.tableheadFontSize ? props.tableheadFontSize : "16px"
        }}
        noWrap={true}
      >
        {props.data.toUpperCase()}
      </Typography>
      {/* {props.index === 0 ? (
        <img
          src={""}
          alt={"icon"}
          className={classes.sortIcon}
          style={{ transform: props.sorted ? null : "rotate(180deg)" }}
        />
      ) : null} */}
    </CardActionArea>
  );
};

TableHeaderColumn.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TableHeaderColumn);

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Paper from "@material-ui/core/Paper";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { FeatureStyles } from "../../../styles";
import FeatureCard from "./featureCard";

import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiTags from "../../../../managers/api/ApiTags";
import { DRIVER_APIS } from "../../../../managers/api/ApiEndPoints";
import { saveSessionData } from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";

class FeaturesGrid extends React.Component {
  state = {
    spacing: "16",
    featureData: []
  };

  // componentDidMount() {
  //   ApiManager.callGetApi(
  //     'App',
  //     ApiTags.CONFIG_API,
  //     DRIVER_APIS.DRIVER_APP_FEATURES_API_URL
  //   )

  //     .then((response) => {
  //       console.log("%%%%%" + JSON.stringify(response))
  //       switch (parseInt(response.result)) {

  //         case 0:
  //           console.log('config api error')
  //           break;

  //         case 1:
  //           this.setState({
  //             featureData: response.response
  //           })
  //           break

  //         default:
  //           break;
  //       }
  //     })

  //     .catch((error) => {
  //       console.log(error)
  //     })
  // }

  render() {
    const { classes, data, mayaMode } = this.props;
    const { spacing } = this.state;

    if (mayaMode) {
      return (
        <div className={classes.root} style={{ marginTop: "5%" }}>
          <Grid container style={{ paddingLeft: "15%", paddingRight: "15%" }}>
            {this.props.data.map((row, index) => (
              <FeatureCard data={row} mayaMode={true} />
            ))}
          </Grid>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <Grid container style={{ padding: 0 }} spacing={16}>
          {/* {this.props.data.map((row, index) => ( */}
          {this.props.DRIVER_LIST.map((row, index) => (
            <FeatureCard key={index} data={row} />
          ))}
        </Grid>
      </div>
    );
  }
}

FeaturesGrid.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(FeatureStyles)(FeaturesGrid);

// import { getTheme } from "../../../../managers/theme/ThemeManager";
import { CUSTOM_FONT } from "../../../../values/Typography";

const styles = theme => {
  // const configTheme = getTheme()

  return {
    root: {
      flexGrow: 1,
      paddingRight: theme.spacing.unit * 1,
      paddingLeft: theme.spacing.unit * 1,
      marginTop: theme.spacing.unit * 5,
      marginBottom: theme.spacing.unit * 5
      //  backgroundColor:'gray'
    },
    imageBox: {
      "max-height": "500px",
      overflow: "hidden",
      "border-radius": "20px"
    },
    rootCard: {
      backgroundColor: "red",
      width: "60%"
    },
    tileImage: {
      width: 100,
      height: 50
    },
    textView: {
      justify: "flex-start",
      alignItems: "center",
      flex: 5
    },
    featureText: {
      fontSize: "1rem",
      fontFamily: CUSTOM_FONT,
      [theme.breakpoints.up("sm")]: {
        width: 320
      }
    },
    featureTitle: {
      fontSize: "2rem",
      fontWeight: "bold",
      fontFamily: CUSTOM_FONT
    },
    featureContainer: {
      flex: 1,
      justify: "flex-start",
      alignItems: "center",
      marginRight: theme.spacing.unit * 1,
      marginLeft: theme.spacing.unit * 1,
      padding: theme.spacing.unit
    },
    featureVectorView: {
      flex: 2,
      justify: "center",
      alignItems: "center"
    },
    paper: {
      padding: theme.spacing.unit * 2,
      margin: "auto",
      maxWidth: 500
    },
    image: {
      width: 128,
      height: 128
    },
    img: {
      margin: "auto",
      display: "block",
      width: "50%",
      height: "50%"
    },
    textContainer: {
      paddingLeft: theme.spacing.unit * 1,
      paddingRight: theme.spacing.unit * 1
    },
    featureTitleMaya: {
      fontSize: 20
    },
    featureTextMaya: {
      fontSize: 14,
      paddingLeft: "10%",
      paddingRight: "10%"
    }
  };
};

export default styles;

// import { getTheme } from "../../../managers/theme/ThemeManager";
// import { PADDING, MARGIN } from "../../../values/Typography";

const styles = theme => {
  // const configTheme = getTheme();

  return {
    root: {
      display: "flex"
    },
    MapBookImage: {
      width: "100%",
      backgroundSize: "100%",
      [theme.breakpoints.down("sm")]: {
        display: "none !important"
      }
    },
    headerButtonView: {
      fontSize: 12,
      borderRadius: 5,
      width: "100%",
      paddingRight: "1%",
      paddingLeft: "1%"
    },
    headerButton: {
      color: "white",
      fontSize: 12,
      fontFamily: "Google Sans",
      width: "100%"
    },
    WidthDialoginner: {
      [theme.breakpoints.down("sm")]: {
        width: "90vw !important"
      }
    }
  };
};

export default styles;

import React, { Component } from "react";
import {
  Paper,
  Grid,
  Typography,
  Button,
  Avatar,
  CardMedia,
  withStyles
} from "@material-ui/core";
import { ManageTripStyles } from "../../../styles";
import StarRate from "@material-ui/icons/StarRate";

class Activetrips extends Component {
  getMapSizeImage(image) {
    let image1 = image.split("&size");
    return image1[0] + "&size=400x200";
  }

  componentDidMount() {
    console.log("JIJI" + JSON.stringify(this.props.data));
  }
  render() {
    const { classes } = this.props;

    return (
      <Paper
        onClick={() =>
          this.props.openSpecificDialog(this.props.data.booking_id)
        }
        style={{
          display: "flex",
          width: "100%",
          padding: "1%",

          marginTop: "1%",
          flex: 1
        }}
      >
        <Grid container xs={12}>
          <Grid item xs={6} style={{ padding: "1%" }}>
            <Grid
              container
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Grid item>
                <Typography
                  className={classes.SmFont}
                  style={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: "#00649A"
                  }}
                >
                  {/* {`RIDE ID: #${this.props.data.booking_id}`} */}
                  RIDE ID{"  "}
                  {this.props.data.highlighted_small_text
                    ? this.props.data.highlighted_small_text
                    : ""}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={classes.SmFont}
                  style={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: "#00649A"
                  }}
                >
                  {this.props.data.highlighted_text}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  className={classes.BtFont}
                  style={{
                    borderRadius: 18,
                    backgroundColor: "#1F447F",
                    color: "white",
                    fontSize: "0.6rem",
                    fontWeight: "bold"
                  }}
                >
                  {this.props.data.status_text}
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              {this.props.data.small_text && (
                <Typography
                  style={{ fontSize: "0.8rem", color: "#279AD3" }}
                  className={classes.SmFont}
                >
                  {`Booking Time: ${this.props.data.small_text}`}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} style={{ marginTop: "2%" }}>
              {this.props.data.driver_block_visibility &&
                this.props.data.driver_name && (
                  <Grid container>
                    <Typography
                      style={{ fontSize: "0.8rem", fontWeight: "400" }}
                    >
                      Riding With
                    </Typography>
                    <Grid item md={3} xs={6}>
                      <Avatar
                        src={
                          this.props.data.circular_image
                            ? this.props.data.circular_image
                            : this.props.data.driver_image
                        }
                        style={{ width: 60, height: 60 }}
                      />
                    </Grid>

                    <Grid item md={3} xs={6}>
                      <Typography
                        style={{ fontSize: "0.8rem" }}
                        className={classes.SmFont}
                      >
                        {this.props.data.driver_name}
                      </Typography>
                      <Typography
                        style={{ fontSize: "0.8rem" }}
                        className={classes.SmFont}
                      >
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            textAlign: "center"
                          }}
                        >
                          {this.props.data.driver_rating} <StarRate />
                        </Grid>
                      </Typography>
                      <Typography
                        style={{ fontSize: "0.8rem", color: "#279AD3" }}
                      >
                        {/* +919991617237 */}
                      </Typography>
                    </Grid>
                    <Grid style={{}} />
                  </Grid>
                )}
            </Grid>
          </Grid>
          <Grid container xs={6} style={{ padding: "1%" }}>
            <Grid
              md={6}
              xs={12}
              className={classes.PickdropHeight}
              style={{
                justifyContent: "center",
                height: "100%"
              }}
            >
              <Grid
                xs={12}
                className={classes.PickdropOutter}
                style={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",

                  justifyContent: "center"
                }}
              >
                {this.props.data.pick_visibility && (
                  <div
                    style={{
                      display: "flex",

                      marginTop: "2%",
                      marginBottom: "2%"
                    }}
                  >
                    <Grid
                      style={{
                        minWidth: 8,
                        maxWidth: 8,
                        minHeight: 40,
                        maxHeight: 60,
                        borderRadius: 30,
                        backgroundColor: "#40D590"
                      }}
                    />
                    <Typography
                      className={classes.pickClass}
                      style={{
                        fontSize: "0.8rem",
                        marginLeft: "2%",
                        textAlign: "left"
                      }}
                    >
                      {this.props.data.pick_text}
                    </Typography>
                  </div>
                )}
                {this.props.data.drop_visibility && (
                  <div
                    style={{
                      display: "flex",
                      marginTop: "2%",
                      marginBottom: "2%"
                    }}
                  >
                    <div
                      style={{
                        minWidth: 8,
                        maxWidth: 8,
                        minHeight: 40,
                        maxHeight: 60,
                        borderRadius: 30,

                        backgroundColor: "red"
                      }}
                    />
                    <Typography
                      className={classes.pickClass}
                      style={{
                        fontSize: "0.8rem",
                        marginLeft: "2%",

                        textAlign: "left"
                      }}
                    >
                      {this.props.data.drop_location}
                    </Typography>
                  </div>
                )}
              </Grid>
            </Grid>

            {this.props.data.map_image_visibility && (
              <Grid item xs={6} style={{ height: "100%" }}>
                <CardMedia
                  className={classes.imageMap}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: 5
                  }}
                  image={this.getMapSizeImage(this.props.data.map_image)}
                  // image={require("../../../../assets/images/staticmap.png")}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
export default withStyles(ManageTripStyles)(Activetrips);

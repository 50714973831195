import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { TextField, Tooltip, Typography } from "@material-ui/core";

const styles = theme => ({
  button: {
    display: "block"
    //  marginTop: theme.spacing(2)
  },
  formControl: {
    //margin: theme.spacing(1),
    minWidth: "100%"
  }
});

class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}
  getValByProp(obj, propString) {
    if (!propString) return obj;

    var prop,
      props = propString.split(".");

    for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
      prop = props[i];

      var candidate = obj[prop];
      if (candidate !== undefined) {
        obj = candidate;
      } else {
        break;
      }
    }
    return obj[props[i]];
  }

  render() {
    const {
      placeholder,
      selectedItem,
      listFieldName,
      objFieldName,
      data,
      onSelect,
      disabled,
      classes,
      hoverEnable,
      hoverText
    } = this.props;
    const isHover = hoverEnable !== null ? hoverEnable : false;
    const dropDownList = data.map(obj => {
      return {
        value: listFieldName ? obj[listFieldName] : obj,
        label: listFieldName ? obj[listFieldName] : obj
      };
    });

    const val = selectedItem
      ? typeof selectedItem == "object"
        ? this.getValByProp(selectedItem, objFieldName)
        : selectedItem
      : "";

    return (
      <Tooltip
        title={hoverText ? <Typography>{hoverText}</Typography> : ""}
        disableHoverListener={!isHover}
      >
        <TextField
          id="standard-select"
          select
          fullWidth
          label={placeholder}
          disabled={disabled}
          value={val}
          onChange={event => {
            if (listFieldName) {
              let item = data.find(
                obj => obj[listFieldName] == event.target.value
              );
              onSelect(item);
            } else {
              onSelect(event.target.value);
            }
          }}
          style={{ marginTop: "-3px" }}
          // helperText="Please select your product"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {dropDownList.map((option, i) => (
            <MenuItem key={i} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Tooltip>
    );
  }
}

export default withStyles(styles)(DropDown);

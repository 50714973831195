// import { getTheme } from "../../../../managers/theme/ThemeManager";
import { CUSTOM_FONT } from "../../../../values/Typography";

const styles = theme => {
  // const configTheme = getTheme()

  return {
    root: {
      flexGrow: 1,
      // backgroundColor:'red',
      "&$tabSelected": {
        color: "red",
        fontWeight: "bold" //theme.typography.fontWeightMedium,
      }
    },

    tabRoot: {
      textTransform: "initial",
      fontFamily: CUSTOM_FONT,
      fontSize: 22,
      "&$tabSelected": {
        fontWeight: "bold" //theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: "black"
      }
    },
    tabSelected: {},
    tabStyle: {
      textTransform: "none",
      fontFamily: CUSTOM_FONT,
      fontSize: 22
    },
    tabsIndicator: {
      backgroundColor: "black"
    },
    listItem: {
      fontFamily: CUSTOM_FONT,
      fontSize: 22
    }
  };
};

export default styles;

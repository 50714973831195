import React, { Component } from "react";
import { Grid, Avatar, Typography } from "@material-ui/core";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import * as ApiManager from "../../../../managers/api/ApiManager";

class ShowFavDriver extends Component {
  state = {
    driverList: []
  };
  componentDidMount() {
    ApiManager.callPostApi(
      "ShowFavDriver",
      "fav driver list",
      ApiEndPoints.APPORIO_TAXI_APIS.SELECT_FAV_DRIVER,
      {
        checkout_id: this.props.checkoutId
      }
    )
      .then(res => this.setState({ driverList: res.data }))
      .catch(err => alert(JSON.stringify(err)));
  }
  render() {
    return (
      <div style={{ width: "30vw", height: "70vh", overflowY: "auto" }}>
        {this.state.driverList.length === 0 ? (
          <div
            style={{
              justifyContent: "center",

              width: "100%",
              height: "100%"
            }}
          >
            <Typography style={{ fontSize: 16 }}>
              No Favourite Driver{" "}
            </Typography>
          </div>
        ) : (
          this.state.driverList.map(val => (
            <Grid container xs={12} style={{ margin: "2%" }}>
              <Avatar
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxLifYX-t0LoqI6p0VGAmJAvZaTaRnwLeh5rfymHAluSWMHPqs"
                style={{ width: 60, height: 60 }}
              />
              <Grid item>
                <Typography style={{ fontSize: 12 }}>Manish Sharma </Typography>
                <Typography style={{ fontSize: 12 }}>
                  manishShat@gmail.com
                </Typography>
                <Typography style={{ fontSize: 12 }}>+919991617237 </Typography>
              </Grid>
            </Grid>
          ))
        )}
      </div>
    );
  }
}

export default ShowFavDriver;

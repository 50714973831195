import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Grid,
  Fab,
  CardMedia,
  Icon,
  TextField,
  Button,
  Paper,
  Typography,
  IconButton,
  Card
} from "@material-ui/core";
import { ManageTripStyles, DriverWalletStyles } from "../../../styles";
import ArrowBack from "@material-ui/icons/ArrowBack";
import AddMoneyCard from "./AddMoneyCard";
import {APPORIO_TAXI_APIS} from '../../../../managers/api/ApiEndPoints';
import * as ApiManager from "../../../../managers/api/ApiManager";
import CustomLoader from "../../../custom/CustomLoader";
import CustomSnackbar from "../../../custom/CustomSnackbar";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
        textAlign: "left",
      }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class CardsList extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    value: 0,
    checked: false,
    money: "",
    isLoading:false,
    showSnackbar: false,
    snackbarVariant: "error",
    snakBarMessage: "",
    transactionLog:[],
    showAddMoney:false,
    disableButton:true,
    openCCForm:false,
    openDCForm:false,
    addNewCard:false,
    cardsList:[]
  };

componentDidMount(){
  this.fetchAllCards();
}

getResponse(result){
  if(result){
    this.setState({addNewCard:false},()=>{
      this.fetchAllCards();
    })
  }}

    fetchAllCards(){
    this.setState({ isLoading: true });
    ApiManager.callPostApi(
      "UserWallet  Component",
      "UserCards API",
      APPORIO_TAXI_APIS.USER_CARDS,
      {
        payment_option:"STRIPE"
      }
    )
      .then(res => {
        if (res.result === "1") {
          console.log("@0@0@0@0"+JSON.stringify(res))
          this.setState({ isLoading: false, cardsList:res.data });
        }else{
          this.setState({isLoading:false,
            snackbarVariant:'error',
          showSnackbar:true,
        snakBarMessage:res.message})
        }
      })
      .catch(err => alert(JSON.stringify(err)));
  }

  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false
    });
  };

  addMoneyToWallet(){
    this.setState({ isLoading: true });
    ApiManager.callPostApi(
      "UserCards  Component",
      "Add money to wallet API",
      APPORIO_TAXI_APIS.USER_ADDTOWALLET,
      {
        amount:this.props.addAmount
      }
    )
      .then(res => {
        if (res.result === "1") {
          console.log("#*#*#*#"+JSON.stringify(res))
          this.props.fetchCashAdded(res.data.amount)
              this.setState({isLoading:false,
              snackbarVariant:'success',
            showSnackbar:true,
          snakBarMessage:res.message},()=>this.props.dismiss())
          
        }else{
          this.setState({isLoading:false,
            snackbarVariant:'error',
          showSnackbar:true,
        snakBarMessage:res.message},()=>this.props.dismiss())
        }
      })
      .catch(err => alert(JSON.stringify(err)));
  }

  render() {
    const { classes } = this.props;
    return (
        <div>
                    <CustomLoader showLoader={this.state.isLoading} />
          <CustomSnackbar
          showSnackbar={this.state.showSnackbar}
          variant={this.state.snackbarVariant}
          message={this.state.snakBarMessage}
          onClose={this.handleSnackBarClose}
        />

          {
            (!this.state.addNewCard)?
            <Grid xl container className={classes.cardHeader} justify="space-between" alignItems="center">
            {/* <Icon style={{height:50,width:50}}>
                arrow_back
            </Icon> */}
            <IconButton  aria-label="back" onClick={()=>this.props.dismiss()}>
            <Icon style={{color:'white'}}>
                arrow_back
            </Icon>
          </IconButton>
            <Typography className={classes.headerBar}>
                Manage your cards
            </Typography>

            {/* <Typography onClick={()=>this.setState({addNewCard:true})} variant="subtitle" style={{color:'#13A7F7',fontSize:18}}>
                Add new
            </Typography> */}
            <Button className={classes.topRightButton} onClick={()=>this.setState({addNewCard:true})}>
              Add New
            </Button>
            </Grid>:
                        <Grid xl container className={classes.cardHeader} justify="space-between" alignItems="center">
                        <IconButton  aria-label="back" onClick={()=>this.setState({addNewCard:false})}>
                        <Icon style={{color:'white'}}>
                            arrow_back
                        </Icon>
                      </IconButton>
                        <Typography className={classes.headerBar}>
                            Add a new Card
                        </Typography>
            
                        <Typography variant="subtitle" style={{color:'rgba(0,0,0,0.7)',fontSize:18}}>
                          ____
                        </Typography>
                        </Grid>
          }
{
  (!this.state.addNewCard)?
         this.state.cardsList.map((item)=>(
                           <Paper xs={6} className={classes.cardPaper} elevation={1}>

            <Grid  container  justify="space-between" alignItems="center" className={classes.dotContainerList}>
            <Grid xs={1} justify="space-between"className={classes.cardInfo}>
                        <Icon className={classes.icon} color="secondary">
                            credit_card
                        </Icon>
                        <div style={{justifyContent:'flex-start'}}>
                            <Typography variant="body1" style={{color:'black'}}>
                                {item.card_number}
                            </Typography>
                            <Typography variant="body2" style={{color:'gray',fontSize:14}}>
                                {item.exp_month}/{item.exp_year}
                            </Typography>
                        </div>
                        </Grid>
      
                        <Button style={{color:'blue',fontSize:14}} onClick={()=>this.addMoneyToWallet()}>
                          Select
                        </Button>
                    </Grid>
                    </Paper>
         )):
         <AddMoneyCard cardSaveCallback={this.getResponse.bind(this)}/>
}
            
        </div>
    );
  }
}

export default withStyles(DriverWalletStyles)(CardsList);

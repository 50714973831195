// import {AddressData1,AddressData2} from '../actions/ActionTypes';

const initialState = {
  startAddress: "",
  endAddress: "",
  AddressObject: null,
  AddressObjects: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "AddressData1":
      console.log("###### reducer1 ", action.payload);

      return { ...state, AddressObject: action.payload };

    case "AddressData2":
      console.log("###### reducer2 ", action.payload);

      return { ...state, AddressObjects: action.payload };

    default:
      return state;
  }
};

import React from "react";
import { withStyles } from "@material-ui/core";
import { DriverHomeStyles } from "../../../styles";
import HomeHeader from "../common/HomeHeader";
import Hero from "./Hero";
import CardMedia from "@material-ui/core/CardMedia";
import IntroTextLayer from "../common/IntroTextLayer";
import Grid from "@material-ui/core/Grid";
import { DRIVER_HERO_IMAGE } from "../../../../values/Icons";
import { SCREEN_WIDTH } from "../../../../values/Typography";
import FeaturesGrid from "../common/featuresGrid";
import Typography from "@material-ui/core/Typography";
import TabFamily from "../common/tabFamily";
import FooterComponent from "../common/FooterComponent";
import TimelineComponent from "../common/TimelineComponent";
import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import CustomLoader from "../../../custom/CustomLoader";
import * as LanguageManager from "../../../../managers/language/LanguageManager";
import EventBus from "eventing-bus";

class DriverHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      documentData: {},
      featureData: null,
      languages: [],
      DRIVER_LIST: undefined,
      icon: ""
    };
  }

  componentDidMount() {
    EventBus.publish("HAHAHA", "1");

    this.setState({
      loading: true
    });

    ApiManager.callPostApi(
      "Driver Home",
      "Driver COnfig",
      ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_GETCONFIG,
      {
        device: 1,
        apk_version: "6.5",
        slug: "terms_and_Conditions",
        country_id: 4
      }
    )
      .then(response => {
        // console.log("response1234567890", response.data.segments[0].icon);
        SessionManager.saveSessionData(
          SessionKeys.DRIVER_CONFIG,
          JSON.stringify(response.data)
        );

        // this.fetchFeatures();
        this.setState(
          {
            loading: false,
            languages: response.data.languages
            // icon: response.data.segments[0].icon
          },
          () => this.ListApi()
        );
        // console.log("response1234567890", this.state.languages);
      })
      .catch(error => {
        console.log(error);
      });
  }

  fetchFeatures() {
    ApiManager.callGetApi(
      "App"
      // ApiEndPoints.CONFIG_API,"",
      // ApiEndPoints.DRIVER_APIS.DRIVER_APP_FEATURES_API_URL
    )

      .then(response => {
        // console.log("%%%%%" + JSON.stringify(response));
        switch (parseInt(response.result)) {
          case 0:
            console.log("config api error");
            break;

          case 1:
            this.setState({
              featureData: response.response
            });
            break;

          default:
            break;
        }
      })

      .catch(error => {
        console.log(error);
      });
  }

  ListApi() {
    this.setState({
      loading: true
    });

    ApiManager.callPostApi(
      "DriverHomeList",
      "DriverHomeList",
      ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_HOME_LISTING
    )
      .then(response => {
        SessionManager.saveSessionData(
          SessionKeys.DRIVER_BG,
          JSON.stringify(response.data.driver_login_bg_image)
        );
        // console.log("DRIVER_HOME_LISTING" + JSON.stringify(response));
        switch (parseInt(response.result)) {
          case 0:
            alert(response.message);
            break;
          case 1:
            this.setState(
              {
                // loading: false,
                DRIVER_LIST: response.data
              },
              async () => {
                const getLogo = await JSON.parse(
                  SessionManager.getSessionData(SessionKeys.DRIVER_LOGO)
                );
                this.setState({ icon: getLogo, loading: false });
              }
            );

            break;
          default:
            break;
        }
        // this.setState({
        //   loading: false,
        //   DRIVER_LIST: response.data
        // });
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const { classes } = this.props;

    if (this.state.loading) {
      return <CustomLoader showLoader={this.state.loading} />;
    } else {
      console.log("driver home languages", this.state.languages);

      return (
        <div className={classes.root}>
          {this.state.DRIVER_LIST !== undefined && (
            <div className={classes.root}>
              <HomeHeader
                icon={this.state.icon}
                location={this.props.location}
                languages={this.state.languages}
              />
              <CardMedia
                justify="center"
                image={this.state.DRIVER_LIST.header.image}
                className={classes.heroImage}
              >
                <Grid
                  container
                  xl={12}
                  className={classes.formViewContainer}
                  direction="row"
                >
                  <Grid item className={classes.farjiview}>
                    <IntroTextLayer DRIVER_LIST={this.state.DRIVER_LIST} />
                  </Grid>
                </Grid>
              </CardMedia>
              <FeaturesGrid DRIVER_LIST={this.state.DRIVER_LIST.features} />

              <Typography
                variant="h2"
                gutterBottom
                className={classes.pageHeading}
              >
                {LanguageManager.Strings.APP_WORKING_TITLE}
              </Typography>

              <Grid xl item align="center">
                {this.state.DRIVER_LIST.how_app_works != null && (
                  <TimelineComponent
                    DRIVER_LIST={this.state.DRIVER_LIST.how_app_works}
                    direction="vertical"
                  />
                )}
                {/* <TimelineComponent direction="vertical" /> */}
                {/* <Grid xs={1} style={{backgroundColor:'red',height:100}}/> */}
              </Grid>

              {/* <Typography variant="h2" gutterBottom className={classes.pageHeading}>
Documents Required.
</Typography>

<Grid container xl={12} justify="center" className={classes.TabView}>
<Grid xs={6}>
  <TabFamily tabTitleSet={["Owner Documents", "Car Documents", "Driver Documents"]} />
</Grid>
</Grid> */}
              <FooterComponent DRIVER_LIST={this.state.DRIVER_LIST.footer} />
            </div>
          )}
        </div>
      );
    }
  }
}

export default withStyles(DriverHomeStyles)(DriverHome);

//--------------------------------------------------------------------------------------------------------------------------------------------
//-----------------------------------------------------------MAYA4WOMEN-----------------------------------------------------------------------
//--------------------------------------------------------------------------------------------------------------------------------------------

// import React from 'react'
// import { withStyles } from '@material-ui/core';
// import { DriverHomeStyles } from '../../../styles';
// import HomeHeader from '../common/HomeHeader';
// import Hero from './Hero';
// import CardMedia from '@material-ui/core/CardMedia';
// import IntroTextLayer from '../common/IntroTextLayer';
// import Grid from '@material-ui/core/Grid';
// import { MAYA_USERBANNER,MAYA_FOOT } from '../../../../values/Icons';
// import { SCREEN_WIDTH } from '../../../../values/Typography';

// import FeaturesGrid from '../common/featuresGrid';
// import {Typography,ButtonBase,Icon} from '@material-ui/core/';
// import TabFamily from '../common/tabFamily';
// import FooterComponent from '../common/FooterComponent';
// import TimelineComponent from '../common/TimelineComponent';
// import { Link } from "react-router-dom";

// import * as ApiManager from "../../../../managers/api/ApiManager";
// import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
// import * as SessionManager from "../../../../managers/session/SessionManager";
// import * as SessionKeys from "../../../../managers/session/SessionKeys";
// import CustomLoader from '../../../custom/CustomLoader';
// import * as LanguageManager from '../../../../managers/language/LanguageManager'
// import EventBus from "eventing-bus";

// const featuresData= [
//   {
//     "id": 1,
//     "title": "Women Empowerment",
//     "iconUrl": "balance-scale",
//     "description": "Maya4Women aims to provide women across South Africa with an opportunity to empower themselves and be their own boss."
//   },
//   {
//     "id": 2,
//     "title": "Safe Environment",
//     "iconUrl": "umbrella",
//     "description": "Our safety measures ensure that you get to your destination safely without feeling unsafe or threatened."
//   },
//   {
//     "id": 3,
//     "title": "Value added Services",
//     "iconUrl": "handshake",
//     "description": "Maya4Women is constantly adapting to our clients and drivers needs in order to provide the best experience."
//   },
//   {
//     "id": 4,
//     "title": "Changing the future",
//     "iconUrl": "road",
//     "description": "Women entrepreneurs are the future and Maya4Women aims to help our female drivers achieve their goals."
//   },
//   {
//     "id": 5,
//     "title": "Leading Technology",
//     "iconUrl": "globe-americas",
//     "description": "Maya4Women is innovative and makes use of the best technology available and constantly adapts to change."
//   },
//   {
//     "id": 6,
//     "title": "Customer First",
//     "iconUrl": "smile",
//     "description": "We always put our customers first and aim to provide the best service in all areas and are dedicated 24/7."
//   }]

// class DriverHome extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       buttonColor1: "gold",
//       buttonColor2: "gray",
//       spacing: "16",
//       gridLayout: true,
//       rowData: [],
//       imageData: {},
//       textFieldHint: null,
//       featureBoardData: [],
//       estimateData: null,
//       featureCompData: null,
//       mayaSkin:true
//      // userBannerImage:RYDE_BANNER
//     };
//   }

//   componentDidMount() {

//     this.setState({ isLoading: true })

//     ApiManager.callGetApi(
//       "Home.js",
//       "UserConfig API",
//       ApiEndPoints.APPORIO_TAXI_APIS.MOCK_USER_API
//     )
//       .then(response => {
//         if (response.result === "1") {
//           this.setState({
//             isLoading:false,
//             userBannerImage:ApiEndPoints.TAXI_IMAGE_URL+response.data.banner_image,
//             textFieldHint:{"pick":response.data.book_form_config.start_address_hint,
//                           "drop":response.data.book_form_config.end_address_hint,
//                           "button":response.data.book_form_config.book_btn_title},
//             featureBoardData:response.data.feature_board_data,
//             estimateData:response.data.estimate_container,
//             featureCompData:response.data.features_component,
//             iosLink:response.data.ios_link,
//             androidLink:response.data.android_link
//           })
//         } else {
//           this.setState({ isLoading: false })
//           console.log("HOME CONFIG ERR:" + response.message)
//         }
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   }

//   render() {
//     const { classes } = this.props;
//     return (
//       <div style={{flex:1}}>
//         <CustomLoader showLoader={this.state.isLoading} userHome={true} />
//         <HomeHeader appLogo={this.state.appIcon} mayaMode={true} />
//         <div style={{overflowY:'scroll',height:'650px'}}>
//         <CardMedia
//           className={classes.bgImageMaya}
//           image={MAYA_USERBANNER}>
//             <div style={{justifyContent:'center',flexDirection:'column',paddingTop:'20%',paddingBottom:'20%'}}>
//       <Typography variant="h3"gutterBottom style={{color:'white'}}>
//         South Africa's first Rideshare Service by Women 4 Women!
//       </Typography>

//       <Link className="link-text" to="/driver/signup" location={this.props.location}>
//       <ButtonBase
//           focusRipple
//           style={{borderRadius:100}}
//           className={classes.image}
//           focusVisibleClassName={classes.focusVisible}
//           style={{
//             width:'20%',
//           }}
//         >
//           <span
//           style={{borderRadius:100}}
//             className={classes.imageSrc}
//           />
//           <span style={{borderRadius:100}}className={classes.imageBackdrop} />
//           <span className={classes.imageButton}>
//             <Typography
//               component="span"
//               variant="subtitle1"
//               color="inherit"
//               className={classes.imageTitle}>
//               Become a Driver
//             </Typography>
//             <Icon>chevron_right</Icon>
//           </span>
//         </ButtonBase>
// </Link>

//       </div>
//         </CardMedia>

//         <Typography variant="h4"gutterBottom style={{marginTop:'5%'}}>
//           Why Maya ?
//         </Typography>
//         <Typography variant="body2"gutterBottom style={{marginTop:'1%',paddingLeft:'20%',paddingRight:'20%'}}>
//         Crime is ever growing and the fear of losing our Women and Children is a reality that many people face daily. Most women feel uncomfortable if they have to get into a stranger’s car, whether it be a Public Taxi, lift club, car pool or hitch-hiked ride.

//         </Typography>

//         {
//           featuresData?<FeaturesGrid data={featuresData} mayaMode={this.state.mayaSkin}/>:null
//         }

//         <Typography variant="h4"gutterBottom style={{marginTop:'5%'}}>
//         Become a Driver
//         </Typography>
//         <Typography variant="subtitle"gutterBottom style={{marginTop:'1%',paddingLeft:'20%',paddingRight:'20%'}}>
//         Maya drivers are paid more than the industry standard and are incentivized to provide best-in-class service.
//         </Typography>
// <Grid xl container justify="center" style={{backgroundColor:"#262626",marginTop:'50px'}}>
//   <img style={{height:200}} alt="complex" src={MAYA_FOOT} />

//   <div style={{marginLeft:'5%',marginTop:'3%',marginBottom:'3%'}}>
//   <Typography variant="subtitle" align="left" style={{color:'white',fontSize:16,fontWeight:'bold'}}>
//      Company
//   </Typography>
//   <Typography variant="body2" align="left" className={classes.mayaLinks}>
//      About Us
//   </Typography>
//   <Typography variant="body2" align="left" className={classes.mayaLinks}>
//      Become a Driver
//   </Typography>
//   <Typography variant="body2" align="left" className={classes.mayaLinks}>
//      Contact Us
//   </Typography>
//   <Typography variant="body2" align="left" className={classes.mayaLinks}>
//      Become a Driver
//   </Typography>
//   </div>

//   <div style={{marginLeft:'10%',marginTop:'3%',marginBottom:'3%'}}>
//   <Typography variant="subtitle" align="left" style={{color:'white',fontSize:16,fontWeight:'bold'}}>
//      Legal
//   </Typography>
//   <Typography variant="body2" align="left" className={classes.mayaLinks}>
//      Privacy Policy
//   </Typography>
//   <Typography variant="body2" align="left" className={classes.mayaLinks}>
//      Disclaimer
//   </Typography>
//   <Typography variant="body2" align="left" className={classes.mayaLinks}>
//     Data Security Policy
//   </Typography>
//   </div>

// </Grid>
//         </div>
//       </div>
//     );
//   }
// }

// export default withStyles(DriverHomeStyles)(DriverHome);

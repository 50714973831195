// import { getTheme } from "../../../../managers/theme/ThemeManager";
import { CUSTOM_FONT } from "../../../../values/Typography";

const styles = theme => {
  // const configTheme = getTheme();

  return {
    root: {
      flexGrow: 1
    },
    header: {
      display: "flex",
      alignItems: "center",
      height: 50,
      paddingLeft: theme.spacing.unit * 4,
      backgroundColor: theme.palette.background.default
    },
    img: {
      height: 255,
      maxWidth: 400,
      overflow: "hidden",
      display: "block",
      width: "100%"
    },
    connectorActive: {
      "& $connectorLine": {
        borderColor: theme.palette.secondary.main
      }
    },
    connectorCompleted: {
      "& $connectorLine": {
        borderColor: theme.palette.primary.main
      }
    },
    connectorDisabled: {
      "& $connectorLine": {
        borderColor: theme.palette.grey[100]
      }
    },
    connectorLine: {
      transition: theme.transitions.create("border-color")
    },
    stageLabel: {
      fontFamily: CUSTOM_FONT,
      fontSize: 14,
      color: "blue"
    },
    formVerticalContainer: {
      flex: 1
    },
    stepperHolder: {
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center"
      }
    }
  };
};

export default styles;

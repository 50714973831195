import PropTypes from "prop-types";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { TabViewStyles } from "../../../styles";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiTags from "../../../../managers/api/ApiTags";
import { DRIVER_APIS } from "../../../../managers/api/ApiEndPoints";
import { saveSessionData } from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";

class TabFamily extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      ownerDocs: [],
      carDocs: [],
      driverDocs: []
    };
  }

  componentDidMount() {
    ApiManager.callGetApi(
      "App"
      // ApiTags.CONFIG_API,
      // DRIVER_APIS.DRIVER_APP_REQUIRED_DOCS_URL
    )

      .then(response => {
        switch (parseInt(response.result)) {
          case 0:
            console.log("config api error");
            break;

          case 1:
            // save config data
            saveSessionData(
              SessionKeys.CONFIG_DATA,
              JSON.stringify(response.response)
            );
            console.log(",.,.,///aks" + JSON.stringify(response));
            this.setState({
              ownerDocs: response.response.owner_docs,
              carDocs: response.response.car_docs,
              driverDocs: response.response.driver_docs
            });
            break;

          default:
            break;
        }
      })

      .catch(error => {
        console.log(error);
      });
  }

  handleChange(event) {
    this.setState({ value: event });
  }

  TabContainer(props) {
    return (
      <Typography component="div" style={{}}>
        {props.children}
      </Typography>
    );
  }

  renderList(inputArr) {
    let source = this.props;
    return (
      <List dense={false}>
        {inputArr.map((row, index) => (
          <ListItem>
            <ListItemText
              primary={[index + 1 + ". " + row]}
              className={source.classes.listItem}
            />
          </ListItem>
        ))}
      </List>
    );
  }

  render() {
    const { classes, data, tabTitleSet } = this.props;

    return (
      <div className={classes.root} align="center">
        <Tabs
          value={this.state.value}
          onChange={(event, value) => this.handleChange(value)}
          variant="fullWidth"
          classes={{ root: classes.root, indicator: classes.tabsIndicator }}
        >
          <Tab
            label={tabTitleSet[0]} //"Owner Documents"
            disableRipple
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          />
          <Tab
            label={tabTitleSet[1]} //"Car Documents"
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            disableRipple
          />
          <Tab
            label={tabTitleSet[2]} //"Driver Documents"
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            disableRipple
          />
        </Tabs>

        {this.state.value === 0 && this.renderList(this.state.ownerDocs)}
        {this.state.value === 1 && this.renderList(this.state.carDocs)}
        {this.state.value === 2 && this.renderList(this.state.driverDocs)}
      </div>
    );
  }
}

TabFamily.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(TabViewStyles)(TabFamily);

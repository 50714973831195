import React from "react";
import Autocomplete from "../../../custom/Autocomplete";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { IconButton, Grid, Typography, CardMedia } from "@material-ui/core";
import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import * as LanguageManager from "../../../../managers/language/LanguageManager";

class SearchLocation extends React.PureComponent {
  state = {
    location: {
      lat: "",
      lng: "",
      address: ""
    },
    favlocations: []
  };
  componentDidMount() {
    this.executeShowFavLcocation();
  }
  executeShowFavLcocation = () => {
    ApiManager.callPostApi(
      "SearchLocation",
      "show fav location list",
      ApiEndPoints.APPORIO_TAXI_APIS.USER_FAVOURITE_LOCATION_LIST
    )
      .then(res => {
        if (res.result === "1") {
          this.setState({ favlocations: res.data });
        }
      })
      .catch(err => alert(JSON.stringify(err)));
  };
  showPlaceDetails = (place, textAddress, cityString) => {
    if (place.hasOwnProperty("geometry")) {
      console.log("###@###" + cityString);
      this.setState(
        {
          location: {
            ...this.state.location,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            address: textAddress,
            selectedCity: cityString
          }
        },

        () => this.props.location(this.state.location)
      );
    } else {
      //alert("Does not match");
    }
  };

  setCategory = category => {
    if (category === 1) {
      return "Home";
    } else if (category === 2) {
      return "Work";
    } else {
      return "Other";
    }
  };
  render() {
    return (
      <div style={{ flex: 1 }}>
        <Grid
          xs={12}
          container
          style={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={1}>
            <IconButton onClick={() => this.props.onBackPress()}>
              <ArrowBack />
            </IconButton>
          </Grid>

          <Grid item sm={10}>
            <Autocomplete
              placeHolder="Enter  Location"
              onPlaceChanged={(place, textAddress, cityString) =>
                this.showPlaceDetails(place, textAddress, cityString)
              }
            />
          </Grid>
        </Grid>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: "bold",
            textAlign: "left",
            marginLeft: "10.5%"
          }}
        >
          {LanguageManager.Strings.FAV_LABEL}
        </Typography>
        {this.state.favlocations.length > 0 &&
          this.state.favlocations.map((val, index) => (
            <Grid item xs={12}>
              <CardMedia
                onClick={() =>
                  this.setState(
                    {
                      location: {
                        ...this.state.location,
                        lat: val.latitude,
                        lng: val.longitude,
                        address: val.location
                      }
                    },

                    () => this.props.location(this.state.location)
                  )
                }
                style={{ margin: 5, backgroundColor: "white", padding: 10 }}
              >
                <Grid container>
                  <Grid justify="center" item xs={2}>
                    <img
                      alt="complex"
                      src="https://cdn0.iconfinder.com/data/icons/lineo-internet/100/romzicon_home-512.png"
                      style={{ width: 30, height: 30 }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                        textAlign: "left"
                      }}
                    >
                      {this.setCategory(val.category)}
                    </Typography>
                    <Typography
                      noWrap={true}
                      style={{ fontSize: 14, textAlign: "left" }}
                    >
                      {val.location}
                    </Typography>
                  </Grid>
                </Grid>
              </CardMedia>
            </Grid>
          ))}
      </div>
    );
  }
}

export default SearchLocation;

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { DriverProfileStyles } from "../../../../styles";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DriverProfile from "./DriverProfile";
import BankDetails from "./BankDetails";
import HomeLocation from "./HomeLocation";
import ManageDoc from "./ManageDoc";
import DrawerLayout from "../../common/DrawerLayout";
import * as SessionManager from "../../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../../managers/session/SessionKeys";
import CustomLoader from "../../../../custom/CustomLoader";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 10,
        textAlign: "left"
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class DriverTab extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    value: 0,
    DRIVER_CONFIG_ROWS: {},
    wait: true,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };


  componentDidMount() {
    console.log("session1234" + JSON.stringify(JSON.parse(SessionManager.getSessionData(SessionKeys.DRIVER_CONFIG))))
    let data = JSON.parse(SessionManager.getSessionData(SessionKeys.DRIVER_CONFIG));
    this.setState({
      DRIVER_CONFIG_ROWS: data,
      wait: (data) ? false : true,

    }
    )

  }


  render() {
    console.log("driver home render", this.state);
    const { classes } = this.props;
    const { value } = this.state;
    let tabValue = 0;

    if (this.state.wait) {
      return <CustomLoader showLoader={this.state.wait} />
    }
    else {
      console.log("this.state.DRIVER_CONFIG_ROWS.general_config.bank_details_enable", this.state.DRIVER_CONFIG_ROWS.general_config.home_address_enable)
      return (
        <DrawerLayout>
          {/* vaibhav */}
          <div
            position="fixed"
            className={classes.appBar}
            style={{ backgroundImage: " linear-gradient(to top, #ffffff, #e0e0f7, #bac3f0, #8ca8e9, #4990e2)" }}        >
            <Tabs
              value={this.state.value}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.handleChange}
            >
              <Tab className={classes.TabHead} label="DriverProfile" />
              {this.state.DRIVER_CONFIG_ROWS.general_config.bank_details_enable &&
                <Tab className={classes.TabHead} label="BankDetails" />
              }
              {this.state.DRIVER_CONFIG_ROWS.general_config.home_address_enable &&
                <Tab className={classes.TabHead} label="Address" />
              }
              <Tab className={classes.TabHead} label="Manage Documents" />
            </Tabs>
          </div>


          {value == tabValue++ && (
            <TabContainer>
              <DriverProfile />
            </TabContainer>
          )}

          {this.state.DRIVER_CONFIG_ROWS.general_config.bank_details_enable && value == tabValue++ && (

            <TabContainer>

              <BankDetails />

            </TabContainer>
          )}
          {this.state.DRIVER_CONFIG_ROWS.general_config.home_address_enable && value == tabValue++ && (
            <TabContainer>
              <HomeLocation />
            </TabContainer>
          )}
          {value == tabValue++ && (
            <TabContainer>
              <ManageDoc />
            </TabContainer>
          )}
          {/* vaibhav */}
        </DrawerLayout>
      );
    }
  }
}

export default withStyles(DriverProfileStyles)(DriverTab);

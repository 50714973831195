import React from "react";
import AddFavouriteLocation from "../segments/user/userbooking/AddFavouriteLocation";
import { CUSTOM_FONT } from "../../values/Typography";
// import { ThemeContext } from "@emotion/core";
const google = window.google;

class Autocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.state = {
      open: false,
      address: ""
    };
  }

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteInput.current
    );
    this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
  }

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    //this.props.onPlaceChanged(place, this.sadderss);
    this.showData(place);
  }

  getDestnCity = place => {
    if (place.hasOwnProperty("address_components")) {
      var address = place.address_components;
      let city;
      address.forEach(function(component) {
        var types = component.types;
        if (types.indexOf("locality") > -1) {
          city = component.long_name;
        }
      });
      return city;
    } else {
      return "New York";
    }
  };

  showData = place => {
    this.setState({ address: this.autocompleteInput.current.value }, () =>
      // console.log("nununu"+JSON.stringify(place)+":"+this.state.address)

      this.props.onPlaceChanged(
        place,
        this.state.address,
        this.getDestnCity(place)
      )
    );
  };

  handleClose = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <div
        style={{
          display: "flex",
          padding: "2px",
          borderRadius: 5,
          alignItems: "center",
          margin: "2px",
          flex: 1
        }}
      >
        <input
          style={{
            fontFamily: CUSTOM_FONT,
            backgroundColor: "transparent",
            outline: "none",
            width: "auto",
            flex: 1,
            border: "none",
            fontSize: "1rem"
          }}
          ref={this.autocompleteInput}
          id="autocomplete"
          placeholder={this.props.placeHolder}
          type="text"
        />
        <AddFavouriteLocation
          address={this.state.address}
          open={this.state.open}
          close={() => this.handleClose()}
        />
      </div>
    );
  }
}

export default Autocomplete;

import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Grid,
  TextField,
  Button,
  MenuItem,
  Select
} from "@material-ui/core";
import { DriverProfileStyles } from "../../../styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { APPORIO_TAXI_APIS } from "../../../../managers/api/ApiEndPoints";
import * as ApiManager from "../../../../managers/api/ApiManager";
import CustomLoader from "../../../custom/CustomLoader";
import UserTextField from "./UserTextField";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
        textAlign: "left"
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class GetOtp extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    value: 0,
    checked: false,
    drivername: "",
    number: "",
    open: false,
    ConfirmNewPass: "",
    NewPass: ""
  };
  handleSwitch = () => {
    this.setState(state => ({ checked: !state.checked }));
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleInputChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  fetchNewPass(result) {
    this.setState({ NewPass: result });
  }
  fetchConfirmNewPass(result) {
    this.setState({ ConfirmNewPass: result });
  }

  newPass = () => {
    console.log(
      "**************USER_FORGOT_PASSWORD***********",
      this.props.for
    );
    if (this.state.NewPass != this.state.ConfirmNewPass) {
      this.props.openSnack("Password do not match", "error");
      return;
    }
    // alert("OTP MATCH");
    this.setState({
      loading: true
    });
    ApiManager.callPostApi(
      "USER_FORGOT_PASSWORD",
      "",
      APPORIO_TAXI_APIS.USER_FORGOT_PASSWORD,
      {
        // old_password: this.state.ConfirmNewPass,
        password: this.state.NewPass,
        phone: this.props.user_name,
        for: this.props.configData.login.email ? "EMAIL" : "PHONE"
      }
    )

      .then(response => {
        console.log("*****response*******", response);
        // alert(JSON.stringify(response))
        switch (parseInt(response.result)) {
          case 1:
            // this.props.closeEnterPass()
            this.props.handleModal("forgotpass");
            this.props.openSnack("Password changed", "success");
            this.setState({
              loading: false
            });
            break;

          default:
            break;
        }

        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    console.log("driver home render", this.props);
    const { classes } = this.props;
    const { value } = this.state;
    const { checked } = this.state;

    if (this.state.loading) {
      return <CustomLoader showLoader={this.state.loading} />;
    } else {
      return (
        <div>
          <Grid item md={12} style={{}}>
            <Grid
              container
              md={12}
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: -10
              }}
            >
              {/* <IconButton
            onClick={() =>
              this.props.onBackPress()
            }
          >
            <Icon style={{ color: "#666" }}>
              arrow_back
              </Icon>
          </IconButton> */}
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "600", color: "#26699d", paddingTop: 10 }}
              >
                Enter New Password
              </Typography>
            </Grid>
            <hr style={{ color: "#eaeaea" }} />
            <Grid container md={12} style={{ padding: 10 }}>
              <Grid item md={5} style={{ marginTop: 10 }}>
                <Typography variant="body2" style={{ fontWeight: 600 }}>
                  Enter New Password
                </Typography>
                <Typography variant="display4" style={{ fontSize: "15px" }}>
                  New Password should be different from last 5 five passwords
                </Typography>
              </Grid>
              <Grid item md={5} style={{ padding: 5 }}>
                <UserTextField
                  style={{}}
                  placeHolder="Enter New Password"
                  // iconName="assignment_ind"
                  callback={this.fetchNewPass.bind(this)}
                />
                <UserTextField
                  style={{}}
                  placeHolder="Confirm New Password"
                  // iconName="assignment_ind"
                  callback={this.fetchConfirmNewPass.bind(this)}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container md={12} style={{ justifyContent: "center" }}>
              <Grid md={10} align="right" style={{ paddingRight: 0 }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.OtpSend}
                  onClick={() => this.newPass()}
                >
                  Reset Password
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

export default withStyles(DriverProfileStyles)(GetOtp);

import LocalizedStrings from "react-localization";
import { APP_NAME } from "../../utils/AppConstants";
export const Strings = new LocalizedStrings({
  en: {
    LOGIN_BTN: "Log In",
    SIGNUP_BTN: "Sign Up",
    NAVIGATE_TO_USER_BTN: "User",
    APP_WORKING_TITLE: "How App Works",
    DRIVER_TIMELINE_TITLE_1: "Create Account",
    DRIVER_TIMELINE_TITLE_2: "Turn your duty on",
    DRIVER_TIMELINE_TITLE_3: "View Request",
    DRIVER_TIMELINE_TITLE_4: "Track Customer",
    DRIVER_TIMELINE_TITLE_5: "View Receipt",
    DRIVER_FOOTER_SENTENCE: `What are the requirements to work with ${APP_NAME} ? How is ${APP_NAME}  safer than others? How does the app work ? How will I receive my earnings? Find answers to all your queries at our help center.`,
    DRIVER_FOOTER_QUESTN: "Any Questions?",
    TAKE_LOOK_PHRASE: "Take a look at our FAQs",
    DRIVER_ADDRESS: "Address",
    PROVINCE: "Province",
    SUBURB: "Suburb",
    POSTAL_CODE: "Postal Code",
    NAVIGATE_TO_DRIVER_BTN: "Driver",
    USER_LOGIN_BTN: "User",
    FARE_ESTIMATE_TITLE: "Fare Estimate",
    USER_LOGIN_TITLE: "Log In",
    USER_REGISTER_TITLE: "Register",
    EMAIL_HINT: " 📧 Enter Email",
    PHONE_HINT: "📳 Enter Phone",
    PASSWORD_HINT: "Enter Password",
    REFERRAL_CODE_HINT: "Enter Referral Code",
    REFERRAL_CODE_HINT_IF_ANY: "Enter Referral Code(if any)",

    EMAIL_VALIDATION: "Please enter Registered Email ID",
    PHONE_VALIDATION: "Please enter Registered Phone Number.",
    PASSWORD_VALIDATION: "Please enter Password",
    PHONE_MIN_VALIDATION: "Enter valid phone number",
    FNAME_HINT: "👤 Enter First Name",
    LNAME_HINT: "👤 Enter Last Name",
    OTP_PHONE_HINT: "Please enter Phone Number",
    OTP_EMAIL_HINT: "Please enter Email ID",
    DRIVER_SMOKER_QUESTN: "Allow Driver to smoke",
    MALE_LABEL: "Male",
    FEMALE_LABEL: "Female",
    SELF_SMOKER_LABEL: "Type of Smoker",
    SMOKER_OPTION: "Smoker",
    NO_SMOKER_OPTION: "Non-Smoker",
    GENDER_LABEL: "Gender",
    VIEW_DOCS_LABEL: "View Required Docs",
    TERMSCONDITION_LABEL: "Terms & Conditions",
    USER_MENU_OPTION1: "Profile",
    USER_MENU_OPTION2: "My Account",
    USER_MENU_OPTION3: "Log Out",
    USER_DRAWER_OPTION1: "Profile",
    USER_DRAWER_OPTION2: "Book a Ride",
    USER_DRAWER_OPTION3: "Trips History",
    USER_DRAWER_OPTION4: "Wallet",
    DRIVER_MENU_OPTION1: "Profile",
    DRIVER_MENU_OPTION2: "My Account",
    DRIVER_MENU_OPTION3: "Log Out",
    DRIVER_DRAWER_OPTION1: "Manage Vehicles",
    DRIVER_DRAWER_OPTION2: "Manage Trips",
    DRIVER_DRAWER_OPTION3: "Wallet",
    DRIVER_DRAWER_OPTION4: "Earnings",
    WELCOME_LABEL: "Welcome",
    PICKUP_HINT: "Enter pick location",
    DROP_HINT: "Enter drop location",
    RIDE_TIME1: "Ride Now",
    RIDE_TIME2: "Ride Later",
    DATE_LABEL: "Date",
    TIME_LABEL: "Time",
    TODAY_LABEL: "Today",
    TIME_CAPTION: "Time",
    AVAILABLE_BOOKINGS_LABEL: "Available Bookings",
    DESCRIPTION_HERE_LABEL: "Some Description Here",
    NO_SERVICE_MESSAGE: "we are not serving in this area",
    ETA_LABEL: "ETA",
    CONFIRM_BOOKING_BUTTON: "Confirm Booking",
    CONFIRM_OUTSTATION_BUTTON: "Continue",
    FAV_LABEL: "Favourite",
    FROM_LABEL: "From",
    TO_LABEL: "To",
    APPLY_COUPON_LABEL: "Apply Coupon",
    RIDE_ESTIMATE: "Estimate Fare",
    PAYMENT_OPTION_LABEL: "Payment Option",
    ARRIVE_LABEL: "Arrive In",
    FAV_DRIVER_LABEL: "Select Favourite Driver",
    APPLY_LABEL: "Apply",
    CHANGE_LABEL: "Change",
    CONIRM_OUTSTAION_BUTTON: "Continue",
    ONE_WAY_LABEL: "One Way",
    RETURN_LABEL: "Return Journey",
    SAVE_BTN: "Save",
    ACTIVE_TRIP_LABEL: "Active",
    PAST_TRIP_LABEL: "Past",
    SCHEDULED_TRIP_LABEL: "Scheduled",
    NO_JOURNEYS_MESSAGE: "You don't have any Journeys",
    WALLET_LABEL: "Wallet",
    CURRENT_BAL_LABEL: "Current Balance:",
    TRANSACTION_LABEL1: "All Transactions",
    TRANSACTION_LABEL2: "Money In",
    TRANSACTION_LABEL3: "Money Out",
    TABLE_TITLE1: "Sr.No.",
    TABLE_TITLE2: "Amount",
    TABLE_TITLE3: "Date",
    TABLE_TITLE4: "Type",
    TABLE_TITLE5: "Reason",
    DRIVER_HOME_INTRO:
      "Make the most of your time on the road with requests from the largest number of active riders.",
    DRIVER_SIGNBTN: "Sign Up to Drive",
    DRIVER_LOGIN_TITLE: "DRIVER LOGIN",
    FORGOT_PASSWORD_LABEL: "Forgot Password ?",
    SIGNUP_DRIVER: "sign up for new driver",
    PHOTO_UPLOAD_LABEL: "upload a good quality photo of you",
    DRIVER_SIGNUP_TITLE: "DRIVER SIGN UP",
    AREA_LABEL: "Area",
    CREATE_ACC_LABEL: "Create a new account",
    STEPPER_INDICATOR1: "Account Creation",
    STEPPER_INDICATOR2: "Add your vehicle",
    STEPPER_INDICATOR3: "Personal Documents",
    STEPPER_INDICATOR4: "Vehicle Documents",
    STEPPER_INDICATOR5: "Document Verification",
    ADD_CAR_LABEL: "ADD YOUR CAR",
    NEW_CAR_LABEL: "New Vehicle",
    EXISTING_LABEL: "Existing Vehicle",
    CREATE_VEHICLE_LABEL: "Create Vehicle",
    VEHICLE_TYPE_LABEL: "Vehicle Type",
    NONE_LABEL: "None",
    VEHICLE_MAKE_LABEL: "Vehicle Make",
    VEHICLE_MODEL_LABEL: "Vehicle Model",
    SERVICE_SELECT_LABEL: "Select Services",
    ADD_CAR_DETAILS: "Add Vehicle Details",
    UPLOAD_CAR_IMAGE_LABEL: "Upload Vehicle image",
    CAR_COLOR_HINT: "Enter Vehicle Color",
    VEHICLE_NUMBER_HINT: "Enter Vehicle Number",
    NUMBER_PLATE_UPLOAD_IMAGE: "Upload Vehicle Number Plate image",
    ADD_VEHICLE_LABEL: "Add Vehicle",
    CAR_ID_HINT: "Enter Vehicle ID",
    P_DOC_LABEL: "PERSONAL DOCUMENTS",
    V_DOC_LABEL: "VEHICLE DOCUMENTS",
    NEXT_LABEL: "Next",
    NO_DATA_LABEL: "No data to show",
    UPLOAD_DOC_LABEL: "Upload Documents",
    UPLOAD_DOC_MESSAGE:
      "Please upload a good quality image of your documents & enter the expiry date of the document.",
    UPLOAD_DOCIMAGE_LABEL:
      "Select document image of good quality & expiry date of the document.",
    VALID_THRU_LABEL: "Valid Through",
    EXPIRY_HINT: "Enter Date of Expiry",
    SUBMIT_BTN_TITLE: "Submit",
    REQ_FIELDS_ALERT: "Please select required fields",
    EXPIRY_DATE_ALERT: "Please select Date of Expiry.",
    DOC_IMAGE_ALERT: "Please select document Image.",
    VALID_YEAR_ALERT: "Please select valid year of Expiry.",
    VALID_MONTH_ALERT: "Please select valid month of Expiry",
    VALID_DAY_ALERT: "Please select valid day of Expiry",
    VERIFICATION_MESSAGE:
      "Your Documents are under verification, we will notify you on your phone via an SMS, once your uploaded details are verified.",
    OK_BTN_TITLE: "OK",
    TOTAL_RIDE_LABEL: "Total Rides",
    YOUR_RATING_LABEL: "Your Rating",
    TOTAL_EARNING_LABEL: "Total Earnings",
    SERVICE_TERMS_LABEL: "terms of service",
    HELP_LABEL: "need help?",
    DOWNLOAD_LABEL: "Download App",
    ACTIVATED_VEHICLES_LABEL: "Activated Vehicles",
    ADD_NEWCAR_LABEL: "Add New Vehicle",
    UPDATED_AT_LABEL: "Updated At:",
    SERVICES_LABEL: "Services",
    NO_ACTIVE_VEHICLE_MSG: "You have no active vehicle",
    NO_OTHER_VEHICLES_MSG: "No Other Vehicles",
    OTHER_VEHICLES_LABEL: "Other Vehicles",
    VERIFIED_LABEL: "Verified",
    APPROVAL_PENDING_LABEL: "Pending Approval",
    RIDE_WITH_LABEL: "Riding with",
    BOOK_TIME_LABEL: "Booking time:",
    RIDE_ID_LABEL: "RIDE ID",
    CASH_LABEL: "CASH",
    DISTANCE_TRAVEL_LABEL: "Distance Travelled:",
    TIME_TRAVEL_LABEL: "Time Travelled",
    BILL_DETAILS_LABEL: "Bill Details",
    TOTAL_LABEL: "TOTAL",
    PAY_METHD_LABEL: "Payment Method",
    RECHARGE_BY_LABEL: "Reacharged By",
    DESCRIPTION_LABEL: "Description",
    DRIVER_WALLET_LABEL: "Driver's Wallet",
    CURR_BAL_LABEL: "Current Balance",
    EARNING_COL_TITLE1: "Day",
    EARNING_COL_TITLE2: "Rides",
    EARNING_COL_TITLE3: "Amount",
    EARNING_COL_TITLE4: "Date",
    STATEMT_LABEL: "Statements",
    YOUR_EARNINGS_LABEL: "Your Earnings",
    SELECT_DOW_LABEL: "Select any date of week",
    TOTAL_OUTSTANDING_LABEL: "Total Outstanding",
    TOTAL_TRIPS_LABEL: "Total Trips",
    COMMISSION_LABEL: "Select Commission Type",
    LOADING_TEXT: "Please Wait...",
  },
  fr: {
    LOGIN_BTN: "S'identifier",
    SIGNUP_BTN: "S'inscrire",
    NAVIGATE_TO_USER_BTN: "Utilisateur",
    APP_WORKING_TITLE: "Comment fonctionne App",
    DRIVER_TIMELINE_TITLE_1: "Create Account",
    DRIVER_TIMELINE_TITLE_2: "Turn your duty on",
    DRIVER_TIMELINE_TITLE_3: "View Request",
    DRIVER_TIMELINE_TITLE_4: "Track Customer",
    DRIVER_TIMELINE_TITLE_5: "View Receipt",
    DRIVER_FOOTER_SENTENCE:
      "What are the requirements to work with apporio Taxi ? How is apporio taxi safer than others? How does the app work ? How will I receive my earnings? Find answers to all your queries at our help center.",
    DRIVER_FOOTER_QUESTN: "Any Questions?",
    TAKE_LOOK_PHRASE: "Take a look at our FAQs",
    NAVIGATE_TO_DRIVER_BTN: "chauffeur",
    USER_LOGIN_BTN: "Utilisateur",
    FARE_ESTIMATE_TITLE: "Faire une estimation",
    USER_LOGIN_TITLE: "s'identifier",
    USER_REGISTER_TITLE: "registre",
    EMAIL_HINT: " 📧 Enter Email",
    PHONE_HINT: "📳 Enter Phone",
    PASSWORD_HINT: "Enter Password",
    REFERRAL_CODE_HINT: "Enter Referral Code",
    REFERRAL_CODE_HINT_IF_ANY: "Enter Referral Code(if any)",
    EMAIL_VALIDATION: "Please enter Registered Email ID",
    PHONE_VALIDATION: "Please enter Registered Phone Number.",
    PASSWORD_VALIDATION: "Please enter Password",
    FNAME_HINT: "👤 Enter First Name",
    LNAME_HINT: "👤 Enter Last Name",
    OTP_PHONE_HINT: "Please enter Phone Number",
    OTP_EMAIL_HINT: "Please enter Email ID",
    DRIVER_SMOKER_QUESTN: "Allow Driver to smoke",
    MALE_LABEL: "Male",
    FEMALE_LABEL: "Female",
    SELF_SMOKER_LABEL: "Type of Smoker",
    SMOKER_OPTION: "Smoker",
    NO_SMOKER_OPTION: "Non-Smoker",
    GENDER_LABEL: "Gender",
    VIEW_DOCS_LABEL: "View Required Docs",
    TERMSCONDITION_LABEL: "Terms & Conditions",
    USER_MENU_OPTION1: "Profile",
    USER_MENU_OPTION2: "My Account",
    USER_MENU_OPTION3: "Log Out",
    USER_DRAWER_OPTION1: "Profile",
    USER_DRAWER_OPTION2: "Book a Ride",
    USER_DRAWER_OPTION3: "Trips History",
    USER_DRAWER_OPTION4: "Wallet",
    DRIVER_MENU_OPTION1: "Profile",
    DRIVER_MENU_OPTION2: "My Account",
    DRIVER_MENU_OPTION3: "Log Out",
    DRIVER_DRAWER_OPTION1: "Manage Vehicles",
    DRIVER_DRAWER_OPTION2: "Manage Trips",
    DRIVER_DRAWER_OPTION3: "Wallet",
    DRIVER_DRAWER_OPTION4: "Earnings",
    WELCOME_LABEL: "Welcome",
    PICKUP_HINT: "Enter pick location",
    DROP_HINT: "Enter drop location",
    RIDE_TIME1: "Ride Now",
    RIDE_TIME2: "Ride Later",
    DATE_LABEL: "Date",
    TIME_LABEL: "Time",
    TODAY_LABEL: "Today",
    TIME_CAPTION: "Time",
    AVAILABLE_BOOKINGS_LABEL: "Available Bookings",
    DESCRIPTION_HERE_LABEL: "Some Description Here",
    NO_SERVICE_MESSAGE: "we are not serving in this area",
    ETA_LABEL: "ETA",
    CONFIRM_BOOKING_BUTTON: "Confirm Booking",
    CONFIRM_OUTSTATION_BUTTON: "Continue",
    FAV_LABEL: "Favourite",
    FROM_LABEL: "From",
    TO_LABEL: "To",
    APPLY_COUPON_LABEL: "Apply Coupon",
    RIDE_ESTIMATE: "Estimate Fare",
    PAYMENT_OPTION_LABEL: "Payment Option",
    ARRIVE_LABEL: "Arrive In",
    FAV_DRIVER_LABEL: "Select Favourite Driver",
    APPLY_LABEL: "Apply",
    CHANGE_LABEL: "Change",
    CONIRM_OUTSTAION_BUTTON: "Continue",
    ONE_WAY_LABEL: "One Way",
    RETURN_LABEL: "Return Journey",
    SAVE_BTN: "Save",
    ACTIVE_TRIP_LABEL: "Active",
    PAST_TRIP_LABEL: "Past",
    SCHEDULED_TRIP_LABEL: "Scheduled",
    NO_JOURNEYS_MESSAGE: "You don't have any Journeys",
    WALLET_LABEL: "Wallet",
    CURRENT_BAL_LABEL: "Current Balance:",
    TRANSACTION_LABEL1: "All Transactions",
    TRANSACTION_LABEL2: "Money In",
    TRANSACTION_LABEL3: "Money Out",
    TABLE_TITLE1: "Sr.No.",
    TABLE_TITLE2: "Amount",
    TABLE_TITLE3: "Date",
    TABLE_TITLE4: "Type",
    TABLE_TITLE5: "Reason",
    DRIVER_HOME_INTRO:
      "Make the most of your time on the road with requests from the largest number of active riders.",
    DRIVER_SIGNBTN: "Sign Up to Driver",
    DRIVER_LOGIN_TITLE: "DRIVER LOGIN",
    FORGOT_PASSWORD_LABEL: "Forgot Password ?",
    SIGNUP_DRIVER: "sign up for new driver",
    PHOTO_UPLOAD_LABEL: "upload a good quality photo of you",
    DRIVER_SIGNUP_TITLE: "DRIVER SIGN UP",
    AREA_LABEL: "Area",
    CREATE_ACC_LABEL: "Create a new account",
    STEPPER_INDICATOR1: "Account Creation",
    STEPPER_INDICATOR2: "Add your vehicle",
    STEPPER_INDICATOR3: "Personal Documents",
    STEPPER_INDICATOR4: "Vehicle Documents",
    STEPPER_INDICATOR5: "Document Verification",
    ADD_CAR_LABEL: "ADD YOUR CAR",
    NEW_CAR_LABEL: "New Vehicle",
    EXISTING_LABEL: "Existing Vehicle",
    CREATE_VEHICLE_LABEL: "Create Vehicle",
    VEHICLE_TYPE_LABEL: "Vehicle Type",
    NONE_LABEL: "None",
    VEHICLE_MAKE_LABEL: "Vehicle Make",
    VEHICLE_MODEL_LABEL: "Vehicle Model",
    SERVICE_SELECT_LABEL: "Select Services",
    ADD_CAR_DETAILS: "Add Vehicle Details",
    UPLOAD_CAR_IMAGE_LABEL: "Upload Vehicle image",
    CAR_COLOR_HINT: "Enter Vehicle Color",
    VEHICLE_NUMBER_HINT: "Enter Vehicle Number",
    NUMBER_PLATE_UPLOAD_IMAGE: "Upload Vehicle Number Plate image",
    ADD_VEHICLE_LABEL: "Add Vehicle",
    CAR_ID_HINT: "Enter Vehicle ID",
    P_DOC_LABEL: "PERSONAL DOCUMENTS",
    V_DOC_LABEL: "VEHICLE DOCUMENTS",
    NEXT_LABEL: "Next",
    NO_DATA_LABEL: "No data to show",
    UPLOAD_DOC_LABEL: "Upload Documents",
    UPLOAD_DOC_MESSAGE:
      "Please upload a good quality image of your documents & enter the expiry date of the document.",
    UPLOAD_DOCIMAGE_LABEL:
      "Select document image of good quality & expiry date of the document.",
    VALID_THRU_LABEL: "Valid Through",
    EXPIRY_HINT: "Enter Date of Expiry",
    SUBMIT_BTN_TITLE: "Submit",
    REQ_FIELDS_ALERT: "Please select required fields",
    EXPIRY_DATE_ALERT: "Please select Date of Expiry.",
    DOC_IMAGE_ALERT: "Please select document Image.",
    VALID_YEAR_ALERT: "Please select valid year of Expiry.",
    VALID_MONTH_ALERT: "Please select valid month of Expiry",
    VALID_DAY_ALERT: "Please select valid day of Expiry",
    VERIFICATION_MESSAGE:
      "Your Documents are under verification, we will notify you on your phone via an SMS, once your uploaded details are verified.",
    OK_BTN_TITLE: "OK",
    TOTAL_RIDE_LABEL: "Total Rides",
    YOUR_RATING_LABEL: "Your Rating",
    TOTAL_EARNING_LABEL: "Total Earnings",
    SERVICE_TERMS_LABEL: "terms of service",
    HELP_LABEL: "need help?",
    DOWNLOAD_LABEL: "Download App",
    ACTIVATED_VEHICLES_LABEL: "Activated Vehicles",
    ADD_NEWCAR_LABEL: "Add New Vehicle",
    UPDATED_AT_LABEL: "Updated At:",
    SERVICES_LABEL: "Services",
    NO_ACTIVE_VEHICLE_MSG: "You have no active vehicle",
    NO_OTHER_VEHICLES_MSG: "No Other Vehicles",
    OTHER_VEHICLES_LABEL: "Other Vehicles",
    VERIFIED_LABEL: "Verified",
    APPROVAL_PENDING_LABEL: "Pending Approval",
    RIDE_WITH_LABEL: "Riding with",
    BOOK_TIME_LABEL: "Booking time:",
    RIDE_ID_LABEL: "RIDE ID",
    CASH_LABEL: "CASH",
    DISTANCE_TRAVEL_LABEL: "Distance Travelled:",
    TIME_TRAVEL_LABEL: "Time Travelled",
    BILL_DETAILS_LABEL: "Bill Details",
    TOTAL_LABEL: "TOTAL",
    PAY_METHD_LABEL: "Payment Method",
    RECHARGE_BY_LABEL: "Reacharged By",
    DESCRIPTION_LABEL: "Description",
    DRIVER_WALLET_LABEL: "Driver's Wallet",
    CURR_BAL_LABEL: "Current Balance",
    EARNING_COL_TITLE1: "Day",
    EARNING_COL_TITLE2: "Rides",
    EARNING_COL_TITLE3: "Amount",
    EARNING_COL_TITLE4: "Date",
    STATEMT_LABEL: "Statements",
    YOUR_EARNINGS_LABEL: "Your Earnings",
    SELECT_DOW_LABEL: "Select any date of week",
    TOTAL_OUTSTANDING_LABEL: "Total Outstanding",
    TOTAL_TRIPS_LABEL: "Total Trips",
    COMMISSION_LABEL: "Select Commission Type",
    LOADING_TEXT: "S'il vous plaît, attendez...",
  },
  ar: {
    LOGIN_BTN: "تسجيل الدخول",
    SIGNUP_BTN: "سجل",
    NAVIGATE_TO_USER_BTN: "المستعمل",
    APP_WORKING_TITLE: "كيف يعمل التطبيق",
    DRIVER_TIMELINE_TITLE_1: "إنشاء حساب",
    DRIVER_TIMELINE_TITLE_2: "تشغيل واجبك",
    DRIVER_TIMELINE_TITLE_3: "عرض الطلب",
    DRIVER_TIMELINE_TITLE_4: "تتبع العملاء",
    DRIVER_TIMELINE_TITLE_5: "استلام عرض",
    DRIVER_FOOTER_SENTENCE: `ما متطلبات العمل باستخدام ${APP_NAME}؟ كيف يكون ${APP_NAME} أكثر أمانًا من الآخرين؟ كيف يعمل التطبيق؟ كيف سأتلقى أرباحي؟ ابحث عن إجابات لجميع استفساراتك في مركز المساعدة الخاص بنا.`,
    DRIVER_FOOTER_QUESTN: "أي أسئلة؟",
    TAKE_LOOK_PHRASE: "ألق نظرة على الأسئلة الشائعة",
    DRIVER_ADDRESS: "عنوان",
    PROVINCE: "المحافظة",
    SUBURB: "ضاحية",
    POSTAL_CODE: "الرمز البريدي",
    NAVIGATE_TO_DRIVER_BTN: "سائق",
    USER_LOGIN_BTN: "المستعمل",
    FARE_ESTIMATE_TITLE: "تقدير الأجرة",
    USER_LOGIN_TITLE: "تسجيل الدخول",
    USER_REGISTER_TITLE: "تسجيل",
    EMAIL_HINT: " 📧 أدخل البريد الإلكتروني",
    PHONE_HINT: "📳 أدخل الهاتف",
    PASSWORD_HINT: "أدخل كلمة المرور",
    REFERRAL_CODE_HINT: "أدخل رمز الإحالة",
    REFERRAL_CODE_HINT_IF_ANY: "أدخل رمز الإحالة (إن وجد)",

    EMAIL_VALIDATION: "الرجاء إدخال معرف البريد الإلكتروني المسجل",
    PHONE_VALIDATION: "الرجاء إدخال رقم الهاتف المسجل.",
    PASSWORD_VALIDATION: "الرجاء إدخال كلمة المرور",
    PHONE_MIN_VALIDATION: "أدخل رقم هاتف صحيح",
    FNAME_HINT: "👤 أدخل الاسم الأول",
    LNAME_HINT: "👤 إدخال اسم آخر",
    OTP_PHONE_HINT: "الرجاء إدخال رقم الهاتف",
    OTP_EMAIL_HINT: "الرجاء إدخال معرف البريد الإلكتروني",
    DRIVER_SMOKER_QUESTN: "اسمح للسائق بالتدخين",
    MALE_LABEL: "الذكر",
    FEMALE_LABEL: "إناثا",
    SELF_SMOKER_LABEL: "نوع المدخن",
    SMOKER_OPTION: "المدخن",
    NO_SMOKER_OPTION: "غير مدخن",
    GENDER_LABEL: "جنس",
    VIEW_DOCS_LABEL: "عرض المستندات المطلوبة",
    TERMSCONDITION_LABEL: "البنود و الظروف",
    USER_MENU_OPTION1: "الملف الشخصي",
    USER_MENU_OPTION2: "حسابي",
    USER_MENU_OPTION3: "الخروج",
    USER_DRAWER_OPTION1: "الملف الشخصي",
    USER_DRAWER_OPTION2: "حجز رحلة",
    USER_DRAWER_OPTION3: "رحلات التاريخ",
    USER_DRAWER_OPTION4: "محفظة نقود",
    DRIVER_MENU_OPTION1: "الملف الشخصي",
    DRIVER_MENU_OPTION2: "حسابي",
    DRIVER_MENU_OPTION3: "الخروج",
    DRIVER_DRAWER_OPTION1: "إدارة المركبات",
    DRIVER_DRAWER_OPTION2: "إدارة الرحلات",
    DRIVER_DRAWER_OPTION3: "محفظة نقود",
    DRIVER_DRAWER_OPTION4: "أرباح",
    WELCOME_LABEL: "أهلا بك",
    PICKUP_HINT: "أدخل اختيار الموقع",
    DROP_HINT: "أدخل موقع إسقاط",
    RIDE_TIME1: "اركب الآن",
    RIDE_TIME2: "ركوب في وقت لاحق",
    DATE_LABEL: "تاريخ",
    TIME_LABEL: "زمن",
    TODAY_LABEL: "اليوم",
    TIME_CAPTION: "زمن",
    AVAILABLE_BOOKINGS_LABEL: "الحجوزات المتاحة",
    DESCRIPTION_HERE_LABEL: "بعض الوصف هنا",
    NO_SERVICE_MESSAGE: "نحن لا نخدم في هذا المجال",
    ETA_LABEL: "وصول الوقت المقدر",
    CONFIRM_BOOKING_BUTTON: "تأكيد الحجز",
    CONFIRM_OUTSTATION_BUTTON: "استمر",
    FAV_LABEL: "مفضل",
    FROM_LABEL: "من عند",
    TO_LABEL: "إلى",
    APPLY_COUPON_LABEL: "تطبيق القسيمة",
    RIDE_ESTIMATE: "تقدير الأجرة",
    PAYMENT_OPTION_LABEL: "خيار الدفع",
    ARRIVE_LABEL: "يصل إلى",
    FAV_DRIVER_LABEL: "حدد برنامج التشغيل المفضل",
    APPLY_LABEL: "تطبيق",
    CHANGE_LABEL: "يتغيرون",
    CONIRM_OUTSTAION_BUTTON: "استمر",
    ONE_WAY_LABEL: "اتجاه واحد",
    RETURN_LABEL: "رحلة العودة",
    SAVE_BTN: "حفظ",
    ACTIVE_TRIP_LABEL: "نشيط",
    PAST_TRIP_LABEL: "الماضي",
    SCHEDULED_TRIP_LABEL: "المقرر",
    NO_JOURNEYS_MESSAGE: "ليس لديك أي رحلات",
    WALLET_LABEL: "محفظة نقود",
    CURRENT_BAL_LABEL: "الرصيد الحالي:",
    TRANSACTION_LABEL1: "كل الحركات المالية",
    TRANSACTION_LABEL2: "المال في",
    TRANSACTION_LABEL3: "لم يبقى اي من المال",
    TABLE_TITLE1: "الأب رقم.",
    TABLE_TITLE2: "كمية",
    TABLE_TITLE3: "تاريخ",
    TABLE_TITLE4: "نوع",
    TABLE_TITLE5: "السبب",
    DRIVER_HOME_INTRO:
      "حقق أقصى استفادة من وقتك على الطريق مع طلبات من أكبر عدد من الدراجين النشطين.",
    DRIVER_SIGNBTN: "اشترك في حملة",
    DRIVER_LOGIN_TITLE: "سائق تسجيل الدخول",
    FORGOT_PASSWORD_LABEL: "هل نسيت كلمة المرور ؟",
    SIGNUP_DRIVER: "الاشتراك في برنامج التشغيل الجديد",
    PHOTO_UPLOAD_LABEL: "تحميل صورة ذات نوعية جيدة لك",
    DRIVER_SIGNUP_TITLE: "توقيع السائق",
    AREA_LABEL: "منطقة",
    CREATE_ACC_LABEL: "انشاء حساب جديد",
    STEPPER_INDICATOR1: "إنشاء حساب",
    STEPPER_INDICATOR2: "أضف سيارتك",
    STEPPER_INDICATOR3: "مستندات شخصية",
    STEPPER_INDICATOR4: "مستندات المركبة",
    STEPPER_INDICATOR5: "التحقق من المستند",
    ADD_CAR_LABEL: "أضف سيارتك",
    NEW_CAR_LABEL: "سيارة جديدة",
    EXISTING_LABEL: "مركبة موجودة",
    CREATE_VEHICLE_LABEL: "إنشاء مركبة",
    VEHICLE_TYPE_LABEL: "نوع السيارة",
    NONE_LABEL: "لا شيء",
    VEHICLE_MAKE_LABEL: "صناعة المركبات",
    VEHICLE_MODEL_LABEL: "طراز السيارة",
    SERVICE_SELECT_LABEL: "اختر الخدمات",
    ADD_CAR_DETAILS: "إضافة تفاصيل السيارة",
    UPLOAD_CAR_IMAGE_LABEL: "تحميل صورة مركبة",
    CAR_COLOR_HINT: "أدخل لون المركبة",
    VEHICLE_NUMBER_HINT: "أدخل رقم المركبة",
    NUMBER_PLATE_UPLOAD_IMAGE: "تحميل صورة رقم لوحة المركبة",
    ADD_VEHICLE_LABEL: "أضف مركبة",
    CAR_ID_HINT: "أدخل معرف المركبة",
    P_DOC_LABEL: "مستندات شخصية",
    V_DOC_LABEL: "وثائق السيارة",
    NEXT_LABEL: "التالى",
    NO_DATA_LABEL: "لا توجد بيانات لإظهارها",
    UPLOAD_DOC_LABEL: "تحميل المستندات",
    UPLOAD_DOC_MESSAGE:
      "يرجى تحميل صورة ذات نوعية جيدة من المستندات الخاصة بك وإدخال تاريخ انتهاء صلاحية المستند.",
    UPLOAD_DOCIMAGE_LABEL:
      "حدد صورة وثيقة ذات نوعية جيدة وتاريخ انتهاء صلاحية الوثيقة.",
    VALID_THRU_LABEL: "صالحة من خلال",
    EXPIRY_HINT: "أدخل تاريخ انتهاء الصلاحية",
    SUBMIT_BTN_TITLE: "خضع",
    REQ_FIELDS_ALERT: "يرجى اختيار الحقول المطلوبة",
    EXPIRY_DATE_ALERT: "يرجى تحديد تاريخ انتهاء الصلاحية.",
    DOC_IMAGE_ALERT: "يرجى اختيار وثيقة صورة.",
    VALID_YEAR_ALERT: "يرجى تحديد سنة صالحة للانتهاء.",
    VALID_MONTH_ALERT: "يرجى اختيار شهر صالح للانتهاء",
    VALID_DAY_ALERT: "يرجى تحديد يوم صالح للانتهاء",
    VERIFICATION_MESSAGE:
      "المستندات قيد التحقق ، وسنعلمك على هاتفك عبر رسالة نصية قصيرة ، بمجرد التحقق من التفاصيل التي تم تحميلها.",
    OK_BTN_TITLE: "حسنا",
    TOTAL_RIDE_LABEL: "مجموع ركوب الخيل",
    YOUR_RATING_LABEL: "تقييمك",
    TOTAL_EARNING_LABEL: "الأرباح الكلية",
    SERVICE_TERMS_LABEL: "شروط الخدمة",
    HELP_LABEL: "تحتاج مساعدة؟",
    DOWNLOAD_LABEL: "تحميل التطبيق",
    ACTIVATED_VEHICLES_LABEL: "المركبات النشطة",
    ADD_NEWCAR_LABEL: "إضافة سيارة جديدة",
    UPDATED_AT_LABEL: "تم التحديث في:",
    SERVICES_LABEL: "خدمات",
    NO_ACTIVE_VEHICLE_MSG: "ليس لديك سيارة نشطة",
    NO_OTHER_VEHICLES_MSG: "لا مركبات أخرى",
    OTHER_VEHICLES_LABEL: "مركبات أخرى",
    VERIFIED_LABEL: "تم التحقق منه",
    APPROVAL_PENDING_LABEL: "ما زال يحتاج بتصدير",
    RIDE_WITH_LABEL: "ركوب مع",
    BOOK_TIME_LABEL: "وقت الحجز:",
    RIDE_ID_LABEL: "معرف ركوب",
    CASH_LABEL: "السيولة النقدية",
    DISTANCE_TRAVEL_LABEL: "المسافة المقطوعة:",
    TIME_TRAVEL_LABEL: "وقت السفر",
    BILL_DETAILS_LABEL: "تفاصيل الفاتورة",
    TOTAL_LABEL: "مجموع",
    PAY_METHD_LABEL: "طريقة الدفع او السداد",
    RECHARGE_BY_LABEL: "إعادة شحنها بواسطة",
    DESCRIPTION_LABEL: "وصف",
    DRIVER_WALLET_LABEL: "محفظة السائق",
    CURR_BAL_LABEL: "الرصيد الحالي",
    EARNING_COL_TITLE1: "يوم",
    EARNING_COL_TITLE2: "ركوب الخيل",
    EARNING_COL_TITLE3: "كمية",
    EARNING_COL_TITLE4: "تاريخ",
    STATEMT_LABEL: "صياغات",
    YOUR_EARNINGS_LABEL: "أرباحك",
    SELECT_DOW_LABEL: "حدد أي تاريخ الأسبوع",
    TOTAL_OUTSTANDING_LABEL: "المجموع المتميز",
    TOTAL_TRIPS_LABEL: "مجموع الرحلات",
    COMMISSION_LABEL: "اختر نوع العمولة",
    LOADING_TEXT: "ارجوك انتظر...",
  },
  es: {
    LOGIN_BTN: "Login", //Log In
    SIGNUP_BTN: "Registrarse", //Sign Up
    NAVIGATE_TO_USER_BTN: "Pasajero", //User
    APP_WORKING_TITLE: "Cómo funciona la aplicación", //How App Works
    DRIVER_TIMELINE_TITLE_1: "Crear Cuenta", //Create Account
    DRIVER_TIMELINE_TITLE_2: "Active su Registro", //Turn your duty on
    DRIVER_TIMELINE_TITLE_3: "Ver solicitud", //View Request
    DRIVER_TIMELINE_TITLE_4: "Seguir Cliente", //Track Customer
    DRIVER_TIMELINE_TITLE_5: "Ver Recibo", //View Receipt
    DRIVER_FOOTER_SENTENCE:
      "¿Cuáles son los requisitos para trabajar con TopApp? ¿Cómo es TopApp más seguro que otros? ¿Cómo funciona la aplicación? ¿Cómo recibiré mis ganancias? Encuentre respuestas a todas sus preguntas en nuestro Centro de ayuda.", //What are the requirements to work with apporio Taxi ? How is apporio taxi safer than others? How does the app work ? How will I receive my earnings? Find answers to all your queries at our help center.
    DRIVER_FOOTER_QUESTN: "Preguntas?", //Any Questions
    TAKE_LOOK_PHRASE: "Consulte nuestras preguntas frecuentes (FAQ)", // Take a look at our FAQs
    DRIVER_ADDRESS: "Dirección", //Address
    PROVINCE: "Provincia", //Province
    SUBURB: "Suburbio", //Suburb
    POSTAL_CODE: "Código Postal", //Postal Code
    NAVIGATE_TO_DRIVER_BTN: "Conductor", //Driver
    USER_LOGIN_BTN: "Pasajero", //User
    FARE_ESTIMATE_TITLE: "Estimación de Tarifa", //Fare Estimate
    USER_LOGIN_TITLE: "Log In",
    USER_REGISTER_TITLE: "Registrarse", //Register
    EMAIL_HINT: "📧 Ingrese e-mail", //Enter Email
    PHONE_HINT: "📳 Ingrese Mobile", //Enter Phone
    PASSWORD_HINT: "Su Contraseña", //Enter Password
    REFERRAL_CODE_HINT: "Ingrese el Código de Referencia", //Enter Referral Code
    REFERRAL_CODE_HINT_IF_ANY: "Código de Referencia(si corresponde)", // if any

    EMAIL_VALIDATION: "Por Favor, digite o e-mail cadastrado", //Please enter Registered Email ID
    PHONE_VALIDATION: "Por favor, digite o Celular cadastrado.", //Please enter Registered Phone Number
    PASSWORD_VALIDATION: "Ingrese su Contraseña", //Please enter Password
    PHONE_MIN_VALIDATION: "Ingrese un número de teléfono válido", // Enter valid phone number
    FNAME_HINT: "👤 Ingrese el Nombre", //Enter First Name
    LNAME_HINT: "👤 Ingrese el Apellido", //Enter Last Name
    OTP_PHONE_HINT: "Ingrese el número de teléfono", //Please enter Phone Number
    OTP_EMAIL_HINT: "Ingrese su correo electrónico", //Please enter Email ID
    DRIVER_SMOKER_QUESTN: "Permitir que el conductor fume", //Allow Driver to smoke

    MALE_LABEL: "Hombre", //Male
    FEMALE_LABEL: "Mujer", // Female
    SELF_SMOKER_LABEL: "Tipo de Fumador", //Type of Smoker
    SMOKER_OPTION: "Fumador",
    NO_SMOKER_OPTION: "No Fumador", //Non-Smoker
    GENDER_LABEL: "Género", //Gender
    VIEW_DOCS_LABEL: "Ver documentos requeridos", //View Required Docs
    TERMSCONDITION_LABEL: "Términos y Condiciones", //Terms & Conditions
    USER_MENU_OPTION1: "Perfil", //Profile
    USER_MENU_OPTION2: "Mi cuenta", //My Account
    USER_MENU_OPTION3: "Cerrar Sesión", //Log Out
    USER_DRAWER_OPTION1: "Perfil", //Profile
    USER_DRAWER_OPTION2: "Reserve un viaje", //Book a Ride
    USER_DRAWER_OPTION3: "Historial de Viajes", //Trips History
    USER_DRAWER_OPTION4: "Monedero", //Wallet
    DRIVER_MENU_OPTION1: "Perfil", //Profile
    DRIVER_MENU_OPTION2: "Mi Cuenta", //My Account
    DRIVER_MENU_OPTION3: "Cerrar Sesión", //Log Out
    DRIVER_DRAWER_OPTION1: "Administrar Vehículos", //Manage Vehicles
    DRIVER_DRAWER_OPTION2: "Administrar viajes", //Manage Trips
    DRIVER_DRAWER_OPTION3: "Monedero", //Wallet
    DRIVER_DRAWER_OPTION4: "Ingresos", //Earnings
    WELCOME_LABEL: "Bienvenido", //Welcome
    PICKUP_HINT: "Introduzca el Lugar de Salida", //Enter pick location
    DROP_HINT: "Introduzca la Ubicación de Destino", // Enter drop location
    RIDE_TIME1: "Llame Ahora", // Ride Now
    RIDE_TIME2: "Programación", // Ride Later
    DATE_LABEL: "Fecha", // Date
    TIME_LABEL: "Hora", // Time
    TODAY_LABEL: "Hoy", // Today
    TIME_CAPTION: "Hora", // Time
    AVAILABLE_BOOKINGS_LABEL: "Reservas Disponibles", // Available Bookings
    DESCRIPTION_HERE_LABEL: "Descripción aquí", // Some Description Here
    NO_SERVICE_MESSAGE: "No estamos sirviendo en esta área", // we are not serving in this area
    ETA_LABEL: "ETA", //ETA
    CONFIRM_BOOKING_BUTTON: "Confirmar Reserva", // Confirm Booking
    CONFIRM_OUTSTATION_BUTTON: "Continuar", // Continue
    FAV_LABEL: "Favorito", // Favourite
    FROM_LABEL: "De", // From
    TO_LABEL: "Para", // To
    APPLY_COUPON_LABEL: "Aplicar Cupón", //Apply Coupon
    RIDE_ESTIMATE: "Precio Estimado", // Estimate Fare
    PAYMENT_OPTION_LABEL: "Opción de Pago", // Payment Option
    ARRIVE_LABEL: "Llegada a", // Arrive In
    FAV_DRIVER_LABEL: "Seleccionar controlador favorito", //Select Favourite Driver

    APPLY_LABEL: "Aplicar", // Apply
    CHANGE_LABEL: "Cambiar", // Change
    CONIRM_OUTSTAION_BUTTON: "Continuar", // Continue
    ONE_WAY_LABEL: "Única Manera", //One Way
    RETURN_LABEL: "Viaje de regreso", //Return Journey
    SAVE_BTN: "Guardar", //Save
    ACTIVE_TRIP_LABEL: "Activo", //Active
    PAST_TRIP_LABEL: "Anterior", //Past
    SCHEDULED_TRIP_LABEL: "Programado", //Scheduled
    NO_JOURNEYS_MESSAGE: "No tiene viajes", //You don't have any Journeys

    WALLET_LABEL: "Monedero", //Wallet
    CURRENT_BAL_LABEL: "Saldo Actual:", //Current Balance
    TRANSACTION_LABEL1: "Todas las Transacciones", //All Transactions
    TRANSACTION_LABEL2: "R$ Entrada", //Money In
    TRANSACTION_LABEL3: "R$ Salida", //Money Out
    TABLE_TITLE1: "Sr.No.", //Sr.No.
    TABLE_TITLE2: "Total", //Amount
    TABLE_TITLE3: "Fecha", //Date
    TABLE_TITLE4: "Tipo", //Type
    TABLE_TITLE5: "Motivo", //Reason
    DRIVER_HOME_INTRO:
      "Aproveche al máximo su tiempo en la calle con las solicitudes del mayor número de conductores activos.", //Make the most of your time on the road with requests from the largest number of active riders
    DRIVER_SIGNBTN: "Regístrese para Conducir", //Sign Up to Drive
    DRIVER_LOGIN_TITLE: "LOGIN CONDUCTOR", //DRIVER LOGIN
    FORGOT_PASSWORD_LABEL: "¿Olvidó su contraseña?", //Forgot Password ?
    SIGNUP_DRIVER: "Regístrese para Conducir", //sign up for new driver
    PHOTO_UPLOAD_LABEL: "Sube una foto de buena calidad", //upload a good quality photo of you
    DRIVER_SIGNUP_TITLE: "REGISTRO DE CONDUCTOR", //DRIVER SIGN UP
    AREA_LABEL: "Area", //Area
    CREATE_ACC_LABEL: "Crear una nueva cuenta", //Create a new account
    STEPPER_INDICATOR1: "Creación de cuenta", //Account Creation
    STEPPER_INDICATOR2: "Agregue su vehículo", //Add your vehicle
    STEPPER_INDICATOR3: "Documentos personales", //Personal Documents
    STEPPER_INDICATOR4: "Documentos del vehículo", //Vehicle Documents
    STEPPER_INDICATOR5: "Verificación de documentos", //Document Verification
    ADD_CAR_LABEL: "AGREGAR SU COCHE", //ADD YOUR CAR
    NEW_CAR_LABEL: "Vehículo Nuevo", //New Vehicle
    EXISTING_LABEL: "Vehículo Existente", //Existing Vehicle
    CREATE_VEHICLE_LABEL: "Registrar vehículo",
    VEHICLE_TYPE_LABEL: "Tipo de vehículo", //Vehicle Type
    NONE_LABEL: "Ninguno", //None
    VEHICLE_MAKE_LABEL: "Marca del vehículo", //Vehicle Make
    VEHICLE_MODEL_LABEL: "Modelo de vehículo", //Vehicle Model
    SERVICE_SELECT_LABEL: "Seleccionar servicios", //Select Services
    ADD_CAR_DETAILS: "Agregar detalles del vehículo", //Add Vehicle Details
    UPLOAD_CAR_IMAGE_LABEL: "Subir imagen del vehículo", //Upload Vehicle image
    CAR_COLOR_HINT: "Ingrese el color del vehículo", //Enter Vehicle Color
    VEHICLE_NUMBER_HINT: "Ingrese el número del vehículo", //Enter Vehicle Number
    NUMBER_PLATE_UPLOAD_IMAGE: "Cargar imagen de matrícula del vehículo", //Upload Vehicle Number Plate image
    ADD_VEHICLE_LABEL: "Agregar vehículo", //Add Vehicle
    CAR_ID_HINT: "Ingrese la identificación del vehículo", //Enter Vehicle ID
    P_DOC_LABEL: "DOCUMENTOS PERSONALES", //PERSONAL DOCUMENTS
    V_DOC_LABEL: "DOCUMENTOS DEL VEHÍCULO", //VEHICLE DOCUMENTS
    NEXT_LABEL: "Siguiente", //Next
    NO_DATA_LABEL: "No hay datos para mostrar", //No data to show
    UPLOAD_DOC_LABEL: "Cargar Documentos", //Upload Documents
    UPLOAD_DOC_MESSAGE:
      "Cargue una imagen de buena calidad de sus documentos e ingrese su fecha de vencimiento.", //Please upload a good quality image of your documents & enter the expiry date of the document.
    UPLOAD_DOCIMAGE_LABEL:
      "Seleccione una imagen de buena calidad e ingrese su fecha de vencimiento.", //Select document image of good quality & expiry date of the document.
    VALID_THRU_LABEL: "Válido Hasta", //Valid Through
    EXPIRY_HINT: "Ingrese la fecha de vencimiento", //Enter Date of Expiry
    SUBMIT_BTN_TITLE: "Enviar", //Submit
    REQ_FIELDS_ALERT: "Seleccione los campos obligatorios", //Please select required fields
    EXPIRY_DATE_ALERT: "Seleccione la Fecha de caducidad.", //Please select Date of Expiry
    DOC_IMAGE_ALERT: "Seleccione la imagen del documento.", //Please select document Image
    VALID_YEAR_ALERT: "Seleccione el año de caducidad válido", //Please select valid year of Expiry
    VALID_MONTH_ALERT: "Seleccione el mes de vencimiento válido", //Please select valid month of Expiry
    VALID_DAY_ALERT: "Seleccione el día de caducidad válido", //Please select valid day of Expiry
    VERIFICATION_MESSAGE:
      "Sus documentos están bajo revisión. Le notificaremos en su teléfono celular por SMS tan pronto como se verifiquen los documentos enviados.", //Your Documents are under verification, we will notify you on your phone via an SMS, once your uploaded details are verified.
    OK_BTN_TITLE: "OK", //OK
    TOTAL_RIDE_LABEL: "Viagens totales", //Total Rides
    YOUR_RATING_LABEL: "Tu Calificación", //Your Rating
    TOTAL_EARNING_LABEL: "Ganancias Totales", //Total Earnings
    SERVICE_TERMS_LABEL: "Términos de servicio", //terms of service
    HELP_LABEL: "¿Necesita Ayuda?", //need help?
    DOWNLOAD_LABEL: "Download del App", //Download App
    ACTIVATED_VEHICLES_LABEL: "Vehículos activados", //Activated Vehicles
    ADD_NEWCAR_LABEL: "Agregar nuevo vehículo", //Add New Vehicle
    UPDATED_AT_LABEL: "Actualizado en:", //Updated At:
    SERVICES_LABEL: "Servicios", //Services
    NO_ACTIVE_VEHICLE_MSG: "No tiene vehículo activo", //You have no active vehicle
    NO_OTHER_VEHICLES_MSG: "No hay otros vehículos", //No Other Vehicles
    OTHER_VEHICLES_LABEL: "Otros vehículos", //Other Vehicles
    VERIFIED_LABEL: "Verificado", //Verified
    APPROVAL_PENDING_LABEL: "Aprobación pendiente", //Pending Approval
    RIDE_WITH_LABEL: "Viajar con", //Riding with
    BOOK_TIME_LABEL: "Tiempo de reserva:", //Booking time
    RIDE_ID_LABEL: "ID DE VIAJE", //RIDE ID
    CASH_LABEL: "DINERO", //CASH
    DISTANCE_TRAVEL_LABEL: "Distancia recorrida:", //Distance Travelled
    TIME_TRAVEL_LABEL: "Tiempo recorrido", //Time Travelled
    BILL_DETAILS_LABEL: "Detalles de la factura", //Bill Details
    TOTAL_LABEL: "TOTAL", //TOTAL
    PAY_METHD_LABEL: "Método de pago", //Payment Method
    RECHARGE_BY_LABEL: "Recargado por", //Reacharged By
    DESCRIPTION_LABEL: "Descripción", //Description
    DRIVER_WALLET_LABEL: "Licencia de Conducir", //Driver's Wallet
    CURR_BAL_LABEL: "Saldo actual", //Current Balance
    EARNING_COL_TITLE1: "Día", //Day
    EARNING_COL_TITLE2: "Viaje", //Rides
    EARNING_COL_TITLE3: "Total", //Amount
    EARNING_COL_TITLE4: "Fecha", //Date
    STATEMT_LABEL: "Confirmación", //Statements
    YOUR_EARNINGS_LABEL: "Sus Ganancias", //Your Earnings
    SELECT_DOW_LABEL: "Seleccione un día de la semana", //Select any date of week
    TOTAL_OUTSTANDING_LABEL: "Total Pendiente", //Total Outstanding
    TOTAL_TRIPS_LABEL: "Total Viagnes", //Total Trips
    COMMISSION_LABEL: "Seleccionar tipo de comisión", // Select Commission Type
  },
  pt: {
    LOGIN_BTN: "Entrar", //Log In
    SIGNUP_BTN: "Registrar-se", //Sign Up
    NAVIGATE_TO_USER_BTN: "Passageiro", //User
    APP_WORKING_TITLE: "Como o App Funciona", //How App Works
    DRIVER_TIMELINE_TITLE_1: "Criar Conta", //Create Account
    DRIVER_TIMELINE_TITLE_2: "Ative seu Cadastro", //Turn your duty on
    DRIVER_TIMELINE_TITLE_3: "Visualiar Requisição", //View Request
    DRIVER_TIMELINE_TITLE_4: "Seguir Cliente", //Track Customer
    DRIVER_TIMELINE_TITLE_5: "Ver Recibo", //View Receipt
    DRIVER_FOOTER_SENTENCE:
      "Quais são os requisitos para trabalhar com a TopApp? Como a TopApp é mais seguro do que outros? Como o aplicativo funciona? Como receberei meus ganhos? Encontre respostas para todas as suas perguntas em nossa Central de Ajuda.", //What are the requirements to work with apporio Taxi ? How is apporio taxi safer than others? How does the app work ? How will I receive my earnings? Find answers to all your queries at our help center.
    DRIVER_FOOTER_QUESTN: "Dúvidas?", //Any Questions
    TAKE_LOOK_PHRASE: "Consulte nossas Perguntas Frequentes(FAQ)", // Take a look at our FAQs
    DRIVER_ADDRESS: "Endereço", //Address
    PROVINCE: "Estado", //Province
    SUBURB: "Bairro", //Suburb
    POSTAL_CODE: "CEP", //Postal Code
    NAVIGATE_TO_DRIVER_BTN: "Motorista", //Driver
    USER_LOGIN_BTN: "Passageiro", //User
    FARE_ESTIMATE_TITLE: "Estimativa", //Fare Estimate
    USER_LOGIN_TITLE: "Log In",
    USER_REGISTER_TITLE: "Registrar", //Register
    EMAIL_HINT: "📧 Seu e-mail", //Enter Email
    PHONE_HINT: "📳 Seu Celular", //Enter Phone
    PASSWORD_HINT: "Sua Senha", //Enter Password
    REFERRAL_CODE_HINT: "Código de Referência", //Enter Referral Code
    REFERRAL_CODE_HINT_IF_ANY: "Código de Referência(se existir)", // if any

    EMAIL_VALIDATION: "Por Favor, digite o e-mail cadastrado", //Please enter Registered Email ID
    PHONE_VALIDATION: "Por favor, digite o Celular cadastrado.", //Please enter Registered Phone Number
    PASSWORD_VALIDATION: "Por Favor, digite sua Senha", //Please enter Password
    PHONE_MIN_VALIDATION: "Informe um Celular Válido", // Enter valid phone number
    FNAME_HINT: "👤 Seu Primeiro Nome", //Enter First Name
    LNAME_HINT: "👤 Seu Último Nome", //Enter Last Name
    OTP_PHONE_HINT: "Por Favor, digite seu Celular", //Please enter Phone Number
    OTP_EMAIL_HINT: "Por Favor, digite seu E-mail", //Please enter Email ID
    DRIVER_SMOKER_QUESTN: "Permitir o Motorista Fumar", //Allow Driver to smoke
    MALE_LABEL: "Masculino", //Male
    FEMALE_LABEL: "Feminino", // Female
    SELF_SMOKER_LABEL: "Tipo de Fumante", //Type of Smoker
    SMOKER_OPTION: "Fumante",
    NO_SMOKER_OPTION: "Não Fumante", //Non-Smoker
    GENDER_LABEL: "Gênero", //Gender
    VIEW_DOCS_LABEL: "Exibir Documentos Obrigatórios", //View Required Docs
    TERMSCONDITION_LABEL: "Termos e Condições", //Terms & Conditions
    USER_MENU_OPTION1: "Perfil", //Profile
    USER_MENU_OPTION2: "Minha Conta", //My Account
    USER_MENU_OPTION3: "Sair", //Log Out
    USER_DRAWER_OPTION1: "Perfil", //Profile
    USER_DRAWER_OPTION2: "Reserve uma Viagem", //Book a Ride
    USER_DRAWER_OPTION3: "Histórico de Viagens", //Trips History
    USER_DRAWER_OPTION4: "Carteira", //Wallet
    DRIVER_MENU_OPTION1: "Perfil", //Profile
    DRIVER_MENU_OPTION2: "Minha Conta", //My Account
    DRIVER_MENU_OPTION3: "Sair", //Log Out
    DRIVER_DRAWER_OPTION1: "Gerenciar Veículos", //Manage Vehicles
    DRIVER_DRAWER_OPTION2: "Gerenciar Viagens", //Manage Trips
    DRIVER_DRAWER_OPTION3: "Carteira", //Wallet
    DRIVER_DRAWER_OPTION4: "Ganhos", //Earnings
    WELCOME_LABEL: "Bem-vindo", //Welcome
    PICKUP_HINT: "Entre com o Local de Embarque", //Enter pick location
    DROP_HINT: "Entre com o Local de Desembarque", // Enter drop location
    RIDE_TIME1: "Chamar Agora", // Ride Now
    RIDE_TIME2: "Agendar", // Ride Later
    DATE_LABEL: "Data", // Date
    TIME_LABEL: "Hora", // Time
    TODAY_LABEL: "Hoje", // Today
    TIME_CAPTION: "Hora", // Time
    AVAILABLE_BOOKINGS_LABEL: "Reservas Disponíveis", // Available Bookings
    DESCRIPTION_HERE_LABEL: "Descrição Aqui", // Some Description Here
    NO_SERVICE_MESSAGE: "Não Temos Cobertura nesta Área", // we are not serving in this area
    ETA_LABEL: "ETA", //ETA
    CONFIRM_BOOKING_BUTTON: "Confirmar Reserva", // Confirm Booking
    CONFIRM_OUTSTATION_BUTTON: "Continuar", // Continue
    FAV_LABEL: "Favorito", // Favourite
    FROM_LABEL: "De", // From
    TO_LABEL: "Para", // To
    APPLY_COUPON_LABEL: "Aplicar Cupom", //Apply Coupon
    RIDE_ESTIMATE: "Preço Estimado", // Estimate Fare
    PAYMENT_OPTION_LABEL: "Opção de Pagamento", // Payment Option
    ARRIVE_LABEL: "Chegar em", // Arrive In
    FAV_DRIVER_LABEL: "Selecione Motorista Favorito", //Select Favourite Driver
    APPLY_LABEL: "Aplicar", // Apply
    CHANGE_LABEL: "Alterar", // Change
    CONIRM_OUTSTAION_BUTTON: "Continuar", // Continue
    ONE_WAY_LABEL: "Somente Ida", //One Way
    RETURN_LABEL: "Jornada de volta", //Return Journey
    SAVE_BTN: "Salvar", //Save
    ACTIVE_TRIP_LABEL: "Ativo", //Active
    PAST_TRIP_LABEL: "Anteriores", //Past
    SCHEDULED_TRIP_LABEL: "Programado", //Scheduled
    NO_JOURNEYS_MESSAGE: "Você não tem nenhuma Viagem", //You don't have any Journeys
    WALLET_LABEL: "Carteira", //Wallet
    CURRENT_BAL_LABEL: "Saldo Atual:", //Current Balance
    TRANSACTION_LABEL1: "Todas as Transações", //All Transactions
    TRANSACTION_LABEL2: "R$ Entrada", //Money In
    TRANSACTION_LABEL3: "R$ Saída", //Money Out
    TABLE_TITLE1: "Sr.No.", //Sr.No.
    TABLE_TITLE2: "Total", //Amount
    TABLE_TITLE3: "Data", //Date
    TABLE_TITLE4: "Tipo", //Type
    TABLE_TITLE5: "Motivo", //Reason
    DRIVER_HOME_INTRO:
      "Aproveite ao máximo seu tempo na rua com solicitações do maior número de Motoristas ativos.", //Make the most of your time on the road with requests from the largest number of active riders
    DRIVER_SIGNBTN: "Cadastre-se para dirigir", //Sign Up to Drive
    DRIVER_LOGIN_TITLE: "LOGIN MOTORISTA", //DRIVER LOGIN
    FORGOT_PASSWORD_LABEL: "Esqueceu a senha ?", //Forgot Password ?
    SIGNUP_DRIVER: "Cadastre-se para dirigir", //sign up for new driver
    PHOTO_UPLOAD_LABEL: "faça upload de uma foto de boa qualidade", //upload a good quality photo of you
    DRIVER_SIGNUP_TITLE: "MOTORISTA CADASTRE-SE", //DRIVER SIGN UP
    AREA_LABEL: "Área", //Area
    CREATE_ACC_LABEL: "Criar uma nova Conta", //Create a new account
    STEPPER_INDICATOR1: "Criação da Conta", //Account Creation
    STEPPER_INDICATOR2: "Adicione seu Veículo", //Add your vehicle
    STEPPER_INDICATOR3: "Documentos Pessoais", //Personal Documents
    STEPPER_INDICATOR4: "Documentos Veículo", //Vehicle Documents
    STEPPER_INDICATOR5: "Verificação de documentos", //Document Verification
    ADD_CAR_LABEL: "ADICIONE SEU CARRO", //ADD YOUR CAR
    NEW_CAR_LABEL: "Novo Veículo", //New Vehicle
    EXISTING_LABEL: "Veículo Existente", //Existing Vehicle
    CREATE_VEHICLE_LABEL: "Cadastrar Veículo",
    VEHICLE_TYPE_LABEL: "Tipo Veículo", //Vehicle Type
    NONE_LABEL: "Nenhum", //None
    VEHICLE_MAKE_LABEL: "Marca Veículo", //Vehicle Make
    VEHICLE_MODEL_LABEL: "Modelo Veículo", //Vehicle Model
    SERVICE_SELECT_LABEL: "Selecione Serviços", //Select Services
    ADD_CAR_DETAILS: "Adicionar Detalhes do Veículo", //Add Vehicle Details
    UPLOAD_CAR_IMAGE_LABEL: "Carregar Imagem do Veículo", //Upload Vehicle image
    CAR_COLOR_HINT: "Inserir Cor do Veículo", //Enter Vehicle Color
    VEHICLE_NUMBER_HINT: "Informe a Placa do Veículo", //Enter Vehicle Number
    NUMBER_PLATE_UPLOAD_IMAGE: "Faça Upload da Imagem da Placa", //Upload Vehicle Number Plate image
    ADD_VEHICLE_LABEL: "Adicionar Veículo", //Add Vehicle
    CAR_ID_HINT: "Entre com o Veículo ID", //Enter Vehicle ID
    P_DOC_LABEL: "DOCUMENTOS PESSOAIS", //PERSONAL DOCUMENTS
    V_DOC_LABEL: "DOCUMENTOS DO VEÍCULO", //VEHICLE DOCUMENTS
    NEXT_LABEL: "Próximo", //Next
    NO_DATA_LABEL: "Não há dados para Exibir", //No data to show
    UPLOAD_DOC_LABEL: "Upload de Documentos", //Upload Documents
    UPLOAD_DOC_MESSAGE:
      "Faça o upload de uma imagem de boa qualidade dos seus documentos e informe sua data de validade.", //Please upload a good quality image of your documents & enter the expiry date of the document.
    UPLOAD_DOCIMAGE_LABEL:
      "Selecione uma imagem de boa qualidade e informe sua data de validade.", //Select document image of good quality & expiry date of the document.
    VALID_THRU_LABEL: "Valido Até", //Valid Through
    EXPIRY_HINT: "Informe a Data de Validade", //Enter Date of Expiry
    SUBMIT_BTN_TITLE: "Enviar", //Submit
    REQ_FIELDS_ALERT: "Por favor, selecione os Campos Obrigatórios", //Please select required fields
    EXPIRY_DATE_ALERT: "Por favor, selecione a Data de Validade.", //Please select Date of Expiry
    DOC_IMAGE_ALERT: "Por favor, selecione a imagem do Documento.", //Please select document Image
    VALID_YEAR_ALERT: "Selecione o Ano de Expiração da Validade", //Please select valid year of Expiry
    VALID_MONTH_ALERT: "Selecione o mês de Expiração da Validade", //Please select valid month of Expiry
    VALID_DAY_ALERT: "Selecione o Dia de Expiração da Validade", //Please select valid day of Expiry
    VERIFICATION_MESSAGE:
      "Seus documentos estão sob Análise. Nós o notificaremos no seu cellular via SMS, assim que os documentos enviados forem verificados.", //Your Documents are under verification, we will notify you on your phone via an SMS, once your uploaded details are verified.
    OK_BTN_TITLE: "OK", //OK
    TOTAL_RIDE_LABEL: "Total Viagens", //Total Rides
    YOUR_RATING_LABEL: "Sua Avaliação", //Your Rating
    TOTAL_EARNING_LABEL: "Total Ganhos", //Total Earnings
    SERVICE_TERMS_LABEL: "Termos do Serviço", //terms of service
    HELP_LABEL: "Necessita de Ajuda?", //need help?
    DOWNLOAD_LABEL: "Download do App", //Download App
    ACTIVATED_VEHICLES_LABEL: "Veículos Ativados", //Activated Vehicles
    ADD_NEWCAR_LABEL: "Adicionar Novo Veículo", //Add New Vehicle
    UPDATED_AT_LABEL: "Atualizado em:", //Updated At:
    SERVICES_LABEL: "Serviços", //Services
    NO_ACTIVE_VEHICLE_MSG: "Você não tem veículo ativo", //You have no active vehicle
    NO_OTHER_VEHICLES_MSG: "Nenhum outro Veículo", //No Other Vehicles
    OTHER_VEHICLES_LABEL: "Outros Veículos", //Other Vehicles
    VERIFIED_LABEL: "Verificado", //Verified
    APPROVAL_PENDING_LABEL: "Aprovação Pendente", //Pending Approval
    RIDE_WITH_LABEL: "Viajando com", //Riding with
    BOOK_TIME_LABEL: "Hora Reserva:", //Booking time
    RIDE_ID_LABEL: "VIAGEM ID", //RIDE ID
    CASH_LABEL: "DINHEIRO", //CASH
    DISTANCE_TRAVEL_LABEL: "Distância Percorrida:", //Distance Travelled
    TIME_TRAVEL_LABEL: "Tempo de Viagem", //Time Travelled
    BILL_DETAILS_LABEL: "Detalhes Fatura", //Bill Details
    TOTAL_LABEL: "TOTAL", //TOTAL
    PAY_METHD_LABEL: "Forma de Pagamento", //Payment Method
    RECHARGE_BY_LABEL: "Recarregado por", //Reacharged By
    DESCRIPTION_LABEL: "Descrição", //Description
    DRIVER_WALLET_LABEL: "Carteira Motorista", //Driver's Wallet
    CURR_BAL_LABEL: "Saldo atual", //Current Balance
    EARNING_COL_TITLE1: "Dia", //Day
    EARNING_COL_TITLE2: "Viagens", //Rides
    EARNING_COL_TITLE3: "Total", //Amount
    EARNING_COL_TITLE4: "Data", //Date
    STATEMT_LABEL: "Confirmação", //Statements
    YOUR_EARNINGS_LABEL: "Seus Ganhos", //Your Earnings
    SELECT_DOW_LABEL: "Selecione um dia da semana", //Select any date of week
    TOTAL_OUTSTANDING_LABEL: "Total Pendente", //Total Outstanding
    TOTAL_TRIPS_LABEL: "Total Viagnes", //Total Trips
    COMMISSION_LABEL: "Selecionar Tipo de Comissão", // Select Commission Type
  },
});

export const saveLanguage = (languageCode) => {
  Strings.setLanguage(languageCode);
};

export const getLaungage = () => {
  return Strings.getLanguage();
};

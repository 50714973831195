// import { getTheme } from "../../../../managers/theme/ThemeManager";
// import { PADDING, MARGIN } from "../../../../values/Typography";

const styles = theme => {
  // const configTheme = getTheme();

  const drawerWidth = 300;

  return {
    root: {
      display: "flex"
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      marginLeft: drawerWidth,
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`
      }
    },
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing.unit * 3
    },
    textWhite: {
      color: "#fff !important"
    },
    YourEarn: {
      fontWeight: "600",
      color: "#26699d",
      marginTop: 10
    },
    hrOpacity: {
      color: "#dbdbdb",
      opacity: "0.5"
    },
    SelectAnyDateGrid: {
      height: "auto"
    },
    SelectAnyDateText: {
      fontWeight: "600",
      color: "#26699d",
      marginTop: 10
    },
    FirstClass: {
      display: "flex",
      justifyContent: "center"
    },
    outstandingAmt: {
      fontWeight: 600,
      color: "#4a4a4a",
      paddingTop: 10
    },
    colorEarn: {
      color: "#e84c3d"
    },
    earnPad: {
      padding: 10
    },
    borderRightEarn: {
      borderRight: "1px solid lightgrey"
    },
    TotalTrip: {
      fontWeight: 600,
      color: "#4a4a4a"
    },
    TotalWeeklyRide: {
      fontWeight: 600,
      color: "#f4a522"
    },
    DrawerOption: {
      fontWeight: 600,
      color: "#4a4a4a",
      paddingbottom: "20px"
    },
    TotalWeeklyAmt: {
      fontWeight: 600,
      color: "#2dcc70"
    },
    StatementLabel: {
      fontWeight: "600",
      color: "#26699d"
    },
    horizontalColor: {
      color: "#eaeaea",
      opacity: "0.5"
    },
    hrUP: {
      color: "#f6f6f6",
      width: "50%",
      opacity: "0.5"
    }
  };
};

export default styles;

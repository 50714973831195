import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Grid,
  Fab,
  CardMedia,
  Icon,
  TextField,
  Button,
  ButtonBase,
  Card,
  CardContent
} from "@material-ui/core";
import { ManageTripStyles, DriverWalletStyles,AddCarStyles } from "../../../styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import CustomLoader from "../../../../UI/custom/CustomLoader";
import { Redirect } from "react-router-dom";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import {APPORIO_TAXI_APIS} from '../../../../managers/api/ApiEndPoints';
import CustomSnackbar from '../../../../UI/custom/CustomSnackbar';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from '../../../../../src/CheckoutForm';
import StripeCheckout from 'react-stripe-checkout';
import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
        textAlign: "left",
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class AddMoneyCard extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    value: 0,
    checked: false,
    money: "",
    isLoading:false,
    transactionLog:[],
    showAddMoney:false,
    showService:false,
    showSnackbar: false,
    snackbarVariant: "error",
    snakBarMessage: "",
    dismissAddCard:false
  };


  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false
    });
  };

  onToken = (token) => {
    console.log("@@@@@@@@"+JSON.stringify(token))
    this.setState({ isLoading: true });
  
    ApiManager.callPostApi(
      "CheckoutFOrm",
      "Save USer Card api",
      ApiEndPoints.APPORIO_TAXI_APIS.USER_SAVECARD,
      {
        "payment_option":"STRIPE",
         "token":token.id
      }
    ).then(res => {
      console.log("GoGoGoGoGoGoGo"+JSON.stringify(res))
      if (res.result === "1") {
        this.setState({isLoading:false,
            dismissAddCard:true,
            snackbarVariant:'success',
            showSnackbar:true,
            snakBarMessage:res.message},()=>this.props.cardSaveCallback(this.state.dismissAddCard))
      } else {
        this.setState({isLoading:false,
          snackbarVariant:'error',
          showSnackbar:true,
          snakBarMessage:res.message})
      }
    });
  }


  render() {
    console.log("driver home render", this.props);
    const { classes } = this.props;
    const { value } = this.state;
    const { checked } = this.state;


return(

 <div style={{justifyContent:'center',alignItems:'center',marginTop:'50px'}}>
           <CustomSnackbar
            showSnackbar={this.state.showSnackbar}
            variant={this.state.snackbarVariant}
            message={this.state.snakBarMessage}
            onClose={this.handleSnackBarClose}/>

          <CustomLoader showLoader={this.state.isLoading} />
<StripeCheckout
token={this.onToken}
stripeKey="pk_test_32bSkOd3mR4RjF2IowXhoAvC00A6LyljsT"/>
</div> 
);
  }
}

export default withStyles(AddCarStyles)(AddMoneyCard);

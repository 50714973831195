import React from "react";
import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  Icon,
  Paper,
  IconButton,
  TextField,
  Select,
  MenuItem
} from "@material-ui/core";
import { DriverTextFieldStyles } from "../../../styles";

class TextInputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBorder: null,
      visibility: "visibility_off",
      phonecode: "+1", //this.props.defaultCode,
      secureMode: this.props.type ? this.props.type : null
    };
  }

  toggleVisibility() {
    if (this.state.visibility == "visibility") {
      this.setState({ visibility: "visibility_off", secureMode: "password" });
    } else {
      this.setState({ visibility: "visibility", secureMode: "text" });
    }
  }

  handleChange(event) {
    console.log(event.target.value);
    let index = this.props.dataSet.findIndex(
      res => res.phonecode === event.target.value
    );
    //console.log(this.props.dataSet[this.props.dataSet.findIndex(res => res.phonecode === event.target.value)].country_area[0].AreaName)
    this.setState({ phonecode: event.target.value }, () => {
      this.props.getCountryCode(event.target.value, index);
    });
  }

  // componentDidMount(){
  //   console.log("!!@@"+JSON.stringify(this.props.defaultCode))
  // }

  render() {
    const {
      classes,
      iconName,
      placeHolder,
      secure,
      callback,
      maxLimit,
      type,
      phoneNumber,
      numberInput,
      password,
      dataSet,
      phonecode
    } = this.props;

    return (
      <Grid
        container
        alignItems="center"
        className={
          !iconName ? classes.noIconFieldContainer : classes.fieldContainer
        }
      >
        {iconName ? (
          <Grid item>
            <Icon>{iconName}</Icon>
          </Grid>
        ) : phoneNumber ? (
          <Select
            style={{ fontSize: "1rem", marginTop: 5 }}
            value={this.state.phonecode}
            onChange={event => this.handleChange(event)}
            inputProps={{
              name: "phonecode",
              id: "prefix-simple"
            }}
          >
            {dataSet.length > 0 &&
              dataSet.map(country => {
                return (
                  <MenuItem value={country.phonecode}>
                    {country.phonecode}
                  </MenuItem>
                );
              })}
          </Select>
        ) : null}
        {/* <input

                type={numberInput?"tel":"text"}
                maxlength={numberInput?"10":"30"}
                pattern={phoneNumber?"[1-9]{1}[0-9]{9}":null}
                    className={classes.textFeild}
                    onChange={(evt)=>this.props.callback(evt.target.value)}
                    placeholder={placeHolder}
                    type={(secure)?this.state.secureMode:'none'}
                    style={{ width:'auto', borderBottom: '1px solid silver' }}
                /> */}
        <TextField
          type={this.state.secureMode}
          placeholder={placeHolder}
          value={this.props.value}
          InputProps={{
            maxLength: 10,
            style: {
              fontSize: 15,
              fontFamily: "Montserrat",
              fontWeight: "bold"
            }
          }}
          InputLabelProps={{
            style: {
              fontSize: 15,
              fontFamily: "Montserrat",
              fontWeight: "bold"
            }
          }}
          className={classes.detailFields}
          onChange={evt => this.props.callback(evt.target.value)}
          margin="normal"
          maxLength={this.props.maxLength}
          visibility={this.state.visibility}
        />
        {secure ? (
          <IconButton
            className={classes.showPasswordButton}
            aria-label="show/hide_password"
            onClick={() => {
              this.toggleVisibility();
            }}
          >
            <Icon>{this.state.visibility}</Icon>
          </IconButton>
        ) : null}
      </Grid>
    );
  }
}

export default withStyles(DriverTextFieldStyles)(TextInputComponent);

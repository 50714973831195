// import { getTheme } from "../../../../managers/theme/ThemeManager";
// import { PADDING, MARGIN } from "../../../../values/Typography";

const styles = theme => {
  // const configTheme = getTheme();

  const drawerWidth = 300;

  return {
    root: {},
    nested: {
      paddingLeft: theme.spacing.unit * 4
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      marginLeft: drawerWidth,
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`
      }
    },
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing.unit * 3
    },
    textWhite: {
      color: "#fff !important"
    }
  };
};

export default styles;

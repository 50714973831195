import React from "react";
import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  Icon,
  IconButton,
  Grid,
  CardMedia,
  Select
} from "@material-ui/core";
import { DriverHomeHeaderStyles } from "../../../styles";

import { App_logo } from "../../../../values/Icons";
import { Link, withRouter } from "react-router-dom";
import * as LanguageManager from "../../../../managers/language/LanguageManager";
import CustomLoader from "../../../custom/CustomLoader";
import { CUSTOM_FONT } from "../../../../values/Typography";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";

const DRIVER_LOGIN = "/driver/login";
//const USER_HOME= "/ApporioTest";
// const USER_HOME= "/TransiApp";
const USER_HOME = "/";

class HomeHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showBorder: null,
      mobileAnchor: false,
      isMobileMenuOpen: false,
      anchorEl: null,
      setAnchorEl: null,
      mobileMoreAnchorEl: null,
      setMobileMoreAnchorEl: null,
      locale: SessionManager.getSessionData(SessionKeys.LANGUAGE_LOCALE)
        ? SessionManager.getSessionData(SessionKeys.LANGUAGE_LOCALE)
        : this.props.languages[0].locale,
      open: false,
      isLoading: false
    };
    this.navbarRef = React.createRef();
  }

  handleChange = event => {
    this.setState(
      { [event.target.name]: event.target.value, isLoading: true },
      () => {
        LanguageManager.saveLanguage(event.target.value);
        SessionManager.saveSessionData(
          SessionKeys.LANGUAGE_LOCALE,
          event.target.value
        );
        this.setState({
          isLoading: false,
          redirectTo: window.location.pathname
        });
      }
    );
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleProfileMenuOpen(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleMobileMenuClose() {
    // setMobileMoreAnchorEl(null);
    this.setState({ setMobileMoreAnchorEl: null });
  }

  handleMenuClose() {
    this.setState({ setAnchorEl: null });
    this.handleMobileMenuClose();
  }

  handleMobileMenuOpen(event) {
    this.setState({ setMobileMoreAnchorEl: event.currentTarget });
  }
  renderMobileMenu = styles => {
    return (
      <Menu
        anchorEl={this.state.setMobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={
          this.state.setMobileMoreAnchorEl
            ? this.state.setMobileMoreAnchorEl
            : false
        }
        onClose={() => this.handleMobileMenuClose()}
      >
        <MenuItem onClick={() => this.props.history.push(USER_HOME)}>
          <Typography
            variant="body1"
            className={styles.menuElement}
            align="left"
          >
            {LanguageManager.Strings.NAVIGATE_TO_USER_BTN}
          </Typography>
        </MenuItem>

        <MenuItem>
          {/* <p>SignUp</p> */}
          <Link className="link-text" to="/driver/signup">
            <Typography
              variant="body1"
              className={styles.menuElement}
              align="left"
              style={{ fontWeight: "bold" }}
            >
              {LanguageManager.Strings.SIGNUP_BTN}
            </Typography>
          </Link>
        </MenuItem>
        <MenuItem
          onClick={() => this.props.history.push(DRIVER_LOGIN, { id: 2 })}
          style={{ backgroundColor: "#3F51B5" }}
        >
          <Typography
            variant="body1"
            className={styles.logInElement}
            align="left"
          >
            {LanguageManager.Strings.LOGIN_BTN}
          </Typography>
          {/* <p>LogIn</p> */}
        </MenuItem>
      </Menu>
    );
  };

  render() {
    console.log("lang1234567890", this.props);
    const { classes, mayaMode } = this.props;

    if (this.state.redirectTo) {
      window.location = this.state.redirectTo;
    }

    if (mayaMode) {
      return (
        <AppBar
          elevation={2}
          justify="center"
          alignItems="center"
          style={{
            backgroundColor: "white",
            paddingTop: "2%",
            paddingBottom: "2%"
          }}
          position="static"
        >
          <Grid container direction="row" justify="space-around">
            <CardMedia
              className={classes.media}
              image={App_logo}
              title="App_logo"
            />

            <div>
              <Button
                color={this.state.homeBtn}
                className={classes.mayaButton}
                disableRipple={true}
                onClick={() => this.props.history.push(USER_HOME)}
              >
                Home
              </Button>
              <Button
                color={this.state.abtBtn}
                className={classes.mayaButton}
                disableRipple={true}
              >
                About Us
              </Button>
              <Button
                color={this.state.driveBtn}
                className={classes.mayaButton}
                disableRipple={true}
                onClick={() => this.props.history.push(USER_HOME)}
              >
                Go to User
              </Button>
              <Button
                color={this.state.contactBtn}
                className={classes.mayaButton}
                disableRipple={true}
              >
                Contact Us
              </Button>
              <Link className="link-text" to="/driver/signup">
                <Button
                  color={this.state.contactBtn}
                  className={classes.mayaButton}
                  disableRipple={true}
                >
                  SignUp
                </Button>
              </Link>
            </div>
          </Grid>
        </AppBar>
      );
    }

    return (
      <div className={classes.root}>
        <AppBar
          ref={this.navbarRef}
          className={classes.navbar}
          position="static"
        >
          <CustomLoader showLoader={this.state.isLoading} />
          <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
            {/* <Typography>
              <img
                height="100"
                width="100"
                src={this.props.icon}
                // src={TAXI_IMAGE_URL + this.props.icon}
                className={classes.companyLogo}
              />
            </Typography> */}
            <Link to="/" style={{ textDecoration: "none" }}>
              <CardMedia
                className={classes.unitLogo}
                // image={TAXI_IMAGE_URL + this.props.icon}
                image={this.props.icon}
              />
            </Link>
            {/* <div style={{ flexGrow: 1}} /> */}
            <div className={classes.headerLinks}>
              <Select
                style={{ fontFamily: CUSTOM_FONT }}
                open={this.state.open}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
                value={this.state.locale}
                onChange={event => this.handleChange(event)}
                inputProps={{
                  name: "locale",
                  id: "demo-controlled-open-select"
                }}
              >
                {this.props.languages.map((item, i) => (
                  <MenuItem
                    key={i}
                    style={{ fontFamily: CUSTOM_FONT }}
                    value={item.locale}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <Button
                variant={this.state.showBorderOne}
                onClick={() => this.props.history.push(USER_HOME)}
                color="primary"
                style={{ backgroundColor: "white" }}
                className={classes.buttonLink}
                onMouseOver={() => {
                  this.setState({ showBorderOne: "outlined" });
                }}
                onMouseOut={() => {
                  this.setState({ showBorderOne: null });
                }}
              >
                {LanguageManager.Strings.NAVIGATE_TO_USER_BTN}
              </Button>

              <Button
                variant={this.state.showBorderThree}
                color="primary"
                style={{ backgroundColor: "white" }}
                className={classes.buttonLink}
                onMouseOver={() => {
                  this.setState({ showBorderThree: "outlined" });
                }}
                onMouseOut={() => {
                  this.setState({ showBorderThree: null });
                }}
              >
                <Link className="link-text" to="/driver/signup">
                  {LanguageManager.Strings.SIGNUP_BTN}
                </Link>
              </Button>

              <Button
                variant="contained"
                className={classes.buttonBlock}
                onClick={() => this.props.history.push(DRIVER_LOGIN, { id: 2 })}
              >
                {LanguageManager.Strings.LOGIN_BTN}
              </Button>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={evt => this.handleMobileMenuOpen(evt)}
              >
                <Icon>more_vert</Icon>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {this.renderMobileMenu(classes)}
      </div>
    );
  }
}

// export default withStyles(DriverHomeHeaderStyles)(HomeHeader);
export default withStyles(DriverHomeHeaderStyles)(withRouter(HomeHeader));

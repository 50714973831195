import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Grid,
  Fab,
  CardMedia,
  Icon,
  Button,
  Avatar,
  Card
} from "@material-ui/core";
import { TrackRideStyles } from "../../../../styles";
import DriverLayout from "../../common/DriverLayout";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {
  LIMO_CAR,
  CAR_TRIP,
  VEHICLES_GRAPH
} from "../../../../../values/Icons";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import NoReq from "../../common/NoReq";
import { GOOGLE_API_KEY } from "../../../../../constants/AppConstants";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
        textAlign: "left"
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class TrackRide extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {};

  handleChange = (event, value) => {
    this.setState({ value });
  };
  render() {
    console.log("driver home render", this.props);
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <DriverLayout>
        <Grid container md={12}>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: "600", color: "#26699d", marginTop: -10 }}
          >
            Track Ride
          </Typography>
        </Grid>
        <hr style={{ color: "#eaeaea" }} />
        <Grid container md={12}>
          <Grid
            item
            md={7}
            style={{
              position: "relative",
              padding: 10
            }}
          >
            <Map
              style={{
                width: "100%",
                height: "85vh",
                boxShadow: "0px 0px 10px 0.5px rgba(0,0,0,0.2)"
              }}
              google={this.props.google}
              initialCenter={{
                lat: 28.4595,
                lng: 77.0266
              }}
              zoom={10}
            />
          </Grid>
          <Grid item md={5} style={{ padding: "10px 10px 10px 40px" }}>
            <Grid>
              <Paper>
                <Grid container md={12} style={{ padding: "10px 10px 0 10px" }}>
                  <Grid item md={4} align="left">
                    <Avatar
                      src="https://banner2.kisspng.com/20180630/ltq/kisspng-computer-icons-user-avatar-clip-art-skincare-cartoon-5b371025a6d8a7.5354815915303352696834.jpg"
                      style={{
                        width: 60,
                        height: 60,
                        bottom: 0
                      }}
                    />
                  </Grid>
                  <Grid item md={8} align="left">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Card
                        style={{
                          marginTop: 5,
                          width: 15,
                          height: 15,
                          borderRadius: 15 / 2,
                          backgroundColor: "#2dcc70"
                        }}
                      />
                      <Typography variant="subtitle1">Pick Location</Typography>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Card
                        style={{
                          marginTop: 5,
                          width: 15,
                          height: 15,
                          borderRadius: 15 / 2,
                          backgroundColor: "#F14741"
                        }}
                      />
                      <Typography variant="subtitle1">Drop Location</Typography>
                    </div>
                  </Grid>
                </Grid>
                <hr />
                <Grid container md={12}>
                  <Grid
                    item
                    md={4}
                    style={{ padding: 5, borderRight: "1px solid lightgrey" }}
                  >
                    <Typography variant="body1">Payment Method</Typography>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: "600", color: "#2dcc70" }}
                    >
                      CASH
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    style={{ padding: 5, borderRight: "1px solid lightgrey" }}
                  >
                    <Typography variant="body1">Dist. Travelled</Typography>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: "600", color: "#666" }}
                    >
                      10 km
                    </Typography>
                  </Grid>
                  <Grid item md={4} style={{ padding: 5 }}>
                    <Typography variant="body1">Time Travelled</Typography>
                    <Typography
                      variant="h5"
                      style={{ fontWeight: "600", color: "#F14741" }}
                    >
                      1 H 20 M
                    </Typography>
                  </Grid>
                  <hr />
                </Grid>
              </Paper>
            </Grid>
            <Grid style={{ paddingTop: 20 }}>
              <Paper>
                <Grid container md={12} style={{ padding: "10px 10px 0 10px" }}>
                  <Typography variant="h6">Upcoming rides</Typography>
                </Grid>
                <hr />
                <Grid align="left" container md={12} style={{ padding: 10 }}>
                  <NoReq />
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </DriverLayout>
    );
  }
}

export default withStyles(TrackRideStyles)(
  GoogleApiWrapper({
    apiKey: GOOGLE_API_KEY
  })(TrackRide)
);

import React, { Component } from "react";
import {
  CardMedia,
  Paper,
  CardActionArea,
  Typography,
  InputBase,
  Button,
  withStyles,
  Card
} from "@material-ui/core";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Redirect } from "react-router-dom";

import DialogForm from "../../../custom/DialogForm";
import OtpVerification from "../otpverification/OtpVerification";
import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import CustomLoader from "../../../custom/CustomLoader";
import { getPlayerId } from "../../../../managers/OneSignalManager";
import CustomSnackbar from "../../../custom/CustomSnackbar";
import { APPORIO_TAXI_APIS } from "../../../../managers/api/ApiEndPoints";

class UserRegister extends Component {
  state = {
    selectedGender: -1,
    selectedSmoke: -1,
    showForm: false,
    phoneNumber: "",
    first_name: "",
    last_name: "",
    password: "",
    email: "",
    accept_smoke: 0,
    isLoading: false,
    showSnackbar: false,
    snackbarVariant: "error",
    snakBarMessage: "",
    enableSocialRegister:false,
  };

  componentDidMount(){
this.handleSigninSocial()
  }

  checkSession = () => {
    console.log(
      "Session token",
      SessionManager.getSessionData(SessionKeys.ACCESS_TOKEN)
    );
    if (SessionManager.getSessionData(SessionKeys.ACCESS_TOKEN)) {
      return <Redirect to="/Home" />;
    }
  };

  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false
    });
  };

  checkSession = () => {
    console.log(
      "Session token",
      SessionManager.getSessionData(SessionKeys.ACCESS_TOKEN)
    );
    console.log(
      "Session token",
      SessionManager.getSessionData(SessionKeys.USER_DATA)
    );
    if (SessionManager.getSessionData(SessionKeys.ACCESS_TOKEN)) {
      return <Redirect to="/user_booking" />;
    }
  };

  executeSpecificDetailsApi = () => {
    this.setState({ isLoading: true });
    ApiManager.callPostApi(
      "Social Login  Component",
      "ExecuteSpecificDetails APi",
      APPORIO_TAXI_APIS.USER_DETAILS,
      {
        player_id: SessionManager.getSessionData(SessionKeys.PLAYER_ID),
        unique_no: Math.random(),
        package_name: "com.appoiro.website"
      }
    )
      .then(res => {
        console.log("xsxsxs"+JSON.stringify(res))
        if (res.result === "1") {
          SessionManager.saveSessionData(
            SessionKeys.USER_DATA,
            JSON.stringify(res.data)
          );

          this.setState({ isLoading: false });
          this.checkSession();
        }
      })
      .catch(err => alert(JSON.stringify(err)));
  };

  handleSigninSocial(){
    this.setState({ isLoading: true });
    ApiManager.callPostApi(
      "Social Login  Component",
      "Social Login APi",
      ApiEndPoints.APPORIO_TAXI_APIS.USER_SOCIALOGIN,
      {
        social_id:(this.props.profileData.googleId)?this.props.profileData.googleId:this.props.profileData.id,
      }
    )
      .then(res => {
        console.log("u&U&U&U&U"+JSON.stringify(res))
        if (res.result ==="1") {
          this.setState({
            isLoading:false,
            showSnackbar:true,
            snackbarVariant:'success',
            snakBarMessage:res.message
          })
          
          SessionManager.saveSessionData(
            SessionKeys.ACCESS_TOKEN,
            res.data.access_token
          );
          this.executeSpecificDetailsApi();

        }else {
          this.setState({
            isLoading:false,
            showSnackbar:true,
            snackbarVariant:'error',
            snakBarMessage:res.message
          })
        }
      })
      .catch(err => alert(JSON.stringify(err)));
  }

  handleSocialRegister = () => {
    this.setState({ isLoading: true });
    ApiManager.callPostApi(
      "Social Signup  Component",
      "Social Signup APi",
      ApiEndPoints.APPORIO_TAXI_APIS.USER_SOCIALREGISTER,
      {
        social_id:(this.props.profileData.googleId)?this.props.profileData.googleId:this.props.profileData.id,
        platfrom:this.props.platFormId, // {1 for normal , 2 for google , 3 for facebook}
        first_name: (this.props.profileData.givenName)?this.props.profileData.givenName:this.props.profileData.name,
        user_gender:this.state.selectedGender + 1,
        smoker_type:this.state.selectedSmoke,
        allow_other_smoker:this.state.accept_smoke,
        phone:this.state.phoneNumber,
        email:(this.props.profileData.email)?this.props.profileData.email:'akshay.singh@apporio.com',
        country_id:"4"
      }
    )
      .then(res => {
        console.log("b@b@b@b@"+JSON.stringify(res))
        if (res.result ==="1") {
          this.setState({
            isLoading:false,
            showSnackbar:true,
            snackbarVariant:'success',
            snakBarMessage:res.message
          })
          this.handleSigninSocial();
        }else {
          this.setState({
            isLoading:false,
            showSnackbar:true,
            snackbarVariant:'error',
            snakBarMessage:res.message
          })
        }
      })
      .catch(err => alert(JSON.stringify(err)));
  };


  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
               {this.checkSession()}
          <CustomLoader showLoader={this.state.isLoading} />
          <CustomSnackbar
          showSnackbar={this.state.showSnackbar}
          variant={this.state.snackbarVariant}
          message={this.state.snakBarMessage}
          onClose={this.handleSnackBarClose}
        />
        <CardActionArea
          onClick={() => {
            this.setState({ showForm: true });
          }}
          style={{
            padding: "2%",
            display: "flex",
            alignItems: "center"
          }}
        >
          <img
            src="http://aux.iconspalace.com/uploads/email-icon-256-1250130517.png"
            style={{ width: 20, height: 20, backgroundSize: "contain" }}/>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginLeft: 15
            }}>
            <Typography
              style={{
                textAlign: "left",
                color: "grey",
                fontSize: "0.8rem",
                width: "100%",
                borderBottom: "1px solid grey"
              }}>
              {this.state.phoneNumber === ""
                ? "Please Enter your number"
                : this.state.phoneNumber}
            </Typography>
          </div>
        </CardActionArea>


        <div style={{ display: "flex ", padding: "0.5em" }}>
          <Typography style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
            Gender
          </Typography>
          {["Male", "Female"].map((val, index) => (
            <CardActionArea
              onClick={() => {
                this.setState({ selectedGender: index });
              }}
              style={{ display: "flex", marginLeft: "1rem" }}
            >
              <Card
                style={{
                  width: 15,
                  height: 15,
                  borderRadius: 15 / 2,
                  backgroundColor:
                    index == this.state.selectedGender ? "#00649A" : "#CCCCCC"
                }}
              />
              <Typography style={{ fontSize: "0.8rem", marginLeft: "0.5rem" }}>
                {val}
              </Typography>
            </CardActionArea>
          ))}
        </div>

        <div style={{ display: "flex ", padding: "0.5em" }}>
          <Typography style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
            Smoke
          </Typography>
          {["Smoker", "Non-Smoker"].map((val, index) => (
            <CardActionArea
              onClick={() => {
                this.setState({ selectedSmoke: index });
              }}
              style={{ display: "flex", marginLeft: "1rem" }}
            >
              <Card
                style={{
                  width: 15,
                  height: 15,
                  borderRadius: 15 / 2,
                  backgroundColor:
                    index == this.state.selectedSmoke ? "#00649A" : "#CCCCCC"
                }}
              />
              <Typography style={{ fontSize: "0.8rem", marginLeft: "0.5rem" }}>
                {val}
              </Typography>
            </CardActionArea>
          ))}
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.accept_smoke}
              onChange={this.handleChange("accept_smoke")}
              value="accept_smoke"
            />
          }
          label="Allow Driver to smoke"
        />

        <Button
          onClick={this.handleSocialRegister}
          style={{
            margin: "5%",
            backgroundColor: "#1F447F",
            color: "white",
            fontWeight: "bold"
          }}>
          Register
        </Button>

        <DialogForm
          visible={this.state.showForm}
          onOutsideTouch={() => {
            this.setState({ showForm: !this.state.showForm });
          }}
        >
          <OtpVerification
            getOtp={phoneNumber =>
              this.setState({
                phoneNumber: phoneNumber,
                showForm: !this.state.showForm
              })
            }
          />
        </DialogForm>
      </div>
    );
  }
}
const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing.unit * 2
  },
  group: {
    margin: `${theme.spacing.unit}px 0`
  }
});

export default withStyles(styles)(UserRegister);

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { CUSTOM_COLOUR } from "../../values/Typography";

const styles = {
  appBar: {
    position: "relative",
    backgroundColor: CUSTOM_COLOUR,
  },
  flex: {
    flex: 1,
  },
  dialogContent: {
    margin: "20px",
    fontSize: 16,
    alignText: "center",
  },
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class FullScreenDialog extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  setContent(data, layout, style) {
    console.log("***>>>>>", layout);
    switch (layout) {
      case "paragraph":
        return <div dangerouslySetInnerHTML={{ __html: data }} />;

      case "list":
        return (
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              paddingLeft: "50px",
              paddingRight: "50px",
              marginTop: "20px",
            }}
          >
            <ListSubheader
              align="left"
              style={{ fontWeight: "bold", fontSize: "2rem" }}
            >
              Personal Documents
            </ListSubheader>

            <List dense={true}>
              {data
                ? data.personal.map((row, index) => (
                    <ListItem>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            type="body2"
                            style={{
                              fontSize: "1rem",
                            }}
                          >
                            {index + 1}. {row.documentname}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))
                : null}
            </List>

            <ListSubheader
              align="left"
              style={{ fontWeight: "bold", fontSize: "2rem" }}
            >
              Vehicle Documents
            </ListSubheader>

            <List dense={true}>
              {data
                ? data.vehicle.map((row, index) => (
                    <ListItem>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            type="body2"
                            style={{
                              fontSize: "1rem",
                            }}
                          >
                            {index + 1}. {row.documentname}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))
                : null}
            </List>
          </div>
        );

      default:
        return 1;
    }
  }

  render() {
    const {
      classes,
      showDialog,
      handleClose,
      contentBody,
      contentTitle,
      contentStyle,
    } = this.props;
    return (
      <div>
        <Dialog
          fullScreen
          open={showDialog ? showDialog : false}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.flex}
                align="center"
              >
                {contentTitle}
              </Typography>
            </Toolbar>
          </AppBar>

          {this.setContent(contentBody, contentStyle, classes)}
        </Dialog>
      </div>
    );
  }
}

FullScreenDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FullScreenDialog);

const styles = theme => {
  const drawerWidth = 300;

  return {
    noJourneyMsg: {
      fontSize: "1rem"
      // [theme.breakpoints.down('sm')]: {
      //   marginTop:16
      // },
    },
    carImage: {
      width: "30%",
      height: "30%"
    },
    godContainer: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "50px"
      }
    },
    root: {
      display: "flex"
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    appBar: {
      //margin: -10,
      // marginTop: "64px",
      [theme.breakpoints.down("sm")]: {
        // width: `calc(100% - ${drawerWidth}px)`
        // marginTop: "59px",
      }
    },
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing.unit * 3
    },
    textWhite: {
      color: "#fff !important"
    },
    DashPagination: {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "-148px"
      }
    },
    StatusBtn: {
      paddingRight: 30,
      [theme.breakpoints.down("sm")]: {
        paddingRight: "0 !important"
      }
    },
    pick_location: {
      borderLeft: "10px solid #2DCC70",
      fontSize: 11,
      padding: "0 5px",
      [theme.breakpoints.down("sm")]: {
        borderLeft: "5px solid #2DCC70",
        fontSize: 10,
        fontWeight: 600,
        padding: 5,
        lineHeight: 1
        // height: "60px"
      }
    },
    drop_location: {
      borderLeft: "10px solid #E84C3D",
      fontSize: 11,
      padding: "0 5px",
      [theme.breakpoints.down("sm")]: {
        borderLeft: "5px solid #E84C3D",
        fontSize: 10,
        fontWeight: 600,
        padding: 5,
        lineHeight: 1
        // height: "60px"
      }
    },
    ridingWith: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    stat: {
      backgroundColor: "#F6AD36",
      color: "#fff",
      margin: 5,
      padding: "0 2px",
      fontWeight: "600",
      [theme.breakpoints.down("sm")]: {
        fontSize: 8
      }
    },
    paymethod: {
      backgroundColor: "#2DCC70",
      color: "#fff",
      margin: 5,
      padding: "0 2px",
      fontWeight: "600",
      [theme.breakpoints.down("sm")]: {
        fontSize: 10
      }
    },
    pick_active: {
      [theme.breakpoints.down("sm")]: {
        lineHeight: "1 !important"
      }
    },
    ActivePaper: {
      cursor: "pointer",
      position: "relative"
    },
    ActiveGridContainer: {
      padding: 10,
      marginTop: 10
    },
    ActiveRideIDgrid: {
      display: "flex",
      flexDirection: "row"
    },
    RideIdLabel: {
      fontWeight: "600"
    },
    BOOK_TIME_LABEL: {
      color: "#76abe9"
    },
    AvatarDimension: {
      height: "100",
      width: "100"
    },
    UserNameText: {
      fontWeight: "Bold",
      color: "#000"
    },
    UserDesText: {
      color: "#76abe9"
    },
    starRate: {
      marginTop: -5
    },
    MarginFive: {
      margin: 5
    },

    Papercursor: {
      cursor: "pointer",
      position: "relative"
    },
    Gridfivex: {
      padding: 10,
      marginTop: 5
    },
    Gridfifth: {
      display: "flex",
      flexDirection: "row"
    },
    BookTimeLabel: {
      color: "#76abe9"
    },
    TypoRidingWith: {
      fontWeight: "600"
    },
    typoCap: {
      fontWeight: "Bold",
      color: "#000",
      textTransform: "capitalize"
    },
    desText: {
      color: "#76abe9"
    },
    starrate: {
      marginTop: -5
    },
    marginFive: {
      marign: 5
    },
    PaddingTen: {
      padding: 10
    },
    testClass: {
      height: "100%",
      width: "100%",
      borderRadius: 5
    },
    taxiimageurl: {
      height: "100",
      width: "100"
    },
    pickClass: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "9px !important"
      }
    },
    imageMap: {
      [theme.breakpoints.down("sm")]: {
        height: "10vh !important"
      }
    },
    SmFont: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px !important"
      }
    },

    BtFont: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "7px !important"
      }
    },
    PickdropOutter: {
      [theme.breakpoints.down("sm")]: {
        display: "flow-root  !important"
      }
    },
    PickdropHeight: {
      [theme.breakpoints.down("sm")]: {
        height: "auto  !important"
      }
    },
    DialogWidth: {
      [theme.breakpoints.down("sm")]: {
        width: "-webkit-fill-available  !important"
      }
    }
  };
};

export default styles;

import React from "react";
import { withStyles, Typography, Button, Grid, Paper } from "@material-ui/core";
import { LoginFormStyles } from "../../../styles";
import DriverTextField from "../common/DriverTextField";
import { Link, Redirect } from "react-router-dom";
import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import CustomLoader from "../../../custom/CustomLoader";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import CustomSnackbar from "../../../custom/CustomSnackbar";
import ForgotPassword from "./ForgotPassword";
import DialogForm from "../../../custom/DialogForm";
import FullScreenDialog from "../../../custom/FullScreenDialog";
import * as LanguageManager from "../../../../managers/language/LanguageManager";

class FormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      wait: true,
      showBorder: null,
      userName: "",
      driverEmail: "",
      password: "",
      isDiverVehicle: false,
      showSnackbar: false,
      snackbarVariant: "error",
      snakBarMessage: "",
      detailSet: null,
      prefix: "",
      forgotpass: null,
      DRIVER_CONFIG_ROWS: [],
      openDialog: false,
      contentTitle: "",
      listView: false,
      docsArray: [],
      contentStyle: "paragraph",
      showForm: false,
      phonecode: "",
      selectedCountry: null
    };
  }

  redirectToDriveVehicle = () => {
    if (this.state.isDiverVehicle) {
      return (
        <Redirect
          to={{
            pathname: "/driver/manage_vehicles"
          }}
        />
      );
    } else if (this.state.goToAddcar) {
      return (
        <Redirect
          to={{
            pathname: "/driver/signup",
            state: {
              driverID: this.state.driverId,
              targetStep: this.state.targetStep,
              countryCode: this.state.countryID,
              vehicleID: this.state.vehicleID
            }
          }}
        />
      );
    } else if (this.state.goToAddDoc) {
      return (
        <Redirect
          to={{
            pathname: "/driver/signup",
            state: {
              driverID: this.state.driverId,
              targetStep: this.state.targetStep,
              countryCode: this.state.countryID,
              vehicleID: this.state.vehicleID
            }
          }}
        />
      );
    }
  };

  getDocumentList = () => {
    this.setState({ isLoading: true });
    ApiManager.callPostApi(
      "LoginForm",
      "driver_get_DocsList",
      ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_GET_DOCSLIST,
      {
        driver: 111,
        driver_vehicle: 100
      }
    ).then(res => {
      console.log("driverDocsList./././" + JSON.stringify(res));
      this.setState({ isLoading: true });
      if (res.result === "1") {
        this.setState(
          {
            contentBody: res.data,
            contentTitle: res.message,
            isLoading: false,
            contentStyle: "list"
          },
          () => {
            this.setState({ openDialog: true });
          }
        );
      } else {
        this.setState({
          isLoading: false,
          showSnackbar: true,
          snakBarMessage: res.message,
          snackbarVariant: "error"
        });
      }
      //alert(JSON.stringify(res))
    });
  };

  checkSession = async data => {
    let sessionToken = await SessionManager.getSessionData(
      SessionKeys.ACCESS_TOKEN
    );
    if (sessionToken) {
      this.setState(
        {
          driverId: data.id,
          targetStep: data.signupStep,
          countryID: data.country_area_id,
          vehicleID:
            data.driver_vehicles.length > 0
              ? data.driver_vehicles[0].id
              : "no carID"
        },
        () => {
          this.props.navigateToHome();
        }
      );
    } else {
      alert("NO TOKEN ");
    }
  };

  fetchDriverCmsData = slugValue => {
    this.setState({ isLoading: true });

    ApiManager.callPostApi(
      "LoginForm",
      "driver_cms_data_api",
      ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_CMSDATA,
      {
        slug: slugValue,
        country_id: 1
      }
    ).then(res => {
      console.log("*(*(*(*" + JSON.stringify(res));
      if (res.result === "1") {
        this.setState(
          {
            contentBody: res.data.description,
            contentTitle: res.data.title,
            isLoading: false
          },
          () => {
            this.setState({ openDialog: true });
          }
        );
      } else {
        this.setState({
          isLoading: false,
          showSnackbar: true,
          snakBarMessage: res.message,
          snackbarVariant: "error"
        });
      }
    });
  };

  async manageLoginData(result) {
    await SessionManager.saveSessionData(
      SessionKeys.DRIVER_DATA,
      JSON.stringify(result)
    );

    if (result.signupStep == 1) {
      this.setState({
        goToAddcar: true,
        driverId: result.id,
        targetStep: result.signupStep,
        countryID: result.country_area_id
      });
    } else if (result.signupStep == 2) {
      this.setState({
        goToAddDoc: true,
        driverId: result.id,
        targetStep: result.signupStep,
        countryID: result.country_area_id,
        vehicleID:
          result.driver_vehicles.length > 0
            ? result.driver_vehicles[0].id
            : "no carID"
      });
    } else if (result.signupStep == 3) {
      this.checkSession(result);
    }
  }

  storeSession(tokenData) {
    if (tokenData) {
      SessionManager.saveSessionData(
        SessionKeys.ACCESS_TOKEN,
        tokenData.access_token
      );
      return true;
    }
  }

  exexuteDriverDetailsApi = tokenSaved => {
    if (tokenSaved) {
      this.setState({ isLoading: true });

      ApiManager.callPostApi(
        "LoginForm",
        "drive details api",
        ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_DETAILS,
        {}
      ).then(res => {
        console.log("*(*(*(*" + JSON.stringify(res));
        this.setState({ isLoading: false });

        if (res.result === "1") {
          this.manageLoginData(res.data);
        } else {
          alert(JSON.stringify(res.message));
        }
      });
    } else {
      alert("NO TOKEN SAVED");
    }
  };

  fetchUsername(result) {
    if (result.length <= this.state.selectedCountry.maxNumPhone) {
      this.setState({ userName: result });
    }
  }
  fetchPassword(result) {
    this.setState({ password: result });
  }

  fetchDriverEmail(result) {
    this.setState({ driverEmail: result });
  }
  handleLoading = () => {};

  openSnack = (message = "", variant = "error") => {
    this.setState({
      showSnackbar: true,
      snakBarMessage: message,
      snackbarVariant: variant
    });
  };

  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false
    });
  };

  handleClickOpenModal = name => {
    this.setState({
      [name]: !this.state[name]
    });
  };

  handleLogin = async () => {
    this.setState({ isLoading: true });
    if (this.state.userName === "" && this.state.driverEmail === "") {
      // alert("Please enter user name");
      this.setState({
        showSnackbar: true,
        snakBarMessage: "Please enter all field",
        isLoading: false,
        snackbarVariant: "warning"
      });
    } else if (this.state.password === "") {
      // alert("Please enter password");
      // this.setState({ isLoading: false });
      this.setState({
        showSnackbar: true,
        snakBarMessage: "Please enter Password",
        isLoading: false,
        snackbarVariant: "warning"
      });
    } else {
      ApiManager.callPostApi(
        "LoginForm",
        "driver login api",
        ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_LOGIN,
        {
          phone:
            this.state.userName === ""
              ? this.state.driverEmail
              : this.state.phonecode + this.state.userName,
          password: this.state.password,
          player_id: "c372814e-c427-45ee-8c73-e8254c9a4a8a",
          requested_from: "website"
        }
      ).then(res => {
        this.setState({ isLoading: false });

        if (res.result === "1") {
          this.exexuteDriverDetailsApi(this.storeSession(res.data));
        } else {
          this.setState({
            showSnackbar: true,
            snakBarMessage: res.message,
            snackbarVariant: "error",
            isLoading: false
          });
        }
      });
    }
  };

  getResponse(result) {
    let country = this.state.DRIVER_CONFIG_ROWS.countries.find(
      obj => obj.phonecode == result
    );
    this.setState({ phonecode: result, selectedCountry: country });
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentDidMount() {
    let data = JSON.parse(
      SessionManager.getSessionData(SessionKeys.DRIVER_CONFIG)
    );
    this.setState(
      {
        DRIVER_CONFIG_ROWS: data,
        selectedCountry: data.countries[0],
        phonecode: data.countries.length ? data.countries[0].phonecode : null,
        wait: false
      },
      () => {
        this.setState({ showForm: true });
        console.log(
          "login form..... " + JSON.stringify(this.state.DRIVER_CONFIG_ROWS)
        );
      }
    );
  }

  handlePhoneCode = event => {
    this.setState({
      phonecode: event.target.value
    });
  };

  render() {
    const { classes } = this.props;
    console.log("login form state", this.state);

    if (this.state.wait) {
      return <CustomLoader showLoader={this.state.wait} />;
    } else {
      console.log("config Driver!@#$%^&*()", this.state.DRIVER_CONFIG_ROWS);
      return (
        <Grid
          container
          className={classes.formContainer}
          style={{
            border: "2px solid white",
            borderWidth: 2,
            borderRadius: 10
          }}
        >
          {this.redirectToDriveVehicle()}

          <CustomSnackbar
            showSnackbar={this.state.showSnackbar}
            variant={this.state.snackbarVariant}
            message={this.state.snakBarMessage}
            onClose={this.handleSnackBarClose}
          />

          <FullScreenDialog
            contentStyle={this.state.contentStyle}
            showDialog={this.state.openDialog}
            handleClose={() =>
              this.state.contentStyle === "list"
                ? this.setState({
                    openDialog: false,
                    contentStyle: "paragraph",
                    contentBody: ""
                  })
                : this.setState({ openDialog: false })
            }
            contentTitle={this.state.contentTitle}
            contentBody={this.state.contentBody}
          />

          <CustomLoader showLoader={this.state.isLoading} />

          <Paper container className={classes.loginFormSheet} elevation={1}>
            <Typography
              variant="h5"
              component="h3"
              className={classes.loginTitle}
              align="left"
            >
              {LanguageManager.Strings.DRIVER_LOGIN_TITLE}
            </Typography>

            <Grid
              xl
              container
              justify="flex-start"
              spacing={8}
              style={{ marginTop: "20px" }}
            >
              {/* <Select
                style={{fontSize:'1rem' }}
                value={this.state.phonecode}
                onChange={(event) => this.handlePhoneCode(event)}
                inputProps={{
                  name: 'phonecode',
                  id: 'prefix-simple',
                }}
              >
                {this.state.DRIVER_CONFIG_ROWS.countries.map(country => {
                  return (
                    <MenuItem value={country.phonecode}>{country.phonecode}</MenuItem>
                  );
                })
                }
              </Select> */}

              {this.state.DRIVER_CONFIG_ROWS.login.phone &&
                this.state.showForm && (
                  <DriverTextField
                    getCountryCode={this.getResponse.bind(this)}
                    placeHolder={LanguageManager.Strings.PHONE_HINT}
                    dataSet={this.state.DRIVER_CONFIG_ROWS.countries}
                    defaultCode={
                      this.state.selectedCountry
                        ? this.state.selectedCountry.phonecode
                        : null
                    }
                    value={this.state.userName}
                    phoneNumber={true}
                    numberInput={true}
                    callback={this.fetchUsername.bind(this)}
                  />
                )}

              {this.state.DRIVER_CONFIG_ROWS.login.email && (
                <DriverTextField
                  placeHolder="Email"
                  iconName="email"
                  callback={this.fetchDriverEmail.bind(this)}
                />
              )}
            </Grid>

            <Grid item style={{ marginTop: "20px" }}>
              <DriverTextField
                placeHolder={LanguageManager.Strings.PASSWORD_HINT}
                iconName="https"
                secure={true}
                type="password"
                callback={this.fetchPassword.bind(this)}
              />
            </Grid>
            <Button
              variant="contained"
              disableTouchRipple={true}
              disableFocusRipple={true}
              className={classes.SignupButton}
              onClick={() => this.handleLogin()}
            >
              {LanguageManager.Strings.LOGIN_BTN}
            </Button>

            <Grid
              container
              spacing={8}
              justify="space-between"
              style={{ borderBottom: "1px solid gray" }}
            >
              <Grid item>
                <Button
                  className={classes.FPbutton}
                  onClick={() => this.handleClickOpenModal("forgotpass")}
                >
                  {LanguageManager.Strings.FORGOT_PASSWORD_LABEL}
                </Button>
              </Grid>
              <DialogForm
                visible={this.state.forgotpass}
                onOutsideTouch={() => this.handleClickOpenModal("forgotpass")}
              >
                <ForgotPassword
                  handleSnackBarClose={this.handleSnackBarClose}
                  DRIVER_CONFIG_ROWS={this.state.DRIVER_CONFIG_ROWS}
                  openSnack={this.openSnack}
                  handleModal={this.handleClickOpenModal}
                />
              </DialogForm>
              <Grid item>
                <Link className="link-text" to="/driver/signup">
                  <Button color="primary" className={classes.SUbutton}>
                    {LanguageManager.Strings.SIGNUP_DRIVER}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={8}
              justify="space-between"
              className={classes.linkContainer}
            >
              <Grid item>
                <Button
                  color="primary"
                  className={classes.formLink}
                  onClick={() => this.getDocumentList()}
                >
                  {LanguageManager.Strings.VIEW_DOCS_LABEL}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  className={classes.formLink}
                  onClick={() =>
                    this.fetchDriverCmsData("terms_and_conditions")
                  }
                >
                  {LanguageManager.Strings.TERMSCONDITION_LABEL}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      );
    }
  }
}

export default withStyles(LoginFormStyles)(FormComponent);

export const ACCESS_TOKEN = "access_token";
export const CONFIG_DATA = "config_data";
export const DRIVER_DATA = "driver_data";
export const USER_DATA = "user_data";
export const USER_ACCESS_TOKEN = "user_access_token";
export const PLAYER_ID = "player_id";
export const USER_BG = "user_bg";
export const IOS_LINK = "ios_Link";
export const ANDROID_LINK = "android_Link";
export const LANGUAGE_LOCALE = "locale";

export const USER_CONFIG = "user_config";

export const DRIVER_CONFIG = "driver_config";
export const DRIVER_FIRST_NAME = "driver_data.first_name";
export const DRIVER_LAST_NAME = "driver_lastname"; //"driver_data.last_name";
export const DRIVER_EMAIL = "driver_email"; //"driver_data.email";
export const DRIVER_IMAGE = "driver_profile_image"; //"driver_data.profile_image";
export const DRIVER_LOGO = "driver_logo";
export const DRIVER_BG = "driver_bg";

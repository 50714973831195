import React, { Component } from "react";
import {
  CardMedia,
  Paper,
  CardActionArea,
  Typography,
  InputBase,
  Button,
  withStyles,
  Card,
  MenuItem,
  Select,
  ButtonBase,
  Grid
} from "@material-ui/core";
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Redirect, withRouter } from "react-router-dom";
import DialogForm from "../../../custom/DialogForm";
import OtpVerification from "../otpverification/OtpVerification";
import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import CustomLoader from "../../../custom/CustomLoader";
import { getPlayerId } from "../../../../managers/OneSignalManager";
import CustomSnackbar from "../../../custom/CustomSnackbar";
import * as LanguageManager from "../../../../managers/language/LanguageManager";
import EventBus from "eventing-bus";
import { CUSTOM_COLOUR } from "../../../../values/Typography";

// {
//   "smoker": false,
//   "email": true,
//   "user_email_otp": true,
//   "phone": true,
//   "user_phone_otp": false,
//   "gender": false,
//   "userImage_enable": true
// }

class UserRegister extends Component {
  constructor(props) {
    super(props);
    this.imageRef = React.createRef();

    this.state = {
      selectedGender: -1,
      selectedSmoke: -1,
      showForm: false,
      phoneNumber: null,
      userEmail: null,
      first_name: "",
      last_name: "",
      password: "",
      referralCode: "",
      email: null,
      accept_smoke: 0,
      isLoading: false,
      countryId: "",
      showSnackbar: false,
      snackbarVariant: "error",
      snakBarMessage: "",
      enableSocialRegister: false,
      country_code: this.props.country_code,
      phonecode: this.props.configData.countries[0].phonecode,
      emailMode: this.props.configData.register.user_email_otp ? true : false,
      userImage: null,
      showSpecialForm: false,
      country_id: this.props.country_code[0].id,
      maxNum: this.props.configData.countries[0].maxNumPhone
    };
  }

  // ---- open explorer ----
  openExplorer = () => {
    this.imageRef.current.click();
  };

  // --- handle upload ----
  handleUpload = event => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = e => {
        console.log("uhuhu>>>" + JSON.stringify(e.target.result));
        this.setState({
          userImage: e.target.result
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  checkSession = () => {
    console.log(
      "Session token",
      SessionManager.getSessionData(SessionKeys.ACCESS_TOKEN)
    );
    if (SessionManager.getSessionData(SessionKeys.ACCESS_TOKEN)) {
      return this.props.history.push("/user_booking");
    }
  };

  executeSpecificDetailsApi = () => {
    this.setState({ isLoading: true });
    ApiManager.callPostApi(
      "Login  Component",
      "Login APi",
      ApiEndPoints.APPORIO_TAXI_APIS.USER_DETAILS,
      {
        player_id: "c372814e-c427-45ee-8c73-e8254c9a4a8a",
        unique_no: Math.random(),
        package_name: "com.appoiro.website"
      }
    ).then(res => {
      console.log("xsxsxs" + JSON.stringify(res));
      if (res.result === "1") {
        SessionManager.saveSessionData(
          SessionKeys.USER_DATA,
          JSON.stringify(res.data)
        );

        this.setState({ isLoading: false }, () => {
          EventBus.publish("SET_HOME_ACTIVE", "1");
          this.checkSession();
        });
      }
    });
  };

  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false
    });
  };

  handleSocialRegister = () => {
    this.setState({ isLoading: true });
    ApiManager.callPostApi(
      "Social Login  Component",
      "Social Login APi",
      ApiEndPoints.APPORIO_TAXI_APIS.USER_SOCIALREGISTER,
      {
        social_id: this.props.profileData.googleId
          ? this.props.profileData.googleId
          : this.props.profileData.id,
        platfrom: this.props.platFormId, // {1 for normal , 2 for google , 3 for facebook}
        first_name: this.props.profileData.givenName
          ? this.props.profileData.givenName
          : this.props.profileData.name,
        user_gender: this.state.selectedGender + 1,
        smoker_type: this.state.selectedSmoke,
        allow_other_smoker: this.state.accept_smoke,
        phone: this.state.phoneNumber,
        email: this.props.profileData.email
          ? this.props.profileData.email
          : "akshay.singh@apporio.com",
        // country_id: "4",
        country_id: this.state.country_id
      }
    )
      .then(res => {
        console.log("b@b@b@b@" + JSON.stringify(res));
        if (res.result === 1) {
          this.setState({
            showSnackbar: true,
            snackbarVariant: "success",
            snakBarMessage: res.message
          });
        } else {
          this.setState({
            showSnackbar: true,
            snackbarVariant: "error",
            snakBarMessage: res.message
          });
        }
      })
      .catch(err => alert(JSON.stringify(err)));
  };

  getResponse(result) {
    if (result.result === "1") {
      this.setState({
        showSnackbar: true,
        snackbarVariant: "success",
        snakBarMessage: result.message
      });
    } else {
      this.setState({
        showSnackbar: true,
        snackbarVariant: "error",
        snakBarMessage: result.message
      });
    }
  }

  handleRegisterBtn = () => {
    // if (this.state.first_name == "") {
    //   this.setState({
    //     showSnackbar: true,
    //     snakBarMessage:"Please enter your name",
    //     isLoading: false,
    //     snackbarVariant:"warning"
    //   });
    // }  else if (this.state.email == "") {
    //   this.setState({
    //     showSnackbar: true,
    //     snakBarMessage:"Please enter your Email",
    //     isLoading: false,
    //     snackbarVariant:"warning"
    //   });
    // } else if (this.state.phoneNumber == "") {
    //   this.setState({
    //     showSnackbar: true,
    //     snakBarMessage:"Please enter your Phone number",
    //     isLoading: false,
    //     snackbarVariant:"warning"
    //   });
    // } else if (this.state.password == "") {
    //   this.setState({
    //     showSnackbar: true,
    //     snakBarMessage:"Please enter a Password",
    //     isLoading: false,
    //     snackbarVariant:"warning"
    //   });
    // } else if (this.state.selectedGender == -1) {
    //   this.setState({
    //     showSnackbar: true,
    //     snakBarMessage:"Please select Gender",
    //     isLoading: false,
    //     snackbarVariant:"warning"
    //   });
    // } else if (this.state.selectedSmoke == -1) {
    //   this.setState({
    //     showSnackbar: true,
    //     snakBarMessage:"Please select your smoking preference",
    //     isLoading: false,
    //     snackbarVariant:"warning"
    //   });
    // } else {

    this.setState({ isLoading: true });
    ApiManager.callPostApi(
      "User Register",
      "register api",
      ApiEndPoints.APPORIO_TAXI_APIS.USER_REGISTER,
      {
        profile_image: this.props.configData.register.userImage_enable
          ? this.state.userImage
          : "",
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        password: this.state.password,
        phone: this.props.configData.register.phone
          ? this.state.phonecode + this.state.phoneNumber
          : "",
        email: this.props.configData.register.email ? this.state.userEmail : "",
        // country_id: "4",
        country_id: this.state.country_id,
        user_gender: this.props.configData.register.gender
          ? this.state.selectedGender + 1
          : "",
        smoker_type: this.props.configData.register.smoker
          ? this.state.selectedSmoke
          : "2",
        accept_smoker_driver: this.state.accept_smoke,
        referral_code: this.state.referralCode,
        requested_from: "website",
        player_id: SessionManager.getSessionData(SessionKeys.PLAYER_ID)
      }
    ).then(res => {
      if (res.result === "1") {
        SessionManager.saveSessionData(
          SessionKeys.ACCESS_TOKEN,
          res.data.access_token
        );
        this.setState({ isLoading: false }, () =>
          this.executeSpecificDetailsApi()
        );
      } else if (res.result === "0") {
        this.setState({
          showSnackbar: true,
          snackbarVariant: "error",
          snakBarMessage: "Signup Error:" + res.message,
          isLoading: false
        });
      }
    });
  };

  handlePhoneChange = async event => {
    let index = await this.state.country_code.findIndex(
      res => res.phonecode === event.target.value
    );

    this.setState({
      phonecode: event.target.value,
      country_id: this.state.country_code[index].id,
      maxNum: this.state.country_code[index].maxNumPhone
    });
    console.log("1234567890", this.state.country_id);
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  _getReferralPlaceholderText = () => {
    return this.props.configData.general_config
      .referral_code_mandatory_user_signup
      ? LanguageManager.Strings.REFERRAL_CODE_HINT
      : LanguageManager.Strings.REFERRAL_CODE_HINT_IF_ANY;
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ flexDirection: "column" }}>
        <ButtonBase
          accept="image/*"
          htmlFor="icon-button-file"
          id="icon-button-file"
          focusRipple
          onClick={() => this.openExplorer()}
          style={{
            display: this.props.configData.register.userImage_enable
              ? "flex"
              : "none"
          }}
        >
          <Grid item className={classes.avatarContainer}>
            {Boolean(this.state.userImage) ? (
              <img
                src={this.state.userImage}
                className={classes.DriverProfileUpload}
              />
            ) : (
              <img
                src="https://image.flaticon.com/icons/svg/74/74472.svg"
                style={{ padding: 5 }}
                className={classes.avatarStyle}
              />
            )}
          </Grid>
        </ButtonBase>
        <input
          type="file"
          ref={this.imageRef}
          style={{
            width: "0",
            height: "0",
            overflow: "hidden",
            display: "none"
          }}
          onChange={event => this.handleUpload(event)}
        />

        <div
          style={{
            padding: "2%",
            display: "flex",
            alignItems: "center"
          }}
        >
          <CustomLoader showLoader={this.state.isLoading} />
          <CustomSnackbar
            showSnackbar={this.state.showSnackbar}
            variant={this.state.snackbarVariant}
            message={this.state.snakBarMessage}
            onClose={this.handleSnackBarClose}
          />
          <img
            src="http://pluspng.com/img-png/user-png-icon-png-ico-512.png"
            style={{ width: 20, height: 20, backgroundSize: "contain" }}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              marginLeft: 15
            }}
          >
            <InputBase
              onChange={(event, value) =>
                this.setState({ first_name: event.target.value })
              }
              placeholder={LanguageManager.Strings.FNAME_HINT}
              style={{
                margin: 5,
                fontSize: "0.8rem",
                width: "100%",
                borderBottom: "1px solid grey"
              }}
            />
            <InputBase
              onChange={(event, value) =>
                this.setState({ last_name: event.target.value })
              }
              placeholder={LanguageManager.Strings.LNAME_HINT}
              style={{
                margin: 5,
                fontSize: "0.8rem",
                width: "100%",
                borderBottom: "1px solid grey"
              }}
            />
          </div>
        </div>

        <CardActionArea
          onClick={() => {
            this.setState({ showForm: true });
          }}
          style={{
            padding: "2%",
            display:
              this.props.configData.register.email &&
              this.props.configData.register.user_email_otp
                ? "flex"
                : "none",
            alignItems: "center"
          }}
        >
          <img
            src="http://aux.iconspalace.com/uploads/email-icon-256-1250130517.png"
            style={{ width: 20, height: 20, backgroundSize: "contain" }}
          />

          <div
            style={{
              display: "flex",
              width: "100%",
              marginLeft: 15
            }}
          >
            <Typography
              style={{
                textAlign: "left",
                color: "grey",
                fontSize: "0.8rem",
                width: "100%",
                borderBottom: "1px solid grey"
              }}
            >
              {this.state.userEmail == null
                ? LanguageManager.Strings.OTP_EMAIL_HINT
                : this.state.userEmail}
            </Typography>
          </div>
        </CardActionArea>

        <div
          style={{
            padding: "2%",
            display:
              this.props.configData.register.email &&
              !this.props.configData.register.user_email_otp
                ? "flex"
                : "none", //"flex",
            alignItems: "center"
          }}
        >
          <img
            src="http://aux.iconspalace.com/uploads/email-icon-256-1250130517.png"
            style={{ width: 20, height: 20, backgroundSize: "contain" }}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              marginLeft: 15
            }}
          >
            <InputBase
              onChange={(event, value) =>
                this.setState({ userEmail: event.target.value })
              }
              placeholder={LanguageManager.Strings.EMAIL_HINT}
              style={{
                fontSize: "0.8rem",
                width: "100%",
                borderBottom: "1px solid grey"
              }}
              required
            />
          </div>
        </div>

        <CardActionArea
          onClick={() => {
            this.setState({ showSpecialForm: true });
          }}
          style={{
            padding: "2%",
            display:
              this.props.configData.register.phone &&
              this.props.configData.register.user_phone_otp
                ? "flex"
                : "none",
            alignItems: "center"
          }}
        >
          <img
            src="http://aux.iconspalace.com/uploads/email-icon-256-1250130517.png"
            style={{ width: 20, height: 20, backgroundSize: "contain" }}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              marginLeft: 15
            }}
          >
            <Typography
              style={{
                textAlign: "left",
                color: "grey",
                fontSize: "0.8rem",
                width: "100%",
                borderBottom: "1px solid grey"
              }}
            >
              {this.state.phoneNumber == null
                ? LanguageManager.Strings.OTP_PHONE_HINT
                : this.state.phoneNumber}
            </Typography>
          </div>
        </CardActionArea>

        <div
          style={{
            padding: "2%",
            display:
              this.props.configData.register.phone &&
              !this.props.configData.register.user_phone_otp
                ? "flex"
                : "none",
            alignItems: "center"
          }}
        >
          <div
            style={{
              display: this.props.configData.register.phone ? "flex" : "none",
              width: "100%"
            }}
          >
            <Select
              value={this.state.phonecode}
              onChange={this.handlePhoneChange}
              inputProps={{
                name: "age",
                id: "age-simple"
              }}
            >
              {this.props.configData.countries.map((res, index) => (
                <MenuItem key={index} value={res.phonecode}>
                  {res.phonecode}
                </MenuItem>
              ))}
            </Select>

            <InputBase
              inputProps={{
                maxLength: this.state.maxNum
              }}
              onChange={(event, val) => {
                // if (
                //   this.props.configData.login.phone &&
                //   event.target.value.length <=
                //     this.props.configData.countries[0].maxNumPhone
                // )
                {
                  // if (event.target.value.length <= 10)
                  this.setState({ phoneNumber: event.target.value });
                }
              }}
              placeholder={LanguageManager.Strings.PHONE_HINT}
              style={{
                fontSize: "0.8rem",
                width: "100%",
                borderBottom: "1px solid grey"
              }}
            />
          </div>
        </div>

        {/* <div
          style={{
            padding: "2%",
            display: this.props.registerConfiguration.email&&!this.props.registerConfiguration.user_email_otp?"flex":"none",
            alignItems: "center"
          }}
        >
          <div
            style={{
              display:this.props.registerConfiguration.phone?"flex":"none",
              width: "100%"
            }}
          >
            <InputBase
              onChange={(event, val) => {
                this.setState({ phoneNumber: event.target.value });
              }}
              placeholder={this.state.userIDHint}
              style={{
                fontSize: "0.8rem",
                width: "100%",
                borderBottom: "1px solid grey"
              }}
            />
          </div>
        </div> */}

        <div
          style={{
            padding: "2%",
            marginTop: "1%",
            display: "flex",
            alignItems: "center"
          }}
        >
          <img
            src="https://png.pngtree.com/svg/20170622/d39689bb9c.png"
            style={{ width: 20, height: 20 }}
          />

          <div
            style={{
              display: "flex",
              width: "100%",
              marginLeft: 15
            }}
          >
            <InputBase
              type="password"
              onChange={(event, value) =>
                this.setState({ password: event.target.value })
              }
              placeholder={LanguageManager.Strings.PASSWORD_HINT}
              style={{
                fontSize: "0.8rem",
                width: "100%",
                borderBottom: "1px solid grey"
              }}
            />
            {console.log(
              "ReferralCode--->",
              this.props.configData.general_config
                .referral_code_mandatory_user_signup
            )}
          </div>
        </div>

        <div
          style={{
            padding: "2%",
            marginTop: "1%",
            display: "flex",
            alignItems: "center"
          }}
        >
          <InputBase
            type="Referral Code"
            onChange={(event, value) =>
              this.setState({ referralCode: event.target.value })
            }
            placeholder={this._getReferralPlaceholderText()}
            style={{
              fontSize: "0.8rem",
              width: "100%",
              borderBottom: "1px solid grey"
            }}
          />
        </div>

        <div
          style={{
            display: this.props.configData.register.gender ? "flex" : "none",
            padding: "0.5em"
          }}
        >
          <Typography style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
            {LanguageManager.Strings.GENDER_LABEL}
          </Typography>
          {[
            LanguageManager.Strings.MALE_LABEL,
            LanguageManager.Strings.FEMALE_LABEL
          ].map((val, index) => (
            <CardActionArea
              onClick={() => {
                this.setState({ selectedGender: index });
              }}
              style={{ display: "flex", marginLeft: "1rem" }}
            >
              <Card
                style={{
                  width: 15,
                  height: 15,
                  borderRadius: 15 / 2,
                  backgroundColor:
                    index == this.state.selectedGender ? "#00649A" : "#CCCCCC"
                }}
              />
              <Typography style={{ fontSize: "0.8rem", marginLeft: "0.5rem" }}>
                {val}
              </Typography>
            </CardActionArea>
          ))}
        </div>

        <div
          style={{
            display: this.props.configData.register.smoke ? "flex" : "none",
            padding: "0.5em"
          }}
        >
          <Typography style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
            {LanguageManager.Strings.SELF_SMOKER_LABEL}
          </Typography>
          {[
            LanguageManager.Strings.SMOKER_OPTION,
            LanguageManager.Strings.NO_SMOKER_OPTION
          ].map((val, index) => (
            <CardActionArea
              onClick={() => {
                this.setState({ selectedSmoke: index });
              }}
              style={{ display: "flex", marginLeft: "1rem" }}
            >
              <Card
                style={{
                  width: 15,
                  height: 15,
                  borderRadius: 15 / 2,
                  backgroundColor:
                    index == this.state.selectedSmoke ? "#00649A" : "#CCCCCC"
                }}
              />
              <Typography style={{ fontSize: "0.8rem", marginLeft: "0.5rem" }}>
                {val}
              </Typography>
            </CardActionArea>
          ))}
        </div>

        {this.props.configData.register.smoker && (
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.accept_smoke}
                onChange={this.handleChange("accept_smoke")}
                value="accept_smoke"
              />
            }
            label={LanguageManager.Strings.DRIVER_SMOKER_QUESTN}
          />
        )}

        <Button
          onClick={this.handleRegisterBtn}
          style={{
            margin: "5%",
            backgroundColor: CUSTOM_COLOUR,
            color: "white",
            fontWeight: "bold"
          }}
        >
          Register
        </Button>

        <DialogForm
          visible={this.state.showForm}
          onOutsideTouch={() => {
            this.setState({ showForm: !this.state.showForm });
          }}
        >
          <OtpVerification
            emailMode={this.state.emailMode}
            countrySet={this.props.configData.countries}
            otpCallback={this.getResponse.bind(this)}
            otpType={1}
            getOtp={(phoneNumber, country_id) => {
              if (this.props.configData.register.user_email_otp) {
                this.setState({
                  userEmail: phoneNumber,
                  showForm: !this.state.showForm,
                  countryId: country_id
                });
              } else {
                this.setState({
                  phoneNumber: phoneNumber,
                  showForm: !this.state.showForm,
                  countryId: country_id
                });
              }
            }}
          />
        </DialogForm>

        <DialogForm
          visible={this.state.showSpecialForm}
          onOutsideTouch={() => {
            this.setState({ showSpecialForm: !this.state.showSpecialForm });
          }}
        >
          <OtpVerification
            emailMode={false}
            countrySet={this.props.configData.countries}
            otpCallback={this.getResponse.bind(this)}
            otpType={1}
            getOtp={(phoneNumber, country_id, phonecode) => {
              this.setState({
                phoneNumber: phoneNumber,
                showSpecialForm: false,
                countryId: country_id,
                phonecode: phonecode
              });
            }}
          />
        </DialogForm>
      </div>
    );
  }
}
const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing.unit * 2
  },
  group: {
    margin: `${theme.spacing.unit}px 0`
  },
  avatarStyle: {
    width: "10vw",
    height: "10vh",
    [theme.breakpoints.down("sm")]: {
      width: "35vw",
      height: "10vh"
    }
  },
  DriverProfileUpload: {
    width: "10vw",
    height: "10vh",
    [theme.breakpoints.down("xs")]: {
      width: "25vw"
    }
  },
  avatarContainer: {
    backgroundColor: "silver",
    border: "1px solid gray",
    borderRadius: 5
  }
});

export default withStyles(styles)(withRouter(UserRegister));

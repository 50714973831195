import React from "react";
import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  Icon,
  Paper,
  IconButton
} from "@material-ui/core";
import { DocBarStyles } from "../../../styles";
import DocumentUploader from "../../driver/signUp/DocumentUploader";

class TextInputComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.getResponse = this.getResponse.bind(this);
  }

  componentDidMount() {
    console.log("wewewewe" + JSON.stringify(this.props));
  }

  setStatus(status) {
    console.log("freeaa" + status);

    // if(parseInt(status) == 0)
    if (status == "0" || status === 0 || status == null) {
      return "Upload";
    } else if (status == "1" || status === 1) {
      return "Verification Pending";
    } else if (status == "2" || status === 2) {
      return "Verified";
    }
  }

  setStatusColor(status) {
    if (status == "0" || status === 0 || status == null) {
      return "primary";
    } else if (status == "1" || status === 1) {
      return null;
    } else if (status == "2" || status === 1) {
      return "secondary";
    }
  }

  setStatusDisabled(status) {
    if (status === "0" || status === 0 || status == null) {
      return false;
    } else {
      return true;
    }
  }

  // handler(e){
  //     this.props.callBack(e.target.value);
  //   }

  getResponse(result) {
    console.log("lililili.." + JSON.stringify(result));
    if (result.status == 1) {
      this.setState({ visible: false });
    }
    this.props.callBack(result);
  }

  render() {
    console.log("expire", this.props);
    const {
      classes,
      barLabel,
      buttonColor,
      buttonTitle,
      clickFunction,
      disableButton,
      expire_date,
      docID,
      driverID,
      carDocMode,
      vehicleID,
      flagValue
    } = this.props;

    return (
      <div>
        {this.state.visible === true ? (
          <DocumentUploader
            visible={this.state.visible}
            onClose={() => this.setState({ visible: false })}
            keyValue={this.state.indexValue}
            carDocMode={carDocMode}
            callback={this.getResponse}
            expire_date={expire_date}
            docID={docID}
            driverID={driverID}
            vehicleID={vehicleID}
            postLogin={flagValue}
          />
        ) : null}
        <Grid
          container
          xl
          justify="space-between"
          alignItems="center"
          className={classes.fieldContainer}
        >
          <Typography variant="h6" className={classes.barLabel} gutterBottom>
            {barLabel}
          </Typography>
          {buttonTitle != null ? (
            <Button
              color={this.setStatusColor(buttonTitle)}
              className={classes.button}
              onClick={() => this.setState({ visible: true, indexValue: 1 })}
              disabled={this.setStatusDisabled(buttonTitle)}
            >
              {this.setStatus(buttonTitle)}
            </Button>
          ) : null}
        </Grid>
      </div>
    );
  }
}

export default withStyles(DocBarStyles)(TextInputComponent);

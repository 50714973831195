

import { getTheme } from "../../../managers/theme/ThemeManager";
import { BUTTON,CUSTOM_FONT } from "../../../values/Typography";


const styles = (theme) => {
    const configTheme = getTheme()

    return (
        {
                textFeild:{
                    fontFamily:CUSTOM_FONT,
                    fontSize:22,
                    border: '0px solid',
                    outline: 'none !important'
                },
                fieldContainer:{
                    marginTop:20,
                    borderBottom:'1px solid silver',
                    marginRight:40,
                    marginLeft:40
                },
                button:{
                    textTransform:'none',
                    fontSize:22,
                    fontFamily:CUSTOM_FONT,
                },
                barLabel:{
                    fontFamily:CUSTOM_FONT,
                    
                },
                genderContainer: {
                    marginTop: 10,
                    paddingLeft: "2.5%"
                  },
                  genderTitle: {
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                    color:'gray'
                  },
                  heading: {
                    marginTop:theme.spacing.unit*5,
                    fontFamily: 'Montserrat',//FONT_FAMILY,
                    fontWeight:'bold'
                },
                sectionHead:{
                    marginTop:'3%',
                    backgroundColor: 'silver', 
                    paddingLeft: 10,
                    fontFamily:CUSTOM_FONT,
                    fontWeight:'bold',
                  },
            }
        
    )
}



export default styles
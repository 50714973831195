import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import { CarFormStyles } from "../../../styles";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import DocBarComponent from '../common/DocBarComponent';
import LinearTimeline from './LinearTimeline';//'../common/TimelineComponent';
import DocumentUploader from './DocumentUploader';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '../common/MenuListBox'

class CreateCarForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            ownerDocs: [],
            carDocs: [],
            driverDocs: [],
            carTypes:['Hatchback','compact medan','sedan','7-seater'],
            carMakes:['Honda','Hyundai','Maruti Suzuki','Toyota','Chevrolet','Nissan'],
            carModels:['Ertiga','Etios','WagonR','alto','swift Dzire','eon','city','amaze','xcent'],
            serviceTypes:['Rental','Normal','Outstation']
        };
    }


    submitFunction=(inputVal)=>{
        this.props.callBack(inputVal+1)
    }
    

    render() {
        const { classes,indexKey } = this.props;

        return (
            <div style={{ margin: '10px' }}>
                <Typography variant="h6" gutterBottom align="left" className={classes.sectionHead}>
                    Create Vehicle
                </Typography>
                <div style={{ width: '300px' }}>
                    <Autocomplete fieldLabel="Select Vehicle Type" suggestions={this.props.carTypes} className={classes.listItem}/>
                    <Autocomplete fieldLabel="Select Vehicle Make" suggestions={this.state.carMakes} />
                    <Autocomplete fieldLabel="Select Vehicle Model" suggestions={this.state.carModels} />
                    <Autocomplete fieldLabel="Types of Services" suggestions={this.state.serviceTypes} />
                </div>
                <Typography variant="h6" gutterBottom align="left" className={classes.sectionHead}>
                    Add Vehicle Details
                </Typography>


                <div style={{ width: '300px' }}>

                    <TextField
                        label="Enter Vehicle Number"
                         className={classes.detailFields}
                        InputProps={{
                            style: { fontSize: 15, fontFamily: 'Montserrat', fontWeight: 'bold' }
                        }}
                        InputLabelProps={{
                            style: { fontSize: 15, fontFamily: 'Montserrat', fontWeight: 'bold' }
                        }}
                        onChange={(text) => this.setState({ vehicleNumber: text })}
                        margin="normal"
                        variant="outlined"
                    />
                    <TextField
                        label="Enter Vehicle Color"
                        InputProps={{
                            style: { fontSize: 15, fontFamily: 'Montserrat', fontWeight: 'bold' }
                        }}
                        InputLabelProps={{
                            style: { fontSize: 15, fontFamily: 'Montserrat', fontWeight: 'bold' }
                        }}
                         className={classes.detailFields}
                        onChange={(text) => this.setState({ vehicleColor: text })}
                        margin="normal"
                        variant="outlined"
                    />
                </div>
                
            <Button variant="contained" disableTouchRipple={true} disableFocusRipple={true} className={classes.SignupButton} onClick={()=>this.submitFunction(indexKey)}>
                Add Vehicle
            </Button>
            </div>
        );
    }
}

CreateCarForm.propTypes = {
    classes: PropTypes.object.isRequired
};
export default withStyles(CarFormStyles)(CreateCarForm);

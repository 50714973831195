import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Grid,
  Fab,
  CardMedia,
  Icon,
  TablePagination,
  Paper,
  Avatar
} from "@material-ui/core";
import { ManageTripStyles } from "../../../../../styles";
import Typography from "@material-ui/core/Typography";
import DashboardPaginationActions from "../DashboardPaginationActions";
import * as ApiManager from "../../../../../../managers/api/ApiManager";
import {
  APPORIO_TAXI_APIS,
  TAXI_IMAGE_URL
} from "../../../../../../managers/api/ApiEndPoints";
import NoReq from "../../../common/NoReq";
import CustomLoader from "../../../../../../UI/custom/CustomLoader";
import FullScreenDialog from "./FullScreenDialog";
import * as LanguageManager from "../../../../../../managers/language/LanguageManager";
import classNames from "classnames";

class Scheduled extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    value: 0,
    SCH_ROWS: [],
    page: 0,
    rowsPerPage: 3,
    loading: true,
    fullmodal: {
      open: false,
      row: null
    }
  };

  closeModal = () => {
    this.setState({
      fullmodal: {
        row: null,
        open: false
      }
    });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  // -- handle page -- //
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  componentDidMount() {
    console.log(APPORIO_TAXI_APIS.SCH_TRIPS);

    ApiManager.callPostApi("Scheduled", "", APPORIO_TAXI_APIS.SCH_TRIPS)

      .then(response => {
        console.log("*******", "success");
        // alert(JSON.stringify(response))

        this.setState({
          ...this.state,
          SCH_ROWS: response.data,
          loading: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  openModal = row => {
    this.setState({
      fullmodal: {
        open: true,
        row: row
      }
    });
  };

  render() {
    const { rowsPerPage, page } = this.state;
    console.log("driver home render", this.props);
    const { classes } = this.props;
    const { value } = this.state;
    if (this.state.loading) {
      return <CustomLoader showLoader={this.state.loading} />;
    } else {
      return (
        <div>
          {this.state.SCH_ROWS.length > 0 ? (
            this.state.SCH_ROWS.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            ).map(row => {
              return (
                <Paper
                  className={classes.Papercursor}
                  onClick={() => {
                    this.openModal(row);
                    console.log("click is working");
                  }}
                >
                  <Grid
                    container
                    md={12}
                    style={{ padding: 15, marginTop: 10 }}
                  >
                    <Grid item md={6}>
                      <Grid container md={12}>
                        <Grid item md={7} xs={9}>
                          <Grid item md={12} className={classes.Gridfifth}>
                            <Typography
                              variant="body1"
                              gutterBottom
                              style={{ fontWeight: "600" }}
                            >
                              {LanguageManager.Strings.RIDE_ID_LABEL}{" "}
                              {row.highlighted_left_text}
                            </Typography>
                          </Grid>
                          <Grid item md={12}>
                            <Typography
                              variant="caption"
                              gutterBottom
                              className={classes.BookTimeLabel}
                            >
                              {LanguageManager.Strings.BOOK_TIME_LABEL}{" "}
                              {row.small_left_text}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          md={5}
                          xs={3}
                          align="center"
                          className={classes.StatusBtn}
                        >
                          <Paper className={classes.stat}>
                            {row.status_text}
                          </Paper>
                          <Paper className={classes.paymethod}>
                            {row.highlighted_right_text}
                          </Paper>
                        </Grid>
                      </Grid>
                      <Grid md={12} className={classes.ridingWith}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          classname={classes.TypoRidingWith}
                        >
                          {LanguageManager.Strings.RIDE_WITH_LABEL}
                        </Typography>

                        <Grid container md={12}>
                          <Grid item md={2}>
                            <Avatar className={classes.taxiimageurl}>
                              <img
                                src={TAXI_IMAGE_URL + row.circular_image}
                                height="70"
                                width="70"
                              />
                            </Avatar>
                          </Grid>

                          <Grid item md={10}>
                            <Typography
                              variant="caption"
                              gutterBottom
                              className={classes.typoCap}
                            >
                              {row.user_name_text}
                            </Typography>

                            <Grid container md={8}>
                              <Grid item md={6}>
                                <Typography
                                  variant="caption"
                                  gutterBottom
                                  className={classes.desText}
                                >
                                  {row.user_descriptive_text}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                md={6}
                                xs={12}
                                className={classes.starrate}
                              >
                                <Icon>star_rate</Icon>
                                <Icon>star_rate</Icon>
                                <Icon>star_rate</Icon>
                                <Icon>star_rate</Icon>
                                <Icon>star_rate</Icon>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <Grid container xs={12} md={12}>
                        <Grid item md={6} xs={12}>
                          <Grid
                            item
                            md={12}
                            xs={12}
                            className={classes.marginFive}
                          >
                            <Paper className={classes.pick_location}>
                              {row.pick_location}
                            </Paper>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            xs={12}
                            className={classes.marginFive}
                          >
                            <Paper className={classes.drop_location}>
                              {row.drop_location}
                            </Paper>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={12}
                          className={classes.PaddingTen}
                        >
                          <CardMedia
                            className={classNames(
                              classes.cover,
                              classes.testClass
                            )}
                            style={{
                              height: "100%",
                              width: "100%",
                              borderRadius: 5
                            }}
                            image={require("../../../../../../assets/images/staticmap.png")}
                            title="map"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })
          ) : (
            <NoReq />
          )}
          {this.state.fullmodal.open && (
            <FullScreenDialog
              TripRow={this.state.fullmodal.row}
              open={this.state.fullmodal.open}
              onClose={this.closeModal}
            />
          )}

          {this.state.SCH_ROWS.length > 0 && (
            <div className={classes.DashPagination}>
              <TablePagination
                rowsPerPageOptions={[3, 6, 9]}
                colSpan={3}
                count={this.state.SCH_ROWS.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  native: true
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={DashboardPaginationActions}
              />
            </div>
          )}
        </div>
      );
    }
  }
}

export default withStyles(ManageTripStyles)(Scheduled);

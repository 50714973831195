import React from "react";
import PropTypes from "prop-types";
import { withStyles, Grid } from "@material-ui/core";
import { DriverWalletStyles } from "../../../../styles";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CustomLoader from "../../../../custom/CustomLoader";
import * as ApiManager from "../../../../../managers/api/ApiManager";
import { APPORIO_TAXI_APIS } from "../../../../../managers/api/ApiEndPoints";
import PaginationTable from "../../../../custom/PaginationTable";
import CsText from "./tableCols/CsText";
import XLoader from "../../../../custom/XLoader";
import DrawerLayout from "../../common/DrawerLayout";
import * as SessionManager from "../../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../../managers/session/SessionKeys";
import * as LanguageManager from "../../../../../managers/language/LanguageManager";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        marginTop: -20,
        textAlign: "left"
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

const columns = [
  LanguageManager.Strings.RECHARGE_BY_LABEL,
  LanguageManager.Strings.DESCRIPTION_LABEL,
  LanguageManager.Strings.PAY_METHD_LABEL,
  LanguageManager.Strings.DATE_LABEL
];

class DriverWallet extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    value: 0,
    checked: true,
    textValue: null,
    wait: true,
    loading: false,
    openAddMoney: false,
    DRIVER_CONFIG_ROWS: []
  };

  handleClickOpenModal = name => {
    this.setState({
      [name]: !this.state[name]
    });
  };

  handleSwitch = () => {
    this.setState(state => ({ checked: !state.checked }));
  };

  handleChange = (event, value) => {
    this.setState({ value }, () => this.List_table());
  };

  handleAddMoney = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  componentDidMount() {
    this.List_table();

    // console.log("session1234" + JSON.stringify(JSON.parse(SessionManager.getSessionData(SessionKeys.DRIVER_CONFIG))))
    let data = JSON.parse(
      SessionManager.getSessionData(SessionKeys.DRIVER_CONFIG)
    );
    this.setState({
      DRIVER_CONFIG_ROWS: data,
      currency: data.countries[0].isoCode
    });
  }

  List_table = () => {
    console.log("!@#$%^&*()");
    // start laoding
    this.setState({
      loading: true
    });
    ApiManager.callPostApi(
      "DRIVER_WALLET",
      "",
      APPORIO_TAXI_APIS.DRIVER_WALLET,
      {
        filter: 3 - this.state.value
      }
    )

      .then(response => {
        console.log("*****response*******" + JSON.stringify(response));

        this.setState({
          totalMoney: response.data.wallet_money,
          DRIVER_WALLET_TABLE: response.data,
          wait: false,
          loading: false
        });
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  getTransactions(result) {
    console.log("R%R%R%R%R%" + result);
    if (typeof result == "number") {
      console.log("#F#F" + result);
      this.setState({ totalMoney: parseInt(this.state.totalMoney) + result });
    } else {
      if (result) {
        this.List_table();
      }
    }
  }

  prepareTableData = DRIVER_WALLET_TABLE => {
    console.log("prepare table data", DRIVER_WALLET_TABLE.recent_transactoin);
    let allTableRows = [];
    {
      DRIVER_WALLET_TABLE.recent_transactoin.map((row, index) => {
        let singleRow = [];
        singleRow.push(<CsText data={row.narration} />);
        singleRow.push(<CsText data={row.description} />);
        singleRow.push(<CsText data={row.payment_method} />);
        singleRow.push(<CsText data={row.date} />);

        allTableRows.push(singleRow);
      });
    }

    return allTableRows;
  };

  UpdateInput = money => {
    this.setState({
      textValue: money
    });
  };

  render() {
    console.log(
      "driver home render",
      this.state.DRIVER_CONFIG_ROWS.general_config
    );
    const { classes } = this.props;
    const { value } = this.state;
    const { checked } = this.state;

    if (this.state.wait) {
      return <CustomLoader showLoader={this.state.wait} />;
    } else {
      return (
        <DrawerLayout>
          <Grid md={12} xs={12} align="left">
            <Grid container md={12} xs={12}>
              <Grid item md={6}>
                <Typography
                  variant="subtitle1"
                  className={classes.driverWalletLabel}
                >
                  {LanguageManager.Strings.DRIVER_WALLET_LABEL}
                </Typography>
              </Grid>

              <Grid item md={6} xs={6} align="right">
                <Typography variant="subtitle1" className={classes.CurrentBal}>
                  {LanguageManager.Strings.CURR_BAL_LABEL} :{" "}
                  {this.state.totalMoney}
                </Typography>

                {/* <Typography
                  variant="subtitle1"
                  style={{ color: "#2dcc70", fontWeight: 700 }}>
                  {this.state.totalMoney}
                </Typography>
 */}
              </Grid>
            </Grid>
            <hr className={classes.hrProps} />
            {/* <Paper style={{ padding: 20 }}>
              <Grid container xs={12} md={12}>
                <Grid it md={6} xs={6} align="center" style={{ marginTop: 20 }}>
                  <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
                    Current Balance
                </Typography>

                <Typography
                variant="h4"
                style={{ color: "#2dcc70", fontWeight: 700 }}>
                {this.state.totalMoney}
              </Typography>
                

                </Grid>
                <Grid md={6} xs={6}>
                  <Grid item>
                    <TextField
                      placeholder="1000"
                      id="outlined-number"
                      label="Add Money To Wallet"
                      value={this.state.textValue}
                      onChange={this.handleAddMoney("textValue")}
                      type="number"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true
                      }}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item style={{}}>


                    {this.state.DRIVER_CONFIG_ROWS.general_config.driver_wallet_package.map(row => {
                      return (
                        <Button
                          variant="contained"
                          size="medium"
                          color="primary"
                          className={classes.margin}
                          style={{ margin: "0 5px" }}
                          onClick={() => this.UpdateInput(row.amount)}
                        >
                          {row.amount}
                        </Button>
                      );
                    })
                    }
                 
                    <Button
                      variant="contained"
                      size="Large"
                      className={classes.AddMoney}
                      style={{

                      }}
                      onClick={() =>
                        this.handleClickOpenModal("openAddMoney")
                      }
                    >
                      + Add Money
                  </Button>

                    <DialogForm
                      visible={this.state.openAddMoney}
                      onOutsideTouch={() =>
                        this.handleClickOpenModal("openAddMoney")}>
                      <AddMoney handleClickOpenModal={this.handleClickOpenModal} textValue={this.state.textValue} updateTransactions={this.getTransactions.bind(this)}/>
                    </DialogForm>

                  </Grid>
                </Grid>
              </Grid>
            </Paper> */}
          </Grid>
          <Grid md={12} xs={12} className={classes.LinearGradient}>
            <Tabs
              value={this.state.value}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.handleChange}
            >
              <Tab label={LanguageManager.Strings.TRANSACTION_LABEL1} />
              <Tab label={LanguageManager.Strings.TRANSACTION_LABEL2} />
              <Tab label={LanguageManager.Strings.TRANSACTION_LABEL3} />
            </Tabs>

            <div style={{ position: "relative" }}>
              {this.state.loading && <XLoader />}
              {value === 0 && (
                <TabContainer>
                  <PaginationTable
                    columns={columns}
                    tableRowColor={"#efefef"}
                    tableHeadBackground={"#2ECC71"}
                    tableHeadFontColor={"#ffffff"}
                  >
                    {this.prepareTableData(this.state.DRIVER_WALLET_TABLE)}
                  </PaginationTable>
                </TabContainer>
              )}
              {value === 1 && (
                <TabContainer>
                  <PaginationTable
                    columns={columns}
                    tableRowColor={"#efefef"}
                    tableHeadBackground={"#2ECC71"}
                    tableHeadFontColor={"#ffffff"}
                  >
                    {this.prepareTableData(this.state.DRIVER_WALLET_TABLE)}
                  </PaginationTable>
                </TabContainer>
              )}
              {value === 2 && (
                <TabContainer>
                  <PaginationTable
                    columns={columns}
                    tableRowColor={"#efefef"}
                    tableHeadBackground={"#2ECC71"}
                    tableHeadFontColor={"#ffffff"}
                  >
                    {this.prepareTableData(this.state.DRIVER_WALLET_TABLE)}
                  </PaginationTable>
                </TabContainer>
              )}
            </div>
          </Grid>
        </DrawerLayout>
      );
    }
  }
}

export default withStyles(DriverWalletStyles)(DriverWallet);

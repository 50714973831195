import React from "react";
import { Typography } from "@material-ui/core";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// import { pureBlack } from "../../../../../../values/Color";
import { FONT_FAMILY } from "../../../../../../utils/AppConstants";

const styles = theme => ({
  root: {
    display: "flex",
    flex: 1,

    //  alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px"
  },
  colName: {
    alignSelf: "center",
    fontSize: "16px",

    // color: pureBlack,
    fontFamily: FONT_FAMILY
  }
});

const CsText = props => {
  const { classes } = props;
  return (
    <div container className={classes.root}>
      <Typography className={classes.colName}>{props.data}</Typography>
    </div>
  );
};

CsText.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CsText);

import React from "react";
import PropTypes from "prop-types";
import {
  withStyles,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem
} from "@material-ui/core";
import { DriverProfileStyles } from "../../../../styles";
import Typography from "@material-ui/core/Typography";
import CustomLoader from "../../../../custom/CustomLoader";
import * as ApiManager from "../../../../../managers/api/ApiManager";
import { APPORIO_TAXI_APIS } from "../../../../../managers/api/ApiEndPoints";
import CustomSnackbar from "../../../../custom/CustomSnackbar";

function TabContainer(props) {
  return (
    <Typography
      component="div"
      style={{
        padding: 8 * 3,
        textAlign: "left"
      }}
    >
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

class BankDetails extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    wait: true,
    value: 0,
    checked: false,
    open: false,
    name: "",
    bankname: "",
    accountname: "",
    accountnumber: "",
    DriverData: null,
    showSnackbar: false,
    snackbarVariant: "error",
    snakBarMessage: "",
    openType: false,
    AccType: "",
    nameType: ""
  };

  // componentDidMount() {
  //   console.log(JSON.parse(getSessionData(DRIVER_DATA)))
  //   let data = JSON.parse(getSessionData(DRIVER_DATA));
  //   this.setState({
  //     DriverData: data,
  //     wait: (data) ? false : true,
  //   });
  // }

  componentDidMount() {
    // console.log(JSON.parse(getSessionData(DRIVER_DATA)))
    // let data = JSON.parse(getSessionData(DRIVER_DATA));

    ApiManager.callPostApi("Driver data", "", APPORIO_TAXI_APIS.DRIVER_DETAILS)

      .then(response => {
        console.log("*****response*******", response);

        this.setState(
          {
            DriverData: response.data
            // wait: response.data ? false : true
          },
          () => this.ListAccType()
        );
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  ListAccType = () => {
    ApiManager.callPostApi("Driver Acctype", "", APPORIO_TAXI_APIS.ACC_TYPE)

      .then(response => {
        console.log("*****Acctype*******", response);

        this.setState({
          AccType: response,
          wait: response ? false : true
        });
        console.log(this.state.AccType);
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleDriverDataInput = (event, name) => {
    this.setState({
      DriverData: {
        ...this.state.DriverData,
        [name]: event.target.value
      }
    });
  };

  handleSnackBarClose = () => {
    this.setState({
      showSnackbar: false
    });
  };

  handleSwitch = () => {
    this.setState(state => ({ checked: !state.checked }));
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleInputChange = name => event => {
    this.setState({
      DriverData: {
        ...this.state.DriverData,
        [name]: event.target.value
      }
    });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };
  handleClickShowConfirmPassword = () => {
    this.setState(state => ({
      showConfirmPassword: !state.showConfirmPassword
    }));
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClickOpen = () => {
    this.setState({
      openList: true
    });
  };

  handleClose = () => {
    this.setState({ openType: false });
  };

  handleOpen = () => {
    this.setState({ openType: true });
  };
  handleChangeType = event => {
    this.setState({ nameType: event.target.value });
  };
  //edit

  saveBank = () => {
    console.log(this.state.DriverData);
    this.setState({ wait: true });

    if (
      this.state.DriverData.bank_name == "" ||
      this.state.DriverData.online_code == "" ||
      this.state.DriverData.account_holder_name == "" ||
      this.state.DriverData.account_number == ""
    ) {
      // alert('error field')
      this.setState({
        showSnackbar: true,
        snakBarMessage: "Please enter Fields",
        wait: false,
        snackbarVariant: "warning"
      });
    }

    // // start laoding
    // this.setState({
    //   wait: true,
    // })
    else {
      //  Edit form
      ApiManager.callPostApi(
        "EDIT_BANK",
        "",
        APPORIO_TAXI_APIS.EDIT_BANK,

        {
          bank_name: this.state.DriverData.bank_name,
          online_code: this.state.DriverData.online_code,
          account_holder_name: this.state.DriverData.account_holder_name,
          account_number: this.state.DriverData.account_number,
          account_type: String(this.state.nameType)
        }
      )

        .then(response => {
          console.log("******EDIT_BANK******", response);
          // this.setState({
          //   wait: false,
          // })

          switch (parseInt(response.result)) {
            case 1:
              this.setState({
                showSnackbar: true,
                snakBarMessage: response.message,
                snackbarVariant: "success",
                wait: false
              });
              break;

            case 0:
              this.setState({
                showSnackbar: true,
                snakBarMessage: response.message,
                wait: false
              });
              break;
          }

          console.log(response);
        })

        .catch(error => {});
    }
  };

  render() {
    console.log("driver home render", this.state);
    const { classes } = this.props;
    const { value } = this.state;
    const { checked } = this.state;

    if (this.state.wait) {
      return <CustomLoader showLoader={this.state.wait} />;
    } else {
      return (
        <Grid item xs={12} style={{}}>
          <CustomSnackbar
            showSnackbar={this.state.showSnackbar}
            variant={this.state.snackbarVariant}
            message={this.state.snakBarMessage}
            onClose={this.handleSnackBarClose}
          />
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row"
              // marginTop: -10
            }}
          >
            {/* <IconButton
            onClick={() =>
              this.props.onBackPress()
            }
          >
            <Icon style={{ color: "#666" }}>
              arrow_back
              </Icon>
          </IconButton> */}
            <Grid style={{ marginTop: 10 }}>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "600", color: "#26699d" }}
              >
                Bank Details
              </Typography>
            </Grid>
          </Grid>
          <hr style={{ color: "#eaeaea" }} />
          <Grid container xs={12} style={{}}>
            <Grid item xs={5} style={{ marginTop: 20 }}>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                Bank Name
              </Typography>
              <Typography variant="display4" style={{ fontSize: "15px" }}>
                Introduce your Bank Name
              </Typography>
            </Grid>
            <Grid item xs={7} md={5} style={{ padding: 5 }}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-bankname"
                label="Bank Name"
                value={
                  this.state.DriverData != ""
                    ? this.state.DriverData.bank_name
                    : ""
                }
                className={classes.textField}
                onChange={event =>
                  this.handleDriverDataInput(event, "bank_name")
                }
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container xs={12} style={{}}>
            <Grid item xs={5} style={{ marginTop: 20 }}>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                Branch code
              </Typography>
              <Typography variant="display4" style={{ fontSize: "15px" }}>
                Introduce your Bank Branch Code
              </Typography>
            </Grid>
            <Grid item xs={7} md={5} style={{ padding: 5 }}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-ifsc"
                label="Branch Code"
                value={
                  this.state.DriverData != ""
                    ? this.state.DriverData.online_code
                    : ""
                }
                className={classes.textField}
                onChange={event =>
                  this.handleDriverDataInput(event, "online_code")
                }
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container xs={12} style={{}}>
            <Grid item xs={5} style={{ marginTop: 20 }}>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                Account Holder Name
              </Typography>
              <Typography variant="display4" style={{ fontSize: "15px" }}>
                Introduce your Account Name
              </Typography>
            </Grid>
            <Grid item md={5} xs={7} style={{ padding: 5 }}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-bankname"
                label="Account Holder Name"
                value={
                  this.state.DriverData != ""
                    ? this.state.DriverData.account_holder_name
                    : ""
                }
                className={classes.textField}
                onChange={event =>
                  this.handleDriverDataInput(event, "account_holder_name")
                }
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container xs={12} style={{}}>
            <Grid item xs={5} style={{ marginTop: 20 }}>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                Account Number
              </Typography>
              <Typography variant="display4" style={{ fontSize: "15px" }}>
                Introduce your Account Number
              </Typography>
            </Grid>
            <Grid item xs={7} md={5} style={{ padding: 5 }}>
              <TextField
                style={{ width: "100%" }}
                id="outlined-number"
                label="Account Number"
                value={
                  this.state.DriverData != ""
                    ? this.state.DriverData.account_number
                    : ""
                }
                onChange={event =>
                  this.handleDriverDataInput(event, "account_number")
                }
                type="number"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid container xs={12} style={{}}>
            <Grid item xs={5} style={{ marginTop: 20 }}>
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                Account Type
              </Typography>
              <Typography variant="display4" style={{ fontSize: "15px" }}>
                Select your Account Type
              </Typography>
            </Grid>
            <Grid item xs={7} md={5} style={{ padding: 5 }}>
              <Select
                openType={this.state.openType}
                onClose={this.handleClose}
                onOpen={this.handleOpen}
                value={this.state.nameType}
                onChange={event => this.handleChangeType(event)}
                inputProps={{
                  name: "acctype",
                  id: "demo-controlled-open-select"
                }}
              >
                {this.state.AccType.account_types.map(item => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>

          <Grid md={10} xs={12} align="right" style={{ paddingRight: 0 }}>
            <Button
              variant="contained"
              style={{
                width: "20%",
                fontWeight: "800",
                backgroundColor: "#4990E2",
                color: "#fff"
              }}
              onClick={() => this.saveBank()}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      );
    }
  }
}

export default withStyles(DriverProfileStyles)(BankDetails);

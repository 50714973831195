// import { getTheme } from "../../../../managers/theme/ThemeManager";
import { CUSTOM_FONT } from "../../../../values/Typography";

const styles = theme => {
  // const configTheme = getTheme();

  return {
    parentView: {
      backgroundColor: "blue",
      marginTop: "30vh",
      height: "50vh",
      paddingRight: theme.spacing.unit * 10
    },
    textView: {
      paddingTop: theme.spacing.unit * 2
    },
    imageView: {
      height: 300,
      width: 300,
      [theme.breakpoints.down("sm")]: {
        height: 200,
        width: 200
      }
    },
    imageContainer: {
      flex: 1
    },
    footerTitle: {
      // color: "white",
      fontFamily: CUSTOM_FONT,
      fontSize: 28,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16
      }
    },
    footerText: {
      // color: "white",
      fontFamily: CUSTOM_FONT,
      fontSize: 18,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16
      }
    },
    media: {
      height: "100%",
      width: "60%",
      color: "white"
    },
    footerDiv: {
      backgroundColor: "red"
    },
    footerTitlePart: {
      fontWeight: "bold",
      color: "white",
      fontFamily: CUSTOM_FONT
    },
    featureText: {
      fontSize: 18,
      fontFamily: CUSTOM_FONT,
      width: 320
    },
    featureTitle: {
      fontSize: 24,
      fontWeight: "bold",
      fontFamily: CUSTOM_FONT
    },
    featureContainer: {
      flex: 1,
      justify: "flex-start",
      alignItems: "center",
      marginRight: theme.spacing.unit * 1,
      marginLeft: theme.spacing.unit * 1,
      padding: theme.spacing.unit
    },
    textContainer: {
      paddingLeft: theme.spacing.unit * 1,
      paddingRight: theme.spacing.unit * 1
    },
    root: {
      flexGrow: 1,
      paddingRight: theme.spacing.unit * 1,
      paddingLeft: theme.spacing.unit * 1,
      marginTop: theme.spacing.unit * 5
      //  backgroundColor:'gray'
    },
    img: {
      margin: "auto",
      display: "block",
      width: 100,
      height: 100
    },
    footerTextWrapper: {
      justifyContent: "flex-start",
      marginTop: theme.spacing.unit * 10,
      marginLeft: theme.spacing.unit * 10,
      marginRight: theme.spacing.unit * 10,
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
      }
    },
    footerContainer: {
      // backgroundColor: "#E7519D",
      // backgroundColor: "#00649a",
      paddingRight: "5%",
      paddingLeft: "5%"
    }
  };
};

export default styles;

export const saveSessionData = async (key, data) => {
  sessionStorage.setItem(key, data);
};

export const getSessionData = key => {
  return sessionStorage.getItem(key);
};

export const particularKey = key => {
  return sessionStorage.removeItem(key);
};

export const clearSession = () => {
  return sessionStorage.clear();
};

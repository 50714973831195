import React from "react";
import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  Icon,
  Paper,
  ButtonBase
} from "@material-ui/core";
import { UploadDocStyles } from "../../../styles";
import DocBarComponent from "../common/DocBarComponent";
import LinearTimeline from "../signUp/LinearTimeline"; //'../common/TimelineComponent';
import * as ApiManager from "../../../../managers/api/ApiManager";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import * as LanguageManager from "../../../../managers/language/LanguageManager";

class UploadDocComponent extends React.Component {
  constructor(props) {
    super(props);
    this.getResponse = this.getResponse.bind(this);

    this.state = {
      showBorder: null,
      visible: false,
      disableButton: false,
      disableButton2: false,
      disableButton3: false,
      disableButton4: false,
      uploadTitle: "Upload",
      uploadTitle2: "Upload",
      uploadTitle3: "Upload",
      uploadTitle4: "Upload",
      isLoading: false,
      buttonColor: "primary",
      buttonTitle: "Upload",
      showFlag: 0
    };
  }

  async showNextButton(data) {
    // await data.map(item=>this.setState({showFlag:showFlag+item.document_verification_status}))
    // console.log("jojojojo"+this.state.showFlag)
  }

  componentDidMount() {
    console.log("lllllll" + JSON.stringify(this.props));
    // if(this.props.changeData){
    //     this.setState({showDocBar:true})
    // }

    // this.props.docsData.map(item=>this.setState({showFlag:this.state.showFlag+item.document_verification_status}))
  }

  submitFunction = value => {
    this.props.getValue(value + 1);
    console.log("UDCVAl " + (value + 1));
  };

  getResponse(result) {
    console.log("pipipipii.." + JSON.stringify(result));
    this.props.getValue(result);
  }

  render() {
    console.log("expire", this.props);
    const {
      classes,
      callback,
      indexValue,
      driverID,
      postLoginFlag,
      componentTitle,
      vehicleDocStage,
      vehicleID
    } = this.props;
    return (
      <Grid item xl className={classes.docBarContainer}>
        <Typography
          variant="h5"
          component="h3"
          className={classes.loginTitle}
          align="left"
        >
          {componentTitle}
        </Typography>
        <div
          className={classes.listContainer}
          justify="center"
          style={{ overflowY: "auto", margin: "1%" }}
        >
          {this.props.docsData.length > 0 ? (
            this.props.docsData.map(item => (
              <DocBarComponent
                expire_date={item.expire_date}
                barLabel={item.documentname}
                buttonTitle={item.document_verification_status}
                docID={item.id}
                driverID={driverID}
                callBack={this.getResponse}
                flagValue={postLoginFlag}
                carDocMode={vehicleDocStage}
                vehicleID={vehicleID}
              />
            ))
          ) : (
            <Typography
              variant="subtitle"
              className={classes.noDataText}
              align="center"
            >
              {LanguageManager.Strings.NO_DATA_LABEL}
            </Typography>
          )}
        </div>
        <Button
          fullWidth
          variant="contained"
          disableTouchRipple={true}
          disableFocusRipple={true}
          className={classes.SignupButton}
          onClick={() => this.submitFunction(indexValue)}
          style={{
            display:
              this.props.docsData.findIndex(
                row => row.document_verification_status == 0
              ) > -1
                ? "none"
                : null
          }}
        >
          {LanguageManager.Strings.NEXT_LABEL}
        </Button>
      </Grid>
    );
  }
}

export default withStyles(UploadDocStyles)(UploadDocComponent);

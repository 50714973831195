import React, { Component } from "react";
import {
  CardMedia,
  Grid,
  Card,
  Typography,
  Select,
  Menu,
  MenuItem,
  InputBase,
  Paper,
  Button,
  Avatar,
  Radio,

} from "@material-ui/core";
import EventBus from "eventing-bus";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import CustomLoader from "../../../custom/CustomLoader";

import "react-datepicker/dist/react-datepicker.css";
import { Redirect } from "react-router-dom";
import ArrowDropDown from "@material-ui/icons/KeyboardArrowDown";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { IconButton } from "@material-ui/core";
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints";
import * as ApiManager from "../../../../managers/api/ApiManager";
import RideLoader from "./RideLoader";
import DialogForm from "../../../custom/DialogForm";
import ShowFavDriver from "./ShowFavDriver";
import CustomSnackbar from "../../../custom/CustomSnackbar";
import { OutStationStyles } from "../../../styles";
import { ThemeContext } from "@emotion/core";
// import DateFnsUtils from '@date-io/date-fns';
// import { MuiPickersUtilsProvider, TimePicker, DatePicker } from 'material-ui-pickers';
import ConfirmBookingContainer from "./ConfirmBookingContainer";
import * as LanguageManager from '../../../../managers/language/LanguageManager'

//   alert(JSON.stringify(event));
// });

class OutstationJourneyCard extends Component {
  state = {
    isTrackVisible: false,
    booking_id: "",
    isFavDriverVisible: false,
    isLoaderVisible: false,
    isVisibleEnterCoupon: false,
    coupon_text: "",
    checkoutData:"",
    payment_option: [],
    showSnackbar: false,
    snackbarVariant: "error",
    snakBarMessage: "",
    payment_option_id: 3,//this.props.data.SelectedPaymentMethod.id,
    showSnackbar: false,
    isRedirectVisible: false,
    femaleSelected:false,
    maleSelected:true,
    leaveTime:"Select",
    returnTime:"Select",
    oneWaySelected:false,
    returnSelected:true,
    selectedDate:'Select',
    leavingDate:'Select',
    leavingTime:'Select',
    returningDate:'Select',
    returningTime:'Select',
    isBookingContainerVisible:false,
    isLoading:false,
    singleCars:this.props.detailsData.single,
    roundCars:this.props.detailsData.round
  };

  redirectToTrack = () => {
    if (this.state.isTrackVisible) {
      return <Redirect to={"/driver/track_ride"} />;
    }
  };

  showLoader() {
    return <CustomLoader showLoader={this.state.isLoading} />;
  }

  handleLeaveDateChange(date) {
    let date1 = new Date(date);
    let year = date1.getFullYear();
    let month = date1.getMonth() + 1;
    let dt = date1.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    this.setState({ leavingDate: `${year}-${month}-${dt}`,leaveDateObj:date1 },()=>console.log(this.state.leaveDateObj));
  }

  handleLeaveTimeChange(date) {
    console.log("$$"+date)
    let today=new Date();
    let dateFormat = new Date(date);
    let hours = dateFormat.getHours();
    let min = dateFormat.getMinutes();
    if(today.getDate()==this.state.leaveDateObj.getDate() && today.getMonth()==this.state.leaveDateObj.getMonth()){
      console.log("@@1@@"+today.getDate()+"//"+dateFormat.getDate()+"//"+today.getMonth()+"//"+dateFormat.getMonth())
    if(hours<today.getHours()){
      this.setState({
        showSnackbar:true,
        snakBarMessage:"Leaving time not valid.",
        snackbarVariant:'error'
      })
    }else{
      if(hours<10){
        hours="0"+hours;
    }

    if(min<10){
      min="0"+min;
    }
    this.setState({ leavingTime: `${hours}  : ${min}` },()=>this.setState({leavingHours:hours}));
    }}else{
      if(hours<10){
        hours="0"+hours;
    }

    if(min<10){
      min="0"+min;
    }
    this.setState({ leavingTime: `${hours}  : ${min}` },()=>this.setState({leavingHours:hours}));
    }
  }

  handleReturnDateChange(date) {
    let date1 = new Date(date);
    let year = date1.getFullYear();
    let month = date1.getMonth() + 1;
    let dt = date1.getDate();
    console.log("@@2@@"+date1.getDate()+"//"+this.state.leaveDateObj.getDate()+"//"+date1.getMonth()+"//"+this.state.leaveDateObj.getMonth())

    if(this.state.leaveDateObj.getDate()<=date1.getDate()&&this.state.leaveDateObj.getMonth()<=date1.getMonth()){

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    this.setState({ returningDate: `${year}-${month}-${dt}` });
  }else{
    this.setState({
      showSnackbar:true,
      snakBarMessage:"Invalid Return Date.",
      snackbarVariant:'error'
    })
  }
  }

  handleReturnTimeChange(date) {
    let today=new Date();
    let dateFormat = new Date(date);
    let hours = dateFormat.getHours();
    let min = dateFormat.getMinutes();

    if(this.state.leavingDate==this.state.returningDate){
          if(hours>this.state.leavingHours){
            if(hours<10){
              hours="0"+hours;
            }
      
          if(min<10){
            min="0"+min;
          }
          this.setState({ returningTime: `${hours}  : ${min}` });
          }else{
            this.setState({
              showSnackbar:true,
              snakBarMessage:"Returning time should be greater than leaving time on the same date.",
              snackbarVariant:'error'
            })
          }
    }else{
    if(hours<10){
        hours="0"+hours;
    }

    if(min<10){
      min="0"+min;
    }
    this.setState({ returningTime: `${hours}  : ${min}` });
    }
  }

  handleTimeChange(date) {
    let dateFormat = new Date(date);
    let hours = dateFormat.getHours();
    let min = dateFormat.getMinutes();

    if(hours<10){
        hours="0"+hours;
    }

    if(min<10){
      hours="0"+hours;
    }
    this.setState({ laterTime: `${hours}  : ${min}` });
  }


  scheduleRide(result){
     console.log("ghghghgh",result)

    let yearString_R= new Date(this.state.returningDate.toString()).getFullYear();
    let yearString_L= new Date(this.state.leavingDate.toString()).getFullYear();

    let monthString_R= (new Date(this.state.returningDate.toString()).getMonth()<10)?'0'+new Date(this.state.returningDate.toString()).getMonth():new Date(this.state.returningDate.toString()).getMonth();
    let monthString_L= (new Date(this.state.leavingDate.toString()).getMonth()<10)?'0'+new Date(this.state.leavingDate.toString()).getMonth():new Date(this.state.leavingDate.toString()).getMonth();

    let dateString_R= (new Date(this.state.returningDate.toString()).getDate()<10)?'0'+new Date(this.state.returningDate.toString()).getDate():new Date(this.state.returningDate.toString()).getDate();
    let dateString_L= (new Date(this.state.leavingDate.toString()).getDate()<10)?'0'+new Date(this.state.leavingDate.toString()).getDate():new Date(this.state.leavingDate.toString()).getDate();

    let hourString_R= (new Date(this.state.returningTime.toString()).getHours()<10)?'0'+new Date(this.state.returningTime.toString()).getHours():new Date(this.state.returningTime.toString()).getHours();
    let hourString_L= (new Date(this.state.leavingTime.toString()).getHours()<10)?'0'+new Date(this.state.leavingTime.toString()).getHours():new Date(this.state.leavingTime.toString()).getHours();

    let minuteString_R= (new Date(this.state.returningTime.toString()).getMinutes()<10)?'0'+new Date(this.state.returningTime.toString()).getMinutes():new Date(this.state.returningTime.toString()).getMinutes();
    let minuteString_L= (new Date(this.state.leavingTime.toString()).getMinutes()<10)?'0'+new Date(this.state.leavingTime.toString()).getMinutes():new Date(this.state.leavingTime.toString()).getMinutes();

    let dateObj={};
    dateObj["returnYear"]=yearString_R;
    dateObj["leaveYear"]=yearString_L;
    dateObj["returnMonth"]=monthString_R;
    dateObj["leaveMonth"]=monthString_L;
    dateObj["returnDay"]=dateString_R;
    dateObj["leaveDay"]=dateString_L;
    dateObj["returnHour"]=hourString_R;
    dateObj["leaveHour"]=hourString_L;
    dateObj["returnMinute"]=minuteString_R;
    dateObj["leaveMinute"]=minuteString_L;



    this.executeCheckOutApi(dateObj,result);
    
   }


  executeCheckOutApi = (dateData,carData) => {
    if(this.state.leavingDate=='Select'||this.state.leavingTime=='Select'||this.state.returningDate=='Select'||this.state.returningTime=='Select'){
      this.setState({
        showSnackbar:true,
        snakBarMessage:'Please select Start & End Trip Schedules.'
      })
    }else{
      this.setState({isLoading:true})
    ApiManager.callPostApi(
      "User booking",
      "OutStation Checkout APi",
      ApiEndPoints.APPORIO_TAXI_APIS.CHECKOUT_API,
      {
        service_type: 4,
        vehicle_type: carData.vehicle_type_id,//this.props.detailsData.vehicle_type_id,
        area: this.props.areaID,
        pick_up_locaion: this.props.startAddress.address,
        later_date: this.state.leavingDate,//dateData.leaveYear+"/"+dateData.leaveMonth+"/"+dateData.leaveDay,
        later_time: this.state.leavingTime,//dateData.leaveHour+":"+dateData.leaveMinute,
        number_of_rider: "",
        pickup_latitude: String(this.props.startAddress.lat),
        pickup_longitude: String(this.props.startAddress.lng),
        package_id: "",
        drop_location: JSON.stringify([
          {
            stop: "1",
            drop_latitude: String(this.props.dropAddress.lat),
            drop_longitude: String(this.props.dropAddress.lng),
            drop_location: this.props.dropAddress.address,
            status: "1"
          }
        ]),
        total_drop_location: 1,
        booking_type: this.props.rideType,
        return_date: this.state.returningDate,//dateData.returnYear+"/"+dateData.returnMonth+"/"+dateData.returnDay,
        return_time: this.state.returningTime//dateData.returnHour+":"+dateData.returnMinute
      }
    )
      .then(res => {
        console.log("$$$$"+JSON.stringify(res))
        if (res.result === "1") {
          this.setState({
            checkoutData: res.data,
            isBookingContainerVisible: true,
            isLoading:false
          });
        } else {
          this.setState({
            isLoading:false,
            showSnackbar:true,
            snakBarMessage:res.message,
            snackbarVariant:'error'
          })
        }
      })
      .catch(err => console.warn(err));
  }
};

  // changeLeavingDate = date => {
  //   this.setState({ leavingDate: date });
  // };
  // changeReturningDate = date => {
  //   this.setState({ returningDate: date });
  // };

  // changeReturningTime = time => {
  //   this.setState({ returningTime: time });
  // };
  // changeLeavingTime = time => {
  //   this.setState({ leavingTime: time });
  // };

  executeCancelRideApi = () => {
    ApiManager.callPostApi(
      "Ride Loader",
      "auto cancel ride api",
      ApiEndPoints.APPORIO_TAXI_APIS.USER_AUTO_CANCEL_API,
      {
        booking_id: this.state.booking_id
      }
    )
      .then(res => {
        if (res.result === "1") {
          this.props.onBackPress();
        }
      })
      .catch(err => alert(JSON.stringify(err)));
  };

  componentWillMount() {
    EventBus.on("RIDE_EVENT", data => {
      this.setState({
        isLoaderVisible: !this.state.isLoaderVisible,
        isTrackVisible: true
      });
    });
  }

//   handleChange = event => {
//     this.executeChnagePaymentApi(event.target.value);
//   };

  handleChange(event) {
    if(event.target.value==1){
      this.setState({ oneWaySelected: true, returnSelected: false });
    }else{
      this.setState({ oneWaySelected: false, returnSelected: true });
    }
  }

  handleSnackBarClose = () => {
    this.setState({
      finalMessage: "",
      showSnackbar: false
    });
  };
  
  resetComponent = () => {
    return <Redirect to="/Home" />;
  };
  render() {
    const { classes,detailsData } = this.props;
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative"
        }}>
        <CustomSnackbar
          showSnackbar={this.state.showSnackbar}
          variant={this.state.snackbarVariant}
          message={this.state.snakBarMessage}
          onClose={this.handleSnackBarClose}
        />
        {this.showLoader()}
        {
      (this.state.isBookingContainerVisible)?
                    <ConfirmBookingContainer
                      routeData={this.props.routeData}
                      bookingType={this.state.rideType}
                      data={this.state.checkoutData}
                      onBackPress={() =>
                      this.setState({isBookingContainerVisible: !this.state.isBookingContainerVisible})}/>:

                        <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          position: "relative"
                        }}>
        <Grid item style={{ position: "absolute", top: 0 }}>
          <IconButton onClick={() => this.props.backFunction()}>
            <ArrowBack/>
          </IconButton>
        </Grid>
        <Paper
          style={{
            alignSelf: "center",
            padding:10,
            width: "85%",
            height: "90%",
            flexDirection: "column"}}>

{/* ''''''''''''''''''START_ADDRESS'''''''''''''''''''''''''''''' */}
            <Grid justify="flex-start" container xl >  
            <Grid item >
            <div
                        style={{
                          width: 30,
                          height: 30,
                          borderRadius: 30 / 2,
                          backgroundColor: "#40D590"
                        }}
                      />
                      </Grid>
                <Grid xs={1} item justify="flex-start">
              <Typography
                align="center"
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  padding: 5,
                  color: "#40D590"
                }}>
                {LanguageManager.Strings.FROM_LABEL}
              </Typography>
              </Grid>

              <Grid xl item>
              <Typography
              fullWidth
              align="center"
                style={{
                  padding: 5,
                  fontSize: 14,

                  fontWeight: 400
                }}>
                {this.props.startAddress.address}
              </Typography>
              </Grid>
            </Grid>
{/* ''''''''''''''''''END_ADDRESS'''''''''''''''''''''''''''''' */}

            <Grid justify="flex-start" container xl >
            <Grid item >
            <div
                        style={{
                          width: 30,
                          height: 30,
                          borderRadius: 30 / 2,
                          backgroundColor: "red"
                        }}
                      />
                      </Grid>
                <Grid xs={1} item justify="flex-start">
              <Typography
                align="center"
                style={{
               
                  fontSize: 16,
                  fontWeight: "bold",
                  padding: 5,

                  color: "red"
                }}
              >
                {LanguageManager.Strings.TO_LABEL}
              </Typography>
              </Grid>

              <Grid xl item>
              <Typography
              fullWidth
              align="center"
                style={{
                  padding: 5,
                  fontSize: 14,

                  fontWeight: 400
                }}
              >
  {this.props.dropAddress.address} 
            </Typography>
              </Grid>
            </Grid>
{/* ''''''''''''''''''JOURNEY_SELECTION_BUTTONS'''''''''''''''''''''''''''''' */}

          <Grid xl container justify="center" alignItems="center" className={classes.genderContainer}>
                  <Grid xs={3} container justify="flex-start" alignItems="center">
                  <Radio
                  disabled={detailsData.single.length>0?false:true}
                    style={{ marginLeft: "2%" }}
                    checked={this.state.oneWaySelected}
                    onChange={(evt) => this.handleChange(evt)}
                    value={1}
                    color="primary"
                    name="radio-oneWay"
                    aria-label="M"
                  />
                  <Typography variant="body1" className={classes.genderTitle}>
                  {LanguageManager.Strings.ONE_WAY_LABEL}
                  </Typography>
                  </Grid>

                  <Grid xs={3} container justify="flex-start" alignItems="center">
                  <Radio
                    checked={this.state.returnSelected}
                    onChange={(evt) => this.handleChange(evt)}
                    value={2}
                    color="primary"
                    name="radio-return"
                    aria-label="F"
                  />
                  <Typography variant="body1" className={classes.genderTitle}>
                  {LanguageManager.Strings.RETURN_LABEL}
                  </Typography>
                  </Grid>
                </Grid>
                <p align="left" style={{color:'red',fontSize:12,display:detailsData.single.length>0?'none':'flex'}}>*{this.props.warningMessage}</p>

                {/* ''''''''''''''''''LEAVE_BAR'''''''''''''''''''''''''''''' */}

<div>
<Typography variant="h6" gutterBottom align="left" className={classes.sectionHead}>
                 Leave On
                </Typography>

                <Grid container sm={11} justify="space-between" alignItems="center" className={classes.fieldContainer}>
{/* 
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container className={classes.grid} justify="space-around">
                    <DatePicker
                        margin="normal"
                        label="Date"
                        value={this.state.leavingDate}
                        onChange={(date)=>this.changeLeavingDate(date)}
                    />
                    <TimePicker
                        margin="normal"
                        label="Time"
                        value={this.state.leavingTime}
                        onChange={(time)=>this.changeLeavingTime(time)}
                    />
                    </Grid>
                </MuiPickersUtilsProvider> */}

<div style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%"
                          }}
                        >
                          <DatePicker
                            customInput={
                              <Button
                                style={{
                                  display: "flex",

                                  background: "#EAEAEA",
                                  padding: "0.5em",
                                  justifyContent: "space-around",
                                  borderRadius: 5,
                                  alignItems: "center",
                                  margin: "0.5rem"
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: 16,
                                    fontWeight: "bold"
                                  }}
                                >
                                  Date
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: 16,
                                    marginLeft: 10,
                                    marginRight: 10
                                  }}
                                >
                                  {this.state.leavingDate}
                                </Typography>
                                <IconButton style={{ padding: 0, margin: 0 }}>
                                  <ArrowDropDown />
                                </IconButton>
                              </Button>
                            }
                            dateFormat="yyyy/MM/dd"
                            minDate={new Date()}
                            showDisabledMonthNavigation
                            onChange={this.handleLeaveDateChange.bind(this)}
                          />
                          <DatePicker
                            customInput={
                              <Button
                                style={{
                                  flex: 0.5,
                                  display: "flex",

                                  background: "#EAEAEA",
                                  padding: "0.5em",
                                  justifyContent: "space-around",
                                  borderRadius: 5,
                                  alignItems: "center",
                                  margin: "0.5rem"
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: 16,
                                    fontWeight: "bold"
                                  }}
                                >
                                  Time
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: 16,
                                    marginLeft: 10,
                                    marginRight: 10
                                  }}
                                >
                                  {this.state.leavingTime}
                                </Typography>
                                <IconButton style={{ padding: 0, margin: 0 }}>
                                  <ArrowDropDown />
                                </IconButton>
                              </Button>
                            }
                            onChange={this.handleLeaveTimeChange.bind(this)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={5}
                            dateFormat="h:mm aa"
                            timeCaption="Time"
                          />
                        </div>
                      </div>

                </Grid>
                </div>
                {/* ''''''''''''''''''REACH_BAR'''''''''''''''''''''''''''''' */}

                <div>
                <Typography variant="h6" gutterBottom align="left" className={classes.sectionHead}>
                 Return By
                </Typography>

                <Grid container sm={11} justify="space-between" alignItems="center" className={classes.fieldContainer}>

                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container className={classes.grid} justify="space-around">
                    <DatePicker
                        margin="normal"
                        label="Date"
                        value={this.state.returningDate}
                        onChange={(date)=>this.changeReturningDate(date)}
                    />
                    <TimePicker
                        margin="normal"
                        label="Time"
                        value={this.state.returningTime}
                        onChange={(time)=>this.changeReturningTime(time)}
                    />
                    </Grid>
                </MuiPickersUtilsProvider> */}
                <div style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%"
                          }}
                        >
                          <DatePicker
                            customInput={
                              <Button
                                style={{
                                  display: "flex",

                                  background: "#EAEAEA",
                                  padding: "0.5em",
                                  justifyContent: "space-around",
                                  borderRadius: 5,
                                  alignItems: "center",
                                  margin: "0.5rem"
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: 16,
                                    fontWeight: "bold"
                                  }}
                                >
                                  Date
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: 16,
                                    marginLeft: 10,
                                    marginRight: 10
                                  }}
                                >
                                  {this.state.returningDate}
                                </Typography>
                                <IconButton style={{ padding: 0, margin: 0 }}>
                                  <ArrowDropDown />
                                </IconButton>
                              </Button>
                            }
                            dateFormat="yyyy/MM/dd"
                            minDate={new Date()}
                            showDisabledMonthNavigation
                            onChange={this.handleReturnDateChange.bind(this)}
                          />
                          <DatePicker
                            customInput={
                              <Button
                                style={{
                                  flex: 0.5,
                                  display: "flex",

                                  background: "#EAEAEA",
                                  padding: "0.5em",
                                  justifyContent: "space-around",
                                  borderRadius: 5,
                                  alignItems: "center",
                                  margin: "0.5rem"
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: 16,
                                    fontWeight: "bold"
                                  }}
                                >
                                  Time
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: 16,
                                    marginLeft: 10,
                                    marginRight: 10
                                  }}
                                >
                                  {this.state.returningTime}
                                </Typography>
                                <IconButton style={{ padding: 0, margin: 0 }}>
                                  <ArrowDropDown />
                                </IconButton>
                              </Button>
                            }
                            onChange={this.handleReturnTimeChange.bind(this)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={5}
                            dateFormat="h:mm aa"
                            timeCaption="Time"
                          />
                        </div>
                      </div>

                </Grid>
                </div>
                <Typography variant="h6" gutterBottom align="left" className={classes.sectionHead}>
                    Select Vehicle Type
                </Typography>
                <div style={{overflowY:'auto',height:200}}>
                {detailsData.single.map((item,index) => (
                <Paper
                onClick={() =>this.scheduleRide(item)}
                style={{
                  // backgroundColor:
                  //   index == this.state.selectedCarIndex && "#D6E9FD",
                  margin: "0.5rem",
                  display: "flex",
                  flex: 3
                }}>
                <div style={{ display: "flex", flex: 0.6 }}>
                  <img
                  //   src={
                  //     ApiEndPoints.TAXI_IMAGE_URL + res.vehicleTypeImage
                  //   }
                  src={ApiEndPoints.USERAPP_IMAGE_URL+item.vechile_image}
                    style={{
                      width: 80,
                      height: 80,
                      margin: "0.5rem",
                      backgroundSize: "cover"
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1.7,
                    flexDirection: "column"
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      textAlign: "left"
                    }}
                  >
                  {item.vechile_name}
                  </Typography>
                  <Typography
                    style={{ fontSize: "0.7rem", textAlign: "left" }}
                  >
                    {item.vechile_description}
                  </Typography>
                  <Typography
                    style={{ fontSize: "0.7rem", textAlign: "left" }}>
                    ___
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 0.8,
                    justifyContent: "ceter",
                    alignItems: "center"
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "bolder",
                      color: "#40D590"
                    }}
                  >
                    {item.base_fare}
                  </Typography>
                </div>
              </Paper>
            ))}
              {detailsData.round.map((item,index) => (
                <Paper
                onClick={() =>this.scheduleRide(item)}
                style={{
                  // backgroundColor:
                  //   index == this.state.selectedCarIndex && "#D6E9FD",
                  margin: "0.5rem",
                  display: "flex",
                  flex: 3
                }}>
                <div style={{ display: "flex", flex: 0.6 }}>
                  <img
                  //   src={
                  //     ApiEndPoints.TAXI_IMAGE_URL + res.vehicleTypeImage
                  //   }
                  src={ApiEndPoints.USERAPP_IMAGE_URL+item.vechile_image}
                    style={{
                      width: 80,
                      height: 80,
                      margin: "0.5rem",
                      backgroundSize: "cover"
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1.7,
                    flexDirection: "column"
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      textAlign: "left"
                    }}
                  >
                  {item.vechile_name}
                  </Typography>
                  <Typography
                    style={{ fontSize: "0.7rem", textAlign: "left" }}
                  >
                    {item.vechile_description}
                  </Typography>
                  <Typography
                    style={{ fontSize: "0.7rem", textAlign: "left" }}>
                    ___
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 0.8,
                    justifyContent: "ceter",
                    alignItems: "center"
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: "bolder",
                      color: "#40D590"
                    }}
                  >
                    {item.base_fare}
                  </Typography>
                </div>
              </Paper>
            ))}
            </div>

        </Paper>
        </div>
      }
      </div>
    );
  }
}

OutstationJourneyCard.propTypes = {
    classes: PropTypes.object.isRequired
  };
  
export default withStyles(OutStationStyles)(OutstationJourneyCard);

import React from "react";
import PropTypes from "prop-types";
import {
    withStyles,
    Grid,
    TextField,
    Button,
    MenuItem,
    Select
} from "@material-ui/core";
import { DriverProfileStyles } from "../../../../styles";
import Typography from "@material-ui/core/Typography";
import CustomLoader from "../../../../custom/CustomLoader";
import DriverTextField from "../../common/DriverTextField";
// import EnterNewPass from "./EnterNewPass";



function TabContainer(props) {
    return (
        <Typography
            component="div"
            style={{
                padding: 8 * 3,
                textAlign: "left"
            }}
        >
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

class EditPhoneOtp extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        value: 0,
        checked: false,
        drivername: "",
        number: "",
        open: false,
        openList: false,
        changePassword: false,
        otp: "",
        mainOtpContent: true,
        isLoading: false,
        enterNewPass: false

    };

    closeEnterPass = () => {
        this.setState({
            mainOtpContent: false,
            enterNewPass: false

        });

    }

    handleSwitch = () => {
        this.setState(state => ({ checked: !state.checked }));
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleInputChange = name => event => {
        this.setState({
            [name]: event.target.value
        });
    };


    sendOtp = () => {
        console.log("**************OTP MATCH***********", this.props.OTP_ROW.otp)
        console.log("*******this.props.number**********", this.props.user_name)
        console.log("**************OTP state MATCH***********", this.state.otp)

        this.setState({ isLoading: true });
        if (this.state.otp == this.props.OTP_ROW.otp) {
            // alert("OTP MATCH");
            this.setState({
                // showSnackbar: true,
                enterNewPass: true,
                mainOtpContent: false,
                isLoading: false
            }, () => this.props.setPhoneNumber(this.props.user_name)
            );
        }
        else {
            this.props.openSnack("OTP does not match", "warning")
            this.setState({
                isLoading: false

            })

        }

    }

    fetchOTP(result) {
        this.setState({ otp: result });
    }


    render() {
        console.log("driver home render", this.props);
        const { classes } = this.props;
        const { value } = this.state;
        const { checked } = this.state;

        if (this.state.isLoading) {
            return <CustomLoader showLoader={this.state.isLoading} />
        }

        else {
            return (
                <div>
                    {this.state.mainOtpContent && (
                        <Grid item md={12} style={{}}>
                            <Grid container md={12} style={{
                                display: "flex",
                                flexDirection: "row",
                                marginTop: -10

                            }}>
                                {/* <IconButton
            onClick={() =>
              this.props.onBackPress()
            }
          >
            <Icon style={{ color: "#666" }}>
              arrow_back
              </Icon>
          </IconButton> */}
                                <Typography
                                    variant="subtitle1"
                                    style={{ fontWeight: "600", color: "#26699d", paddingTop: 10 }}
                                >
                                    Enter OTP
            </Typography>
                            </Grid>
                            <hr style={{ color: "#eaeaea" }} />
                            <Grid container md={12} style={{ padding: 10 }}>
                                <Grid item md={5} style={{ marginTop: 10 }}>
                                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                                        Enter OTP
       </Typography>
                                    <Typography variant="display4" style={{ fontSize: "15px" }}>
                                        Enter OTP sent to your phone number
         </Typography>
                                </Grid>
                                <Grid item md={5} style={{ padding: 5 }}>
                                    <div style={{ display: "flex", flexDirection: "row", marginTop: "-10%" }}>
                                        <DriverTextField
                                            style={{}}
                                            placeHolder="Enter OTP"
                                            // iconName="assignment_ind"
                                            callback={this.fetchOTP.bind(this)}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container md={12} style={{}}>
                                <Grid md={10} align="right" style={{ paddingRight: 0 }}>
                                    <Button variant="contained" style={{ width: "40%", fontWeight: "800", backgroundColor: "#4990E2", color: "#fff" }}
                                        onClick={() => this.sendOtp()}>
                                        SEND
                </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {/* // {alert(JSON.stringify(this.props.user_name))} */}

                    {/* {this.state.enterNewPass && (
                        <EnterNewPass
                            openSnack={this.props.openSnack}
                            handleModal={this.props.handleModal}
                            user_name={this.props.user_name}
                        // closeEnterPass={this.closeEnterPass}
                        // onBackPress={() =>
                        //   this.setState({
                        //     enterNewPass: !this.state.enterNewPass,
                        //     mainOtpContent: !this.state.mainContent
                        //   })
                        // }
                        />
                    )} */}

                </div>
            );
        }
    }
}

export default withStyles(DriverProfileStyles)(EditPhoneOtp);

import React from "react";
import {
    withStyles,
    AppBar,
    Toolbar,
    Typography,
    Button,
    Grid,
    Icon,
    Paper,
    ButtonBase,
    Input,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    TextField,
    CardActionArea,
} from "@material-ui/core";

import { DriverSignUpStyles } from '../../../styles';
import DriverTextField from '../common/DriverTextField';
import LinearTimeline from '../signUp/LinearTimeline';//'../common/TimelineComponent';
import CustomLoader from '../../../custom/CustomLoader';
import * as ApiManager from "../../../../managers/api/ApiManager"
import * as ApiEndPoints from "../../../../managers/api/ApiEndPoints"
import Radio from '@material-ui/core/Radio';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Checkbox from '@material-ui/core/Checkbox';
import CustomSnackbar from "../../../custom/CustomSnackbar";
import { validateEmail, validateFirstName, validateLastName, validatePassword, validatePhone } from '../../../../utils/validation';
import * as SessionManager from "../../../../managers/session/SessionManager";
import * as SessionKeys from "../../../../managers/session/SessionKeys";
import OtpVerification from "../../user/otpverification/OtpVerification";
import DialogForm from "../../../custom/DialogForm";
import FullScreenDialog from "../../../custom/FullScreenDialog";
import Dotp from "../otpverification/Dotp";
import DriverOtpVerification from "../otpverification/DriverOtpVerification"
import * as LanguageManager from '../../../../managers/language/LanguageManager';


class SignupFormComponent extends React.Component {

    constructor(props) {
        super(props);
        this.imageRef = React.createRef()

        this.state = {
            showBorder: null,
            driverImage: (props.imageUrl) ? props.imageUrl : null,
            isLoading: false,
            driverArea: '',
            driverSmokeAccept: true,
            driverSmokeType: 'smoker',
            driverGender: 'Male',
            selectedValue: 'm',
            setSelectedValue: 'm',
            maleSelected: true,
            femaleSelected: false,
            nonSmokerSelected: true,
            smokerSelected: false,
            age: '',
            comissionValue:'',
            labelWidth: 0,
            firstName: '',
            lastName: '',
            areaData: [],
            showSnackbar: false,
            snackbarVariant: "error",
            snakBarMessage: "",
            showSnackbar: false,
            showForm: false,
            showEmailForm: false,
            phoneNumber: "",
            DRIVER_CONFIG_ROWS: {},
            phonecode: null,
            wait: true,
            contentTitle: "",
            contentBody: "",
            contentStyle: 'paragraph',
            openDialog: false,
            emailMode: "",
            cmsn:'',
            address:"",
            postalCode:"",
            province:"",
            suburb:""
        };
    }

    componentDidMount() {

        ApiManager.callPostApi("SignupForm", "driver_getConfigs", ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_GETCONFIG, {

            device: 1,
            apk_version: 4.1

        }).then(res => {
            this.setState({
                isLoading: true,
                DRIVER_CONFIG_ROWS: res.data,
                phonecode: (res.data.countries.length) ? res.data.countries[0].phonecode : null,
                emailMode: res.data.register.driver_email_otp == true ? true : false,
                wait: false
            })
            if (res.result == 1) {
                this.setState({ isLoading: false })
                this.setState({ areaData: res.data.countries })
            } else {
                this.setState({ isLoading: false })
                alert("Add Vehicle Error: " + res.message)
            }
            //alert(JSON.stringify(res))
        })
        //this.getCountryCodes();

    }


    fetchDriverCmsData = (slugValue) => {
        this.setState({ isLoading: true });
    
        ApiManager.callPostApi(
          "LoginForm",
          "driver_cms_data_api",
          ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_CMSDATA,
          {
            slug: slugValue,
            country_id: 1
          }
        ).then(res => {
          console.log("*(*(*(*" + JSON.stringify(res))
          if (res.result === "1") {
            this.setState({
              contentBody: res.data.description,
              contentTitle: res.data.title,
              isLoading: false
            }, () => { this.setState({ openDialog: true }) })
          } else {
            this.setState({
              isLoading: false,
              showSnackbar: true,
              snakBarMessage: res.message,
              snackbarVariant: "error"
            })
          }
        });
      };

      getDocumentList = () => {

        this.setState({ isLoading: true })
    
        ApiManager.callPostApi("LoginForm", "driver_get_DocsList", ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_GET_DOCSLIST, {
          driver: 111,
          driver_vehicle: 100
    
        }).then(res => {
          console.log("driverDocsList./././" + JSON.stringify(res))
          this.setState({ isLoading: true })
    
          if (res.result === "1") {
    
            this.setState({
              contentBody: res.data,
              contentTitle: res.message,
              isLoading: false,
              contentStyle: "list"
            }, () => { this.setState({ openDialog: true }) })
    
          } else {
    
            this.setState({
              isLoading: false,
              showSnackbar: true,
              snakBarMessage: res.message,
              snackbarVariant: "error"
            })
    
          }
          //alert(JSON.stringify(res))
        })
      }



    getResponsePhone(result, areaIndex) {
        console.log("@@DD"+result+"__"+areaIndex)
        
        this.setState({ phonecode: result, phonecodeIndex: areaIndex })
    }

    getCountryCodes = () => {
        //  SessionManager.particularKey(SessionKeys.ACCESS_TOKEN);
        // SessionManager.clearSession();
        let sessiondata = JSON.parse(
            SessionManager.getSessionData(SessionKeys.DRIVER_CONFIG)
        );
        // return sessiondata.countries;
        this.setState({
            country_code: sessiondata.countries,
            phonecode: sessiondata.countries[0].phonecode
        });
        console.log("Seion Data========>", sessiondata.countries);

    }

    async handleChanges(event) {
        this.setState({ areaIndex: event.target.value })
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
        });
    }

    async handleCommissionChanges(event) {
        this.setState({ commissionValue: event.target.value })
        this.setState({
            ...this.state,
            [event.target.cmsn]: event.target.value,
        });
    }

    getDocumentList = () => {

        this.setState({ isLoading: true })

        ApiManager.callPostApi("LoginForm", "driver_get_DocsList", ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_GET_DOCSLIST, {
            driver: 111,
            driver_vehicle: 100

        }).then(res => {
            console.log("driverDocsList./././" + JSON.stringify(res))
            this.setState({ isLoading: true })

            if (res.result === "1") {

                this.setState({
                    contentBody: res.data,
                    contentTitle: res.message,
                    isLoading: false,
                    contentStyle: "list"
                }, () => { this.setState({ openDialog: true }) })

            } else {

                this.setState({
                    isLoading: false,
                    showSnackbar: true,
                    snakBarMessage: res.message,
                    snackbarVariant: "error"
                })

            }
            //alert(JSON.stringify(res))
        })
    }

    fetchDriverCmsData = (slugValue) => {
        this.setState({ isLoading: true });

        ApiManager.callPostApi(
            "Signup form",
            "driver_cms_data_api",
            ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_CMSDATA,
            {
                slug: slugValue,
                country_id: 1
            }
        ).then(res => {
            console.log("*(*(*(*" + JSON.stringify(res))
            if (res.result === "1") {
                this.setState({
                    contentBody: res.data.description,
                    contentTitle: res.data.title,
                    isLoading: false
                }, () => { this.setState({ openDialog: true }) })
            } else {
                this.setState({
                    showSnackbar: true,
                    snakBarMessage: res.message,
                    snackbarVariant: "error"
                })
            }
        });
    }

    getComboResult(idVal, areaCode, driverID) {
        let comboResult = {}
        comboResult["id"] = idVal + 1
        comboResult["areaCode"] = areaCode
        comboResult["driverID"] = driverID

        return (comboResult);
    }

    handleSignup = (idVal) => {
        this.setState({ isLoading: true })

        // let firstNameResult = validateFirstName(this.state.firstName)
        // if (firstNameResult.status == true) {
        //     let lastNameResult = validateLastName(this.state.lastName)
        //     if (lastNameResult.status == true) {
        //         let emailResult = validateEmail(this.state.driverEmail)
        //         if (emailResult.status == true) {
        //             if (this.state.phoneNumber) {
        //                 let passwordResult = validatePassword(this.state.driverPassword)
        //                 if (passwordResult.status == true) {
        //                     if (this.state.maleSelected || this.state.femaleSelected) {
        //                         if (this.state.smokerSelected || this.state.nonSmokerSelected) {
        //                             if (this.state.areaIndex) {
        ApiManager.callPostApi("SignupForm", "driver SignUp api", ApiEndPoints.APPORIO_TAXI_APIS.DRIVER_SIGNUP, {
            api_type: 1,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            name: this.state.firstName + " " + this.state.lastName,
            email: this.state.driverEmail,
            password: this.state.driverPassword,
            phone: this.state.phonecode?this.state.phonecode+this.state.phoneNumber:this.state.phoneNumber,
            area: this.state.areaIndex,//this.state.areaData[this.state.areaIndex-1].id,
            driver_gender: (this.state.maleSelected) ? 1 : 1,
            smoker_type: (this.state.smokerSelected) ? 1 : 1,
            accept_smoker_driver: (this.state.userSmoker) ? 'Yes' : 'No',
            image: this.state.driverImage,
            player_id: "40ae9548-9ddc-4ef0-b77f-84db6f4c4242",
            requested_from:'web',
            driver_commission_type:this.state.DRIVER_CONFIG_ROWS.register.driver_commission_choice?this.state.commissionValue:undefined,
            driver_additional_data:JSON.stringify(
           { 
            address_line_1 : this.state.address,
                province : this.state.province,
                subhurb : this.state.suburb,
                pincode :this.state.postalCode
           }
        )

        }).then(res => {
            this.setState({ isLoading: false })
            if (res.result == 1) {
                this.props.callBack(this.getComboResult(idVal, res.data.country_area_id, res.data.id))
            } else {
                this.setState({
                    showSnackbar: true,
                    snakBarMessage: "Signup Error:" + res.message,
                    isLoading: false,
                    snackbarVariant: "error"
                });
            }
        })
        //                             } else {
        //                                 this.setState({
        //                                     showSnackbar: true,
        //                                     snakBarMessage: "Please select area of service",
        //                                     isLoading: false,
        //                                     snackbarVariant: "warning"
        //                                 });
        //                             }
        //                         } else {
        //                             this.setState({
        //                                 showSnackbar: true,
        //                                 snakBarMessage: "Please select if you smoke or not",
        //                                 isLoading: false,
        //                                 snackbarVariant: "warning"
        //                             });
        //                         }

        //                     } else {
        //                         this.setState({
        //                             showSnackbar: true,
        //                             snakBarMessage: "Please select Gender",
        //                             isLoading: false,
        //                             snackbarVariant: "warning"
        //                         });
        //                     }
        //                 } else {
        //                     this.setState({
        //                         showSnackbar: true,
        //                         snakBarMessage: passwordResult.error,
        //                         isLoading: false,
        //                         snackbarVariant: "warning"
        //                     });
        //                 }
        //             } else {
        //                 this.setState({
        //                     showSnackbar: true,
        //                     snakBarMessage: "Please enter your phone no.",
        //                     isLoading: false,
        //                     snackbarVariant: "warning"
        //                 });
        //             }
        //         } else {
        //             this.setState({
        //                 showSnackbar: true,
        //                 snakBarMessage: emailResult.error,
        //                 isLoading: false,
        //                 snackbarVariant: "warning"
        //             });
        //         }
        //     }
        //     else {
        //         this.setState({
        //             showSnackbar: true,
        //             snakBarMessage: lastNameResult.error,
        //             isLoading: false,
        //             snackbarVariant: "warning"
        //         });
        //     }
        // } else {
        //     this.setState({
        //         showSnackbar: true,
        //         snakBarMessage: firstNameResult.error,
        //         isLoading: false,
        //         snackbarVariant: "warning"
        //     });
        // }

        // this.props.callBack(idVal+1)

    }


    // ---- open explorer ----
    openExplorer = () => {
        this.imageRef.current.click()
    }


    // --- handle upload ----
    handleUpload = (event) => {
        if (event.target.files && event.target.files[0]) {

            let reader = new FileReader
            reader.onload = (e) => {
                console.log("uhuhu>>>" + JSON.stringify(e.target.result))
                this.setState({
                    driverImage: e.target.result
                })
            }
            reader.readAsDataURL(event.target.files[0])
        }}

    getResponse(result) {
        if (result.result === "1") {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'success',
                snakBarMessage: result.message
            })
        } else {
            this.setState({
                showSnackbar: true,
                snackbarVariant: 'error',
                snakBarMessage: result.message
            })
        }}

    // onImageChange = (event) => {
    //     if (event.target.files && event.target.files[0]) {
    //       let reader = new FileReader();
    //       reader.onload = (e) => {
    //         this.setState({image: e.target.result});
    //       };
    //       reader.readAsDataURL(event.target.files[0]);
    //     }
    //   }

    // ---- handle date ----
    handleDate = (event) => {
        this.setState({
            date: event.target.value
        })
    }

    fetchFirstName(result) {
        this.setState({ firstName: result })
    }
    fetchLastName(result) {
        this.setState({ lastName: result })
    }
    fetchDriverEmail(result) {
        this.setState({ driverEmail: result })
    }
    // fetchDriverPhone(result) {
    //     this.setState({ driverPhone: result })
    // }
    fetchDriverPhone(result) {
        this.setState({ phoneNumber: result })
    }
    fetchdriverPassword(result) {
        this.setState({ driverPassword: result })
    }

    handleSnackBarClose = () => {
        this.setState({
            finalMessage: "",
            showSnackbar: false
        });
    };

    handlePhoneChange = async event => {
        let index = await this.state.country_code.findIndex(
            res => res.phonecode === event.target.value
        );
    }

    handleChange() {
        // setSelectedValue(event.target.value);
        //    this.setState({setSelectedValue:event.target.value, selectedValue:event.target.value})
        if (this.state.maleSelected) {
            this.setState({ femaleSelected: true, maleSelected: false })
        } else {
            this.setState({ femaleSelected: false, maleSelected: true })
        }
    }

    handleSmokerChange() {
        if (this.state.smokerSelected) {
            this.setState({ nonSmokerSelected: true, smokerSelected: false })
        } else {
            this.setState({ nonSmokerSelected: false, smokerSelected: true })
        }
    }


    fetchUsername(result) {
        if (result.length <= this.state.DRIVER_CONFIG_ROWS.countries[0].maxNumPhone) {
            console.log("1234567890",result);
        this.setState({ phoneNumber: result });
    }
}
fetchAddress(result) {
    this.setState({ address: result })
}
fetchProvince(result) {
    this.setState({ province: result })
}
fetchSuburb(result) {
    this.setState({ suburb: result })
}
fetchPostalCode(result) {
    this.setState({ postalCode: result })
}








    // ---- upload doc ----
    uploadDoc = () => {
        if (this.state.driverImage == null ||
            (Boolean(this.state.dateRequired) && this.state.date == "")
        ) {
            this.setState({
                snack: {
                    ...this.state.snack,
                    open: true,
                }
            })

            return
        }

        this.props.handleDocs(this.state.driverImage, this.state.date)
    }

    render() {
        // console.log("DRIVER_CONFIG_ROWS", this.state.DRIVER_CONFIG_ROWS);

        const { classes, callback, indexValue } = this.props;


        if (this.state.wait) {
            return <CustomLoader showLoader={this.state.wait} />
        }
        else {
            console.log("DRIVER_CONFIG_ROWS", this.state.DRIVER_CONFIG_ROWS.countries[0].maxNumPhone);

            return (

                <Grid xl container justify="flex-start" alignItems="center"  >
                
          <FullScreenDialog
            contentStyle={this.state.contentStyle}
            showDialog={this.state.openDialog}
            handleClose={() => (this.state.contentStyle == "list") ? this.setState({ openDialog: false, contentStyle: 'paragraph', contentBody: '' }) : this.setState({ openDialog: false })}
            contentTitle={this.state.contentTitle}
            contentBody={this.state.contentBody} />
                    <CustomLoader showLoader={this.state.isLoading} />
                    <CustomSnackbar
                        showSnackbar={this.state.showSnackbar}
                        variant={this.state.snackbarVariant}
                        message={this.state.snakBarMessage}
                        onClose={this.handleSnackBarClose} />

                    <FullScreenDialog
                        contentStyle={this.state.contentStyle}
                        showDialog={this.state.openDialog}
                        handleClose={() => (this.state.contentStyle == "list") ? this.setState({ openDialog: false, contentStyle: 'paragraph', contentBody: '' }) : this.setState({ openDialog: false })}
                        contentTitle={this.state.contentTitle}
                        contentBody={this.state.contentBody} />

                    <Grid item className={classes.avatarGrid}>
                        <ButtonBase accept="image/*" htmlFor="icon-button-file" id="icon-button-file" focusRipple onClick={() => this.openExplorer()} >
                            <Grid item className={classes.avatarContainer}>
                                {
                                    (Boolean(this.state.driverImage)) ?
                                        <img src={this.state.driverImage} className={classes.DriverProfileUpload}/>
                                        :
                                        <img src='https://image.flaticon.com/icons/svg/74/74472.svg' style={{ padding: 5 }} className={classes.avatarStyle}/>
                                }
                            </Grid>
                        </ButtonBase>
                        <input type="file" ref={this.imageRef} style={{ width: '0', height: '0', overflow: 'hidden', display: "none" }} onChange={(event) => this.handleUpload(event)}></input>
                        <Typography variant="body1" className={classes.avatarText}>
                        {LanguageManager.Strings.PHOTO_UPLOAD_LABEL}
                        </Typography>
                    </Grid>
                    <Grid container lg justify='center' className={classes.letterContainer}>
                        <Typography variant="h5" component="h3" className={classes.loginTitle} align="left">
                        {LanguageManager.Strings.DRIVER_SIGNUP_TITLE}
                        </Typography>
                        <DriverTextField maxLimit={20} placeHolder={LanguageManager.Strings.FNAME_HINT} iconName="assignment_ind" callback={this.fetchFirstName.bind(this)} />
                        <DriverTextField maxLimit={20} placeHolder={LanguageManager.Strings.LNAME_HINT} iconName="assignment_ind" callback={this.fetchLastName.bind(this)} />
                        {/* <DriverTextField placeHolder="username" iconName="assignment_ind" callback={this.fetchDrivrName.bind(this)}/> */}

                        {this.state.DRIVER_CONFIG_ROWS.register.email && this.state.DRIVER_CONFIG_ROWS.register.driver_email_otp ?
                            <CardActionArea
                                onClick={() => {
                                    this.setState({ showEmailForm: true });
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "5px 0"
                                }}>
                                <Grid container xl alignItems="center" className={classes.fieldContainer}>
                                    <Grid item>
                                        <Icon>email</Icon>
                                    </Grid>
                                    <input
                                        disabled={true}
                                        // maxLength={10}
                                        value={this.state.driverEmail}
                                        className={classes.textField}
                                        onChange={(evt) => this.props.callback(evt.target.value)}
                                        placeholder= {LanguageManager.Strings.EMAIL_HINT}
                                        // type={(secure)?this.state.secureMode:'none'}
                                        style={{ width: 'auto', borderBottom: '1px solid silver', width: '70%' }}
                                        type="email"/>
                                </Grid>
                            </CardActionArea>
                            :
                            <DriverTextField placeHolder= {LanguageManager.Strings.EMAIL_HINT} iconName="email" callback={this.fetchDriverEmail.bind(this)}/>
                        }
                        {this.state.DRIVER_CONFIG_ROWS.register.phone && this.state.DRIVER_CONFIG_ROWS.register.driver_phone_otp
                            ?
                            <CardActionArea
                                onClick={() => {
                                    this.setState({ showForm: true });
                                }}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    margin: "5px 0"
                                }}>
                                <Grid container xl alignItems="center" className={classes.fieldContainer}>
                                    <Grid item>
                                        <Icon>smartphone</Icon>
                                    </Grid>
                                    <input
                                        disabled={true}
                                        // maxLength={this.state.DRIVER_CONFIG_ROWS.countries[0].maxNumPhone}
                                        value={this.state.phoneNumber}
                                        className={classes.textField}
                                        onChange={(evt) => this.props.callback(evt.target.value)}
                                        placeholder= {LanguageManager.Strings.PHONE_HINT}
                                        // type={(secure)?this.state.secureMode:'none'}
                                        style={{ width: 'auto', borderBottom: '1px solid silver', width: '70%' }}
                                    />
                                </Grid>
                            </CardActionArea>
                            :
                            <DriverTextField
                                getCountryCode={this.getResponsePhone.bind(this)}
                                placeHolder= {LanguageManager.Strings.PHONE_HINT}
                                // dataSet={this.getCountryCodes()}
                                dataSet={this.state.DRIVER_CONFIG_ROWS.countries}
                                defaultCode={(this.state.phonecode !== null) ? this.state.phonecode : null}
                                phoneNumber={true}
                                numberInput={true}
                                callback={this.fetchUsername.bind(this)}
                                // maxLength={10}
                                type={"number"}
                                value={this.state.phoneNumber}
                                />
                            // <DriverTextField
                            //     getCountryCode={this.getResponse.bind(this)}
                            //     placeHolder="Mobile number"
                            //     dataSet={this.state.DRIVER_CONFIG_ROWS.countries}
                            //     defaultCode={(this.state.phonecode !== null) ? this.state.phonecode : null}
                            //     phoneNumber={true}
                            //     numberInput={true}
                            //     iconName="call"
                            //     callback={this.fetchDriverPhone.bind(this)} />

                            // <DriverTextField
                            //  maxLimit={13} 
                            //  placeHolder="phone"
                            //   iconName="call" 
                            //   callback={this.fetchDriverPhone.bind(this)} />
                        }
                        <DriverTextField placeHolder={LanguageManager.Strings.PASSWORD_HINT} iconName="https" type={"password"} secure={true} callback={this.fetchdriverPassword.bind(this)} />

                        {this.state.DRIVER_CONFIG_ROWS.register.gender &&

                            <Grid xl container justify="flex-start" alignItems="center" className={classes.genderContainer}>
                                <Typography variant="h6" className={classes.genderTitle}>
                                {LanguageManager.Strings.GENDER_LABEL}
                                </Typography>
                                <Radio
                                    style={{ marginLeft: '2%' }}
                                    checked={this.state.maleSelected}
                                    onChange={() => this.handleChange()}
                                    value="m"
                                    color="primary"
                                    name="radio-male"
                                    aria-label="M"
                                />
                                <Typography variant="body1" className={classes.genderTitle}>
                                {LanguageManager.Strings.MALE_LABEL}
                                </Typography>
                                <Radio
                                    checked={this.state.femaleSelected}
                                    onChange={() => this.handleChange()}
                                    value="f"
                                    color="primary"
                                    name="radio-female"
                                    aria-label="F"
                                />
                                <Typography variant="body1" className={classes.genderTitle}>
                                {LanguageManager.Strings.FEMALE_LABEL}
                                </Typography>
                            </Grid>
                        }

                        {this.state.DRIVER_CONFIG_ROWS.register.smoker &&
                            <Grid xl container justify="flex-start" alignItems="center" className={classes.genderContainer}>
                                <Typography variant="h6" className={classes.smokerTitle}>
                                {LanguageManager.Strings.SELF_SMOKER_LABEL}
                                 </Typography>

                                <Radio
                                    style={{ marginLeft: '2%' }}
                                    checked={this.state.smokerSelected}
                                    onChange={() => this.handleSmokerChange()}
                                    value="s"
                                    color="primary"
                                    name="radio-smoker"
                                    aria-label="S"
                                />
                                <Typography variant="body1" className={classes.smokerTitle}>
                                {LanguageManager.Strings.SMOKER_OPTION}
                                </Typography>
                                <Radio
                                    checked={this.state.nonSmokerSelected}
                                    onChange={() => this.handleSmokerChange()}
                                    value="n"
                                    color="primary"
                                    name="radio-noSmoker"
                                    aria-label="NS"
                                />
                                <Typography variant="body1" className={classes.smokerTitle}>
                                {LanguageManager.Strings.NO_SMOKER_OPTION}
                                </Typography>
                            </Grid>
                        }
                        {this.state.DRIVER_CONFIG_ROWS.register.smoker &&
                            <Grid xl container justify="flex-start" alignItems="center" className={classes.genderContainer}>
                                <Checkbox
                                    style={{ marginLeft: "-2%" }}
                                    checked={this.state.userSmoker}
                                    onChange={() => this.setState({ userSmoker: !this.state.userSmoker })}
                                    value="userSmoker"
                                    color="primary" />
                                <Typography variant="body1" className={classes.genderTitle}>
                                {LanguageManager.Strings.DRIVER_SMOKER_QUESTN}
                                </Typography>
                            </Grid>
                        }

                        {this.state.phonecode &&
                            <Grid xl container justify="flex-start" alignItems="center">
                                <Typography variant="body1" className={classes.genderTitle}>
                                {LanguageManager.Strings.AREA_LABEL}
                                </Typography>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="age-simple" className={classes.areaItem}></InputLabel>

                                    <Select
                                        value={this.state.age}
                                        displayEmpty
                                        onChange={(evt) => this.handleChanges(evt)}
                                        inputProps={{
                                            name: 'age',
                                            id: 'age-simple',
                                        }}>

                                        {(this.state.phonecodeIndex > -1) ?
                                            this.state.areaData[this.state.phonecodeIndex].country_area.map(row => (
                                                <MenuItem value={row.id} className={classes.areaItem}>{row.AreaName}</MenuItem>
                                            )) : this.state.areaData.length > 0 && this.state.areaData[0].country_area.map(row => (
                                                <MenuItem value={row.id} className={classes.areaItem}>{row.AreaName}</MenuItem>
                                            ))}
                                            
                                    </Select>
                                </FormControl>
                            </Grid>
                        }

                        {this.state.DRIVER_CONFIG_ROWS.register.driver_commission_choice &&
                            <Grid xl container justify="flex-start" alignItems="center">
                                <Typography variant="body1" className={classes.genderTitle}>
                                    {LanguageManager.Strings.COMMISSION_LABEL}
                                </Typography>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="commission_select" className={classes.areaItem}></InputLabel>

                                    <Select
                                        value={this.state.commissionValue}
                                        displayEmpty
                                        onChange={(evt) => this.handleCommissionChanges(evt)}
                                        inputProps={{
                                            name: 'commission',
                                            id: 'commission_select',
                                        }}>
                                        {
                                             this.state.DRIVER_CONFIG_ROWS.driver_commission_choices.map(row => (
                                                <MenuItem value={row.id} className={classes.areaItem}>{row.lang_data}</MenuItem>
                                            ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                        }

{this.state.DRIVER_CONFIG_ROWS.general_config.driver_address && 
                        <Grid xl container justify="flex-start" alignItems="center">
                        <DriverTextField maxLimit={20} placeHolder={LanguageManager.Strings.DRIVER_ADDRESS}  callback={this.fetchAddress.bind(this)} />
                        <DriverTextField maxLimit={20} placeHolder={LanguageManager.Strings.SUBURB} callback={this.fetchSuburb.bind(this)} />
                        <DriverTextField maxLimit={20} placeHolder={LanguageManager.Strings.PROVINCE}  callback={this.fetchProvince.bind(this)} />
                        <DriverTextField maxLimit={20} type="number" placeHolder={LanguageManager.Strings.POSTAL_CODE}  callback={this.fetchPostalCode.bind(this)} />
</Grid>
} 


                        <Button variant="contained" fullWidth disableTouchRipple={true} disableFocusRipple={true} className={classes.SignupButton} onClick={() => this.handleSignup(indexValue)}>
                        {LanguageManager.Strings.CREATE_ACC_LABEL}
                        </Button>

                        <Grid container spacing={8} justify="space-between" className={classes.linkContainer} style={{ borderTop: '1px solid silver' }}>
                            <Grid item>
                                {/* <Button color="primary" className={classes.formLink} onClick={() => this.getDocumentList()}>
                                {LanguageManager.Strings.VIEW_DOCS_LABEL}
                                </Button> */}
                            </Grid>
                            <Grid item>
                                <Button color="primary" className={classes.formLink} onClick={() => this.fetchDriverCmsData("terms_and_conditions")}>
                                {LanguageManager.Strings.TERMSCONDITION_LABEL}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <DialogForm
                        className={classes.OtpSignupDialog}
                        visible={this.state.showForm}
                        onOutsideTouch={() => {
                            this.setState({ showForm: !this.state.showForm });
                        }}>
                        <Dotp
                            countrySet={this.state.areaData}
                            otpCallback={this.getResponse.bind(this)}
                            otpType={1}
                            getOtp={(phoneNumber, country_id, phonecode) =>
                                this.setState({
                                    phoneNumber: phoneNumber,
                                    showForm: !this.state.showForm,
                                    countryId: country_id,
                                    phonecode : phonecode,
                                    phonecodeIndex: this.state.areaData.findIndex(row => row.phonecode == phonecode)
                                }, () => console.log("FFFFF" + this.state.phonecodeIndex + "**" + phonecode))
                            } />
                    </DialogForm>

                    <DialogForm
                        className={classes.OtpSignupDialog}
                        visible={this.state.showEmailForm}
                        onOutsideTouch={() => {
                            this.setState({ showEmailForm: !this.state.showEmailForm });
                        }}>
                        <DriverOtpVerification
                            DRIVER_CONFIG_ROWS={this.state.DRIVER_CONFIG_ROWS}
                            emailMode={this.state.emailMode}
                            // emailMode={true}
                            countrySet={this.state.areaData}
                            otpCallback={this.getResponse.bind(this)}
                            getOtp={(driverEmail) =>
                                this.setState({
                                    driverEmail: driverEmail,
                                    showEmailForm: !this.state.showEmailForm,
                                    // countryId: country_id,
                                    // phonecodeIndex: this.state.areaData.findIndex(row => row.phonecode == phonecode)
                                }
                                    // , () => console.log("FFFFF" + this.state.phonecodeIndex + "**" + phonecode)
                                )
                            } />
                    </DialogForm>
                </Grid>


            );
        }
    }
}

export default withStyles(DriverSignUpStyles)(SignupFormComponent);
